import { Box, Skeleton, Typography } from '@mui/material';
import * as hookz from '@react-hookz/web';
import _ from 'lodash';
import React from 'react';

import { useWorkspace } from '@stargate/features/workspaces';

import useDirectoryTree from '../../hooks/use-directory-tree';
import type { DirectoryTreeFilter } from '../../types';
import {
  ActionButton,
  DirectoryCreateAction,
  DocumentAction,
} from '../Actions';
import { DirectoryTreeView } from '../DirectoryTreeView';
import { DocumentDirectoryExplorerFilters } from './DirectoryTreeExplorerFilters';
import { DocumentDirectoryExplorerHeader } from './DirectoryTreeExplorerHeader';

/*
|==========================================================================
| DocumentDirectoryExplorer
|==========================================================================
|
| A component that displays the directory structure of a workspace and allows
| the user to navigate to documents.
|
*/

export interface DirectoryTreeExplorerProps {
  maxHeight?: number;
  width: number;
}

export const DirectoryTreeExplorer: React.FC<DirectoryTreeExplorerProps> = (
  props
) => {
  const directoryTree = useDirectoryTree();
  const workspace = useWorkspace();
  const [showFilters, setShowFilters] = React.useState(false);
  const [filterMeasurements, filterRef] = hookz.useMeasure();
  const [headerMeasurements, headerRef] = hookz.useMeasure();

  /*
  |------------------
  | Computed
  |------------------
  */

  // Account for filter height, when visible
  const maxHeight = React.useMemo(() => {
    if (!props.maxHeight) return undefined;
    const baseBottom = 36;

    if (showFilters && filterMeasurements && headerMeasurements) {
      return (
        props.maxHeight -
        filterMeasurements.height -
        headerMeasurements.height -
        baseBottom
      );
    }
    if (headerMeasurements) {
      return props.maxHeight - headerMeasurements.height - baseBottom;
    }
    return props.maxHeight - baseBottom;
  }, [props.maxHeight, showFilters, headerMeasurements, filterMeasurements]);

  /*
  |------------------
  | Callbacks: Filters
  |------------------
  */

  const handleChangeFilter = React.useCallback(
    (filter: DirectoryTreeFilter) => {
      directoryTree.onFilter(filter);
    },
    [directoryTree]
  );

  const handleToggleFilters = React.useCallback(() => {
    setShowFilters((prev) => !prev);
  }, []);

  /*
  |------------------
  | Callbacks: Nodes
  |------------------
  */

  const handleCollapseNodes = React.useCallback(() => {
    directoryTree.onCollapseNodes(directoryTree.expandedNodes);
  }, [directoryTree]);

  /*
  |------------------
  | Effects
  |------------------
  */

  // Load on mount and when workspace changes
  const previousWorkspaceId = hookz.usePrevious(workspace.data?.id);
  React.useEffect(() => {
    if (
      workspace.data?.id !== previousWorkspaceId &&
      !_.isNil(workspace.data?.id)
    ) {
      void directoryTree.onLoad();
    }
  }, [workspace.data?.id, previousWorkspaceId, directoryTree]);

  /*
  |------------------
  | Render
  |------------------
  */

  if (
    !directoryTree.workspace ||
    (directoryTree.loading && !directoryTree.tree)
  ) {
    return (
      <DirectoryTreeExplorerSkeleton
        maxHeight={maxHeight}
        width={props.width}
      />
    );
  }

  return (
    <Box
      sx={{
        width: props.width,
      }}
    >
      <Box ref={headerRef}>
        <DocumentDirectoryExplorerHeader
          workspace={directoryTree.workspace}
          width={props.width}
          actionLeft={
            <React.Fragment>
              <DirectoryCreateAction />
              <DocumentAction />
            </React.Fragment>
          }
          actionRight={
            <React.Fragment>
              <ActionButton
                hint='Filter directories and documents'
                icon='filter'
                onClick={handleToggleFilters}
              />
              <ActionButton
                hint='Collapse all directories'
                icon='square-rounded-minus'
                onClick={handleCollapseNodes}
                disabled={directoryTree.expandedNodes.length === 0}
              />
            </React.Fragment>
          }
        />
      </Box>
      <Box
        ref={filterRef}
        sx={{
          display: showFilters ? 'block' : 'none',
        }}
      >
        <DocumentDirectoryExplorerFilters onFilterChange={handleChangeFilter} />
      </Box>
      <Box
        sx={{
          maxHeight,
          width: props.width,
          overflowY: 'auto',
          '&::-webkit-scrollbar': {
            width: '8px',
          },
        }}
      >
        <DirectoryTreeView maxWidth={props.width} />
        {!directoryTree.tree?.length && (
          <Typography
            variant='body2'
            sx={{
              mt: 2,
              textAlign: 'center',
              px: 1,
            }}
          >
            No directories or documents found.
            <br />
            You can adjust the filters to show more.
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default DirectoryTreeExplorer;

/*
|------------------
| Utils
|------------------
*/

const DirectoryTreeExplorerSkeleton: React.FC<{
  maxHeight?: number;
  width: number;
}> = ({ maxHeight, width }) => {
  return (
    <Box
      sx={{
        mt: 2,
        textAlign: 'center',
        ml: 'auto',
        mr: 'auto',
        maxHeight,
        width,
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
          width: '8px',
        },
      }}
    >
      <Skeleton
        variant='rectangular'
        sx={{ height: 36, borderRadius: 1, mb: 2 }}
      />
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((_, index) => (
        <Skeleton
          // biome-ignore lint/suspicious/noArrayIndexKey: this is a skeleton...
          key={index}
          variant='rectangular'
          sx={{
            height: 24,
            borderRadius: 1,
            mt: 1,
          }}
        />
      ))}
    </Box>
  );
};
