import {
  Box,
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import React from 'react';
import TablerIconShare from '~icons/tabler/share';
import TablerIconX from '~icons/tabler/x';

import { DialogTitle } from '@/components/dialog';
import { ComingSoonChip } from '@stargate/components/Chips';
import { useNightingale } from '@stargate/lib/nightingale';

import { JoggrDocShareLink } from './JoggrDocShareLink';

export interface JoggrDocShareDialogProps {
  /**
   * The URL of the JoggrDoc to share.
   */
  shareUrl: string;

  /**
   * Whether the dialog is open.
   */
  open: boolean;

  /**
   * Whether to show the reward message.
   */
  showReward?: boolean;

  /**
   * Callback that triggers when the dialog close action is clicked.
   */
  onClose: () => void;
}

/**
 * Component for sharing a JoggrDoc.
 */
export const JoggrDocShareDialog: React.FC<JoggrDocShareDialogProps> = ({
  shareUrl,
  showReward = false,
  open,
  onClose,
}) => {
  const nightingale = useNightingale();
  const theme = useTheme();

  /*
  |------------------
  | Computed
  |------------------
  */

  const rewardTitle = React.useMemo(() => {
    if (!showReward) return '';
    return Math.floor(Math.random() * 10) % 2 === 0
      ? 'You did an amazing job, on this doc!'
      : 'Great job, this doc is ready to share!';
  }, [showReward]);

  /*
  |------------------
  | Callbacks
  |------------------
  */

  const handleCopy = React.useCallback(() => {
    onClose();
    void nightingale.capture(['click', 'share'], {
      documentId: shareUrl,
      type: 'copy-clipboard',
    });
  }, [onClose, nightingale, shareUrl]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={false}
      maxWidth='md'
      PaperProps={{
        sx: {
          // We set high, the content will drive the width
          maxWidth: '1000px',
          position: 'relative',
        },
      }}
    >
      <IconButton
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: theme.spacing(1),
          top: theme.spacing(1),
        }}
      >
        <TablerIconX />
      </IconButton>
      <DialogTitle
        title={
          <Stack direction='row' alignItems='center' spacing={1}>
            {showReward ? (
              <React.Fragment>
                <Typography
                  variant='h3'
                  component='p'
                  color='primary'
                  gutterBottom
                >
                  🎉 {rewardTitle}
                </Typography>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <TablerIconShare height={24} width={24} />
                <Box>Share Document</Box>
              </React.Fragment>
            )}
            <Box sx={{ flexGrow: 1 }} />
          </Stack>
        }
      />
      <DialogContent>
        {showReward && (
          <Typography variant='body1' component='p' gutterBottom>
            Share this document with your team to get feedback and collaborate,
            you can use the link below to share it.
          </Typography>
        )}
        <JoggrDocShareLink shareUrl={shareUrl} onCopy={handleCopy} />
        <Box sx={{ height: '8px' }} />
        <Tooltip title='Notifies you when this document has been viewed.'>
          <FormControlLabel
            disabled
            control={<Checkbox />}
            label={
              <Stack direction='row' alignItems='center' spacing={1}>
                <Box>Track Views</Box>
                <ComingSoonChip />
              </Stack>
            }
          />
        </Tooltip>
      </DialogContent>
    </Dialog>
  );
};
