import { RikerIcon } from '@joggrdocs/riker-icons';
import { Box, Button, Stack, Tooltip, Typography } from '@mui/material';
import React from 'react';

import { useApp } from '@stargate/app';
import { ButtonLink } from '@stargate/components/Buttons';
import { DialogDeleteConfirmation } from '@stargate/components/dialogs';
import { useDirectoryTree } from '@stargate/features/directories';
import { useJDocDrafts } from '@stargate/features/docs';
import {
  useGitHubOrganizations,
  useGitHubRepositories,
} from '@stargate/features/github';
import { useUser } from '@stargate/features/user';
import { useWorkspace } from '@stargate/features/workspaces';
import { useNotify } from '@stargate/lib/notify';
import { useStorage } from '@stargate/lib/storage';

import SettingsActionCard from '../SettingsActionCard';
import SettingsActionCards from '../SettingsActionCards';
import SettingsSectionHeader from '../SettingsSectionHeader';

const Configuration: React.FC = () => {
  const localStorage = useStorage();
  const authenticatedUser = useUser();
  const githubRepos = useGitHubRepositories();
  const githubOrgs = useGitHubOrganizations();
  const workspace = useWorkspace();
  const directory = useDirectoryTree();
  const app = useApp();
  const notify = useNotify();
  const drafts = useJDocDrafts();
  const [showConfirmation, setShowConfirmation] = React.useState(false);

  const draftCount = React.useMemo(() => {
    return drafts.data?.length ?? 0;
  }, [drafts.data]);

  /*
  |------------------
  | Callbacks
  |------------------
  */

  async function clearCore(): Promise<void> {
    app.setLoading(true);
    await authenticatedUser.load({
      clearCache: true,
    });
    await githubOrgs.load({
      clearCache: true,
    });
    await githubRepos.load({
      clearCache: true,
    });
    await workspace.load();
    await directory.onLoad();
    notify.send({
      message: 'Cleared user data cache',
      severity: 'success',
    });
    app.setLoading(false);
  }

  async function clearAll(): Promise<void> {
    app.setLoading(true);
    localStorage.clear();
    await authenticatedUser.load({
      clearCache: true,
    });
    await githubRepos.load({
      clearCache: true,
    });
    await githubOrgs.load({
      clearCache: true,
    });
    await workspace.load();
    notify.send({
      message: 'Cleared all cached data',
      severity: 'success',
    });
    window.location.reload();
  }

  return (
    <Box>
      <SettingsSectionHeader
        title='Configuration'
        description='Choose how Joggr works for you and your team.'
      />
      <SettingsActionCards>
        <SettingsActionCard
          title='Document Tags'
          icon={<RikerIcon icon='tags' />}
          content='Tags are used to categorize and organize your documents.'
          actions={
            <ButtonLink
              startIcon={<RikerIcon icon='tags' />}
              to='app.tags.list'
            >
              Configure Tags
            </ButtonLink>
          }
          item
        />
        <SettingsActionCard
          title='Cache'
          icon={<RikerIcon icon='database' />}
          content='Manage your cache settings, including clearing the cache.'
          actions={
            <React.Fragment>
              <Tooltip title='Clear the server-side cache for your user data, such as GitHub Repositories, GitHub Organizations, Workspaces and other related data'>
                <Button
                  startIcon={<RikerIcon icon='server' />}
                  onClick={clearCore}
                >
                  Clear User Data
                </Button>
              </Tooltip>
              <Tooltip title='Clear all cached data, including any uncommitted changes to existing JoggrDocs or JoggrDocs you are in the process of creating'>
                <Button
                  startIcon={<RikerIcon icon='trash' />}
                  color='error'
                  variant='outlined'
                  onClick={() => {
                    setShowConfirmation(true);
                  }}
                >
                  Clear All Data
                </Button>
              </Tooltip>
            </React.Fragment>
          }
          item
        />
        <DialogDeleteConfirmation
          open={showConfirmation}
          title='Clear Cache'
          subTitle='Confirm you want to clear your cache'
          question={
            <Box>
              <Typography variant='body1' gutterBottom>
                Are you sure you want to clear your entire cache?
              </Typography>
              <Typography variant='body1'>
                This will delete all cached data, including any uncommitted
                changes to <strong>existing JoggrDocs</strong> or{' '}
                <strong>
                  JoggrDocs drafts
                  {draftCount > 0 ? (
                    <React.Fragment>
                      {' '}
                      <span className='text-error-light'>({draftCount})</span>
                    </React.Fragment>
                  ) : null}
                </strong>{' '}
                that have not been saved yet.
              </Typography>
            </Box>
          }
          confirmation='CLEAR'
          onDelete={clearAll}
          onClose={() => {
            setShowConfirmation(false);
          }}
        />
      </SettingsActionCards>
    </Box>
  );
};

export default Configuration;
