import _ from 'lodash';
import type * as TypeFest from 'type-fest';

/**
 * The namespace for joggr classes.
 * @example `Joggr-Button-root`
 */
export const cssNamespace = 'Joggr';

/**
 * The type for the joggr class name namespace.
 * @example `Joggr-Button-root`
 */
export type CSSNamespace = typeof cssNamespace;

/**
 * Class name for joggr theme.
 */
export type ClassName<
  Namespace extends string,
  Class extends string,
> = `${Namespace}-${Class}`;

/**
 * The classes for a component.
 */
export type ComponentClasses<
  ComponentName extends Capitalize<string>,
  Classes extends ReadonlyArray<string>,
  Namespace extends string = CSSNamespace,
> = TypeFest.Simplify<
  {
    [Class in Classes[number]]: TypeFest.Simplify<
      ClassName<Namespace, `${ComponentName}-${Class}`>
    >;
  } & { root: ClassName<Namespace, `${ComponentName}-root`> }
>;

/**
 * Base util for creating classes.
 *
 * @param modifier A modifier for the class.
 * @returns A class for Stargate, in the format of `Joggr-something`.
 */
export function createClassname<
  Namespace extends string,
  Modifier extends string,
>(namespace: Namespace, modifier: Modifier): ClassName<Namespace, Modifier> {
  return `${namespace}-${modifier}`;
}

/**
 * Create a single class for a component.
 *
 * @example
 * ```ts
 * const buttonClass = createComponentClassname('Button', 'root');
 *
 * // buttonClass = 'Joggr-Button-root'
 * ```
 *
 * @param componentName A component name.
 * @param modifier A modifier for the component.
 * @returns A class for the component, in the format of `Joggr-Button-root`.
 */
export function createComponentClassname<
  ComponentName extends Capitalize<string>,
  Modifier extends string = 'root',
  Namespace extends string = CSSNamespace,
>(
  componentName: ComponentName,
  modifier: Modifier = 'root' as Modifier,
  namespace: Namespace = cssNamespace as Namespace
): ClassName<Namespace, `${ComponentName}-${Modifier}`> {
  return createClassname<Namespace, `${ComponentName}-${Modifier}`>(
    namespace,
    `${componentName}-${modifier}`
  );
}

/**
 * Generate classes for a component.
 *
 * @example
 * ```ts
 * const buttonClasses = createComponentClasses('Button', ['root', 'label'] as const);
 *
 * // buttonClasses = {
 * //   root: 'Joggr-Button-root',
 * //   label: 'Joggr-Button-label',
 * // }
 * ```
 *
 * @param componentName A component name.
 * @param classNames The class names for the component.
 * @returns A map of classes for the component, in the format of `Joggr-Button-root`.
 */
export function createComponentClasses<
  ComponentName extends Capitalize<string>,
  Classes extends ReadonlyArray<string>,
  Namespace extends string = CSSNamespace,
>(
  componentName: ComponentName,
  classNames?: Classes,
  namespace?: Namespace
): ComponentClasses<ComponentName, Classes, Namespace> {
  if (classNames && classNames.length === 0) {
    throw new Error(
      `classNames for component, ${componentName}, must not be empty`
    );
  }

  return _.fromPairs(
    _.uniq(['root', ...(classNames || [])]).map((className) => {
      return [
        className,
        createComponentClassname(
          componentName,
          className,
          namespace ?? (cssNamespace as Namespace)
        ),
      ];
    })
  ) as ComponentClasses<ComponentName, Classes, Namespace>;
}

/**
 * Clone component classes, an overwrite the `Mui` classes to be `Joggr` classes.
 *
 * @example
 * ```ts
 * import { dialogActionsClasses } from '@mui/material';
 * import { cloneMuiComponentClasses } from '@stargate/theme';
 *
 * const clonedClasses = cloneMuiComponentClasses(dialogActionsClasses);
 *
 * // typeof clonedClasses = {
 * //   root: string, <= we cannot infer the type of the values
 * //   spacing: string,
 * // }
 * ```
 *
 * @param classes The classes to clone.
 * @returns A clone of the classes.
 */
export function cloneMuiComponentClasses<
  Classes extends Readonly<{ [Key in keyof Classes]: string }>,
>(classes: Classes): TypeFest.Simplify<Record<keyof Classes, string>> {
  return _.mapValues(classes, replaceMuiClassname);
}

/*
|------------------
| Internals
|------------------
*/

/**
 * Replace the `Mui` class name with the `Joggr` class name.
 *
 * @example
 * ```ts
 * const className = 'MuiButton-root';
 * const replacedClassName = replaceMuiClassname(className);
 *
 * // replacedClassName = 'Joggr-Button-root'
 * ```
 *
 * @param className The class name to replace.
 * @returns The replaced class name.
 */
function replaceMuiClassname(className: string): string {
  return className.replace('Mui', 'Joggr-');
}
