import { CssBaseline, ThemeProvider } from '@mui/material';
import React from 'react';
import { match } from 'ts-pattern';

import { useUserSettings } from '@/features/user';
import { createTheme } from '../theme';

const darkTheme = createTheme('dark');
const lightTheme = createTheme('light');

export interface ThemeProps {
  children: React.ReactNode;
}

/**
 * Wrapper that handles the theme for the application.
 */
export const StargateThemeProvider: React.FC<ThemeProps> = ({ children }) => {
  const userSettings = useUserSettings();
  const theme = match(userSettings.data.theme)
    .with('dark', () => darkTheme)
    .with('light', () => lightTheme)
    .otherwise(() => darkTheme);

  // We need to update the data-color-scheme attribute on the document element
  // to ensure that the correct theme is applied to the Dashdraft editor.
  React.useEffect(() => {
    document.documentElement.setAttribute(
      'data-color-scheme',
      theme.palette.mode
    );
  }, [theme.palette.mode]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export default StargateThemeProvider;
