import { RikerIcon, type RikerIconName } from '@joggrdocs/riker-icons';
import { Button, Tooltip } from '@mui/material';
import React from 'react';

export interface PlaceholderButtonProps {
  icon: RikerIconName;
  hint: string;
  disabled?: boolean;
  onClick: () => void;
}

/**
 * A button for the Placeholder.
 */
export const PlaceholderButton: React.FC<PlaceholderButtonProps> = (props) => {
  const anchorEl = React.useRef<HTMLButtonElement>(null);

  /**
   * This prevents the event from bubbling up to the parent, and changing
   * the focus/selection for ProseMirror/TipTap.
   */
  const handlePreventFocusChange = React.useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
    },
    []
  );

  return (
    <Tooltip
      title={props.hint}
      placement='top'
      enterDelay={500}
      enterNextDelay={500}
      PopperProps={{
        disablePortal: true,
      }}
    >
      <Button
        contentEditable={false}
        className='dashdraft-placeholder-button'
        tabIndex={-1}
        ref={anchorEl}
        onMouseDown={handlePreventFocusChange}
        onClick={props.onClick}
        disabled={props.disabled}
        size='small'
        color='inherit'
        variant='text'
        sx={{
          minWidth: 'unset',
          px: 0.25,
          py: 0.5,
        }}
      >
        <RikerIcon icon={props.icon} size={24} stroke={1.5} />
      </Button>
    </Tooltip>
  );
};
PlaceholderButton.displayName = 'PlaceholderButton';
