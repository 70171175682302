import { produce } from 'immer';
import type * as TypeFest from 'type-fest';
import { create } from 'zustand';

import type { PaletteMode } from '@mui/material';

import { LocalStorage } from '@stargate/lib/storage';

/*
|==========================================================================
| useUserSettings
|==========================================================================
|
| A global store, used to manage the API data for User Settings.
|
| @todo Integrate with the API, currently this is purely a client-side store, backed by LocalStorage.
|
*/

/**
 * Get the default theme mode, based on the system's preference or the user's preference.
 *
 * @returns A default theme mode.
 */
export const getDefaultTheme = (): PaletteMode => {
  const cachedMode = LocalStorage.get<PaletteMode>(
    'settings::appearance::mode'
  );
  if (cachedMode) {
    return cachedMode;
  }
  const isSystemDarkMode = window.matchMedia?.(
    '(prefers-color-scheme: dark)'
  ).matches;
  return isSystemDarkMode ? 'dark' : 'light';
};

/*
|----------------------------------
| Store
|----------------------------------
|
| We are using Zustand to create a global store, which will be used to manage the API data for User Settings. 
|
*/

interface State {
  /**
   * The User Settings data is currently loading from the API.
   */
  loading: boolean;

  /**
   * The User Settings data
   */
  data: {
    theme?: PaletteMode;
    editorStyle: 'markdown' | 'wysiwyg';
  };
}

interface Actions {
  /**
   * Update the User Settings data 'editorStyle'
   */
  onSetEditorStyle: (editorStyle: 'markdown' | 'wysiwyg') => void;

  /**
   * Update the User Settings data 'theme'
   *
   * @param theme A theme mode
   */
  onSetTheme: (theme: PaletteMode) => void;
}

export type UseAuthenticatedUserSettingsStore = TypeFest.Simplify<
  State & Actions
>;

const useStore = create<UseAuthenticatedUserSettingsStore>((set) => ({
  /*
  |------------------
  | State
  |------------------
  */

  data: {
    theme: getDefaultTheme(),
    editorStyle:
      LocalStorage.get('settings::appearance::editor-style') ?? 'wysiwyg',
  },
  loading: false,

  /*
  |------------------
  | Actions
  |------------------
  */

  onSetEditorStyle: (editorStyle: 'markdown' | 'wysiwyg') => {
    set(
      produce((state) => {
        state.data.editorStyle = editorStyle;
      })
    );

    LocalStorage.set('settings::appearance::editor-style', editorStyle);
  },

  onSetTheme: (theme: PaletteMode) => {
    set(
      produce((state) => {
        state.data.theme = theme;
      })
    );

    LocalStorage.set('settings::appearance::mode', theme);
  },
}));

export interface UseUserSettings {
  /**
   * The state of the store.
   */
  loading: boolean;

  /**
   * The data of the store.
   */
  data: State['data'];

  /**
   * Update the User Settings data 'editorStyle'
   */
  onUpdate: (data: Partial<State['data']>) => Promise<void>;

  /**
   * Doesn't do anything, as the store is purely client-side.
   */
  onLoad: () => Promise<State['data']>;
}

/**
 * Hook for managing the API data for User Settings
 *
 * @returns A tuple containing the state and actions of the store.
 */
export const useUserSettings = (): UseUserSettings => {
  const store = useStore((store) => store);

  const onUpdate = async (data: Partial<State['data']>) => {
    if (data.theme) {
      store.onSetTheme(data.theme);
    }

    if (data.editorStyle) {
      store.onSetEditorStyle(data.editorStyle);
    }
  };

  return {
    loading: store.loading,
    data: store.data,
    onUpdate,
    onLoad: async () => store.data,
  };
};
