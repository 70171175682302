import { Box, Tooltip } from '@mui/material';
import _ from 'lodash';
import React from 'react';

import { FormattedMessage } from '@stargate/localization';
import { createComponentClasses } from '@stargate/theme';

import type { JDocComponentProps } from '@/features/docs/types';
import type { JDocModifiableField } from '@stargate/features/docs/lib/fields';
import { JoggrDocDraftChip } from './JoggrDocDraftChip';

export const joggrDocDraftDetailsClasses = createComponentClasses(
  'JoggrDocLocalDraftDetails',
  ['root', 'tooltip', 'chip'] as const
);

export type JoggrDocDraftDetailsProps = JDocComponentProps<{
  /**
   * The modified fields of the JoggrDoc.
   */
  modifiedFields: JDocModifiableField[];
}>;

export const JoggrDocDraftDetails: React.FC<JoggrDocDraftDetailsProps> = ({
  mode,
  modifiedFields,
}) => {
  if (mode === 'create' || (mode === 'edit' && modifiedFields.length > 0)) {
    return (
      <Box className={joggrDocDraftDetailsClasses.root} component='span'>
        <Tooltip
          className={joggrDocDraftDetailsClasses.tooltip}
          title={
            <FormattedMessage id={`features.docs.${mode}.drafts.description`} />
          }
        >
          <JoggrDocDraftChip
            className={joggrDocDraftDetailsClasses.chip}
            mode={mode}
          />
        </Tooltip>
      </Box>
    );
  }
};
