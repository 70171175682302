import {
  Alert,
  AlertTitle,
  Box,
  Button,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import React from 'react';
import TablerIconX from '~icons/tabler/x';

import type {
  JDocCommit,
  JDocComponentProps,
  JDocMode,
} from '@/features/docs/types';
import type {
  JDocModifiableField,
  JDocRequiredField,
} from '@stargate/features/docs/lib/fields';
import { GitHubCommitButton } from '@stargate/features/github';
import { useCoalesce, useHotkeys } from '@stargate/hooks';
import { useNotify } from '@stargate/lib/notify';
import { useLocalization } from '@stargate/localization';

import { JoggrDocSaveDialog } from './JoggrDocSaveDialog';
import {
  JoggrDocSaveForm,
  type JoggrDocSaveFormBranchType,
} from './JoggrDocSaveForm';

export type JoggrDocSaveProps = JDocComponentProps<{
  /**
   * The modified fields in the document
   */
  modifiedFields: JDocModifiableField[];

  /**
   * Missing fields in the document
   */
  missingFields: JDocRequiredField[];

  /**
   * The callback to handle the save
   */
  onSave: (commit: JDocCommit) => Promise<void>;
}>;

export const JoggrDocSave: React.FC<JoggrDocSaveProps> = ({
  doc,
  draft,
  mode,
  modifiedFields,
  missingFields,
  onSave,
}) => {
  const notify = useNotify();
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string | null>(null);
  const messageRecommendations = useGitCommitMessageRecommendations(mode);

  // If the document has a base (parent) JoggrDoc, then we are in a branch and
  // should only allow commits to the current branch.
  const disableBranchSelectReason = React.useMemo<
    'pull-request' | 'none'
  >(() => {
    if (!_.isNil(doc?.github?.pullRequest?.number)) {
      return 'pull-request';
    }

    return 'none';
  }, [doc]);

  const githubRepository = useCoalesce(draft?.doc?.github?.repository);

  const filePath = useCoalesce(
    draft?.doc?.github?.filePath,
    doc?.github?.filePath
  );

  /*
  |------------------
  | State
  |------------------
  */

  const [_commitBranch, setCommitBranch] = React.useState<string>('');
  const commitBranch = React.useMemo(() => {
    // If has a PR, we can ONLY commit to the branch of the PR
    if (disableBranchSelectReason === 'pull-request') {
      return doc?.github?.branch ?? '';
    }

    return _commitBranch;
  }, [_commitBranch, disableBranchSelectReason, doc?.github?.branch]);
  const [commitBranchType, setCommitBranchType] =
    React.useState<JoggrDocSaveFormBranchType>('default');
  const [commitMessage, setCommitMessage] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false);
  const [skipCI, setSkipCI] = React.useState<boolean>(true);

  /*
  |------------------
  | Computed
  |------------------
  */

  /**
   * We don't want to show the save dialog if the document is not modified or
   * we are missing required fields.
   */
  const disableSaveDialog = React.useMemo<boolean>(() => {
    return modifiedFields.length === 0 || missingFields.length > 0;
  }, [modifiedFields, missingFields]);

  /**
   * If the user has made changes that are not saved
   */
  const dangerousCancel = React.useMemo<boolean>(() => {
    return _.some([commitMessage.length > 0, commitBranch.length > 0]);
  }, [commitMessage, commitBranch]);

  /**
   * If the user has made changes that are not saved and ready to commit
   */
  const readyToCommit = React.useMemo<boolean>(() => {
    return _.every([
      commitMessage.length > 0,
      commitBranch.length > 0,
      !loading,
      !disableSaveDialog,
    ]);
  }, [commitBranch, commitMessage, disableSaveDialog, loading]);

  /*
  |------------------
  | Callbacks
  |------------------
  */

  const openDialog = React.useCallback(() => {
    if (!disableSaveDialog) {
      setDialogOpen(true);
    }
  }, [disableSaveDialog]);

  const closeDialog = React.useCallback(() => {
    setDialogOpen(false);
    setError(null);
  }, []);

  const clear = React.useCallback(() => {
    setCommitBranch('');
    setCommitBranchType('default');
    setCommitMessage('');
  }, []);

  const save = React.useCallback(async () => {
    try {
      setError(null);
      setLoading(true);

      await onSave({
        branch: commitBranch,
        message: appendSkipCi(commitMessage, skipCI),
        pullRequest: getPullRequestStateFromBranchType(commitBranchType),
        sha: doc?.github?.sha,
        baseDocId: ['pr-open', 'pr-draft', 'pr-existing'].includes(
          commitBranchType
        )
          ? doc?.id
          : undefined,
      });

      setLoading(false);
    } catch (e) {
      const errMessage =
        e instanceof Error ? e.message : 'An unknown error occurred';
      setLoading(false);
      setError(errMessage);
    }
  }, [onSave, commitBranch, commitMessage, commitBranchType, doc, skipCI]);

  /*
  |------------------
  | Hotkeys
  |------------------
  */

  useHotkeys(
    'mod+s',
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (!disableSaveDialog) {
        openDialog();
      } else {
        notify.send({
          title: 'Unable to commit',
          message:
            "Sorry, you cannot commit this document until you've made edits to it & you've filled out all required fields.",
          severity: 'warning',
        });
      }
    },
    {
      enableOnFormTags: true,
      enableOnContentEditable: true,
    },
    [disableSaveDialog, openDialog]
  );

  return (
    <React.Fragment>
      <SaveWarningTooltip
        modified={modifiedFields.length > 0}
        missingFields={missingFields}
      >
        <GitHubCommitButton disabled={disableSaveDialog} onClick={openDialog} />
      </SaveWarningTooltip>
      {githubRepository && filePath && (
        <JoggrDocSaveDialog
          open={dialogOpen || loading}
          filePath={filePath}
          githubRepository={githubRepository}
          onClose={closeDialog}
          content={
            <React.Fragment>
              {error && (
                <AlertError
                  error={error}
                  onClear={() => {
                    setError(null);
                  }}
                />
              )}
              {/* We SHOULD NEVER hit this... */}
              {_.some([filePath, githubRepository], (x) => _.isNil(x)) && (
                <AlertMissingFileLocation />
              )}
              <JoggrDocSaveForm
                gitHubRepository={githubRepository}
                loading={loading}
                disableBranchSelect={disableBranchSelectReason !== 'none'}
                mode={mode}
                skipCi={skipCI}
                branch={commitBranch}
                branchType={commitBranchType}
                message={commitMessage}
                messageRecommendations={messageRecommendations}
                onChangeBranch={(branch) => {
                  setCommitBranch(branch);
                }}
                onChangeBranchType={(branchType) => {
                  setCommitBranchType(branchType);
                }}
                onChangeMessage={(message) => {
                  setCommitMessage(message);
                }}
                onChangeSkipCI={(skipCI) => {
                  setSkipCI(skipCI);
                }}
              />
            </React.Fragment>
          }
          actions={
            <React.Fragment>
              <GitHubCommitButton
                variant='contained'
                size='large'
                loading={loading}
                disabled={!readyToCommit}
                onClick={save}
              >
                Commit to GitHub
              </GitHubCommitButton>
              <Tooltip
                title={
                  dangerousCancel
                    ? 'You have unsaved changes, are you sure you want to cancel?'
                    : undefined
                }
                placement='bottom'
                arrow
              >
                <span>
                  <Button
                    variant='outlined'
                    size='large'
                    startIcon={<TablerIconX />}
                    color={dangerousCancel ? 'error' : undefined}
                    disabled={loading}
                    onClick={() => {
                      clear();
                      closeDialog();
                    }}
                  >
                    Cancel
                  </Button>
                </span>
              </Tooltip>
            </React.Fragment>
          }
        />
      )}
    </React.Fragment>
  );
};

/*
|------------------
| Utils
|------------------
*/

/**
 * Append the skip CI tag to a commit message.
 *
 * @param message A commit message.
 * @param skipCi A boolean indicating whether to skip CI.
 * @returns A commit message with the skip CI tag appended.
 */
const appendSkipCi = (message: string, skipCi: boolean): string => {
  return skipCi ? `${message} [skip ci]` : message;
};

/**
 * Remove the skip CI tag from a commit message.
 *
 * @param message A commit message.
 * @returns A commit message without the skip CI tag.
 */
const removeSkipCi = (message: string): string => {
  return message.replace('[skip ci]', '');
};

const useGitCommitMessageRecommendations = (mode: JDocMode) => {
  return React.useMemo(() => {
    if (mode === 'create') {
      return [
        /** @see https://www.conventionalcommits.org/en/v1.0.0/ */
        {
          id: 'conventional-commits',
          message: 'docs: Created JoggrDoc',
          priority: 1,
        },
        /** @see https://github.com/sparkles-home/sparkles */
        {
          id: 'sparkles',
          message: '📚 docs: Created JoggrDoc',
          priority: 2,
        },
        { id: 'basic', message: 'Created JoggrDoc', priority: 3 },
      ];
    }

    return [
      /** @see https://www.conventionalcommits.org/en/v1.0.0/ */
      {
        id: 'conventional-commits',
        message: 'docs: Updated JoggrDoc',
        priority: 1,
      },
      /** @see https://github.com/sparkles-home/sparkles */
      {
        id: 'sparkles',
        message: '📚 docs: Updated JoggrDoc',
        priority: 2,
      },
      { id: 'basic', message: 'Updated JoggrDoc', priority: 3 },
    ];
  }, [mode]);
};

/**
 * Get the pull request state from a branch type.
 *
 * @param branchType A branch type
 * @returns A pull request state
 */
const getPullRequestStateFromBranchType = (
  branchType: JoggrDocSaveFormBranchType
): JDocCommit['pullRequest'] => {
  switch (branchType) {
    case 'pr-draft':
      return 'draft';
    case 'pr-open':
      return 'open';
    case 'default':
    case 'pr-existing':
      return 'none';
  }
};

const AlertError: React.FC<{
  error: Error | string;
  onClear?: () => void;
}> = ({ error, onClear }) => {
  return (
    <Alert
      severity='error'
      sx={{
        mb: 1,
      }}
      action={
        <IconButton
          aria-label='close'
          color='inherit'
          size='small'
          onClick={onClear}
        >
          <TablerIconX />
        </IconButton>
      }
    >
      <AlertTitle>Error</AlertTitle>
      <Typography variant='body2'>
        There was an error (see below) saving the JoggrDoc, please try again.
      </Typography>
      <Box
        component='code'
        sx={{
          whiteSpace: 'pre-wrap',
          mt: 2,
          fontSize: '0.875rem',
        }}
      >
        {error instanceof Error ? error.message : error}
      </Box>
    </Alert>
  );
};

/**
 * An alert to inform the user that they are missing the file location.
 */
const AlertMissingFileLocation: React.FC = () => {
  return (
    <Alert
      severity='warning'
      sx={{
        mb: 1,
      }}
    >
      <AlertTitle>Warning</AlertTitle>
      <Typography variant='body2'>
        You are <strong>missing</strong> the File Location, aka the directory in
        GitHub where you will commit this document. Please exit this screen and
        click the File Location button in the top left corner of the editor
        (look for the GitHub icon).
      </Typography>
    </Alert>
  );
};

const SaveWarningTooltip: React.FC<
  React.PropsWithChildren<{
    /**
     * If the document has been modified
     */
    modified: boolean;

    /**
     * Fields that are missing
     */
    missingFields: JDocRequiredField[];
  }>
> = ({ modified, missingFields, children }) => {
  const localz = useLocalization();

  const title = React.useMemo<string | null>(() => {
    if (!modified) {
      return localz.formatMessage('features.docs.save.modified.none.tooltip');
    }

    if (missingFields.length > 0) {
      return localz.formatMessage('features.docs.save.error.missing', {
        missingData: missingFields
          .map((field) => {
            return localz.formatMessage(
              `features.docs.save.error.missing.${field}`
            );
          })
          .join(', '),
      });
    }

    return null;
  }, [localz.formatMessage, missingFields, modified]);

  return (
    <Tooltip title={title} placement='bottom' arrow>
      <span>{children}</span>
    </Tooltip>
  );
};
