import { Chip, type ChipProps } from '@mui/material';
import _ from 'lodash';
import React from 'react';

import { FormattedMessage } from '@stargate/localization';
import { createComponentClasses } from '@stargate/theme';

import type { JDocMode } from '../../types';

export const joggrDocDraftChipClasses = createComponentClasses(
  'JoggrDocDraftChip',
  ['root'] as const
);

export interface JoggrDocDraftChipProps
  extends Omit<ChipProps, 'label' | 'color' | 'warning'> {
  /**
   * The JoggrDoc mode.
   */
  mode: JDocMode;
}

/**
 * A chip to display for a JoggrDoc local draft.
 */
export const JoggrDocDraftChip = React.forwardRef<
  HTMLDivElement,
  JoggrDocDraftChipProps
>(({ mode, ...props }, ref) => {
  if (mode === 'view') {
    return null;
  }

  return (
    <Chip
      {...props}
      classes={{
        ...props.classes,
        root: joggrDocDraftChipClasses.root,
      }}
      ref={ref}
      label={
        <FormattedMessage id={`features.docs.${mode}.drafts.chip.label`} />
      }
      color='secondary'
      variant='outlined'
    />
  );
});
JoggrDocDraftChip.displayName = 'JoggrDocDraftChip';
JoggrDocDraftChip.muiName = 'Chip';
