import { githubDarkInit, githubLightInit } from '@uiw/codemirror-theme-github';
import React from 'react';

import { useTheme } from '@mui/material';

/**
 * Get the theme based which auto-updates based on dark/light mode.
 *
 * @returns the CodeMirror settings for the current theme
 */
export const useCodeMirrorTheme = () => {
  const theme = useTheme();

  // biome-ignore lint/correctness/useExhaustiveDependencies: We only care about the theme.palette.mode
  return React.useMemo(() => {
    if (theme.palette.mode === 'light') {
      return githubLightInit({
        settings: {
          gutterBackground: theme.palette.codeEditor.background,
          background: theme.palette.codeEditor.background,
          lineHighlight: theme.palette.codeEditor.textSelection,
          selection: theme.palette.codeEditor.textSelection,
          selectionMatch: theme.palette.codeEditor.textSelection,
          gutterBorder: 'transparent',
        },
      });
    }
    return githubDarkInit({
      settings: {
        gutterBackground: theme.palette.codeEditor.background,
        background: theme.palette.codeEditor.background,
        lineHighlight: theme.palette.codeEditor.textSelection,
        selection: theme.palette.codeEditor.textSelection,
        selectionMatch: theme.palette.codeEditor.textSelection,
        gutterBorder: 'transparent',
      },
    });
  }, [theme.palette.mode]);
};
