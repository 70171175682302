import { RikerIcon } from '@joggrdocs/riker-icons';
import { Button, Chip, Divider, Tooltip } from '@mui/material';
import React from 'react';

import { type ActionItem, Actions } from '@stargate/components/Actions';
import type { CodeMirrorLang } from '@stargate/lib/codemirror';
import { createComponentClasses } from '@stargate/theme';

import { CodeBlockCardHeader } from '@dashdraft/components/CodeBlockCards';

import type { CodeSnippetAPIResponse } from '../../types';
import { CodeLanguageSelect } from '../shared/CodeLanguageSelect';
import { CodeSnippetStatus } from './CodeSnippetStatus';

export const codeSnippetHeaderClasses = createComponentClasses(
  'CodeSnippetHeader',
  ['root'] as const
);

export type CodeSnippetAction = 'copy' | 'delete';

export interface CodeSnippetHeaderProps {
  /**
   * Language of the code snippet.
   */
  language: CodeMirrorLang;

  /**
   * The code snippet.
   */
  codeSnippet: CodeSnippetAPIResponse | null;

  /**
   * Is the code snippet readonly?
   */
  readonly: boolean;

  /**
   * Callback for when the view code button is clicked.
   */
  onViewCodeClick: () => void;

  /**
   * Callback for when the view code button is clicked.
   *
   * @param lang the language to change to.
   */
  onChangeLanguage: (lang: CodeMirrorLang) => void;

  /**
   * Callback for when an action is clicked.
   *
   * @param action The action to perform.
   */
  onActionClick: (action: CodeSnippetAction) => void;
}

export const CodeSnippetHeader = React.memo<CodeSnippetHeaderProps>(
  ({
    readonly,
    language,
    codeSnippet,
    onChangeLanguage,
    onActionClick,
    onViewCodeClick,
  }) => {
    const actions = React.useMemo<ActionItem<CodeSnippetAction>[]>(() => {
      if (readonly || codeSnippet?.access !== 'full') {
        return [
          {
            type: 'button',
            action: 'copy',
            primaryLabel: 'Copy',
            icon: 'copy',
          },
        ];
      }
      return [
        {
          type: 'button',
          action: 'copy',
          primaryLabel: 'Copy',
          icon: 'copy',
        },
        { type: 'divider' },
        {
          type: 'button',
          action: 'delete',
          primaryLabel: 'Delete',
          icon: 'trash',
        },
      ];
    }, [readonly, codeSnippet?.access]);

    return (
      <CodeBlockCardHeader
        leftAction={
          <React.Fragment>
            <CodeSnippetStatus />
            <Divider orientation='vertical' flexItem />
            <CodeLanguageSelect
              disabled={readonly || codeSnippet?.access !== 'full'}
              language={language}
              onChangeLanguage={onChangeLanguage}
            />
          </React.Fragment>
        }
        rightAction={
          <React.Fragment>
            {codeSnippet?.id && codeSnippet?.access === 'full' && (
              <Button
                variant='contained'
                size='small'
                startIcon={<RikerIcon icon='code-circle-2-filled' />}
                onClick={onViewCodeClick}
              >
                View Source
              </Button>
            )}
            {codeSnippet?.access !== 'full' && !readonly && (
              <Tooltip
                arrow
                title='You cannot make edits to this Code Snippet, as you do not have access to the GitHub Repository or GitHub Organization, where it is located'
                placement='top'
              >
                <Chip
                  color='warning'
                  label='Readonly due to permissions'
                  variant='outlined'
                />
              </Tooltip>
            )}
            <Actions<CodeSnippetAction>
              size='small'
              actions={actions}
              onAction={onActionClick}
            />
          </React.Fragment>
        }
      />
    );
  }
);
