import {
  type ChipProps,
  CircularProgress,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import * as hookz from '@react-hookz/web';
import _ from 'lodash';
import React from 'react';
import TablerIconEdit from '~icons/tabler/edit';

import api from '@stargate/api';
import * as hooks from '@stargate/hooks';
import { useNotify } from '@stargate/lib/notify';

import { useDevLogger } from '@stargate/logger';
import type { Tag } from '../types';
import { TagChip } from './TagChip';
import { TagSaveDialog } from './TagSaveDialog';
import { TagSelect, type TagSelectProps } from './TagSelect';

export interface TagsSelectorProps
  extends Pick<TagSelectProps, 'defaultSelected' | 'disabled'> {
  /**
   * The label for the Tag Selector
   *
   * @default 'Tags'
   */
  label?: React.ReactNode;

  /**
   * If the Tags are readOnly
   */
  readonly?: boolean;

  /**
   * The size of the TagChips
   */
  size?: 'small' | 'medium';

  /**
   * The repository owner id
   */
  repositoryOwnerId?: string;

  /**
   * Callback when the tags change
   *
   * @param tags The new tags
   * @returns A promise that resolves when the tags have been updated
   */
  onChange: (tags: Tag[]) => Promise<void>;
}

/**
 * Tags component that allows the user to select tags from a list or view
 * tags that have already been selected.
 */
export const Tags: React.FC<TagsSelectorProps> = ({
  onChange,
  readonly = true,
  defaultSelected = [],
  label = 'Tags',
  ...props
}) => {
  const notify = useNotify();
  const devLogger = useDevLogger();

  /*
  |------------------
  | HTTP API
  |------------------
  */
  const [listTagsState, listTagsActions] = api.useRequestClient('GET /tags');
  const [createTagsState, createTagsActions] =
    api.useRequestClient('POST /tags');

  /*
  |------------------
  | State
  |------------------
  */

  const [saveDialogOpen, setSaveDialogOpen] = React.useState(false);
  const [tags, setTags] = React.useState<Tag[]>(defaultSelected);
  const [editing, setEditing] = React.useState(!readonly);
  const [saving, setSaving, setDelayedSaving] = hooks.useDelayedState(false);

  /*
  |------------------
  | State Management
  |------------------
  */

  const handleCloseDialog = React.useCallback(() => {
    setSaveDialogOpen(false);
  }, []);

  const handleOpenDialog = React.useCallback(() => {
    if (!readonly) {
      setSaveDialogOpen(true);
    }
  }, [readonly]);

  const handleEditingTruthy = React.useCallback(() => {
    if (!readonly) {
      setEditing(true);
    }
  }, [readonly]);

  /*
  |------------------
  | Handlers
  |------------------
  */

  const handleCreateTag = React.useCallback(
    (tag: Tag) => {
      if (props.repositoryOwnerId) {
        void createTagsActions
          .execute({
            body: {
              repositoryOwnerId: props.repositoryOwnerId,
              name: tag.name,
              color: tag.color,
              description: tag.description,
            },
          })
          .then(async (result) => {
            await listTagsActions.execute({
              querystring: {
                repositoryOwnerId: props.repositoryOwnerId,
              },
            });
            setSaveDialogOpen(false);
          });
      } else {
        notify.error('Unable to create tag, repository not set');
      }
    },
    [props.repositoryOwnerId, createTagsActions, notify, listTagsActions]
  );

  const handleTagsChange = React.useCallback(
    (tags: Tag[]) => {
      if (onChange) {
        setSaving(true);
        setTags(tags);
        onChange(tags)
          .catch((e) => {
            devLogger.error(e);
            notify.error('Unable to update tags');
          })
          .finally(() => {
            setDelayedSaving(false, 300);
          });
      }
    },
    [onChange, notify, setDelayedSaving, setSaving]
  );

  const handleLoadTags = React.useCallback(async () => {
    if (props.repositoryOwnerId) {
      const tags = await listTagsActions.execute({
        querystring: {
          repositoryOwnerId: props.repositoryOwnerId,
        },
      });
      const defaultTags = _.filter(tags, (tag) => {
        return _.some(defaultSelected, (defaultTag) => {
          return defaultTag.id === tag.id;
        });
      });
      if (defaultTags.length > 0) {
        setTags(defaultTags);
      }
    }
  }, [listTagsActions, defaultSelected, props.repositoryOwnerId]);

  /*
  |------------------
  | Effects
  |------------------
  */

  hookz.useMountEffect(() => {
    handleLoadTags();
  });

  // biome-ignore lint/correctness/useExhaustiveDependencies: Only run on changes to the repositoryOwnerId
  React.useEffect(() => {
    if (props.repositoryOwnerId) {
      handleLoadTags();
    }
  }, [props.repositoryOwnerId]);

  hookz.useDeepCompareEffect(() => {
    if (listTagsState.result) {
      const tagsUpdate = _.filter(listTagsState.result, (tag) => {
        return _.some(defaultSelected, (defaultTag) => {
          return defaultTag.id === tag.id;
        });
      });
      setTags(tagsUpdate);
    }
  }, [defaultSelected, listTagsState.result]);

  if (
    listTagsState.status === 'loading' ||
    listTagsState.status === 'not-executed'
  ) {
    return (
      <Stack justifyContent='center' alignItems='center'>
        <CircularProgress size={24} />
      </Stack>
    );
  }

  return (
    <React.Fragment>
      <TagSaveDialog
        open={saveDialogOpen}
        saving={createTagsState.status === 'loading'}
        onSave={handleCreateTag}
        onClose={handleCloseDialog}
      />
      <Stack
        direction='row'
        alignItems='center'
        spacing={0.5}
        onClick={editing ? undefined : handleEditingTruthy}
        sx={{
          cursor: editing ? undefined : readonly ? 'default' : 'pointer',
        }}
      >
        {props.disabled === true && (
          <Tooltip
            title="You are unable to edit tags on versions of a document that are not the 'latest' version. To edit tags,
              view the 'latest' version of the document and edit tags there."
          >
            <TagChips
              tags={tags}
              chipProps={{
                size: props.size ?? 'small',
                clickable: false,
              }}
            />
          </Tooltip>
        )}

        {!editing && !saving && props.disabled !== true && (
          <React.Fragment>
            <TagChips
              tags={tags}
              chipProps={{
                size: props.size ?? 'small',
                clickable: !readonly,
              }}
            />
            {tags.length === 0 && (
              <Typography variant='body2' color='textSecondary'>
                No tags
              </Typography>
            )}
            {!readonly && (
              <IconButton
                size='small'
                onClick={handleEditingTruthy}
                disabled={readonly}
              >
                <TablerIconEdit height={20} width={20} />
              </IconButton>
            )}
          </React.Fragment>
        )}

        {(editing || saving) && props.disabled !== true && !readonly && (
          <TagSelect
            options={listTagsState.result ?? []}
            defaultSelected={tags}
            label={label}
            onChange={handleTagsChange}
            onCreateAction={handleOpenDialog}
            disabled={!props.repositoryOwnerId || readonly}
            size={props.size ?? 'small'}
            sx={{
              minWidth: '160px',
              width: '100%',
            }}
          />
        )}
      </Stack>
    </React.Fragment>
  );
};

/*
|------------------
| Utils & Components
|------------------
*/

const TagChips: React.FC<{
  tags: Tag[];
  chipProps?: Partial<ChipProps>;
}> = (props) => {
  return (
    <Stack direction='row' spacing={0.5}>
      {props.tags.map((tag) => {
        if (tag.description) {
          return (
            <Tooltip key={tag.id} title={tag.description}>
              <TagChip {...props.chipProps} tag={tag} />
            </Tooltip>
          );
        }
        return <TagChip key={tag.id} {...props.chipProps} tag={tag} />;
      })}
    </Stack>
  );
};
