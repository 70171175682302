import { RikerIcon } from '@joggrdocs/riker-icons';
import {
  Box,
  Card,
  CardContent,
  Checkbox,
  Collapse,
  FormControlLabel,
  FormGroup,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import _ from 'lodash';
import React from 'react';

import { IconAvatar } from '@stargate/components/Avatars';
import { LinearProgressWithLabel } from '@stargate/components/Progress';
import { MarkdownPreview } from '@stargate/components/Utils';
import { createComponentClasses } from '@stargate/theme';

import { useJDocLayout } from '../../hooks/use-jdoc-layout';
import type { JDocTemplate } from '../../types';

export const joggrDocTemplateDirectionsClasses = createComponentClasses(
  'DocumentTemplateDirections',
  ['root', 'content', 'header'] as const
);

export interface JoggrDocTemplateDirectionsProps {
  /**
   * The Joggr Doc Template to display directions for.
   */
  template?: JDocTemplate | null;
}

/**
 * Provide directions for a JoggrDocTemplate.
 */
export const JoggrDocTemplateDirections: React.FC<
  JoggrDocTemplateDirectionsProps
> = ({ template }) => {
  const theme = useTheme();
  const [docLayoutState] = useJDocLayout();
  const [completedSteps, setCompletedSteps] = React.useState<number>(0);
  const [collapse, setCollapse] = React.useState<boolean>(false);

  /*
  |------------------
  | Handlers
  |------------------
  */

  const handleToggleCollapse = (): void => {
    setCollapse((prevCollapse) => !prevCollapse);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCompletedSteps((prevCompletedSteps) => {
      return event.target.checked
        ? prevCompletedSteps + 1
        : prevCompletedSteps - 1;
    });
  };

  /*
  |------------------
  | Effects
  |------------------
  */

  if (_.isNil(template?.directions)) {
    return null;
  }

  return (
    <Card
      classes={joggrDocTemplateDirectionsClasses.root}
      sx={{
        borderColor: theme.palette.divider,
        mx: `${docLayoutState.contentPadding}px`,
        mb: 3,
      }}
    >
      <Stack
        classes={{
          root: joggrDocTemplateDirectionsClasses.header,
        }}
        direction={'row'}
        spacing={1}
        alignItems={'center'}
        sx={{
          py: 1,
          px: 2,
        }}
      >
        <IconAvatar icon='directions' size={24} />
        <Typography variant='h4'>How to Use this Template</Typography>
        <Box sx={{ flexGrow: 1 }} />
        <IconButton onClick={handleToggleCollapse}>
          <RikerIcon icon={collapse ? 'chevron-down' : 'chevron-up'} />
        </IconButton>
      </Stack>
      <Collapse in={!collapse}>
        <CardContent
          classes={{
            root: joggrDocTemplateDirectionsClasses.content,
          }}
          sx={{
            '&:last-child': {
              p: 2,
            },
          }}
        >
          <Typography gutterBottom>
            <MarkdownPreview markdown={template.directions.content} />
          </Typography>
          <Box
            sx={{
              mb: 2,
            }}
          />
          <Typography variant='h5' gutterBottom>
            Step-by-Step Guide
          </Typography>
          <FormGroup
            sx={{
              pl: 0.5,
            }}
          >
            {template.directions.steps.map((step) => (
              <FormControlLabel
                key={step}
                control={
                  <Checkbox
                    size='small'
                    sx={{
                      p: '6px',
                    }}
                    onChange={handleChange}
                  />
                }
                label={<MarkdownPreview markdown={step} />}
              />
            ))}
          </FormGroup>
          <LinearProgressWithLabel
            percent={Math.round(
              (completedSteps / template.directions.steps.length) * 100
            )}
          />
          <Typography variant='caption'>
            These directions will not be saved (committed) to the document.
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
};
