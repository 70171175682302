import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material';
import React from 'react';
import TablerIconCodeCircle2 from '~icons/tabler/code-circle-2';
import TablerIconDragDrop from '~icons/tabler/drag-drop';

/*
|==========================================================================
| SirenaModeSelect
|==========================================================================
|
| Used to select the "mode" of the Sirena editor.
|
*/

/*
|------------------
| Types
|------------------
*/

export type Mode = 'default' | 'drag-n-drop';

export interface ModeOption {
  text: string;
  icon: React.ReactNode;
  value: Mode;
  comingSoon?: boolean;
}

/*
|------------------
| Config
|------------------
*/

const options: ModeOption[] = [
  {
    value: 'default',
    text: 'Split Editor',
    icon: <TablerIconCodeCircle2 />,
  },
  {
    value: 'drag-n-drop',
    text: 'Drag and Drop',
    icon: <TablerIconDragDrop />,
    comingSoon: true,
  },
];

/*
|------------------
| Internal Components
|------------------
*/

const ModeOptionContent: React.FC<{
  text: string;
  icon: React.ReactNode;
}> = (props) => {
  return (
    <React.Fragment>
      <ListItemIcon>{props.icon}</ListItemIcon>
      <ListItemText>{props.text}</ListItemText>
    </React.Fragment>
  );
};

/*
|------------------
| Public API
|------------------
*/

export interface Props {
  onChange?: (mode: Mode) => void;
  size?: 'small' | 'medium';
  defaultMode?: Mode;
}

export const SirenaModeSelect: React.FC<Props> = ({ onChange, ...props }) => {
  /*
  |------------------
  | Callbacks
  |------------------
  */

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!onChange) {
        return;
      }
      onChange(event.target.value as Mode);
    },
    [onChange]
  );

  const renderValue = React.useCallback((value: unknown): React.ReactNode => {
    const option = options.find((opt) => opt.value === value);

    if (!option) {
      throw new Error(`Invalid mode: ${value}`);
    }

    return (
      <Stack direction='row' spacing={0} alignItems='center'>
        <ModeOptionContent {...option} />
      </Stack>
    );
  }, []);

  return (
    <TextField
      select
      size={props.size ?? 'medium'}
      label='Edit Mode'
      defaultValue={props.defaultMode || 'default'}
      onChange={handleChange}
      SelectProps={{
        renderValue,
      }}
      sx={{
        minWidth: '224px',
      }}
    >
      {options.map((option) => (
        <MenuItem
          key={option.value}
          value={option.value}
          disabled={!!option.comingSoon}
        >
          <ModeOptionContent text={option.text} icon={option.icon} />
        </MenuItem>
      ))}
    </TextField>
  );
};

const WrappedSirenaModeSelect = React.memo(SirenaModeSelect);
WrappedSirenaModeSelect.displayName = 'SirenaModeSelect';

export default WrappedSirenaModeSelect;
