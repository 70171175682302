import { RikerIcon } from '@joggrdocs/riker-icons';
import {
  Box,
  Button,
  Chip,
  Link,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import _ from 'lodash';
import React from 'react';

import type { APIResponse } from '@stargate/api';
import { IconAvatar } from '@stargate/components/Avatars';
import { FilePathTruncate } from '@stargate/components/Utils';
import { GitHubFileIcon } from '@stargate/features/github';
import CodeMirror, {
  useCodeMirrorLangs,
  type CodeMirrorLang,
} from '@stargate/lib/codemirror';
import { useLocalization } from '@stargate/localization';
import { createComponentClasses } from '@stargate/theme';
import { decodeBase64 } from '@stargate/utils/files';

import { match } from 'ts-pattern';
import type {
  CodeLinkAction,
  CodeLinkPayload,
  CodeSnippetAction,
  CodeSnippetPayload,
} from '../../types';
import { CodeCard, CodeCardContent, CodeCardHeader } from '../cards';
import { CodeLinkButton } from '../sources/CodeLinkButton';
import { CodeSnippetButton } from '../sources/CodeSnippetButton';
import { CodeSnippetRange } from '../sources/CodeSnippetRange';

export const codeExplorerContentFileClasses = createComponentClasses(
  'CodeExplorerContentFile',
  ['root', 'image'] as const
);

export interface CodeExplorerContentFileProps {
  /**
   * The file content data.
   */
  fileContent: APIResponse<'GET /github/repositories/:repositoryId/file-contents/:filePath'>;

  /**
   * Callback for when the code snippet action is triggered.
   *
   * @param payload A payload for the code snippet.
   * @param action An action for the code snippet.
   * @returns A promise that resolves when the action is complete.
   */
  onCodeSnippetAction: (
    payload: Pick<CodeSnippetPayload, 'filePath' | 'startLine' | 'endLine'> & {
      content: string;
      language: CodeMirrorLang;
    },
    action: CodeSnippetAction
  ) => Promise<void>;

  /**
   * Callback for when the code link action is triggered.
   *
   * @param payload A payload for the code link.
   * @param action An action for the code link.
   * @returns A promise that resolves when the action is complete.
   */
  onCodeLinkAction: (
    payload: Pick<CodeLinkPayload, 'filePath'>,
    action: CodeLinkAction
  ) => Promise<void>;

  /**
   * Callback for when the code selection changes.
   *
   * @param selection The selection range.
   * @returns void
   */
  onCodeSelection?: (selection: { start: number; end: number }) => void;

  /**
   * Whether the code is readonly.
   */
  readonly: boolean;

  /**
   * Whether a code snippet can be inserted.
   */
  canInsertCodeSnippet: boolean;

  /**
   * Whether a file has a code link.
   */
  hasCodeLink?: boolean;
}

export const CodeExplorerContentFile: React.FC<
  CodeExplorerContentFileProps
> = ({
  fileContent,
  onCodeSelection,
  onCodeLinkAction,
  onCodeSnippetAction,
  readonly,
  canInsertCodeSnippet,
  hasCodeLink,
}) => {
  const localz = useLocalization();
  const codemirrorLangs = useCodeMirrorLangs();
  const [selectionRange, setSelectionRange] = React.useState<{
    start: number;
    end: number;
  } | null>(null);

  /*
  |------------------
  | Computed
  |------------------
  */

  const code = React.useMemo<string>(() => {
    return decodeBase64(fileContent.content);
  }, [fileContent]);

  const selectionCode = React.useMemo<string | null>(() => {
    if (_.isNil(selectionRange)) {
      return null;
    }

    return _.chain(code)
      .split('\n')
      .filter((_v, index) => {
        return (
          selectionRange &&
          index + 1 >= selectionRange.start &&
          index + 1 <= selectionRange.end
        );
      })
      .join('\n')
      .value();
  }, [selectionRange, code]);

  const filePath = React.useMemo<string>(() => {
    return fileContent.path;
  }, [fileContent]);

  const extension = React.useMemo<string | null>(() => {
    return filePath.split('.').pop() ?? null;
  }, [filePath]);

  const lang = React.useMemo<CodeMirrorLang>(() => {
    if (extension) {
      return codemirrorLangs.findByFileExtension(extension);
    }

    if (filePath.includes('/')) {
      const fileName = filePath.split('/').pop();

      if (fileName === 'Dockerfile') {
        return 'dockerfile';
      }
    }

    return 'plaintext';
  }, [filePath, extension, codemirrorLangs]);

  /*
  |------------------
  | Callbacks
  |------------------
  */

  const handleCodeSnippetClick = React.useCallback(() => {
    if (!canInsertCodeSnippet) {
      return;
    }

    if (selectionRange && filePath && selectionCode) {
      onCodeSnippetAction(
        {
          content: selectionCode,
          filePath,
          startLine: selectionRange.start,
          endLine: selectionRange.end,
          language: lang,
        },
        'insert'
      );
    }
  }, [
    onCodeSnippetAction,
    selectionRange,
    lang,
    filePath,
    selectionCode,
    canInsertCodeSnippet,
  ]);

  const handleCodeLinkClick = React.useCallback(() => {
    if (filePath) {
      onCodeLinkAction({ filePath }, 'link');
    }
  }, [onCodeLinkAction, filePath]);

  const handleCodeSelection = React.useCallback(
    (selection: { start: number; end: number }) => {
      setSelectionRange(selection);
      onCodeSelection?.(selection);
    },
    [onCodeSelection]
  );

  if (extension && isImage(extension)) {
    return <ContentFileImage url={fileContent.url} />;
  }

  return (
    <CodeCard>
      <CodeCardHeader
        leftAction={
          <React.Fragment>
            <Tooltip
              title={localz.formatMessage(
                'features.code.content.path.tooltip',
                {
                  path: filePath,
                  code: (chunks) => <code>{chunks}</code>,
                }
              )}
              arrow
            >
              <Chip
                component={Link}
                href={fileContent.url}
                underline='none'
                target='_blank'
                rel='noopener noreferrer'
                avatar={
                  <GitHubFileIcon fileExtension={extension ?? undefined} />
                }
                label={
                  <React.Fragment>
                    <FilePathTruncate filePath={filePath} />
                    {selectionRange && <CodeSnippetRange {...selectionRange} />}
                  </React.Fragment>
                }
                shape='rounded'
                clickable
              />
            </Tooltip>
          </React.Fragment>
        }
        rightAction={
          !readonly && (
            <React.Fragment>
              <Tooltip
                title={localz.formatMessage(
                  `features.code.links.action.tooltip.${hasCodeLink ? 'exists' : 'description'}`
                )}
              >
                <span>
                  <CodeLinkButton
                    onClick={handleCodeLinkClick}
                    disabled={hasCodeLink}
                  >
                    {localz.formatMessage('features.code.links.action.label')}
                  </CodeLinkButton>
                </span>
              </Tooltip>
              <Tooltip
                title={localz.formatMessage(
                  canInsertCodeSnippet
                    ? 'features.code.snippets.action.tooltip.description'
                    : 'features.code.snippets.action.tooltip.warning.no-insert'
                )}
              >
                <span>
                  <CodeSnippetButton
                    disabled={!selectionRange || !canInsertCodeSnippet}
                    onClick={handleCodeSnippetClick}
                  >
                    {localz.formatMessage(
                      'features.code.snippets.action.label'
                    )}
                  </CodeSnippetButton>
                </span>
              </Tooltip>
            </React.Fragment>
          )
        }
      />
      <CodeCardContent
        sx={{
          width: '100%',
          // Hardcoded and eye-balled height to make the code mirror fit the screen + allow for scrolling
          height: 'calc(100vh - 148px)',
        }}
      >
        <CodeMirror
          code={code}
          lang={lang}
          onCodeSelection={handleCodeSelection}
          readonly
          selectEntireLines
        />
      </CodeCardContent>
    </CodeCard>
  );
};

/*
|------------------
| Utils
|------------------
*/

const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'webp'] as const;

type ImageExtension = (typeof imageExtensions)[number];

/**
 * Determine if the extension is an image.
 *
 * @param extension The extension to check.
 * @returns A boolean indicating if the extension is an image.
 */
const isImage = (extension: string): extension is ImageExtension => {
  return imageExtensions.includes(extension as ImageExtension);
};

/**
 * Show a placeholder image for the file.
 */
const ContentFileImage: React.FC<{ url: string }> = ({ url }) => {
  const theme = useTheme();
  return (
    <Box
      className={codeExplorerContentFileClasses.image}
      sx={{
        [`&.${codeExplorerContentFileClasses.image}`]: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '480px',
          width: '100%',
          p: 2,
          bg: 'background.paper',
          border: '1px solid',
          borderColor: 'divider',
          borderRadius: 1,
          color: 'text.secondary',
          backgroundColor: theme.palette.background.paper,
        },
      }}
    >
      <Stack direction='column' spacing={1} alignItems='center'>
        <IconAvatar
          icon='photo'
          backgroundColor={match(theme.palette.mode)
            .with('light', () => theme.palette.grey[300])
            .with('dark', () => theme.palette.grey[700])
            .exhaustive()}
          iconColor={theme.palette.text.primary}
          size={64}
        />
        <Typography variant='body1' gutterBottom textAlign='center'>
          This is an image file & cannot be viewed using the Code Explorer.
          <br />
          You can click on the button below to view the file on GitHub.
        </Typography>
        <div />
        <Button
          startIcon={<RikerIcon icon='brand-github' />}
          href={url}
          rel='noopener noreferrer'
          target='_blank'
        >
          View on GitHub
        </Button>
      </Stack>
    </Box>
  );
};
