import { Chip, type ChipProps, Tooltip } from '@mui/material';
import React from 'react';

import type { FlaggrRelease } from '@stargate/lib/flaggr';
import { FormattedMessage } from '@stargate/localization';

export type ReleaseStage = FlaggrRelease;

export interface ReleaseChipProps extends ChipProps {
  /**
   * The release stage.
   */
  stage: ReleaseStage;
}

const ReleaseChip = React.forwardRef<HTMLDivElement, ReleaseChipProps>(
  (props, ref) => {
    const { size = 'small', ...restProps } = props;

    const color = React.useMemo(() => {
      switch (props.stage) {
        case 'alpha':
          return 'error';
        case 'beta':
          return 'warning';
        default:
          return 'info';
      }
    }, [props.stage]);

    // If the stage is stable, don't show the chip
    if (props.stage === 'stable') {
      return null;
    }

    return (
      <Tooltip
        title={
          <FormattedMessage
            id={`components.chips.release.${props.stage}.description`}
          />
        }
      >
        <Chip
          {...restProps}
          ref={ref}
          label={
            <FormattedMessage
              id={`components.chips.release.${props.stage}.title`}
            />
          }
          size={size}
          variant='outlined'
          color={color}
        />
      </Tooltip>
    );
  }
);
ReleaseChip.displayName = 'ReleaseChip';
ReleaseChip.muiName = 'Chip';

export default ReleaseChip;
