import React from 'react';

import { Switch, type SwitchProps, switchClasses } from '@mui/material';

import { useUserSettings } from '../hooks/use-user-settings';
import { ModeIcon } from './ModeIcon';

/*
|==========================================================================
| ModeSwitch
|==========================================================================
|
| Toggle between light and dark mode.
|
*/

export type ModeSwitchProps = Omit<
  SwitchProps,
  'checked' | 'defaultChecked' | 'onChange' | 'checkedIcon' | 'icon'
>;

export const ModeSwitch: React.FC<SwitchProps> = (props) => {
  const userSettings = useUserSettings();

  /*
  |------------------
  | Computed
  |------------------
  */

  const trackWidth = 52;

  const switchPadding = 4;

  const iconSize = 32;

  const translateX = {
    unchecked: 0,
    checked: trackWidth - (iconSize + switchPadding * 2),
  };

  const trackPadding = {
    y: 12,
    x: 4,
  };

  /*
  |------------------
  | Callbacks
  |------------------
  */

  const handleChange = React.useCallback(() => {
    userSettings.onUpdate({
      theme: userSettings.data.theme === 'light' ? 'dark' : 'light',
    });
  }, [userSettings.data.theme]);

  return (
    <Switch
      {...props}
      checked={userSettings.data.theme === 'dark'}
      inputProps={{ 'aria-label': 'dark/light mode switch' }}
      sx={{
        overflow: 'visible',
        width: `${trackWidth}px`,
        padding: `${trackPadding.y}px ${trackPadding.x}px`,
        cursor: 'pointer',
        [`& .${switchClasses.switchBase}`]: {
          padding: `${switchPadding}px`,
          top: '-1px',
          transform: `translateX(${translateX.unchecked}px)`,
          [`&.${switchClasses.checked}`]: {
            transform: `translateX(${translateX.checked}px)`,
          },
          [`& .${switchClasses.input}`]: {
            left: '0',
            width: '100%',
          },
        },
        [`& .${switchClasses.track}`]: {
          width: trackWidth,
        },
      }}
      checkedIcon={
        <ModeIcon mode='dark' size={iconSize} sx={{ cursor: 'pointer' }} />
      }
      icon={
        <ModeIcon mode='light' size={iconSize} sx={{ cursor: 'pointer' }} />
      }
      onChange={handleChange}
    />
  );
};
