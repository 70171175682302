import { Box, Button, Typography, useTheme } from '@mui/material';
import React from 'react';
import TablerIconShare from '~icons/tabler/share';

import { useNotify } from '@stargate/lib/notify';

export interface JoggrDocShareLinkProps {
  /**
   * The URL of the JoggrDoc to share.
   */
  shareUrl: string;

  /**
   * Callback that triggers when the user clicks the copy button.
   */
  onCopy?: () => void;
}

export const JoggrDocShareLink: React.FC<JoggrDocShareLinkProps> = ({
  shareUrl,
  onCopy,
}) => {
  const theme = useTheme();
  const notify = useNotify();

  /*
  |------------------
  | Computed
  |------------------
  */

  const buttonWidth = '100px';

  /*
  |------------------
  | Handlers
  |------------------
  */

  const handleCopy = React.useCallback(() => {
    if (onCopy) {
      onCopy();
    }

    navigator.clipboard.writeText(shareUrl).then(() => {
      notify.send({
        severity: 'success',
        title: 'Copied to clipboard',
        message: 'The document share link has been copied to your clipboard.',
      });
    });
  }, [notify, onCopy, shareUrl]);

  return (
    <Box>
      <Typography variant='caption' gutterBottom>
        Share the link below:
      </Typography>
      <Box
        sx={{
          position: 'relative',
          borderRadius: 1,
          color: theme.palette.text.primary,
          bgcolor:
            theme.palette.mode === 'dark'
              ? theme.palette.grey[700]
              : theme.palette.grey[200],
        }}
      >
        <Box
          sx={{
            p: 1,
            paddingRight: `calc(${buttonWidth} + ${theme.spacing(1)})`,
          }}
        >
          <Typography variant='body1'>{shareUrl}</Typography>
        </Box>
        <Button
          startIcon={<TablerIconShare />}
          onClick={handleCopy}
          sx={{
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            maxWidth: buttonWidth,
          }}
          disableElevation
        >
          Copy
        </Button>
      </Box>
    </Box>
  );
};
