import { Box, useTheme } from '@mui/material';
import {
  TreeItemContent,
  type TreeItemContentProps,
  useTreeItemState,
} from '@mui/x-tree-view';
import _ from 'lodash';
import React from 'react';
import { match } from 'ts-pattern';

import { Link } from '@stargate/routes';
import { createComponentClasses } from '@stargate/theme';
import { cn } from '@stargate/utils/styles';

import { isLocalDraftNode } from '../../lib/utils';
import type { DirectoryTreeNode } from '../../types';

export const directoryTreeItemContentClasses = createComponentClasses(
  'DirectoryTreeItemContent',
  ['root'] as const
);

export interface DirectoryTreeItemContentProps extends TreeItemContentProps {
  node: DirectoryTreeNode;
}

/**
 * Component for
 */
export const DirectoryTreeItemContent = React.forwardRef<
  HTMLElement,
  DirectoryTreeItemContentProps
>((props, ref) => {
  const theme = useTheme();
  const {
    classes,
    className,
    displayIcon,
    expansionIcon,
    icon: iconProp,
    label,
    node,
    onClick,
    onMouseDown,
    itemId,
    ...other
  } = props;
  const {
    disabled,
    expanded,
    selected,
    focused,
    handleSelection,
    preventSelection,
  } = useTreeItemState(node.id);

  /*
  |------------------
  | Handlers
  |------------------
  */
  const handleMouseDown = (event: React.MouseEvent<HTMLElement>): void => {
    preventSelection(event as React.MouseEvent<HTMLDivElement>);
    if (!_.isNil(onMouseDown)) {
      onMouseDown(event);
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    handleSelection(event as React.MouseEvent<HTMLDivElement>);
    if (!_.isNil(onClick)) {
      onClick(event);
    }
  };

  if (props.node.nodeType === 'document') {
    return (
      <Box
        {...other}
        ref={ref as React.Ref<HTMLElement>}
        component={Link}
        draggable={false}
        to={match(node)
          .when(
            (n) => isLocalDraftNode(n),
            () => 'app.documents.draft' as const
          )
          .otherwise(() => 'app.documents.view' as const)}
        params={{ id: node.id }}
        className={cn({
          [classes.root]: true,
          [directoryTreeItemContentClasses.root]: true,
          [className ?? '']: true,
          [classes.expanded]: expanded,
          [classes.selected]: selected,
          [classes.focused]: focused,
          [classes.disabled]: disabled,
        })}
        sx={{
          [`&.${directoryTreeItemContentClasses.root}`]: {
            textDecoration: 'none',
            color: theme.palette.text.primary,
          },
        }}
        onClick={handleClick}
        onMouseDown={handleMouseDown}
      >
        <div className={classes.iconContainer}>
          {iconProp ?? expansionIcon ?? displayIcon}
        </div>
        <div className={classes.label}>{label}</div>
      </Box>
    );
  }
  return (
    <TreeItemContent
      {...props}
      itemId={itemId}
      ref={ref as React.Ref<HTMLDivElement>}
    />
  );
});
DirectoryTreeItemContent.displayName = 'DirectoryTreeItemContent';
