import React from 'react';
import type * as TypeFest from 'type-fest';

import {
  RikerIcon,
  type RikerIconComponent,
  type RikerIconProps,
} from '@joggrdocs/riker-icons';

/*
|==========================================================================
| DirectoryMoveIcon 
|==========================================================================
|
| Proxy component for the RikerIcon component.
|
*/

export type DirectoryMoveIconProps = TypeFest.Simplify<
  Omit<RikerIconProps, 'icon'>
>;

export const directoryMoveIconName = 'folder-symlink';

/**
 * Icon for moving a directory.
 */
export const DirectoryMoveIcon = React.forwardRef<
  RikerIconComponent,
  DirectoryMoveIconProps
>((props, ref) => (
  <RikerIcon {...props} ref={ref} icon={directoryMoveIconName} />
));
