import { RikerIcon } from '@joggrdocs/riker-icons';
import { Box, Link, Stack, TextField } from '@mui/material';
import React from 'react';

export interface ImageAltTextFieldProps {
  /**
   * The (default) alt text of the image.
   */
  alt?: string;

  /**
   * Callback function that is called when the alt text of the image is changed.
   *
   * @param alt A string representing the alt text of the image.
   */
  onChange: (alt: string) => void;
}

/**
 * Component for adding or editing the alt text of an image.
 */
export const ImageAltTextField: React.FC<ImageAltTextFieldProps> = ({
  onChange,
  alt,
  ...props
}) => {
  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value);
    },
    [onChange]
  );

  return (
    <TextField
      label={
        <React.Fragment>
          Fallback Text (<code>alt</code>)
        </React.Fragment>
      }
      placeholder='A short description of the image...'
      size='small'
      type='url'
      defaultValue={alt}
      onChange={handleChange}
      helperText={
        <Stack spacing={0.5} direction='row' alignItems='center'>
          <Box component='span'>
            Add fallback text to an image (<code>alt</code>) used primarily for
            accessibility
          </Box>
          <Link
            href='https://developer.mozilla.org/en-US/docs/Web/API/HTMLImageElement/alt#usage_notes'
            rel='noopener noreferrer'
            target='_blank'
            sx={{
              display: 'flex',
            }}
          >
            <RikerIcon icon='info-circle' size={16} />
          </Link>
        </Stack>
      }
      fullWidth
    />
  );
};
ImageAltTextField.displayName = 'ImageAltTextField';
