import { RikerIcon, type RikerIconName } from '@joggrdocs/riker-icons';
import {
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  type SelectChangeEvent,
} from '@mui/material';
import { NodeViewContent, NodeViewWrapper } from '@tiptap/react';
import clsx from 'classnames';
import React from 'react';
import TablerIconTrash from '~icons/tabler/trash';

import { BlockActions } from '@dashdraft/components/BlockActions';
import type { DashDraftNodeViewProps } from '@stargate/lib/dashdraft/types';

import type { AlertNodeAttributes, AlertType } from '../types';

export type AlertNodeViewProps = DashDraftNodeViewProps<AlertNodeAttributes>;

const alertIconMap: {
  [key in AlertType]: { title: string; icon: RikerIconName };
} = {
  note: { title: 'Note', icon: 'note' },
  tip: { title: 'Tip', icon: 'bulb' },
  important: { title: 'Important', icon: 'file-alert' },
  warning: { title: 'Warning', icon: 'alert-triangle' },
  caution: { title: 'Caution', icon: 'alert-octagon' },
};

export const AlertNodeView = React.memo<AlertNodeViewProps>(
  ({ node, editor, updateAttributes, deleteNode }) => {
    const { type } = node.attrs;
    const [active, setActive] = React.useState(false);

    /*
    |------------------
    | Computed
    |------------------
    */

    const iconData = React.useMemo(() => alertIconMap[type], [type]);

    const icon = React.useMemo(
      () => <RikerIcon icon={iconData.icon} />,
      [iconData.icon]
    );

    /*
    |------------------
    | Handlers
    |------------------
    */

    const handleMouseOver = React.useCallback(() => {
      setActive(true);
    }, []);

    const handleMouseOut = React.useCallback(() => {
      setActive(false);
    }, []);

    const handleSelectChange = React.useCallback(
      (e: SelectChangeEvent<AlertType>) => {
        updateAttributes({ type: e.target.value });
      },
      [updateAttributes]
    );

    return (
      <NodeViewWrapper
        as='div'
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        data-alert
        data-type={type}
        className={clsx(
          'dashdraft-alert',
          `dashdraft-alert-${type ?? 'info'}`,
          {
            'dashdraft-alert-placeholder': node.textContent === '',
          }
        )}
      >
        {editor.isEditable && (
          <BlockActions
            className={clsx({
              active,
            })}
            transparent
            contentEditable={false}
          >
            <IconButton size='small' onClick={deleteNode}>
              <TablerIconTrash />
            </IconButton>
          </BlockActions>
        )}
        <div className='dashdraft-alert-sidebar' contentEditable={false} />
        <div className='dashdraft-alert-header' contentEditable={false}>
          {editor.isEditable ? (
            <Select
              className='dashdraft-alert-select'
              size='small'
              value={type}
              onChange={handleSelectChange}
              startAdornment={
                <InputAdornment position='start'>{icon}</InputAdornment>
              }
            >
              {Object.keys(alertIconMap).map((key) => {
                return (
                  <MenuItem key={key} value={key}>
                    {key}
                  </MenuItem>
                );
              })}
            </Select>
          ) : (
            <React.Fragment>
              {icon}
              <span className='dashdraft-alert-title'>{iconData.title}</span>
            </React.Fragment>
          )}
        </div>
        <NodeViewContent
          as='span'
          data-placeholder='Start typing...'
          className='dashdraft-alert-content'
        />
      </NodeViewWrapper>
    );
  }
);
AlertNodeView.displayName = 'Alert';
