import React from 'react';

import { Dialog } from '@mui/material';

/*
|==========================================================================
| ImgFullScreenDialog
|==========================================================================
|
| This component is responsible for rendering the image in fullscreen mode.
|
*/

export interface MediaFullScreenDialogProps {
  children: React.ReactNode;
  open: boolean;
  onClose: () => void;
}

export const MediaFullScreenDialog: React.FC<MediaFullScreenDialogProps> = ({
  children,
  open = false,
  onClose,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      className='dashdraft-media-image-fullscreen'
      fullWidth
      maxWidth='xl'
    >
      {children}
    </Dialog>
  );
};
MediaFullScreenDialog.displayName = 'MediaFullScreenDialog';
