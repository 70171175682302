import React from 'react';

import { Box } from '@mui/material';

/*
|==========================================================================
| RawHtml
|==========================================================================
|
| Simple wrapper component to render raw HTML.
|
*/

export interface Props {
  rawHtml?: string;
  variant?: 'inline' | 'block';
  sx?: React.ComponentProps<typeof Box>['sx'];
}

export const RawHtml: React.FC<Props> = (props) => {
  if (!props.rawHtml) {
    return null;
  }

  if (props.variant === 'block') {
    return (
      <Box
        component='div'
        sx={{
          display: 'block',
          width: 'fit-content',
          height: 'fit-content',
          ...props.sx,
        }}
        // biome-ignore lint/security/noDangerouslySetInnerHtml: We want to allow this since we are rendering raw HTMLß
        dangerouslySetInnerHTML={{ __html: props.rawHtml }}
      />
    );
  }
  return (
    <Box
      component='span'
      sx={props.sx}
      // biome-ignore lint/security/noDangerouslySetInnerHtml: We want to allow this since we are rendering raw HTMLß
      dangerouslySetInnerHTML={{ __html: props.rawHtml }}
    />
  );
};

export const RawHtmlMemo = React.memo(RawHtml);
RawHtmlMemo.displayName = 'RawHtml';

export default RawHtml;
