import * as hookz from '@react-hookz/web';
import _ from 'lodash';
import React from 'react';

import {
  Box,
  Fade,
  LinearProgress,
  TextField,
  type TextFieldProps,
  Typography,
  inputClasses,
} from '@mui/material';
import TablerIconSearch from '~icons/tabler/search';

import apiClient from '@stargate/api';
import { useNotify } from '@stargate/lib/notify';
import { useLocation } from '@stargate/routes';

import { useSearch } from '../hooks/use-search';
import { SearchBarResults } from './SearchBarResults';

const QUERY_MIN_LENGTH = 2;

export const SearchBar: React.FC = () => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const searchResultsRef = React.useRef<HTMLUListElement>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [focused, setFocused] = React.useState(false);
  const [searchResultsOpen, setSearchResultsOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState('');
  const previousSearchQuery = hookz.usePrevious(searchQuery);
  const [searchApiState, searchApiActions] =
    apiClient.useRequestClient('POST /search');
  const notify = useNotify();
  const [, searchHookActions] = useSearch();
  const [focusIndex, setFocusIndex] = React.useState(-1);
  const location = useLocation();

  /*
  |------------------
  | Compute
  |------------------
  */

  const hasActiveDocument = React.useMemo(() => {
    return _.some(
      searchApiState.result?.documents,
      (doc) =>
        location.active('app.documents.view', { id: doc.id }) ||
        location.active('app.documents.edit', { id: doc.id })
    );
  }, [location, searchApiState.result?.documents]);

  /*
  |------------------
  | Handlers
  |------------------
  */

  const handleSearchChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const searchText = event.target.value;
    if (searchText.length >= QUERY_MIN_LENGTH) {
      setLoading(true);
    } else {
      setLoading(false);
    }

    setSearchQuery(searchText);
    if (!focused) {
      setFocused(true);
    }
  };

  const handleSearchResultsClick = (): void => {
    setSearchResultsOpen(false);
    setSearchQuery('');
    searchHookActions.closeDialog();
  };

  const handleFocusSearchField: TextFieldProps['onFocus'] = (e) => {
    setFocused(true);
  };

  /*
  |----------------------------------
  | Keyboard Handlers
  |----------------------------------
  |
  | We manually control the index so we can return to the TextField (Search Input)
  |
  */

  const handleInputKeyDown = React.useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (
        e.key === 'ArrowDown' &&
        !_.isNil(searchApiState.result) &&
        searchApiState.result.documents.length > 0
      ) {
        e.preventDefault();
        e.stopPropagation();

        // We check if the active document is in the search results
        // if we so we set the focus index to 1 as we need to skip the active document
        if (hasActiveDocument && searchApiState.result.documents.length > 1) {
          setFocusIndex(1);
        } else {
          setFocusIndex(0);
        }
      }

      if (e.key === 'Escape') {
        e.preventDefault();
        e.stopPropagation();
        setFocused(false);
        setSearchResultsOpen(false);
      }
    },
    [hasActiveDocument, searchApiState.result]
  );

  const handleItemKeyDown = React.useCallback(
    (e: React.KeyboardEvent<HTMLAnchorElement>) => {
      if (!_.isNil(searchApiState.result)) {
        if (e.key === 'ArrowDown') {
          e.preventDefault();
          e.stopPropagation();
          if (focusIndex + 1 >= searchApiState.result.documents.length) {
            // If we have an active document we need to set to the first
            // not disabled item
            setFocusIndex(hasActiveDocument ? 1 : 0);
          } else {
            setFocusIndex(focusIndex + 1);
          }
        }

        if (e.key === 'ArrowUp') {
          e.preventDefault();
          e.stopPropagation();
          if (
            focusIndex <= 0 ||
            // If we have an active document and the focus index is 1 (first item not disabled)
            // we need to set the focus index to -1 so we can return to the search input
            (hasActiveDocument && focusIndex === 1)
          ) {
            setFocusIndex(-1);
            inputRef.current?.focus();
          } else {
            setFocusIndex(focusIndex - 1);
          }
        }
      }
    },
    [focusIndex, searchApiState.result, hasActiveDocument]
  );

  /*
  |------------------
  | Effects
  |------------------
  */

  hookz.useDebouncedEffect(
    () => {
      // Reset the search results if the search query is empty
      if (_.isEmpty(searchQuery) && !_.isEmpty(previousSearchQuery)) {
        searchApiActions.reset();
      }

      // Run the search query if the query is long enough
      if (
        searchQuery.length >= QUERY_MIN_LENGTH &&
        searchQuery !== previousSearchQuery
      ) {
        searchApiActions
          .execute({
            body: {
              query: searchQuery,
            },
          })
          .then(() => {
            setLoading(false);
            if (!searchResultsOpen) {
              setSearchResultsOpen(true);
            }
          })
          .catch(() => {
            setLoading(false);
            setSearchResultsOpen(false);
            notify.error('Unable to search, please try again');
          });
      }
    },

    [searchApiActions, searchQuery, previousSearchQuery, searchResultsOpen],
    0,
    200
  );

  return (
    <Box
      ref={containerRef}
      sx={{
        width: '500px',
      }}
    >
      <Box
        sx={{
          px: 1,
          pb: 1,
          borderBottom: '1px solid',
          borderColor: 'divider',
          position: 'relative',
        }}
      >
        <TextField
          placeholder='Search'
          type='search'
          size='small'
          autoFocus
          inputRef={inputRef}
          value={searchQuery}
          onKeyDown={handleInputKeyDown}
          onFocus={handleFocusSearchField}
          onChange={handleSearchChange}
          fullWidth
          InputProps={{
            startAdornment: <TablerIconSearch />,
            sx: {
              [`& .${inputClasses.inputTypeSearch}`]: {
                px: 1,
                pl: 2,
                background: 'none',
              },
            },
          }}
        />
        <Box
          sx={{
            height: '2px',
            position: 'absolute',
            bottom: '-1px',
            left: 0,
            right: 0,
          }}
        >
          <Fade
            in={loading}
            style={{
              transitionDelay: loading ? '0ms' : '1200ms',
            }}
            unmountOnExit
          >
            <Box sx={{ width: '100%' }}>
              <LinearProgress sx={{ height: '2px' }} />
            </Box>
          </Fade>
        </Box>
      </Box>
      <Box
        sx={{
          minHeight: '124px',
          maxHeight: 'calc(100vh - 164px)',
          overflowY: 'auto',
        }}
      >
        {!_.isNil(searchApiState.result) && (
          <SearchBarResults
            ref={searchResultsRef}
            focusIndex={focusIndex}
            searchResults={searchApiState.result}
            onItemClick={handleSearchResultsClick}
            onItemKeyDown={handleItemKeyDown}
          />
        )}
        {_.isNil(searchApiState.result) && (
          <Box
            sx={{
              mt: 2,
              textAlign: 'center',
            }}
          >
            <Typography>Start typing to search your documents.</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};
