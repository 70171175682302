import {
  Box,
  Stack,
  type SxProps,
  Typography,
  darken,
  useTheme,
} from '@mui/material';
import _ from 'lodash';
import React from 'react';

export interface BlockEmptyProps extends React.PropsWithChildren {
  /**
   * The class name to apply to the block.
   */
  className?: string;

  /**
   * The helper text to display in the block.
   */
  helperText: React.ReactNode;

  /**
   * An icon to display next to the helper text.
   */
  helperIcon?: React.ReactNode;

  /**
   * Callback when the block is clicked.
   */
  onClick?: () => void;

  /**
   * The sx props to apply to the block.
   */
  sx?: SxProps;
}

/**
 * A block that is displayed when there is no content in the editor.
 */
export const BlockEmpty: React.FC<BlockEmptyProps> = ({
  className,
  helperText,
  sx,
  helperIcon,
  onClick,
}) => {
  const theme = useTheme();
  return (
    <Box
      className={className}
      onClick={onClick}
      sx={{
        ...sx,
        width: '100%',
        minHeight: '64px',
        bgcolor: theme.palette.grey[theme.palette.mode === 'light' ? 100 : 700],
        border: `1px solid ${theme.palette.divider}`,
        color:
          theme.palette.mode === 'light'
            ? theme.palette.text.primary
            : theme.palette.background.paper,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        '&:hover, &:active, &:focus': {
          bgcolor: darken(
            theme.palette.grey[theme.palette.mode === 'light' ? 100 : 700],
            0.1
          ),
          cursor: 'pointer',
        },
      }}
    >
      <Stack
        direction={'row'}
        spacing={1}
        alignItems={'center'}
        sx={{
          color: theme.palette.text.primary,
        }}
      >
        {helperIcon}
        {_.isString(helperText) ? (
          <Typography>{helperText}</Typography>
        ) : (
          helperText
        )}
      </Stack>
    </Box>
  );
};

export default BlockEmpty;
