import { RikerIcon, type RikerIconProps } from '@joggrdocs/riker-icons';
import { Box, Stack, Tooltip, darken, lighten, useTheme } from '@mui/material';
import * as hookz from '@react-hookz/web';
import _ from 'lodash';
import React from 'react';

import { FormattedMessage } from '@stargate/localization';
import { createComponentClasses } from '@stargate/theme';

import { useGitHubUrls } from '@stargate/features/github';
import type { JDocComponentProps, JDocVersion } from '../../types';
import { JoggrDocVersionChip } from './JoggrDocVersionChip';
import { JoggrDocVersionMenu } from './JoggrDocVersionMenu';

export const joggrDocVersionClasses = createComponentClasses(
  'JoggrDocVersion',
  ['root'] as const
);

export type JoggrDocVersionSelectProps = JDocComponentProps<{
  /**
   * The versions of the JoggrDoc.
   */
  versions: JDocVersion[];
}>;

export const JoggrDocVersionSelect: React.FC<JoggrDocVersionSelectProps> = ({
  doc,
  draft,
  mode,
  versions,
}) => {
  const theme = useTheme();
  const ghUrls = useGitHubUrls();
  const [menuAnchorSize, menuAnchorElRef] = hookz.useMeasure<HTMLDivElement>();
  const [menuOpen, setMenuOpen] = React.useState<boolean>(false);
  const [tooltipOpen, setTooltipOpen] = React.useState<boolean>(false);
  const [showWarningMessage, setShowWarningMessage] =
    React.useState<boolean>(false);

  /*
  |------------------
  | Computed
  |------------------
  */

  const showHelperMessage = React.useMemo(() => {
    return versions.length === 1;
  }, [versions]);

  /*
  |------------------
  | Handlers
  |------------------
  */

  const handleOpenTooltip = (): void => {
    setTooltipOpen(true);
    setShowWarningMessage(false);
  };

  const handleCloseTooltip = (): void => {
    setTooltipOpen(false);
    setShowWarningMessage(false);
  };

  const handleOpenMenu = (): void => {
    setMenuOpen(true);
    setShowWarningMessage(false);
  };

  const handleCloseMenu = (): void => {
    setMenuOpen(false);
    setTooltipOpen(false);
    setShowWarningMessage(false);
  };

  /*
  |------------------
  | Effects
  |------------------
  */

  React.useEffect(() => {
    if (
      versions &&
      versions.length > 1 &&
      doc?.version === 'latest' &&
      mode === 'edit'
    ) {
      setShowWarningMessage(true);
      setTooltipOpen(true);
      setMenuOpen(true);
    } else {
      setShowWarningMessage(false);
      setMenuOpen(false);
      setTooltipOpen(false);
    }
  }, [versions, doc?.version, mode]);

  return (
    <Stack classes={joggrDocVersionClasses} direction='row' spacing={0.5}>
      <Tooltip
        title={
          showHelperMessage ? (
            <FormattedMessage id='features.docs.versions.only' />
          ) : showWarningMessage ? (
            <FormattedMessage
              id='features.docs.versions.multiple'
              values={{
                count: versions.length,
                s: versions.length > 1 ? 's' : '',
              }}
            />
          ) : undefined
        }
        open={tooltipOpen}
        placement={showWarningMessage ? 'right' : 'bottom'}
        arrow
      >
        <Stack
          direction='row'
          spacing={0}
          ref={menuAnchorElRef}
          onMouseOver={handleOpenTooltip}
          onMouseOut={handleCloseTooltip}
        >
          {doc?.github?.repository && doc?.github?.pullRequest && (
            <Tooltip
              title='Click to view the pull request on GitHub'
              placement='bottom'
              arrow
            >
              <Stack
                component='a'
                target='_blank'
                rel='noopener noreferrer'
                href={ghUrls.pull({
                  owner: doc.github.repository.owner.login,
                  repo: doc.github.repository.name,
                  pull: doc.github.pullRequest.number,
                })}
                direction='row'
                alignItems='center'
                spacing={0.5}
                sx={{
                  borderTopLeftRadius: '6px',
                  borderBottomLeftRadius: '6px',
                  height: '100%',
                  ...theme.applyStyles('light', {
                    bgcolor: lighten(theme.palette.action.selected, 0.5),
                  }),
                  ...theme.applyStyles('dark', {
                    bgcolor: theme.palette.grey[700],
                  }),
                  color: theme.palette.text.primary,
                  px: 1,
                  textDecoration: 'none',
                  '&:hover': {
                    ...theme.applyStyles('light', {
                      bgcolor: theme.palette.grey[300],
                    }),
                    ...theme.applyStyles('dark', {
                      bgcolor: darken(theme.palette.grey[700], 0.1),
                    }),
                  },
                }}
              >
                <Box
                  component={RikerIcon}
                  icon={
                    doc.github.pullRequest.draft === true
                      ? 'git-pull-request-draft'
                      : 'git-pull-request'
                  }
                  sx={{
                    color: doc.github.pullRequest.draft
                      ? theme.palette.grey[500]
                      : theme.palette.success.main,
                  }}
                />
                <Box>#{doc.github.pullRequest.number}</Box>
              </Stack>
            </Tooltip>
          )}
          {doc?.github?.branch && (
            <JoggrDocVersionChip
              version={doc.github.branch}
              onClick={versions.length > 1 ? handleOpenMenu : undefined}
              clickable={versions.length > 1}
              endIcon={
                <ArrowIcon open={menuOpen} style={{ color: 'inherit' }} />
              }
              sx={{
                borderTopLeftRadius: !_.isNil(doc.github.pullRequest)
                  ? '0 !important'
                  : undefined,
                borderBottomLeftRadius: !_.isNil(doc.github.pullRequest)
                  ? '0 !important'
                  : undefined,
                px: 0.5,
                pl: !_.isNil(doc.github.pullRequest) ? 0.5 : undefined,
                height: '36px',
              }}
            />
          )}
        </Stack>
      </Tooltip>
      {!_.isNil(versions) && (
        <JoggrDocVersionMenu
          versions={versions}
          anchorEl={menuAnchorElRef.current}
          open={menuOpen}
          onClose={handleCloseMenu}
          slotProps={{
            paper: {
              sx: {
                minWidth: menuAnchorSize?.width,
              },
            },
          }}
        />
      )}
    </Stack>
  );
};

/*
|------------------
| Utils
|------------------
*/

const ArrowIcon = React.forwardRef<
  HTMLSpanElement,
  Omit<RikerIconProps, 'icon'> & {
    open: boolean;
  }
>(({ open = false, ...props }, ref) => (
  <RikerIcon
    {...props}
    ref={ref as RefAny}
    icon={open ? 'chevron-up' : 'chevron-down'}
  />
));

// biome-ignore lint/suspicious/noExplicitAny: We need to allow this type to be any
type RefAny = any;
