import {
  RikerIcon,
  type RikerIconComponent,
  type RikerIconProps,
} from '@joggrdocs/riker-icons';
import { useTheme } from '@mui/material';
import React from 'react';

import { IconPickerIcon, type IconType } from '@stargate/components/IconPicker';
import { createComponentClasses } from '@stargate/theme';
import { cn } from '@stargate/utils/styles';

export const directoryIconClasses = createComponentClasses('DirectoryIcon', [
  'root',
  'riker',
  'emoji',
] as const);

export interface DirectoryIconProps extends Omit<RikerIconProps, 'icon'> {
  /**
   * Whether the icon is open.
   */
  open?: boolean;
  /**
   * The type of icon to display.
   */
  iconType?: IconType;
  /**
   * The icon to display.
   */
  icon?: string;
}

/**
 * A component that displays a directory icon.
 */
export const DirectoryIcon = React.forwardRef<
  HTMLDivElement | RikerIconComponent,
  DirectoryIconProps
>(({ open = false, size = 24, icon, iconType, ...props }, ref) => {
  const theme = useTheme();

  // Handle Icons stored in the DB
  if (icon && iconType) {
    return (
      <IconPickerIcon
        {...props}
        icon={icon}
        iconType={iconType}
        className={cn([
          directoryIconClasses.root,
          directoryIconClasses.riker,
          props.className,
        ])}
        size={size}
        ref={ref}
      />
    );
  }

  if (open) {
    return (
      <RikerIcon
        {...props}
        icon='folder-open'
        className={cn([
          directoryIconClasses.root,
          directoryIconClasses.riker,
          props.className,
        ])}
        size={size}
        ref={ref as React.RefObject<RikerIconComponent>}
        fill={theme.palette.primary.light}
        color={theme.palette.primary.dark}
      />
    );
  }

  return (
    <RikerIcon
      {...props}
      icon='folder'
      className={cn([
        directoryIconClasses.root,
        directoryIconClasses.riker,
        props.className,
      ])}
      size={size}
      ref={ref as React.RefObject<RikerIconComponent>}
      fill={theme.palette.primary.light}
      color={theme.palette.primary.dark}
    />
  );
});
DirectoryIcon.displayName = 'DirectoryIcon';
