import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import TablerIconFolderX from '~icons/tabler/folder-x';

import { DialogTitle } from '@/components/dialog';
import { useNightingale } from '@/lib/nightingale';
import { useNotify } from '@/lib/notify';
import useDirectoryTree from '../../hooks/use-directory-tree';
import type { DirectoryTreeNode } from '../../types';

/*
|==========================================================================
| DirectoryActionDeleteDialog
|==========================================================================
|
| Confirm the deletion of a directory.
|
*/

export interface DirectoryActionDeleteDialogProps {
  directory: DirectoryTreeNode;
  loading?: boolean;
  error?: Error;
  onClose: () => void;
}

export const DirectoryActionDeleteDialog: React.FC<
  DirectoryActionDeleteDialogProps
> = (props) => {
  const directoryTree = useDirectoryTree();
  const notify = useNotify();
  const [deleteConfirmation, setDeleteConfirmation] = React.useState('');
  const nightingale = useNightingale();

  /*
  |------------------
  | Callbacks
  |------------------
  */

  const handleConfirmationChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDeleteConfirmation(event.target.value);
  };

  const handleClose = () => {
    setDeleteConfirmation('');
    props.onClose();
  };

  const handleDelete = () => {
    directoryTree
      .onDeleteDirectory(props.directory.id)
      .then(() => {
        directoryTree.onLoad();
        notify.send({
          message: 'Successfully deleted directory',
          severity: 'success',
        });
        nightingale.capture(['destroy', 'directory'], {
          title: props.directory.title,
          summary: props.directory.summary,
          parentId: props.directory.parentId,
          directoryId: props.directory.id,
        });
      })
      .catch((e) => {
        setDeleteConfirmation('');
        notify.error('Failed to delete directory');
      })
      .finally(() => {
        handleClose();
      });
  };

  return (
    <Dialog onClose={handleClose} open>
      <DialogTitle
        startIcon={<TablerIconFolderX />}
        title='Delete Directory'
        onClose={handleClose}
      />
      {props.error && (
        <React.Fragment>
          <DialogContent>
            <Alert severity='error'>
              <Typography variant='body1'>
                Sorry we were unable to delete the directory:{' '}
                <strong>{props.directory.title}</strong>. Please contact support
                for assistance.
              </Typography>
            </Alert>
          </DialogContent>
          <DialogActions sx={{ px: 3 }}>
            <Button onClick={handleClose} fullWidth>
              Close
            </Button>
          </DialogActions>
        </React.Fragment>
      )}
      {!props.error && (
        <React.Fragment>
          <Alert severity='warning' sx={{ borderRadius: 0, mb: 2 }}>
            <Typography variant='body1'>
              This action cannot be undone, please read the following carefully.
            </Typography>
          </Alert>
          <DialogContent sx={{ pt: 0 }}>
            <Typography variant='body1' gutterBottom>
              Are you sure you want to permanently delete the directory:{' '}
              <strong>{props.directory.title}</strong>?
            </Typography>
            <Divider sx={{ my: 1 }} />
            <Typography variant='body1' sx={{ mb: 1.5 }}>
              Please type DELETE to confirm you want to delete this directory.
            </Typography>
            <TextField
              fullWidth
              placeholder='Type DELETE to confirm'
              onChange={handleConfirmationChange}
            />
          </DialogContent>
          <DialogActions sx={{ px: 3, pt: 0, pb: 2 }}>
            <Button
              disabled={deleteConfirmation !== 'DELETE'}
              loading={props.loading}
              sx={{
                textTransform: 'none',
              }}
              onClick={handleDelete}
              fullWidth
            >
              Delete:&nbsp;<strong>{props.directory.title}</strong>
            </Button>
          </DialogActions>
        </React.Fragment>
      )}
    </Dialog>
  );
};
