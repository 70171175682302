import { Button } from '@mui/material';
import React from 'react';
import TablerIconBook from '~icons/tabler/book';

import useMermaidDocs from '../hooks/useMermaidDocs';
import type { MermaidGraphType } from '../lib/mermaidUtils';

/*
|==========================================================================
| SirenaDocsButton
|==========================================================================
|
| Documentation button for mermaid graphs.
|
*/

export interface SirenaDocsButtonProps {
  graphType?: MermaidGraphType;
}

export const SirenaDocsButton: React.FC<SirenaDocsButtonProps> = (props) => {
  const { update, url } = useMermaidDocs(props.graphType);

  React.useEffect(() => {
    if (props.graphType) {
      update(props.graphType);
    }
  }, [props.graphType, update]);

  return (
    <Button
      href={url!}
      color='secondary'
      variant='contained'
      size='small'
      startIcon={<TablerIconBook />}
      rel='noopener noreferrer'
      target='_blank'
    >
      Docs
    </Button>
  );
};

const MemoizedSirenaDocsButton = React.memo(SirenaDocsButton);
MemoizedSirenaDocsButton.displayName = 'SirenaDocsButton';

export default MemoizedSirenaDocsButton;
