import type { Theme } from '@mui/material';

export function createTypography(baseTheme: Theme) {
  return {
    fontFamily: "'Roboto', sans-serif",
    fontSize: 16,
    htmlFontSize: 16,
    h6: {
      fontWeight: 500,
      color: baseTheme.palette.text.primary,
      fontSize: '1rem',
    },
    h5: {
      fontSize: '1.25rem',
      color: baseTheme.palette.text.primary,
      fontWeight: 500,
    },
    h4: {
      fontSize: '1.5rem',
      color: baseTheme.palette.text.primary,
      fontWeight: 600,
    },
    h3: {
      fontSize: '1.75rem',
      color: baseTheme.palette.text.primary,
      fontWeight: 600,
    },
    h2: {
      fontSize: '2rem',
      color: baseTheme.palette.text.primary,
      fontWeight: 700,
    },
    h1: {
      fontSize: '2.5rem',
      color: baseTheme.palette.text.primary,
      fontWeight: 700,
    },
    subtitle1: {
      fontSize: '0.875rem',
      fontWeight: 500,
      color: baseTheme.palette.text.primary,
    },
    subtitle2: {
      fontSize: '0.75rem',
      fontWeight: 400,
      color: baseTheme.palette.text.secondary,
    },
    caption: {
      fontSize: '0.75rem',
      color: baseTheme.palette.text.secondary,
      fontWeight: 400,
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: '1.334em',
      color: baseTheme.palette.text.primary,
    },
    body2: {
      letterSpacing: '0em',
      fontWeight: 400,
      lineHeight: '1.5em',
      color: baseTheme.palette.text.primary,
    },
  };
}
