import { Box } from '@mui/material';
import React from 'react';

import { createComponentClasses } from '@stargate/theme';

export const codeSnippetRangeClasses = createComponentClasses(
  'CodeSnippetRange',
  ['root'] as const
);

export interface CodeSnippetRangeProps {
  /**
   * The start line number of the code snippet.
   */
  start: number;

  /**
   * The end line number of the code snippet.
   */
  end: number;
}

/**
 * Component for rendering a range of lines in a code snippet.
 */
export const CodeSnippetRange: React.FC<CodeSnippetRangeProps> = ({
  start: lineNumberStart,
  end: lineNumberEnd,
}) => {
  const range = React.useMemo(() => {
    const start = lineNumberStart;
    const end = lineNumberEnd;
    if (start === end) {
      return `:${start}`;
    }
    return `:${start}-${end}`;
  }, [lineNumberEnd, lineNumberStart]);

  return (
    <Box className={codeSnippetRangeClasses.root} component='span'>
      {range}
    </Box>
  );
};
