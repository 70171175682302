import _ from 'lodash';
import React from 'react';

import { useJDocContext } from '@/features/docs/hooks/use-jdoc-ctx';
import { buildJDocDraftStorageKey } from '@/features/docs/lib/draft-cache';
import type { JDocDraftLocalCacheData, JDocMode } from '@/features/docs/types';
import { useStorageItem } from '@/lib/storage';

/**
 * @returns A cache item based on the current active JoggrDoc (mode + template + doc)
 */
export const useJdocDraftLocalCache = () => {
  const ctx = useJDocContext();
  const cacheKey = React.useMemo(
    () =>
      getStorageKey({
        mode: ctx.mode,
        templateId: ctx.templateId,
        docId: ctx.docId,
      }),
    [ctx]
  );
  const cache = useStorageItem<JDocDraftLocalCacheData | null>(cacheKey, null);
  return React.useMemo(() => ({ ...cache }), [cache]);
};

/*
|------------------
| Utils
|------------------
*/

/**
 * Create a storage key for a JoggrDocDraft.
 *
 * @param payload a payload used to generate the storage key
 * @returns A JoggrDocDraft storage ID for use in LocalStorage
 */
const getStorageKey = (payload: {
  mode: JDocMode;
  templateId: string | null;
  docId: string | null;
}): string => {
  const id = payload.docId ?? payload.templateId ?? '-';
  return buildJDocDraftStorageKey(id, payload.mode as 'edit' | 'create');
};
