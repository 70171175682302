import { RikerIcon, type RikerIconName } from '@joggrdocs/riker-icons';
import { IconButton, type IconButtonProps } from '@mui/material';
import React from 'react';

export interface DeleteIconButtonProps extends IconButtonProps {
  /**
   * The icon to display.
   *
   * @default 'trash'
   */
  icon?: RikerIconName;
}

export const DeleteIconButton = React.forwardRef<
  HTMLButtonElement,
  DeleteIconButtonProps
>(({ color = 'secondary', children, icon = 'trash', ...props }, ref) => {
  return (
    <IconButton {...props} ref={ref} color={color}>
      <RikerIcon icon={icon} />
    </IconButton>
  );
});
DeleteIconButton.displayName = 'DeleteIconButton';
