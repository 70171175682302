import {
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import React from 'react';
import type { SetRequired } from 'type-fest';

import { createComponentClasses } from '@stargate/theme';

import type { QuackrQuickLinkConfig } from '../types';
import { QuackrAvatar } from './quackr-avatar';

export const quackrQuickLinksClasses = createComponentClasses(
  'QuackrQuickLinks',
  ['root'] as const
);

export interface QuackrQuickLinksProps {
  quickLinks: SetRequired<QuackrQuickLinkConfig, 'title'>[];
}

export const QuackrQuickLinks: React.FC<QuackrQuickLinksProps> = ({
  quickLinks: links,
}) => {
  return (
    <List sx={{ width: '100%', bgcolor: 'background.paper' }} dense>
      {links.map((link) => {
        return (
          <ListItemButton
            href={link.url}
            target='_blank'
            rel='noopener noreferrer'
            key={link.url}
          >
            {link.avatar && (
              <ListItemAvatar>
                <QuackrAvatar avatar={link.avatar} />
              </ListItemAvatar>
            )}
            <ListItemText primary={link.title} secondary={link.description} />
          </ListItemButton>
        );
      })}
    </List>
  );
};
