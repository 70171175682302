import { Box, type SxProps } from '@mui/material';
import _ from 'lodash';
import React, { type ImgHTMLAttributes } from 'react';

import { ImgPlaceholder } from './ImgPlaceholder';

export interface ImgProps extends BaseProps {
  /**
   * The source of the image, in relation to the images folder (/public/assets/images).
   *
   * @example
   *  src='features/docs/hero.png'
   */
  src: string;

  /**
   * The alt text for the image.
   */
  alt: string;

  /**
   * Used to set the ratio of the image.
   * @default 300
   *
   * @todo make required
   */
  height?: number;

  /**
   * Used to set the ratio of the image.
   * @default 200
   *
   * @todo make required
   */
  width?: number;

  /**
   * Should the image be responsive?
   */
  responsive?: boolean;

  /**
   * The sx prop for styling the image.
   */
  sx?: SxProps;
}

/**
 * Wrapper component for the HTML img element.
 */
export const Img = React.forwardRef<HTMLImageElement, ImgProps>(
  (
    { alt, sx, responsive = true, src, height = 200, width = 300, ...rest },
    ref
  ) => {
    const [error, setError] = React.useState(false);

    if (error) {
      return (
        <ImgPlaceholder
          sx={sx}
          severity='error'
          height={height}
          width={width}
          responsive={responsive}
        />
      );
    }

    return (
      <Box<'img'>
        {...rest}
        src={`/assets/images/${_.trimStart(src, '/')}`}
        component='img'
        ref={ref}
        onLoad={() => {
          setError(false);
        }}
        onError={() => {
          setError(true);
        }}
        sx={{
          height: responsive ? 'auto' : undefined,
          width: responsive ? '100%' : undefined,
          ...sx,
        }}
      />
    );
  }
);
Img.displayName = 'Img';

/*
|------------------
| Utils
|------------------
*/

type BaseProps = Omit<ImgHTMLAttributes<HTMLImageElement>, 'style'>;
