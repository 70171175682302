import { Chip, type ChipProps } from '@mui/material';
import React from 'react';

import { createComponentClasses } from '@stargate/theme';
import { CodeLinkIcon } from './CodeLinkIcon';

export const codeLinkChipClasses = createComponentClasses('CodeLinkChip', [
  'root',
] as const);

export interface CodeLinkChipProps extends ChipProps {}

export const CodeLinkChip = React.forwardRef<HTMLDivElement, CodeLinkChipProps>(
  ({ size = 'medium', color = 'primary', ...props }, ref) => {
    return (
      <Chip
        {...props}
        classes={codeLinkChipClasses}
        shape='rounded'
        ref={ref}
        icon={<CodeLinkIcon />}
        color={color}
        size={size}
      />
    );
  }
);
