import { Avatar, Chip, Link } from '@mui/material';
import _ from 'lodash';
import React from 'react';
import TablerIconUser from '~icons/tabler/user';

import type { JDoc } from '@stargate/features/docs';
import { useGitHubUrls } from '@stargate/features/github';
import { createComponentClasses } from '@stargate/theme';

export const joggrDocAuthorClasses = createComponentClasses('JoggrDocAuthor', [
  'root',
  'chip',
  'hover',
] as const);

export interface JoggrDocAuthorProps {
  /**
   * The author of the JoggrDoc.
   */
  author?: JDoc['author'];
}

/**
 * View the author of a JoggrDoc.
 */
export const JoggrDocAuthor: React.FC<JoggrDocAuthorProps> = ({ author }) => {
  const githubUrls = useGitHubUrls();

  const avatarSrc = React.useMemo(() => {
    return !_.isNil(author?.githubUsername)
      ? githubUrls.avatar(author?.githubUsername)
      : undefined;
  }, [author?.githubUsername, githubUrls]);

  const userLink = React.useMemo(() => {
    return !_.isNil(author?.githubUsername)
      ? githubUrls.user(author?.githubUsername)
      : undefined;
  }, [author?.githubUsername, githubUrls]);

  return (
    <Link tabIndex={-1} href={userLink} target='_blank'>
      <Chip
        shape='rounded'
        className={joggrDocAuthorClasses.chip}
        avatar={
          <Avatar sx={{ width: 32, height: 32 }} src={avatarSrc}>
            <TablerIconUser width={24} height={24} />
          </Avatar>
        }
        label={author?.githubUsername ?? 'anonymous'}
      />
    </Link>
  );
};
