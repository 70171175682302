import { useMutation } from '@tanstack/react-query';
import _ from 'lodash';

import { apiClient } from '@stargate/api/api-client';
import { transformFromAPIContent } from '@stargate/api/lib/transformers';
import type { APIBody, UseMutationHookOptions } from '@stargate/api/types';

export interface RenderTemplateMutationPayload {
  /**
   * The ID of the template.
   */
  templateId: string;
  /**
   * The variables to render the template with.
   */
  variables?: APIBody<'POST /templates/:templateId/render'>['variables'];
}

/**
 * Render a template.
 * @api `POST /templates/:templateId/render`
 * @param payload The payload for the mutation.
 * @returns The rendered template.
 */
export async function renderTemplateMutationFn(
  payload: RenderTemplateMutationPayload
) {
  const { templateId, variables = [] } = payload;
  const result = await apiClient.request('POST /templates/:templateId/render', {
    params: { templateId },
    body: { variables },
  });
  return {
    ...result,
    content: await transformFromAPIContent(result.content),
  };
}

/**
 * Render a template.
 * @api `POST /templates/:templateId/render`
 * @param options The mutation options.
 * @returns A mutation result object.
 */
export const useRenderTemplateMutation = (options?: UseMutationHookOptions) => {
  return useMutation({
    ...options,
    mutationFn: renderTemplateMutationFn,
  });
};
