import * as hookz from '@react-hookz/web';
import mermaid, { type MermaidConfig } from 'mermaid';
import React from 'react';

import { useTheme } from '@mui/material';

import { type MermaidGraphType, detectGraphType } from '../lib/mermaidUtils';
import useMermaidConfig from './useMermaidConfig';

/*
|==========================================================================
| useRenderMermaid
|==========================================================================
|
| This hook is used to render mermaid diagrams, including handling errors,
| loading states and configuration.
|
*/

/*
|------------------
| Core Types
|------------------
*/

export type MermaidTheme = 'default' | 'dark' | 'forest' | 'neutral';

export type UpdateThemeFunc = (theme: MermaidTheme) => void;

export type UpdateGraphFunc = (graphCode: string) => void;

export type UpdateGraphAsyncFunc = (graphCode: string) => Promise<void>;

/**
 * Status of the mermaid diagram.
 *
 * Status definitions:
 * - idle: nothing has been triggered, default state
 * - loading: the diagram is being rendered or re-rendered
 * - error: the diagram failed to render
 * - success: the diagram was successfully rendered
 *
 */
export type Status = 'idle' | 'loading' | 'error' | 'success';

/*
|------------------
| Public API
|------------------
*/

export interface MermaidRenderOptions {
  defaultGraphCode?: string;
  defaultTheme?: MermaidTheme;
  mermaidConfig?: MermaidConfig;
}

export interface MermaidRender {
  status: Status;
  graphType: MermaidGraphType | null;
  graphSvg: string | null;
  error: Error | null;
  update: UpdateGraphFunc;
  updateAsync: UpdateGraphAsyncFunc;
  updateTheme: UpdateThemeFunc;
}

export const useMermaidRender = (options?: MermaidRenderOptions) => {
  const config = useMermaidConfig(options?.mermaidConfig);
  const theme = useTheme();

  /*
  |------------------
  | Computed
  |------------------
  */
  const mode = theme.palette.mode;

  /*
  |------------------
  | State
  |------------------
  */

  const [mermaidTheme, setMermaidTheme] = React.useState<MermaidTheme>(
    (options?.defaultTheme ?? mode === 'dark') ? 'dark' : 'default'
  );
  const [svg, setSvg] = React.useState<string | null>(null);
  const [graph, setGraph] = React.useState<string | null>(null);
  const [graphType, setGraphType] = React.useState<MermaidGraphType | null>(
    null
  );
  const [status, setStatus] = React.useState<Status>('idle');
  const [error, setError] = React.useState<Error | null>(null);

  /*
  |------------------
  | Helpers
  |------------------
  */

  const initialize = React.useCallback(
    (theme: MermaidTheme) => {
      mermaid.initialize({
        ...config,
        theme,
      });
    },
    [config]
  );

  /*
  |------------------
  | Handlers
  |------------------
  */

  const updateGraphAsync = React.useCallback<UpdateGraphAsyncFunc>(
    async (graphCode) => {
      // Handle "empty" graph code aka ""
      if (!graphCode) {
        setGraph(graphCode);
        setSvg(null);
        setGraphType(null);
        setStatus('idle');
        return;
      }

      setStatus('loading');
      try {
        await mermaid.parse(graphCode);
        const type = detectGraphType(graphCode);
        // This is a hack, because `mermaid` wants you to attach the svg to the DOM and bind to it
        // this causes problems as it continues to render the same graph over and over again and/or removes the svg.
        // We provide a random ID to the svg so that it doesn't get removed (hopefully...)
        const { svg } = await mermaid.render(
          `mermaid-${crypto.randomUUID()}`,
          graphCode
        );
        setGraph(graphCode);
        setGraphType(type);
        setSvg(svg);
        setStatus('success');
      } catch (error) {
        setStatus('error');
        if (error instanceof Error) {
          setError(error);
        } else {
          setError(new Error('Unknown Error'));
        }
      }
    },
    []
  );

  const updateGraph = React.useCallback<UpdateGraphFunc>(
    (graphCode) => {
      updateGraphAsync(graphCode);
    },
    [updateGraphAsync]
  );

  const updateTheme = React.useCallback<UpdateThemeFunc>((theme) => {
    setMermaidTheme(theme);
  }, []);

  /*
  |------------------
  | Effects
  |------------------
  */

  hookz.useMountEffect(() => {
    initialize(mermaidTheme);

    if (options?.defaultGraphCode) {
      updateGraph(options.defaultGraphCode);
    }
  });

  React.useEffect(() => {
    // Re-initialize mermaid with the new theme
    initialize(mermaidTheme);
    // We have to re-render the graph
    // if the theme changes
    if (graph !== null) {
      updateGraph(graph);
    }
  }, [mermaidTheme]);

  // Flip the theme when the mode changes
  React.useEffect(() => {
    if (mode === 'dark') {
      updateTheme('dark');
    } else {
      updateTheme('default');
    }
  }, [mode]);

  /*
  |------------------
  | Public API
  |------------------
  */

  return React.useMemo(() => {
    return {
      status,
      graphSvg: svg,
      graphType,

      error: status === 'error' ? error! : null,
      update: updateGraph,
      updateAsync: updateGraphAsync,
      updateTheme,
    };
  }, [
    error,
    status,
    svg,
    updateGraph,
    graphType,
    updateGraphAsync,
    updateTheme,
  ]);
};

export default useMermaidRender;
