import type { JDocMode } from '../types';

/**
 * Build a formatted storage key for a JoggrDocDraft.
 * @param id the id of the JoggrDocDraft
 * @param mode the mode of the JoggrDocDraft
 * @returns a formatted storage key for a JoggrDocDraft
 */
export function buildJDocDraftStorageKey(id: string, mode: JDocMode) {
  return `joggrdoc:draft:${mode}:${id}`;
}

/**
 * Check if a storage key is a JoggrDocDraft storage key.
 * @param key the storage key to check
 * @returns true if the storage key is a JoggrDocDraft storage key, false otherwise
 */
export function isJDocDraftStorageKey(key: string, mode: JDocMode): boolean {
  return key.startsWith(`joggrdoc:draft:${mode}`);
}

/**
 * Get the JoggrDocDraft ID from a storage key.
 * @param key the storage key to get the JoggrDocDraft ID from
 * @returns the JoggrDocDraft ID
 */
export function getJDocDraftIdFromStorageKey(key: string): string {
  return key
    .replace('joggrdoc:draft:edit:', '')
    .replace('joggrdoc:draft:create:', '');
}
