import { Alert, AlertTitle, Box, Divider } from '@mui/material';
import React from 'react';

import {
  SendInviteForm,
  type SendInviteFormProps,
  useInvite,
} from '@stargate/features/user-invites';
import { useLogger } from '@stargate/logger';
import { useSentry } from '@stargate/vendors/sentry';

import { OnboardingStep, type OnboardingStepItem } from '../OnboardingStep';

/*
|==========================================================================
| InviteTeam
|==========================================================================
|
| Allows the user to invite their team to Joggr.
|
*/

export type OnboardingStepInviteTeamProps = OnboardingStepItem;

export const OnboardingStepInviteTeam: React.FC<
  OnboardingStepInviteTeamProps
> = ({ onContinue, ...props }) => {
  const invite = useInvite();
  const logger = useLogger();
  const sentry = useSentry();

  const handleSendInvite = React.useCallback<
    SendInviteFormProps['onSendInvite']
  >(
    async (payload) => {
      const result = await invite.submit(payload);

      if (result === 'success') {
        onContinue();
      } else {
        logger.error({ result }, 'Failed to send invites');
        sentry.captureMessage('Failed to send invites');
      }

      return result;
    },
    [invite, logger, onContinue, sentry.captureMessage]
  );

  return (
    <OnboardingStep
      {...props}
      onContinue={onContinue}
      title='Invite your team'
      icon='send'
      contentTitle='Invite your team to Joggr'
      contentDescription='Start collaborating with your teammates by sending them an invite by email.'
      content={
        <Box>
          <Divider sx={{ my: 3 }} />
          {invite.errors.length > 0 && (
            <Alert severity='error' sx={{ mt: 1 }}>
              <AlertTitle>Failed to send invites</AlertTitle>
              {invite.errors.join(', ')}
            </Alert>
          )}
          {invite.status === 'success' && invite.errors.length === 0 && (
            <Alert severity='success' sx={{ mt: 1 }}>
              <AlertTitle>Invites sent</AlertTitle>
              Your invites have been sent successfully.
            </Alert>
          )}
          <SendInviteForm
            loading={invite.status === 'loading'}
            onSendInvite={handleSendInvite}
            onReset={invite.reset}
          />
        </Box>
      }
    />
  );
};
