import { Box, Button } from '@mui/material';
import React from 'react';
import TablerIconBrandGithub from '~icons/tabler/brand-github';
import TablerIconShieldShare from '~icons/tabler/shield-share';

import { GitHubAuthorizeButton } from '@stargate/features/github';
import * as hooks from '@stargate/hooks';

import SettingsActionCard from '../SettingsActionCard';
import SettingsActionCards from '../SettingsActionCards';
import SettingsSectionHeader from '../SettingsSectionHeader';

function Security() {
  const staticUrls = hooks.useStaticUrls();
  return (
    <Box>
      <SettingsSectionHeader
        title='Security'
        description="Manage your account's security settings."
      />
      <SettingsActionCards>
        <SettingsActionCard
          title='GitHub Authorization'
          icon={<TablerIconBrandGithub />}
          content='Reauthorize with Github to get access to your GitHub data (or add an organization).'
          actions={
            <GitHubAuthorizeButton>
              Reauthorize with GitHub
            </GitHubAuthorizeButton>
          }
          item
        />
        <SettingsActionCard
          title='Report a Security Issue'
          icon={<TablerIconShieldShare />}
          content='Report a security issue to the Joggr team.'
          actions={
            <Button
              LinkComponent='a'
              href={staticUrls.securityVulnerabilityForm}
              rel='noopener noreferrer'
              target='_blank'
            >
              Report Security Issue
            </Button>
          }
          item
        />
      </SettingsActionCards>
    </Box>
  );
}

export default Security;
