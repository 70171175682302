import * as hookz from '@react-hookz/web';
import React from 'react';

import { RikerIcon } from '@joggrdocs/riker-icons';
import { Box, darken, useTheme } from '@mui/material';

import { DirectoryTreeExplorer } from '@stargate/features/directories';

import useSideBar from '../../hooks/use-side-bar';

/*
|==========================================================================
| SideBarDrawer
|==========================================================================
|
| A resizable sidebar component that allows the user to adjust its width.
| It includes a content area that can display different components based on
| the current state, such as a document directory explorer.
|
*/

export interface SideBarDrawerProps {
  children?: React.ReactNode;
}

export const SideBarDrawer: React.FC<SideBarDrawerProps> = (props) => {
  const sidebarRef = React.useRef<HTMLDivElement | null>(null);
  const [isResizing, setIsResizing] = React.useState(false);
  const [sidebarState, sidebarActions] = useSideBar();
  const theme = useTheme();
  const windowSize = hookz.useWindowSize();

  const startResizing = React.useCallback(() => {
    setIsResizing(true);
  }, []);

  const stopResizing = React.useCallback(() => {
    setIsResizing(false);
  }, []);

  const resize = React.useCallback(
    (mouseMoveEvent: MouseEvent) => {
      if (sidebarRef.current != null && isResizing) {
        // Calculate new width based on mouse position
        const newWidth =
          mouseMoveEvent.clientX -
          sidebarRef.current.getBoundingClientRect().left;

        // Enforce the width to be within the min and max bounds
        const constrainedWidth = Math.min(
          Math.max(newWidth, sidebarState.drawerMinWidth),
          sidebarState.drawerMaxWidth
        );

        sidebarActions.setDrawerWidth({
          width: constrainedWidth,
        });
      }
    },
    [
      isResizing,
      sidebarActions,
      sidebarState.drawerMaxWidth,
      sidebarState.drawerMinWidth,
    ]
  );

  const handleCloseSidebar = React.useCallback(() => {
    sidebarActions.closeDrawer();
  }, [sidebarActions]);

  // Add mouse event listeners for the resizing functionality.
  React.useEffect(() => {
    window.addEventListener('mousemove', resize);
    window.addEventListener('mouseup', stopResizing);
    return () => {
      window.removeEventListener('mousemove', resize);
      window.removeEventListener('mouseup', stopResizing);
    };
  }, [resize, stopResizing]);

  return (
    <Box
      ref={sidebarRef}
      sx={{
        position: 'fixed',
        boxSizing: 'border-box',
        width: sidebarState.drawerWidth,
        top: `${theme.measurements.topBar.height}px`,
        left: `${theme.measurements.sideBar.width}px`,
        display: sidebarState.drawerOpen ? 'block' : 'none',
        height: `calc(100vh - ${theme.measurements.topBar.height}px)`,
        borderRight: `1px solid ${theme.palette.divider}`,
        bgcolor: theme.palette.background.dashboardSideBar,
        color: theme.palette.text.primary,
        zIndex: theme.zIndex.appBar,
      }}
    >
      {sidebarState.drawerContent === 'explorer' && (
        <Box
          onClick={handleCloseSidebar}
          sx={{
            position: 'absolute',
            top: '50%',
            right: '-12px',
            cursor: 'pointer',
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            alignContent: 'center',
            justifyContent: 'center',
            width: '24px',
            height: '24px',
            textAlign: 'center',
            zIndex: 1,
            backgroundColor: theme.palette.background.dashboardSideBar,
            ...theme.applyStyles('dark', {
              border: `1px solid ${theme.palette.divider}`,
            }),
            ...theme.applyStyles('light', {
              border: `1px solid ${darken(theme.palette.divider, 0.1)}`,
            }),
            '&:hover': {
              borderSize: '2px',
              ...theme.applyStyles('dark', {
                backgroundColor: theme.palette.background.paper,
              }),
              ...theme.applyStyles('light', {
                backgroundColor: darken(theme.palette.background.paper, 0.1),
              }),
            },
          }}
        >
          <RikerIcon icon='chevron-left' size={16} />
        </Box>
      )}
      {sidebarState.drawerContent === 'explorer' && (
        <Box
          sx={{
            width: `${sidebarState.drawerWidth}px`,
            p: '8px',
            position: 'relative',
            overflowX: 'hidden',
          }}
        >
          <DirectoryTreeExplorer
            width={sidebarState.drawerWidth - 16}
            maxHeight={windowSize.height - theme.measurements.topBar.height}
          />
        </Box>
      )}
      <Box
        onMouseDown={startResizing}
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          width: '4px',
          cursor: 'ew-resize',
          zIndex: 0,
          '&:hover': {
            backgroundColor: theme.palette.background.dashboardMain,
          },
        }}
      />
    </Box>
  );
};

export default SideBarDrawer;
