import {
  CardActions,
  type CardActionsProps,
  lighten,
  useTheme,
} from '@mui/material';
import React from 'react';

import { createComponentClasses } from '@stargate/theme';

export const codeCardFooterClasses = createComponentClasses('CodeCardFooter');

export type CodeCardFooterProps = CardActionsProps;

/**
 * CardFooter component for code.
 */
export const CodeCardFooter = React.forwardRef<
  HTMLDivElement,
  CodeCardFooterProps
>((props, ref) => {
  const theme = useTheme();
  return (
    <CardActions
      {...props}
      ref={ref}
      classes={codeCardFooterClasses}
      sx={{
        ...props.sx,
        width: '100%',
        padding: theme.spacing(1),
        borderTop: `0.5px solid ${theme.palette.divider}`,
        background:
          theme.palette.mode === 'dark'
            ? lighten(theme.palette.grey[900], 0.05)
            : theme.palette.grey[100],
      }}
    />
  );
});
CodeCardFooter.displayName = 'CodeCardFooter';
