import { useTheme } from '@mui/material';
import React from 'react';
import TablerIconBuildingCommunity from '~icons/tabler/building-community';

export type GitHubOrganizationIconProps = React.ComponentProps<
  typeof TablerIconBuildingCommunity
>;

/**
 * A component that displays a repository icon.
 */
export const GitHubOrganizationIcon = React.forwardRef<
  SVGSVGElement,
  GitHubOrganizationIconProps
>((props, ref) => {
  const theme = useTheme();
  return (
    <TablerIconBuildingCommunity
      {...props}
      ref={ref}
      fill={theme.palette.primary.light}
      color={theme.palette.primary.dark}
    />
  );
});
GitHubOrganizationIcon.displayName = 'GitHubOrganizationIcon';
