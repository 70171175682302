import { usePostHog } from 'posthog-js/react';
import React from 'react';

import type { User } from '@stargate/features/user';
import type { ActiveWorkspace } from '@stargate/features/workspaces';
import { useConfig } from '@stargate/hooks';
import { useLogger } from '@stargate/logger';
import { useFrigadeGroup, useFrigadeUser } from '@stargate/vendors/frigade';

/*
|==========================================================================
| useNightingaleIdentify
|==========================================================================
|
| A hook that identifies the current user to the Nightingale library.
|
*/

export interface UseNightingaleIdentifyHook {
  /**
   * Identifies the current user to the Nightingale library.
   */
  user: (user: User) => Promise<void>;

  /**
   * Groups the current user to the Nightingale library.
   */
  group: (workspace: ActiveWorkspace) => Promise<void>;

  /**
   * Resets the current user to a "guest" (`null`) in the Nightingale library.
   */
  reset: () => Promise<void>;
}

export const useNightingaleIdentify = (): UseNightingaleIdentifyHook => {
  const posthog = usePostHog();
  const frigadeUser = useFrigadeUser();
  const frigadeGroup = useFrigadeGroup();
  const config = useConfig();
  const logger = useLogger();

  const group = React.useCallback(
    async (workspace: ActiveWorkspace) => {
      posthog.group('workspace', workspace.id, {
        name: workspace.name,
        description: workspace.description,
        environment: config.environment ?? 'unknown',
        application: 'stargate',
      });

      try {
        await frigadeGroup.identify(workspace.id, {
          name: workspace.name,
          description: workspace.description ?? null,
          environment: config.environment ?? 'unknown',
          application: 'stargate',
        });
      } catch (error) {
        logger.error({ error }, 'Failed to identify group for Frigade');
      }
    },
    [config.environment, frigadeGroup, posthog, logger.error]
  );

  const user = React.useCallback(
    async (user: User, activeWorkspace?: ActiveWorkspace) => {
      posthog.identify(user.id, {
        email: user.email,
        auth0Id: user.auth0Id,
      });

      try {
        await frigadeUser.identify(user.id, {
          email: user.email,
          githubUserName: user.githubUsername ?? null,
          createdAt: user.createdAt,
          updatedAt: user.updatedAt,
        });
      } catch (error) {
        logger.error({ error }, 'Failed to identify user for Frigade');
      }
      if (activeWorkspace) {
        await group(activeWorkspace);
      }
    },
    [frigadeUser, group, posthog, logger.error]
  );

  const reset = React.useCallback(async () => {
    posthog.reset();

    try {
      await frigadeUser.reset();
    } catch (error) {
      logger.error({ error }, 'Failed to reset user for Frigade');
    }
  }, [posthog, frigadeUser, logger.error]);

  return { user, group, reset };
};
