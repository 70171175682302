import React from 'react';
import TablerIconGitCommit from '~icons/tabler/git-commit';

import { Button, type ButtonProps } from '@mui/material';

export type GitHubCommitButtonProps = Omit<ButtonProps, 'startIcon'>;

/**
 * Button to represent an Git Commit action.
 */
export const GitHubCommitButton = React.forwardRef<
  HTMLButtonElement,
  GitHubCommitButtonProps
>((props, ref) => {
  const {
    color = 'success',
    children = 'Commit',
    size = 'small',
    variant = 'contained',
    ...restProps
  } = props;
  return (
    <Button
      {...restProps}
      ref={ref}
      startIcon={<TablerIconGitCommit />}
      variant={variant}
      color={color}
      size={size}
    >
      {children}
    </Button>
  );
});
