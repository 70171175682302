import React from 'react';

import {
  AppBar,
  Box,
  Stack,
  Toolbar,
  toolbarClasses,
  useTheme,
} from '@mui/material';

import LogoAnchor, {
  logoAnchorClasses,
} from '@stargate/components/Utils/LogoAnchor';

import { Search } from '@stargate/features/search';
import { ModeSwitch, UserProfileActions } from '@stargate/features/user';

/*
|==========================================================================
| TopBar
|==========================================================================
|
| The TopBar for the DashboardLayout.
|
*/

const TopBar: React.FC = () => {
  const theme = useTheme();
  const spacing = 2;
  return (
    <AppBar
      enableColorOnDark
      position='fixed'
      color='inherit'
      elevation={0}
      sx={{
        maxHeight: theme.measurements.topBar.height,
        bgcolor: theme.palette.background.dashboardTopBar,
        borderBottom: `1px solid ${theme.palette.divider}`,
        [`& .${toolbarClasses.root}`]: {
          px: 2,
          py: 1,
          '& > *:not(:last-child):not(:first-of-type)': {
            ml: spacing / 2,
            mr: spacing / 2,
          },
          '& > *:last-child': {
            ml: spacing / 2,
          },
          '& > *:first-of-type': {
            mr: spacing / 2,
          },
        },
      }}
    >
      <Toolbar variant='dense'>
        <LogoAnchor
          sx={{
            display: 'flex',
            alignItems: 'center',
            [`& .${logoAnchorClasses.img}`]: {
              maxHeight: '40px',
              width: 'auto',
            },
          }}
        />
        <Box sx={{ flexGrow: 1 }} />
        <Search />
        <Box sx={{ flexGrow: 1 }} />
        <ModeSwitch />
        <UserProfileActions />
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
