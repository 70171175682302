import _ from 'lodash';
import React from 'react';

import { type JDocMode, useJDocDrafts } from '@stargate/features/docs';
import { useLocation, useParams, useSearchParams } from '@stargate/routes';

export type JDocContextType = 'new' | 'existing';

export type JDocContext<M extends JDocContextType> = M extends 'new'
  ? JDocNewContext
  : M extends 'existing'
    ? JDocExistingContext
    : never;

/**
 * Infer context metadata of the JoggrDoc based on the current URL & search params, & other inputs.
 *
 * @param expected - The expected context type.
 * @returns {JDocContext}
 */
export const useJDocContext = <M extends JDocContextType>(
  expected?: M
): JDocContext<M> => {
  const location = useLocation();
  const editParams = useParams('app.documents.edit');
  const draftParams = useParams('app.documents.draft');
  const viewParams = useParams('app.documents.view');
  const [createSearchParamState] = useSearchParams('app.documents.create');
  const drafts = useJDocDrafts();

  // A semi-hacky way to determine the mode based on the URL
  const mode = React.useMemo<JDocMode | null>(() => {
    if (!location.pathname.includes('/documents')) {
      return null;
    }

    if (location.pathname.includes('/edit')) {
      return 'edit';
    }
    if (
      location.pathname.includes('/create') ||
      location.pathname.includes('/draft')
    ) {
      return 'create';
    }
    if (location.pathname.includes('/documents/')) {
      return 'view';
    }
    return null;
  }, [location.pathname]);

  const docId = React.useMemo<string | null>(() => {
    return viewParams.id ?? editParams.id ?? draftParams.id ?? null;
  }, [viewParams, editParams, draftParams]);

  const templateId = React.useMemo<string | null>(() => {
    const draft = drafts.find(draftParams.id);
    if (!_.isNil(draft?.templateId)) {
      return draft.templateId;
    }

    if (!_.isNil(createSearchParamState.template)) {
      return createSearchParamState.template;
    }

    if (createSearchParamState.blank === true || mode === 'create') {
      return 'blank';
    }

    return null;
  }, [
    createSearchParamState.template,
    createSearchParamState.blank,
    mode,
    drafts,
    draftParams.id,
  ]);

  const dirId = React.useMemo<string | null>(() => {
    const draft = drafts.find(draftParams.id);
    if (!_.isNil(draft?.parentId)) {
      return draft.parentId;
    }

    return createSearchParamState.dir ?? null;
  }, [createSearchParamState, drafts, draftParams.id]);

  return React.useMemo(() => {
    if (!mode) {
      throw new Error('Could not determine JoggrDoc mode');
    }

    if (mode === 'create') {
      if (expected && expected !== 'new') {
        throw new Error(`Expected JoggrDoc mode to be "${expected}"`);
      }
      return {
        mode,
        docId,
        templateId,
        dirId,
      } as JDocContext<M>;
    }

    if (expected && expected !== 'existing') {
      throw new Error(`Expected JoggrDoc mode to be "${expected}"`);
    }

    if (!docId) {
      throw new Error('Could not determine JoggrDoc ID');
    }

    return {
      mode,
      docId,
      templateId: null,
      dirId: null,
    } as JDocContext<M>;
  }, [mode, docId, templateId, dirId, expected]);
};

/*
|------------------
| Utils
|------------------
*/

export interface JDocExistingContext extends JDocBaseContext {
  mode: 'view' | 'edit';
  docId: string;
  templateId: null;
  dirId: null;
}

export interface JDocNewContext extends JDocBaseContext {
  mode: 'create';
  docId: null;
}

export interface JDocBaseContext {
  /**
   * The mode of the JoggrDoc, based on the URL.
   */
  mode: JDocMode;

  /**
   * The ID of the JoggrDoc, provided by the URL.
   */
  docId: string | null;

  /**
   * The ID of the JoggrDir, provided by the URL. Used to determine the JoggrDir during
   * JoggrDoc creation.
   */
  dirId: 'root' | string | null;

  /**
   * The ID of the template, provided by the URL.
   */
  templateId: 'blank' | string | null;
}
