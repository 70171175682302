import type { ThemePaletteColorVariant } from '../types';

/**
 * Check if a color is a valid palette color variant.
 * @param color A color variant.
 * @returns Whether the color is a valid palette color variant.
 */
export function isPaletteColorVariant(
  color: string
): color is ThemePaletteColorVariant {
  return [
    'primary',
    'secondary',
    'error',
    'warning',
    'success',
    'info',
  ].includes(color);
}
