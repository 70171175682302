import {
  Box,
  Stack,
  Tooltip,
  Typography,
  tooltipClasses,
  useTheme,
} from '@mui/material';
import _ from 'lodash';
import React from 'react';
import type * as TypeFest from 'type-fest';

import { createComponentClasses } from '@stargate/theme';

import type { DirectoryTreeDirectoryNode } from '../../types';
import { DirectoryIcon } from '../Icons';
import { DirectoryBreadCrumbsContainer } from './DirectoryBreadCrumbsContainer';

export const directoryBreadCrumbsClasses = createComponentClasses(
  'DirectoryBreadCrumbs',
  ['root', 'crumb', 'divider', 'tooltip'] as const
);

export type DirectoryBreadCrumbsProps = TypeFest.Simplify<{
  /**
   * The directories to render as breadcrumbs (in order from root to leaf)
   */
  directoryBreadCrumbs: DirectoryBreadCrumb[];

  /**
   * Either show all or just the first and last.
   * @default 'all'
   */
  mode?: 'all' | 'first-and-last';

  /**
   * The tooltip to show when hovering over the breadcrumbs
   */
  tooltip?: React.ReactNode;

  /**
   * A callback to call when the breadcrumbs are clicked
   */
  onClick?: () => void;
}>;

export const DirectoryBreadCrumbs: React.FC<DirectoryBreadCrumbsProps> = ({
  mode = 'all',
  ...props
}) => {
  const theme = useTheme();
  const clickable = _.isFunction(props.onClick);

  // Sort the directories into the correct order
  // based on the parent id
  const directoryBreadCrumbs = React.useMemo(() => {
    return sortDirectoryBreadCrumbs(props.directoryBreadCrumbs).map(
      (directory) => {
        return (
          <Stack
            classes={{
              root: directoryBreadCrumbsClasses.crumb,
            }}
            key={directory.id}
            direction='row'
            spacing={directory.title ? 1 : 0}
            alignItems='center'
            sx={{
              borderRadius: 1,
              border: `1px solid ${theme.palette.divider}`,
              px: 1,
              py: 0.25,
              height: '32px',
            }}
          >
            <DirectoryIcon
              size={16}
              icon={directory.icon}
              iconType={directory.iconType}
            />
            <Typography variant='body2' noWrap>
              {directory.title}
            </Typography>
          </Stack>
        );
      }
    );
  }, [props.directoryBreadCrumbs, theme.palette.divider]);

  return (
    <DirectoryBreadCrumbsContainer
      classes={{
        root: directoryBreadCrumbsClasses.root,
      }}
      sx={{
        borderRadius: 1,
        width: 'fit-content',
        padding: theme.spacing(0.5),
        [`& .${directoryBreadCrumbsClasses.tooltip}`]: {
          backgroundColor: theme.palette.background.paper,
          color: theme.palette.divider,
          border: `1px solid ${theme.palette.divider}`,
          maxWidth: 'unset',
          p: 1,
          [`& .${tooltipClasses.arrow}`]: {
            color: theme.palette.divider,
          },
        },
        ...(clickable && {
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        }),
      }}
      tooltip={props.tooltip}
      onClick={clickable ? props.onClick : undefined}
    >
      {directoryBreadCrumbs.length > 0 && _.first(directoryBreadCrumbs)}
      {directoryBreadCrumbs.length > 2 &&
        mode === 'all' &&
        _.filter(
          directoryBreadCrumbs,
          (_ignore, index) =>
            index !== 0 && index !== directoryBreadCrumbs.length - 1
        )}
      {directoryBreadCrumbs.length > 2 && mode === 'first-and-last' && (
        <Tooltip
          classes={{
            tooltip: directoryBreadCrumbsClasses.tooltip,
          }}
          title={
            <DirectoryBreadCrumbsContainer>
              {_.slice(
                directoryBreadCrumbs,
                1,
                directoryBreadCrumbs.length - 1
              )}
            </DirectoryBreadCrumbsContainer>
          }
          placement='bottom'
          arrow
          PopperProps={{
            disablePortal: true,
          }}
        >
          <Box
            sx={{
              borderRadius: 1,
              border: `1px solid ${theme.palette.divider}`,
              px: 1,
              py: 0.25,
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: theme.palette.action.hover,
              },
            }}
          >
            ...
          </Box>
        </Tooltip>
      )}
      {directoryBreadCrumbs.length > 1 && _.last(directoryBreadCrumbs)}
    </DirectoryBreadCrumbsContainer>
  );
};

export interface DirectoryBreadCrumb {
  /**
   * The id of the directory
   */
  id: string;

  /**
   * The title of the directory
   */
  title?: string;

  /**
   * The icon to render
   */
  icon?: string;

  /**
   * The type of icon to render
   */
  iconType?: DirectoryTreeDirectoryNode['iconType'];

  /**
   * A summary of the directory
   */
  summary?: string;

  /**
   * The parent directory id, if `undefined` then this is the root directory
   */
  parentId?: string;
}

/**
 * Sort the directories into the correct order based on the parent id
 *
 * @param directoryBreadCrumbs A list of directories to sort
 * @returns A sorted list of directories
 */
export const sortDirectoryBreadCrumbs = (
  directoryBreadCrumbs: DirectoryBreadCrumb[]
) => {
  return [...directoryBreadCrumbs]
    .sort((a, b) => {
      if (a.parentId === b.id) {
        return -1;
      }

      if (b.parentId === a.id) {
        return 1;
      }

      // MUST always be first
      if (!b.parentId) {
        return 100;
      }

      return 0;
    })
    .reverse();
};
