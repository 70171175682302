import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Tab,
  Typography,
} from '@mui/material';
import React from 'react';
import TablerIconPalette from '~icons/tabler/palette';
import TablerIconSettings from '~icons/tabler/settings';
import TablerIconShieldHalfFilled from '~icons/tabler/shield-half-filled';

import { IconAvatar } from '@stargate/components/Avatars';

import { type User, UserProfileIcon } from '@stargate/features/user';
import { WorkspaceIcon } from '@stargate/features/workspaces';
import useSearchParams from '@stargate/hooks/use-search-params';
import { FormattedMessage } from '@stargate/localization';

import Appearance from './SettingsSectionAppearance';
import Configuration from './SettingsSectionConfiguration';
import Security from './SettingsSectionSecurity';
import UserProfile from './SettingsSectionUserProfile';
import Workspaces from './SettingsSectionWorkspaces';

export type SettingsSection =
  | 'profile'
  | 'configuration'
  | 'security'
  | 'appearance'
  | 'workspaces';

/*
|----------------------------------
| Utils & Components
|----------------------------------
*/

function Label(props: {
  title: React.ReactNode;
  description: React.ReactNode;
}) {
  return (
    <Stack spacing={0}>
      <Typography variant='h5'>{props.title}</Typography>
      <Typography variant='subtitle2'>{props.description}</Typography>
    </Stack>
  );
}

/*
|------------------
| AccountSettings Definition
|------------------
*/

export interface SettingsSectionsProps {
  authenticatedUser: User;
  reloadUser: () => void;
  defaultTab?: SettingsSection;
}

function SettingsSections(props: SettingsSectionsProps) {
  const { authenticatedUser, defaultTab = 'profile' } = props;
  const [value, setValue] = React.useState<SettingsSection>(defaultTab);
  const [, setSearchParams] = useSearchParams();

  /*
  |------------------
  | Computed
  |------------------
  */
  const buildProps = (accountSetting: SettingsSection) => {
    return {
      value: accountSetting,
      'aria-controls': `account-setting-${accountSetting}`,
      sx: {
        textAlign: 'left',
        textTransform: 'none',
        justifyContent: 'flex-start',
      },
    };
  };

  /*
  |------------------
  | Callbacks
  |------------------
  */
  const handleChange = (
    event: React.SyntheticEvent,
    newValue: SettingsSection
  ) => {
    setSearchParams({ activeTab: newValue });
    setValue(newValue);
  };

  /*
  |------------------
  | Effects
  |------------------
  */

  React.useEffect(() => {
    setValue(defaultTab);
  }, [defaultTab]);

  return (
    <Card>
      <CardHeader
        avatar={<IconAvatar icon='user-circle' />}
        title={<FormattedMessage id='pages.settings.title' />}
        subTitle="Manage your account's settings."
        sx={{ borderBottom: '1px solid', borderColor: 'divider' }}
      />
      <CardContent>
        <Box sx={{ flexGrow: 1, bgcolor: 'transparent', display: 'flex' }}>
          <TabContext value={value}>
            <TabList
              onChange={handleChange}
              orientation='vertical'
              aria-label='Account Settings Panel'
            >
              <Tab
                icon={<UserProfileIcon />}
                iconPosition='start'
                label={
                  <Label
                    title={
                      <FormattedMessage id='pages.settings.profile.title' />
                    }
                    description={
                      <FormattedMessage id='pages.settings.profile.description' />
                    }
                  />
                }
                {...buildProps('profile')}
              />
              <Tab
                icon={<WorkspaceIcon />}
                iconPosition='start'
                label={
                  <Label
                    title={
                      <FormattedMessage id='pages.settings.workspaces.title' />
                    }
                    description={
                      <FormattedMessage id='pages.settings.workspaces.description' />
                    }
                  />
                }
                {...buildProps('workspaces')}
              />
              <Tab
                icon={<TablerIconSettings />}
                iconPosition='start'
                label={
                  <Label
                    title={
                      <FormattedMessage id='pages.settings.config.title' />
                    }
                    description={
                      <FormattedMessage id='pages.settings.config.description' />
                    }
                  />
                }
                {...buildProps('configuration')}
              />
              <Tab
                icon={<TablerIconPalette />}
                iconPosition='start'
                label={
                  <Label
                    title={
                      <FormattedMessage id='pages.settings.appearance.title' />
                    }
                    description={
                      <FormattedMessage id='pages.settings.appearance.description' />
                    }
                  />
                }
                {...buildProps('appearance')}
              />
              <Tab
                icon={<TablerIconShieldHalfFilled />}
                iconPosition='start'
                label={
                  <Label
                    title={
                      <FormattedMessage id='pages.settings.security.title' />
                    }
                    description={
                      <FormattedMessage id='pages.settings.security.description' />
                    }
                  />
                }
                {...buildProps('security')}
              />
            </TabList>
            <TabPanel value='profile'>
              <UserProfile authenticatedUser={authenticatedUser} />
            </TabPanel>
            <TabPanel value='workspaces'>
              <Workspaces
                reloadUser={props.reloadUser}
                authenticatedUser={authenticatedUser}
              />
            </TabPanel>
            <TabPanel value='configuration'>
              <Configuration />
            </TabPanel>
            <TabPanel value='appearance'>
              <Appearance />
            </TabPanel>
            <TabPanel value='security'>
              <Security />
            </TabPanel>
          </TabContext>
        </Box>
      </CardContent>
    </Card>
  );
}

export default SettingsSections;
