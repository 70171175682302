import _ from 'lodash';
import React from 'react';

import { LocalStorage, type LocalStorageRecord } from '../lib/local-storage';
import { useStorageStore } from './use-storage-store';

/**
 * A single key-value storage record.
 */
export interface StorageRecord<V>
  extends Omit<
    LocalStorageRecord<V>,
    'createdAt' | 'updatedAt' | 'lastUpdated'
  > {
  createdAt: Date;
  updatedAt: Date;
}

/**
 * Key-value storage data.
 */
export type StorageData<V> = Record<string, StorageRecord<V> | null>;

export interface UseStorageHook<V = unknown> {
  /**
   * Key-value storage.
   */
  data: StorageData<V>;

  /**
   * Get a key from storage.
   *
   * @param key A string key to get from storage.
   * @returns The value stored in the key or null.
   */
  get(key: string): V | null;

  /**
   * Set a key,value in storage.
   *
   * @param key A string key to set in storage.
   * @param value A value to set in storage.
   */
  set(key: string, value: V): void;

  /**
   * Remove a key from storage.
   *
   * @param key A string key to remove from storage.
   */
  remove(key: string): void;

  /**
   * Check if a key has expired.
   *
   * @param key A string key to check for expiration.
   * @param ttl The time-to-live in milliseconds.
   * @returns True if the key has expired.
   */
  expired(key: string, ttl: number): boolean;

  /**
   * Get all storage items.
   */
  list(): StorageRecord<V>[];

  /**
   * Clear all storage items.
   */
  clear(): void;

  /**
   * Create a key for storage.
   *
   * @param key A string key to create.
   * @returns A string key for storage.
   */
  createKey(key: string): string;
}

export const useStorage = <V>(): UseStorageHook<V> => {
  const store = useStorageStore((x) => x);

  const data = React.useMemo<StorageData<V>>(() => {
    return _.mapValues(store.data, (record) => {
      if (!record) {
        return null;
      }
      const parsedRecord = JSON.parse(record);
      if (!isValidRecord<V>(parsedRecord)) {
        return null;
      }

      return {
        key: parsedRecord.key,
        value: parsedRecord.value,
        createdAt: new Date(parsedRecord.createdAt),
        updatedAt: new Date(parsedRecord.updatedAt),
      };
    });
  }, [store.data]);

  const actions = React.useMemo<Omit<UseStorageHook<V>, 'data'>>(() => {
    return {
      createKey: LocalStorage.createKey,
      expired: LocalStorage.expired<V>,
      get: LocalStorage.get<V>,
      set: (key, value) => {
        LocalStorage.set<V>(key, value);
        const item = LocalStorage.getRecord<V>(key);
        store.set(LocalStorage.createKey(key), JSON.stringify(item));
      },
      remove: (key) => {
        LocalStorage.remove(key);
        store.remove(LocalStorage.createKey(key));
      },
      list: () => {
        return _.chain(LocalStorage.list<V>())
          .map((record) => ({
            ...record,
            createdAt: new Date(record.createdAt),
            updatedAt: new Date(record.updatedAt),
          }))
          .value();
      },
      clear: () => {
        store.clear();
        LocalStorage.clear();
      },
    };
  }, [store.clear, store.remove, store.set]);

  return {
    ...actions,
    data,
  };
};

/*
|------------------
| Utils
|------------------
*/

const isValidRecord = <V>(record: unknown): record is StorageRecord<V> => {
  return !_.isNil(record);
};
