import { Dialog, DialogContent } from '@mui/material';
import * as hookz from '@react-hookz/web';
import _ from 'lodash';
import React from 'react';

import { DialogTitle } from '@/components/dialog';
import { type MermaidGraphType, SirenaLogo, SirenaViewer } from '@/lib/sirena';

/*
|==========================================================================
| MermaidViewDialog
|==========================================================================
|
| A dialog that shows the mermaid graph "full screen".
|
*/

export interface MermaidViewDialogProps {
  svg: string | null;
  graphType: MermaidGraphType | null;
  open: boolean;
  onClose: () => void;
}

export const MermaidViewDialog: React.FC<MermaidViewDialogProps> = (props) => {
  const [dialogContentSize, dialogContentRef] = hookz.useMeasure();

  const svgSizeSx = React.useMemo(() => {
    if (_.isNil(dialogContentSize) || dialogContentSize?.width === 0) {
      return {};
    }
    return {
      maxWidth: 'none !important',
      maxHeight: `${(dialogContentSize?.height ?? 300) - 64}px !important`,
      height: 'auto !important',
    };
  }, [dialogContentSize]);

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      fullScreen
      PaperProps={{
        sx: {
          width: '100%',
          height: '100%',
          '& svg': svgSizeSx,
        },
      }}
    >
      <DialogTitle title={<SirenaLogo />} onClose={props.onClose} />
      <DialogContent ref={dialogContentRef}>
        <SirenaViewer svg={props.svg} graphType={props.graphType} />
      </DialogContent>
    </Dialog>
  );
};

export default MermaidViewDialog;
