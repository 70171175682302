import { Skeleton, Stack, Typography } from '@mui/material';
import React from 'react';

import { useJDocLayout } from '@/features/docs/hooks/use-jdoc-layout';

export const JoggrDocContentSkeleton: React.FC = () => {
  const [docLayoutState] = useJDocLayout();
  return (
    <Stack
      spacing={2}
      sx={{
        px: `${docLayoutState.contentPadding}px`,
      }}
    >
      <Typography variant='h2'>
        <Skeleton variant='text' width={300} />
      </Typography>
      <Typography variant='body1'>
        <Skeleton variant='text' />
        <Skeleton variant='text' />
        <Skeleton variant='text' />
      </Typography>
      <Typography variant='body1'>
        <Skeleton variant='text' />
        <Skeleton variant='text' />
        <Skeleton variant='text' />
      </Typography>
      <Typography variant='h2'>
        <Skeleton variant='text' width={200} />
      </Typography>
      <Skeleton variant='rectangular' height={200} width={400} />
      <Typography variant='body1'>
        <Skeleton variant='text' />
        <Skeleton variant='text' />
        <Skeleton variant='text' />
        <Skeleton variant='text' />
        <Skeleton variant='text' />
      </Typography>
      <Typography variant='h2'>
        <Skeleton variant='text' width={300} />
      </Typography>
      <Typography variant='body1'>
        <Skeleton variant='text' />
        <Skeleton variant='text' />
        <Skeleton variant='text' />
      </Typography>
    </Stack>
  );
};
