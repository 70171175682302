import {
  Box,
  Button,
  Divider,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import React from 'react';
import { z } from 'zod';
import TablerIconMailPlus from '~icons/tabler/mail-plus';

import SendInviteButton from './SendInviteButton';

/*
|------------------
| Utils
|------------------
*/

/**
 * Parse Emails from a string of emails separated by commas
 *
 * @param emails A string of emails separated by commas, i.e. `foo@gmail.com,bar@gmail.com`
 * @returns A list of emails, i.e. `['foo@gmail.com', 'bar@gmail.com']`
 */
const parseEmailsFromString = (emails: string): string[] => {
  return _.chain(emails)
    .split(',')
    .map((x) => x.trim())
    .filter((x) => x.length > 0)
    .uniq()
    .value();
};

/*
|------------------
| Internals
|------------------
*/

interface InvitedEmailProps {
  email: string;
  loading?: boolean;
  onRemove: (email: string) => void;
}

const InvitedEmail: React.FC<InvitedEmailProps> = (props) => {
  /*
  |------------------
  | Handlers
  |------------------
  */

  const handleRemoveEmail = (): void => {
    props.onRemove(props.email);
  };

  return (
    <Stack
      direction='row'
      spacing={1}
      alignItems='center'
      sx={{
        p: 1,
        pl: 2,
        bgcolor: (theme) =>
          theme.palette.mode === 'dark'
            ? theme.palette.grey[800]
            : theme.palette.grey[200],
        borderRadius: 1,
      }}
    >
      <Box
        sx={{
          fontSize: '16px',
        }}
      >
        {props.email}
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <Button
        variant='contained'
        color='error'
        size='small'
        onClick={handleRemoveEmail}
        disabled={props.loading}
      >
        Remove
      </Button>
    </Stack>
  );
};

/*
|------------------
| Public Interface
|------------------
*/

export interface Invite {
  id: string;
  emails: string[];
}

export interface SendInviteFormProps {
  onSendInvite: (invite: Invite) => Promise<'success' | 'error'>;
  onReset?: () => void;
  loading?: boolean;
}

export const SendInviteForm: React.FC<SendInviteFormProps> = (props) => {
  const [emails, setEmails] = React.useState<string[]>([]);
  const [emailInput, setEmailInput] = React.useState<string>('');
  const [errors, setErrors] = React.useState<string[]>([]);

  /*
  |------------------
  | Validation
  |------------------
  */

  // @todo in future move to a library
  const validateEmails = (): boolean => {
    const parsedEmails = parseEmailsFromString(emailInput);

    if (
      _.uniq(parsedEmails).length !== parsedEmails.length ||
      _.some(parsedEmails, (email) => emails.includes(email))
    ) {
      setErrors(['You cannot send multiple invites to the same email address']);
      return false;
    }

    const result = z
      .array(z.string().email('Must be a valid email address'))
      .min(1, 'Please enter at least one valid email address')
      .max(10, 'You can only invite up to 10 people at a time')
      .safeParse(parsedEmails);

    if (!result.success) {
      setErrors(result.error.errors.map((e) => e.message));
    }

    return result.success;
  };

  const validate = (
    defaultMessage: string
  ): {
    error: boolean;
    helperText: string;
  } => {
    return {
      error: errors.length > 0,
      helperText: errors.length > 0 ? errors.join(', ') : defaultMessage,
    };
  };

  /*
  |------------------
  | Handlers: Input
  |------------------
  */

  const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailInput(e.target.value);
    setErrors([]);

    if (props.onReset) {
      props.onReset();
    }
  };

  const handleAddEmails = () => {
    const parsedEmails = parseEmailsFromString(emailInput);
    if (validateEmails()) {
      setEmails([...parsedEmails, ...emails]);
      setEmailInput('');
    }
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleAddEmails();
    }
  };

  /*
  |------------------
  | Handlers: List
  |------------------
  */

  const handleRemoveEmail = (email: string) => {
    setEmails(emails.filter((e) => e !== email));
  };

  /*
  |------------------
  | Handlers: Submit
  |------------------
  */

  const handleSendInvite = (): void => {
    const inviteId = window.crypto.randomUUID();

    void props
      .onSendInvite({
        id: inviteId,
        emails,
      })
      .then(async () => {
        setEmails([]);
        setEmailInput('');
      });
  };

  return (
    <Box>
      <Box
        sx={{
          mt: 2,
        }}
      >
        <TextField
          label='Invite Emails'
          size='small'
          type='text'
          value={emailInput}
          fullWidth
          {...validate(
            'Enter the email addresses of the people you would like to invite, separated by a comma'
          )}
          InputProps={{
            sx: {
              pr: 1,
            },
            endAdornment: (
              <InputAdornment position='end'>
                <Button
                  startIcon={<TablerIconMailPlus />}
                  variant='contained'
                  color='secondary'
                  size='small'
                  onClick={handleAddEmails}
                  disabled={props.loading || emailInput.length === 0}
                >
                  Add
                </Button>
              </InputAdornment>
            ),
          }}
          onKeyUp={handleKeyUp}
          onChange={handleChangeEmail}
        />
      </Box>
      <SendInviteButton
        onClick={handleSendInvite}
        size='large'
        fullWidth
        loading={props.loading}
        disabled={emails.length === 0}
        sx={{
          mt: 2,
          mb: 1,
        }}
      >
        Send Invites to your team!
      </SendInviteButton>
      <Divider
        sx={{
          my: 3,
        }}
      />
      {emails.length > 0 && (
        <Stack direction='column' spacing={1}>
          {emails.map((email) => (
            <InvitedEmail
              key={email}
              email={email}
              onRemove={handleRemoveEmail}
            />
          ))}
        </Stack>
      )}
      {emails.length === 0 && (
        <Typography variant='body1' textAlign='center'>
          No emails have been added yet, please add emails above to send
          invites.
        </Typography>
      )}
    </Box>
  );
};
