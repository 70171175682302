import { Box, Stack } from '@mui/material';
import React from 'react';

import { createComponentClasses } from '@stargate/theme';

export const codeCardHeaderClasses = createComponentClasses('CodeCardHeader');

export interface CodeCardHeaderProps {
  /**
   * Action to display on the left side of the header.
   */
  leftAction?: React.ReactNode;

  /**
   * Action to display on the right side of the header.
   */
  rightAction?: React.ReactNode;
}

/**
 * CardHeader component for code.
 */
export const CodeCardHeader = React.forwardRef<
  HTMLDivElement,
  CodeCardHeaderProps
>(({ leftAction, rightAction }, ref) => {
  return (
    <Box
      className={codeCardHeaderClasses.root}
      ref={ref}
      sx={{
        [`&.${codeCardHeaderClasses.root}`]: {
          borderBottom: (theme) => `0.5px solid ${theme.palette.divider}`,
          py: 1,
          px: 1,
          bgcolor: (theme) =>
            theme.palette.mode === 'dark'
              ? theme.palette.grey[900]
              : theme.palette.grey[200],
        },
      }}
    >
      <Stack direction='row' spacing={1} alignItems='center' flexWrap={'wrap'}>
        {leftAction}
        <Box sx={{ flexGrow: 1 }} />
        {rightAction}
      </Stack>
    </Box>
  );
});
CodeCardHeader.displayName = 'CodeCardHeader';
