import { type Editor, Node } from '@tiptap/core';
import { PluginKey } from '@tiptap/pm/state';
import { ReactRenderer } from '@tiptap/react';
import Suggestion, { type SuggestionOptions } from '@tiptap/suggestion';
import _ from 'lodash';
import tippy, { type GetReferenceClientRect } from 'tippy.js';
import TablerIconBlockquote from '~icons/tabler/blockquote';
import TablerIconBolt from '~icons/tabler/bolt';
import TablerIconBrandLoom from '~icons/tabler/brand-loom';
import TablerIconBrandYoutubeFilled from '~icons/tabler/brand-youtube-filled';
import TablerIconCode from '~icons/tabler/code';
import TablerIconFileCode2 from '~icons/tabler/file-code-2';
import TablerIconFolders from '~icons/tabler/folders';
import TablerIconH1 from '~icons/tabler/h-1';
import TablerIconH2 from '~icons/tabler/h-2';
import TablerIconH3 from '~icons/tabler/h-3';
import TablerIconH4 from '~icons/tabler/h-4';
import TablerIconH5 from '~icons/tabler/h-5';
import TablerIconHierarchy2 from '~icons/tabler/hierarchy-2';
import TablerIconLayersLinked from '~icons/tabler/layers-linked';
import TablerIconLayoutNavbarExpandFilled from '~icons/tabler/layout-navbar-expand-filled';
import TablerIconLinkPlus from '~icons/tabler/link-plus';
import TablerIconList from '~icons/tabler/list';
import TablerIconListCheck from '~icons/tabler/list-check';
import TablerIconListNumbers from '~icons/tabler/list-numbers';
import TablerIconPhoto from '~icons/tabler/photo';
import TablerIconSeparatorHorizontal from '~icons/tabler/separator-horizontal';
import TablerIconTable from '~icons/tabler/table';
import TablerIconTypography from '~icons/tabler/typography';

import { alertTypes } from '../../alerts';
import { SlashMenu } from '../components/SlashMenu';
import type { SlashCommandItem } from '../types';

/*
|==========================================================================
| slashMenu
|==========================================================================
|
| A menu that can be opened with a slash character (/).
|
*/

/*
|------------------
| Constants
|------------------
*/

export const PLUGIN_NAME = 'dashdraft-slash-menu';

export const PLUGIN_KEY = new PluginKey(PLUGIN_NAME);

/*
|------------------
| Utils
|------------------
*/

const filterSuggestions = (
  editor: Editor,
  items: SlashCommandItem[],
  query: string
) => {
  return _.filter(items, (item) => {
    if (item.omit?.({ editor })) {
      return false;
    }

    const cleanQuery = query.toLowerCase();

    const titleMatch = item.title.toLowerCase().startsWith(cleanQuery);
    if (titleMatch) {
      return true;
    }
    if (item.aliases) {
      return _.some(item.aliases, (alias) =>
        alias.toLowerCase().startsWith(cleanQuery)
      );
    }

    return false;
  });
};

/*
|------------------
| Suggestions
|------------------
*/

const suggestion: Omit<SuggestionOptions<SlashCommandItem>, 'editor'> = {
  char: '/',

  pluginKey: PLUGIN_KEY,

  allowSpaces: false,

  startOfLine: true,

  items: ({ query, editor }) => {
    // 🚨🚨🚨🚨 DO NOT REORDER 🚨🚨🚨🚨
    return filterSuggestions(
      editor,
      [
        /*
        |------------------
        | Basic
        |------------------
        */

        {
          id: 'heading-1',
          title: 'Heading 1',
          description: 'Insert large heading',
          group: 'basic',
          icon: TablerIconH1,
          command: ({ editor, range }) => {
            editor
              .chain()
              .focus()
              .deleteRange(range)
              .setHeading({ level: 1 })
              .run();
          },
        },
        {
          id: 'heading-2',
          title: 'Heading 2',
          description: 'Insert medium heading',
          group: 'basic',
          icon: TablerIconH2,
          command: ({ editor, range }) => {
            editor
              .chain()
              .focus()
              .deleteRange(range)
              .setHeading({ level: 2 })
              .run();
          },
        },
        {
          id: 'heading-3',
          title: 'Heading 3',
          description: 'Insert smaller heading',
          group: 'basic',
          icon: TablerIconH3,
          command: ({ editor, range }) => {
            editor
              .chain()
              .focus()
              .deleteRange(range)
              .setHeading({ level: 3 })
              .run();
          },
        },
        {
          id: 'heading-4',
          title: 'Heading 4',
          description: 'Insert small heading',
          group: 'basic',
          icon: TablerIconH4,
          command: ({ editor, range }) => {
            editor
              .chain()
              .focus()
              .deleteRange(range)
              .setHeading({ level: 4 })
              .run();
          },
        },
        {
          id: 'heading-5',
          title: 'Heading 5',
          description: 'Insert smallest heading',
          group: 'basic',
          icon: TablerIconH5,
          command: ({ editor, range }) => {
            editor
              .chain()
              .focus()
              .deleteRange(range)
              .setHeading({ level: 5 })
              .run();
          },
        },
        {
          id: 'paragraph',
          title: 'Paragraph',
          aliases: ['text'],
          description: 'A paragraph aka plain text',
          group: 'basic',
          icon: TablerIconTypography,
          command: ({ editor, range }) => {
            editor
              .chain()
              .focus()
              .deleteRange(range)
              .insertContent({
                type: 'paragraph',
                content: [
                  // We insert text because otherwise the
                  // place holder will show up.
                  {
                    type: 'text',
                    text: 'Some text',
                  },
                ],
              })
              .run();
          },
        },
        {
          id: 'blockquote',
          title: 'Blockquote',
          aliases: ['quote'],
          description: 'Capture a quote',
          group: 'basic',
          icon: TablerIconBlockquote,
          command: ({ editor, range }) => {
            editor.chain().focus().deleteRange(range).setBlockquote().run();
          },
        },
        {
          id: 'divider',
          title: 'Divider',
          aliases: ['horizontal rule', 'hr'],
          description: 'Insert a horizontal divider',
          group: 'basic',
          icon: TablerIconSeparatorHorizontal,
          command: ({ editor, range }) => {
            editor.chain().focus().deleteRange(range).setHorizontalRule().run();
          },
        },
        {
          id: 'list-unordered',
          title: 'Bullet List',
          aliases: ['unordered list'],
          description: 'Create a list',
          group: 'basic',
          icon: TablerIconList,
          command: ({ editor, range }) => {
            editor.chain().focus().deleteRange(range).toggleBulletList().run();
          },
        },
        {
          id: 'list-ordered',
          title: 'Ordered List',
          aliases: ['numbered list'],
          description: 'Create a numbered list of items',
          group: 'basic',
          icon: TablerIconListNumbers,
          command: ({ editor, range }) => {
            editor.chain().focus().deleteRange(range).toggleOrderedList().run();
          },
        },

        /*
        |------------------
        | Code
        |------------------
        */

        {
          id: 'code-block',
          title: 'Code Block',
          description: 'Add a simple code block',
          group: 'code',
          aliases: ['code', 'codeblock'],
          icon: TablerIconCode,
          command: ({ editor, range }) => {
            editor.chain().focus().deleteRange(range).setCodeBlock().run();
          },
        },
        {
          id: 'code-snippet',
          title: 'Code Snippet',
          description: 'Add a codeblock from your codebase',
          group: 'code',
          aliases: ['code', 'snippet', 'codesnippet'],
          icon: TablerIconFileCode2,
          command: ({ editor, range }) => {
            editor
              .chain()
              .focus()
              .deleteRange(range)
              .setEmptyCodeSnippet()
              .run();
          },
        },
        {
          id: 'code-diagram',
          title: 'Diagram',
          aliases: ['mermaid', 'diagram'],
          description: 'Add a diagram using Mermaid',
          group: 'code',
          icon: TablerIconHierarchy2,
          command: ({ editor, range }) => {
            editor.chain().focus().deleteRange(range).setMermaid().run();
          },
        },
        {
          id: 'code-links',
          title: 'Code Links',
          description: 'Link anything to your codebase',
          group: 'code',
          icon: TablerIconLayersLinked,
          comingSoon: false,
          command: ({ editor, range }) => {
            editor.chain().focus().deleteRange(range).openCodeExplorer().run();
          },
        },
        {
          id: 'code-paths',
          title: 'Code Paths',
          aliases: ['directory path', 'file path', 'folder path'],
          description: 'Insert a path to a directory or file',
          group: 'code',
          icon: TablerIconFolders,
          comingSoon: true,
          command: ({ editor, range }) => {
            // empty
          },
        },

        /*
        |------------------
        | Media
        |------------------
        */

        {
          id: 'image',
          title: 'Image',
          aliases: ['photo', 'picture'],
          group: 'media',
          description: 'Upload or embed an image',
          icon: TablerIconPhoto,
          command: ({ editor, range }) => {
            editor
              .chain()
              .focus()
              .deleteRange(range)
              .setEmptyImage({ forceOpen: true })
              .run();
          },
        },
        {
          id: 'video-youtube',
          title: 'YouTube Video',
          aliases: ['video', 'youtube', 'record', 'movie', 'screen'],
          description: 'Embed a YouTube video',
          group: 'media',
          icon: TablerIconBrandYoutubeFilled,
          command: ({ editor, range }) => {
            editor
              .chain()
              .focus()
              .deleteRange(range)
              .setEmptyYoutubeVideo({ forceOpen: true })
              .run();
          },
        },
        {
          id: 'video-loom',
          title: 'Loom Video',
          aliases: ['video', 'loom', 'record', 'movie', 'screen'],
          description: 'Embed a Loom video',
          group: 'media',
          icon: TablerIconBrandLoom,
          command: ({ editor, range }) => {
            editor
              .chain()
              .focus()
              .deleteRange(range)
              .setEmptyLoomVideo({ forceOpen: true })
              .run();
          },
        },

        /*
        |------------------
        | Advanced
        |------------------
        */

        {
          id: 'table',
          title: 'Table',
          description: 'Add simple tabular content',
          group: 'advanced',
          icon: TablerIconTable,
          command: ({ editor, range }) => {
            editor
              .chain()
              .focus()
              .deleteRange(range)
              .insertTable({
                rows: 3,
                cols: 3,
              })
              .run();
          },
        },
        {
          id: 'alert',
          title: 'Alert',
          description: 'Add content that stands out',
          group: 'advanced',
          aliases: ['callout', 'alert', 'panel', 'error', ...alertTypes],
          icon: TablerIconBolt,
          command: ({ editor, range }) => {
            editor
              .chain()
              .focus()
              .deleteRange(range)
              .setAlert({ type: 'note' })
              .run();
          },
        },
        {
          id: 'details',
          title: 'Details',
          description: 'A collapsible section',
          group: 'advanced',
          aliases: ['details', 'collapsible', 'collapse', 'accordion'],
          icon: TablerIconLayoutNavbarExpandFilled,
          command: ({ editor, range }) => {
            editor.chain().focus().deleteRange(range).setDetails().run();
          },
          omit: ({ editor }) => {
            return (
              editor.isActive('details') ||
              editor.isActive('detailsSummary') ||
              editor.isActive('detailsContent')
            );
          },
        },
        {
          id: 'list-check',
          title: 'Check List',
          aliases: ['todo list', 'task list', 'tasks list'],
          description: 'Create a list with checkboxes',
          group: 'advanced',
          icon: TablerIconListCheck,
          comingSoon: true,
          command: ({ editor, range }) => {
            // empty
          },
        },
        {
          id: 'link-to-doc',
          title: 'Link to Doc',
          description: 'Create a link to another document',
          group: 'advanced',
          icon: TablerIconLinkPlus,
          comingSoon: true,
          command: ({ editor, range }) => {
            // empty
          },
        },
      ],
      query
    );
  },

  render: () => {
    let component: ReactRenderer | null = null;
    let popup: ReturnType<typeof tippy> | null = null;

    return {
      onStart(props) {
        component = new ReactRenderer(SlashMenu, {
          props,
          editor: props.editor,
        });

        popup = tippy('body', {
          getReferenceClientRect: props.clientRect as GetReferenceClientRect,
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: 'manual',
          placement: 'bottom-start',
        });
      },

      onUpdate(props) {
        component?.updateProps(props);
        popup?.[0].setProps({
          getReferenceClientRect: props.clientRect as GetReferenceClientRect,
        });
      },

      onKeyDown(props) {
        if (props.event.key === 'Escape') {
          popup?.[0].hide();
          component?.destroy();

          return true;
        }

        const ref = component?.ref as {
          onKeyDown: (props: any) => boolean;
        } | null;
        return ref?.onKeyDown(props) ?? false;
      },

      onExit() {
        popup?.[0].destroy();
        component?.destroy();
      },
    };
  },

  command: ({ editor, range, props }) => {
    props.command({ editor, range });
    window.getSelection()?.collapseToEnd();
  },

  allow: ({ editor, state, range }) => {
    const $from = state.doc.resolve(range.from);
    const type = state.schema.nodes[PLUGIN_NAME];
    const allowedType = !!$from.parent.type.contentMatch.matchType(type);

    return (
      allowedType &&
      editor.isActive('paragraph') &&
      !editor.isActive('alerts') &&
      !editor.isActive('bold') &&
      !editor.isActive('italic') &&
      !editor.isActive('link') &&
      !editor.isActive('strike') &&
      !editor.isActive('code') &&
      !editor.isActive('codeBlock') &&
      !editor.isActive('blockquote') &&
      !editor.isActive('bulletList') &&
      !editor.isActive('orderedList') &&
      !editor.isActive('taskList') &&
      !editor.isActive('table') &&
      !editor.isActive('heading')
    );
  },
};

export const SlashCommands = Node.create({
  name: PLUGIN_NAME,

  group: 'inline',

  inline: true,

  selectable: false,

  atom: true,

  addKeyboardShortcuts() {
    return {
      Backspace: () =>
        this.editor.commands.command(({ tr, state }) => {
          let isSlash = false;
          const { selection } = state;
          const { empty, anchor } = selection;

          if (!empty) {
            return false;
          }

          state.doc.nodesBetween(anchor - 1, anchor, (node, pos) => {
            if (node.type.name === this.name) {
              isSlash = true;
              tr.insertText(
                this.options.suggestion.char || '',
                pos,
                pos + node.nodeSize
              );

              return false;
            }
          });

          return isSlash;
        }),
    };
  },

  addProseMirrorPlugins() {
    return [
      Suggestion({
        editor: this.editor,
        ...suggestion,
      }),
    ];
  },
}).configure();
