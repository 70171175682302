import React from 'react';
import TablerIconCircleDot from '~icons/tabler/circle-dot';

import { Feedback, type FeedbackProps } from './Feedback';

/*
|==========================================================================
| NoResultsFeedback
|==========================================================================
|
| Provide Feedback to Users for "No Results" usually during a search.
|
*/

export type NoResultsFeedbackProps = Partial<FeedbackProps>;

function NoResultsFeedback(
  props: NoResultsFeedbackProps,
  ref: React.Ref<HTMLDivElement>
) {
  const { title = 'Sorry no results found.', ...restProps } = props;

  return (
    <Feedback
      ref={ref}
      imagery={<TablerIconCircleDot style={{ fontSize: '32px' }} />}
      title={title}
      titleTypographyProps={{
        variant: 'h5',
      }}
      center
      {...restProps}
    />
  );
}

export default React.forwardRef(NoResultsFeedback);
