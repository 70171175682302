import { Box, Stack, Typography } from '@mui/material';
import React from 'react';

import { Img } from '@stargate/components/Images';

/*
|==========================================================================
| SirenaLogo
|==========================================================================
|
| A "Joggrified" Mermaid Logo
|
*/

export interface Props {
  size?: 'small' | 'medium' | 'large';
  onClick?: () => void;
}

export const SirenaLogo: React.FC<Props> = ({ size = 'medium', ...props }) => {
  /*
  |------------------
  | Computed
  |------------------
  */

  const sizeProps = React.useMemo(() => {
    switch (size) {
      case 'small':
        return {
          dimensions: 28,
          titleFontSize: 16,
          borderRadius: 4,
          padding: 0.75,
        };
      case 'large':
        return {
          dimensions: 48,
          titleFontSize: 28,
          titleLineHeight: 1.1,
          subTitleFontSize: 12,
          borderRadius: 8,
          padding: 1.25,
        };
      default:
        return {
          dimensions: 36,
          titleFontSize: 20,
          titleLineHeight: 1,
          subTitleFontSize: 10,
          borderRadius: 4,
          padding: 1,
        };
    }
  }, [size]);

  return (
    <Stack
      direction='row'
      alignItems='center'
      spacing={1}
      onClick={props.onClick}
      sx={
        props.onClick
          ? {
              cursor: 'pointer',
              '&:hover, &:focus, &:active': {
                bgcolor: (theme) => theme.palette.action.hover,
              },
              p: 1,
              borderRadius: `${sizeProps.borderRadius}px`,
              width: 'fit-content',
            }
          : undefined
      }
    >
      <Box
        sx={{
          borderRadius: `${sizeProps.borderRadius}px`,
          bgcolor: (theme) => theme.palette.primary.main,
          width: `${sizeProps.dimensions}px`,
          height: `${sizeProps.dimensions}px`,
          p: sizeProps.padding,
          display: 'flex',
        }}
      >
        <Img
          alt='Mermaid Icon'
          src='/lib/sirena/mermaid-icon-white.png'
          sx={{
            display: 'flex',
            alignSelf: 'center',
          }}
          responsive
        />
      </Box>
      <Stack>
        <Typography
          component={'div'}
          variant='body1'
          lineHeight={sizeProps.titleLineHeight}
          fontSize={(theme) =>
            theme.typography.pxToRem(sizeProps.titleFontSize)
          }
          fontWeight='bold'
          sx={{
            color: (theme) =>
              theme.palette.mode === 'dark'
                ? theme.palette.text.primary
                : theme.palette.primary.main,
          }}
        >
          Sirena
        </Typography>
        {size !== 'small' && (
          <Typography
            component={'div'}
            variant='body1'
            fontSize={
              sizeProps.subTitleFontSize
                ? (theme) =>
                    theme.typography.pxToRem(sizeProps.subTitleFontSize)
                : undefined
            }
          >
            A mermaid editor brought to you by Joggr.
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};

const MemoizedSirenaLogo = React.memo(SirenaLogo);
MemoizedSirenaLogo.displayName = 'SirenaLogo';

export default MemoizedSirenaLogo;
