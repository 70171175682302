import React from 'react';

import { type UseConfigHook, useConfig } from '@stargate/hooks';
import { type FetchMethod, fetchUnauthorized } from '@stargate/lib/http';

/**
 * Wrap the Frigade API in a hook.
 *
 * @returns A hook to interact with the Frigade API.
 */
export const useFrigadeAPI = () => {
  const config = useConfig();
  return React.useMemo(() => {
    const request = createFrigadeApiRequest(config);
    return {
      request,
    };
  }, [config]);
};

export type FrigadeAPIRoute =
  | 'POST /v1/public/users'
  | 'POST /v1/public/sessions';

export type FrigadeAPIPayload<Route extends FrigadeAPIRoute> =
  Route extends 'POST /v1/public/users'
    ? FrigadeAPILinkUserDTO
    : Route extends 'POST /v1/public/sessions'
      ? FrigadeAPISessionDTO
      : never;

export type FrigadeAPIRequest<
  Route extends FrigadeAPIRoute = FrigadeAPIRoute,
  Return = void,
> = (route: Route, payload?: FrigadeAPIPayload<Route>) => Promise<Return>;

/*
|------------------
| Internals
|------------------
*/

// biome-ignore lint/suspicious/noExplicitAny: Allow as we copied the types from the Frigade API.
export type FrigadeAPIPropertyPayload = Record<string, any>;

export interface FrigadeAPITrackingEvent {
  event: string;
  properties?: FrigadeAPIPropertyPayload;
}

export interface FrigadeAPISessionDTO extends Record<string, unknown> {
  userId: string;
  groupId?: string;
  userProperties?: FrigadeAPIPropertyPayload;
  userEvents?: FrigadeAPITrackingEvent[];
  groupProperties?: FrigadeAPIPropertyPayload;
  groupEvents?: FrigadeAPITrackingEvent[];
  linkGuestId?: string;
}

export interface FrigadeAPILinkUserDTO extends Record<string, unknown> {
  userId: string;
  linkGuestId: string;
}

function createFrigadeApiRequest(config: UseConfigHook) {
  return async function request<
    Route extends FrigadeAPIRoute = FrigadeAPIRoute,
    Return = void,
  >(route: Route, payload: FrigadeAPIPayload<Route>) {
    const [method, path] = route.split(' ') as [FetchMethod, string];
    const result = await fetchUnauthorized<Return>(path, {
      method,
      headers: {
        Authorization: `Bearer ${config.frigade.apiKey}`,
        'Content-Type': 'application/json',
      },
      data: payload,
      host: 'https://api.frigade.com',
    });

    return result;
  };
}
