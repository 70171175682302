import { RikerIcon } from '@joggrdocs/riker-icons';
import { Chip, type ChipProps } from '@mui/material';
import _ from 'lodash';
import React from 'react';

import { createComponentClasses } from '@stargate/theme';

export const joggrDocVersionChipClasses = createComponentClasses(
  'JoggrDocVersionChip',
  ['root'] as const
);

export interface JoggrDocVersionChipProps
  extends Partial<Omit<ChipProps, 'icon' | 'deleteIcon'>> {
  /**
   * The version of the document
   */
  version: string;

  /**
   * An icon to display at the end of the chip
   */
  endIcon?: React.ReactElement;

  /**
   * Callback when the chip is clicked
   */
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

export const JoggrDocVersionChip = React.forwardRef<
  HTMLDivElement,
  JoggrDocVersionChipProps
>(
  (
    { size = 'medium', clickable = true, version, onClick, endIcon, ...props },
    ref
  ) => {
    return (
      <Chip
        {...props}
        ref={ref}
        classes={joggrDocVersionChipClasses}
        clickable={clickable}
        shape='rounded'
        icon={<RikerIcon icon='git-branch' />}
        size={size}
        sx={{
          height: size === 'small' ? '24px' : '32px',
          ...props.sx,
        }}
        label={version}
        deleteIcon={clickable ? endIcon : undefined}
        onDelete={endIcon && clickable ? onClick : undefined}
        onClick={onClick}
      />
    );
  }
);
JoggrDocVersionChip.displayName = 'JoggrDocVersionChip';
JoggrDocVersionChip.muiName = 'Chip';
