import { useTheme } from '@mui/material';
import Badge, {
  badgeClasses,
  type BadgeProps as MuiBadgeProps,
} from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import React from 'react';
import type { Simplify } from 'type-fest';

import { cn } from '@stargate/utils/styles';

export const badgePulsingClasses = badgeClasses;

export interface BadgePulsingProps extends BadgeProps {
  /**
   * If the badge should be pulsing.
   *
   * @default true
   */
  pulsing?: boolean;

  /**
   * The size of the badge (if a `dot` variant)
   *
   * @default 'small'
   */
  dotSize?: 'small' | 'medium' | 'large';

  /**
   * If the badge should be hidden.
   *
   * @default false
   */
  hidden?: boolean;

  /**
   * The content to render. Does not allow the badge to be a number.
   */
  badgeContent?: React.ReactNode;

  /**
   * The slots to override the default slots.
   */
  slots?: Simplify<
    {
      badgeContentContainer?: React.ElementType;
    } & MuiBadgeProps['slots']
  >;
}

/**
 * A wrapper around the MUI Badge component that adds a pulsing effect to the badge content.
 */
export const BadgePulsing = React.forwardRef<
  HTMLSpanElement,
  BadgePulsingProps
>(
  (
    {
      children,
      pulsing = true,
      hidden = false,
      variant = 'dot',
      dotSize = 'small',
      badgeContent,
      style,
      sx,
      ...props
    },
    ref
  ) => {
    const theme = useTheme();

    const clickable = React.useMemo(() => !!props.onClick, [props.onClick]);

    const BadgeContentContainer = React.useMemo(
      () => props.slots?.badgeContentContainer || React.Fragment,
      [props.slots]
    );

    const size = React.useMemo(() => {
      switch (dotSize) {
        case 'small':
          return '8px';
        case 'medium':
          return '12px';
        case 'large':
          return '16px';
      }
    }, [dotSize]);

    const primaryColor = React.useMemo(() => {
      const c =
        !props.color || props.color === 'default' ? 'primary' : props.color;
      return theme.palette[c].main;
    }, [props.color, theme.palette]);

    return (
      <React.Fragment>
        {hidden ? (
          children
        ) : (
          <Badge
            ref={ref}
            {...props}
            className={cn({
              [badgePulsingClasses.dot]: variant === 'dot',
            })}
            badgeContent={
              <BadgeContentContainer>
                <span>{badgeContent}</span>
                {pulsing && (
                  <Pulse
                    sx={{
                      cursor: clickable ? 'pointer' : 'default',
                    }}
                    primaryColor={primaryColor}
                  />
                )}
              </BadgeContentContainer>
            }
            slotProps={{
              badge: {
                style: {
                  ...style,
                  zIndex: theme.zIndex.speedDial,
                  padding: 0,
                },
              },
            }}
            sx={{
              ...sx,
              width: '100%',
              zIndex: theme.zIndex.speedDial,
              [`&.${badgePulsingClasses.dot} .${badgePulsingClasses.badge}`]: {
                width: size,
                height: size,
                minWidth: size,
              },
            }}
          >
            {children}
          </Badge>
        )}
      </React.Fragment>
    );
  }
);
BadgePulsing.displayName = 'BadgePulsing';

/*
|------------------
| Utils
|------------------
*/

type BadgeProps = Omit<MuiBadgeProps, 'badgeContent' | 'showZero' | 'slots'>;

const Pulse = styled('span')<{ primaryColor: string }>`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 9999px;
  background-color: ${(props) => props.primaryColor};
  opacity: 0.45;
  animation-duration: 2.5s;
  animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  animation-delay: 0.15s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  animation-name: ping;

  @keyframes ping {
    75%,
    to {
      transform: scale(3);
      opacity: 0;
    }
  }
`;
