import {
  Alert,
  Avatar,
  Box,
  Button,
  Divider,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import React from 'react';
import TablerIconFileCode2 from '~icons/tabler/file-code';
import TablerIconTrash from '~icons/tabler/trash';

import { CodeSnippetButton } from '@stargate/features/code';

export interface CodeSnippetPlaceholderProps {
  /**
   * Callback for when the code explorer is opened.
   */
  onOpenCodeExplorer: () => void;

  /**
   * Callback for when the placeholder is deleted.
   */
  onDelete: () => void;
}

/**
 * Placeholder for the code snippet, rendered as an overlay.
 */
export const CodeSnippetPlaceHolder = React.memo<CodeSnippetPlaceholderProps>(
  ({ onOpenCodeExplorer, onDelete }) => {
    const theme = useTheme();
    const [hover, setHover] = React.useState(false);

    return (
      <Box
        onMouseEnter={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
        sx={{
          position: 'absolute',
          inset: 0,
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 2,
            transition: 'opacity 0.3s linear',
            opacity: !hover ? 1 : 0,
          }}
        >
          <Alert
            severity='info'
            sx={{
              py: 1,
              px: 2,
              borderRadius: 2,
              elevation: 1,
              minHeight: 0,
              textAlign: 'center',
              maxWidth: '600px',
              mt: 2,
              ml: 'auto',
              mr: 'auto',
              width: 'fit-content',
            }}
          >
            Placeholder Code Snippet
          </Alert>
        </Box>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -60%)',
            zIndex: 3,
            transition: 'opacity 0.3s linear',
            opacity: hover ? 1 : 0,
          }}
        >
          <Box
            sx={{
              py: 1,
              px: 2,
              borderRadius: 2,
              elevation: 1,
              border: `1px solid ${theme.palette.divider}`,
              minHeight: 0,
              display: 'block',
              textAlign: 'center',
              maxWidth: '600px',
              minWidth: '480px',
              mt: 2,
              ml: 'auto',
              mr: 'auto',
              width: '100%',
              bgcolor: theme.palette.background.paper,
            }}
          >
            <Stack
              direction='row'
              alignItems='center'
              justifyContent='center'
              spacing={1}
              sx={{
                mb: 1,
              }}
            >
              <Avatar
                variant='rounded'
                sx={{
                  height: '32px',
                  width: '32px',
                  bgcolor: theme.palette.primary.main,
                }}
              >
                <TablerIconFileCode2
                  color={theme.palette.primary.contrastText}
                />
              </Avatar>
              <Typography variant='h4' component='div'>
                Code Snippet
              </Typography>
            </Stack>
            <Divider sx={{ mb: 2, mt: 1 }} />
            <Typography variant='body1' gutterBottom>
              Joggr's Code Snippets allows you to link your code directly to
              your documentation and updates the code snippet every time you
              update your code.
            </Typography>
            <Stack
              direction='row'
              alignItems='center'
              justifyContent='center'
              spacing={1}
              sx={{
                mt: 2,
                mb: 1,
              }}
            >
              <CodeSnippetButton
                variant='contained'
                size='small'
                onClick={onOpenCodeExplorer}
              >
                Open Code Drawer
              </CodeSnippetButton>
              <Button
                variant='outlined'
                size='small'
                startIcon={<TablerIconTrash />}
                onClick={onDelete}
              >
                Remove Placeholder
              </Button>
            </Stack>
            <Box>
              <Typography variant='caption'>
                Click the button above to open the code explorer & insert a code
                snippet.
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            position: 'absolute',
            zIndex: 1,
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            bgcolor: theme.palette.common.black,
            transition: 'opacity 0.2s ease-in-out',
            opacity: hover ? 0.6 : 0.3,
          }}
        />
      </Box>
    );
  }
);
CodeSnippetPlaceHolder.displayName = 'CodeSnippetPlaceholder';
