import { RikerIcon } from '@joggrdocs/riker-icons';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import React from 'react';
import { z } from 'zod';

import api from '@stargate/api';
import { HintBadge } from '@stargate/components/Guides';
import { DialogTitle } from '@stargate/components/dialog';
import {
  GitHubBrandAvatar,
  type GitHubRepository,
  useGithubRepository,
} from '@stargate/features/github';
import { useUserDefaults } from '@stargate/features/user';
import { useLocalization } from '@stargate/localization';

import { useJDocDraftMutate } from '@/features/docs/hooks/use-jdoc-draft-mutate';
import type { JDocComponentProps } from '@/features/docs/types';
import { createComponentClasses } from '@/theme';
import { JoggrDocFileLocationChip } from './JoggrDocFileLocationChip';
import { JoggrDocFileLocationForm } from './JoggrDocFileLocationForm';

export const joggrDocFileLocationClasses = createComponentClasses(
  'JoggrDocFileLocation'
);

export type JoggrDocFileLocationProps = JDocComponentProps<{
  /**
   * Whether to show the hint badge.
   * @default false
   */
  showHint?: boolean;
}>;

export const JoggrDocFileLocation: React.FC<JoggrDocFileLocationProps> = ({
  doc,
  draft,
  mode,
  showHint = false,
}) => {
  const localz = useLocalization();
  const jdocDraftMutate = useJDocDraftMutate();
  const [userDefState, userDefActions] = useUserDefaults();
  const [fileTreeState, fileTreeActions] = api.useRequestClient(
    'GET /github/repositories/:repositoryId/file-tree'
  );
  const userDefaultGitHubRepository = useGithubRepository(
    userDefState.repositoryId
  );
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);

  /*
  |------------------
  | Defaults
  |------------------
  */

  const defaultFileName = React.useMemo(() => {
    if (!_.isNil(draft?.doc?.github?.filePath)) {
      const fileName = getFileName(draft.doc.github.filePath);
      if (!_.isNil(fileName) && fileName !== DEFAULT_FILE_NAME) {
        return fileName;
      }
    }

    return draft?.doc?.title
      ? `${_.kebabCase(draft.doc.title)}`
      : DEFAULT_FILE_NAME;
  }, [draft?.doc?.title, draft?.doc?.github?.filePath]);

  const defaultDirPath = React.useMemo(() => {
    // we should NEVER not have the filePath in "edit" mode
    if (!_.isNil(draft?.doc?.github?.filePath)) {
      const dirPath = getDirPath(draft.doc.github.filePath);
      if (!_.isNil(dirPath)) {
        return dirPath;
      }
    }

    return DEFAULT_DIR_PATH;
  }, [draft?.doc?.github?.filePath]);

  const defaultGitHubRepository = React.useMemo(() => {
    // we have to check that the draft has a github
    // repository name, otherwise it might ONLY have the ID
    if (draft?.doc?.github?.repository?.name) {
      return draft.doc.github.repository;
    }

    return userDefaultGitHubRepository.data;
  }, [draft?.doc?.github, userDefaultGitHubRepository.data]);

  /*
  |------------------
  | Input Data - data that is created by the user doing something
  |------------------
  */

  const [inpRepository, setInpRepository] = React.useState<GitHubRepository>();
  const [inpDirPath, setInpDirPath] = React.useState<string>();
  const [inpFileName, setInpFileName] = React.useState<string>();
  const [inpFileNameError, setInpFileNameError] = React.useState<string | null>(
    null
  );

  /*
  |------------------
  | Computed
  |------------------
  */

  const repository = React.useMemo(() => {
    if (inpRepository) {
      return inpRepository;
    }
    return defaultGitHubRepository ?? undefined;
  }, [inpRepository, defaultGitHubRepository]);

  const dirPath = React.useMemo(() => {
    if (!_.isNil(inpDirPath)) {
      return inpDirPath;
    }
    return defaultDirPath;
  }, [inpDirPath, defaultDirPath]);

  const fileName = React.useMemo(() => {
    if (!_.isNil(inpFileName)) {
      return inpFileName;
    }
    return defaultFileName;
  }, [inpFileName, defaultFileName]);

  const filePath = React.useMemo(() => {
    if (fileName && dirPath === '/') {
      return `${fileName}.md`;
    }

    if (fileName && dirPath) {
      return [dirPath, `${fileName}.md`].join('/');
    }
  }, [fileName, dirPath]);

  const github = React.useMemo(() => {
    return {
      filePath,
      repository,
      branch: draft?.doc?.github?.branch ?? repository?.defaultBranch,
    };
  }, [filePath, repository, draft?.doc?.github?.branch]);

  const saveable = React.useMemo(() => {
    return !_.isNil(filePath) && !_.isNil(repository) && !inpFileNameError;
  }, [filePath, repository, inpFileNameError]);

  const modified = React.useMemo(() => {
    return _.some([
      (!_.isNil(draft?.doc?.github?.filePath) &&
        filePath !== draft?.doc?.github?.filePath) ||
        filePath !== doc?.github?.filePath,
      repository?.id !== doc?.github?.repository?.id,
    ]);
  }, [filePath, repository, doc?.github, draft?.doc?.github]);

  /*
  |------------------
  | Callbacks
  |------------------
  */

  const reset = React.useCallback(() => {
    setInpRepository(undefined);
    setInpDirPath(undefined);
    setInpFileName(undefined);
    setInpFileNameError(null);
  }, []);

  const save = React.useCallback(() => {
    if (filePath && repository) {
      // Auto-update the user defaults
      userDefActions.setRepositoryId(repository.id.toString());
      userDefActions.setRepositoryOwnerId(repository.owner.id.toString());

      // If the user confirms the file location, update the state so
      // we don't auto-name it again
      const fileName = getFileName(filePath);
      const dirPath = getDirPath(filePath);

      if (fileName) {
        if (!isValidFileName(fileName)) {
          setInpFileNameError(
            'Filenames should be the same case (all uppercase or lowercase), alphanumeric, and dash/underscore delimited.'
          );
          return;
        }

        setInpFileName(fileName);
      }
      if (dirPath) {
        setInpDirPath(dirPath);
      }

      // Update the draft
      jdocDraftMutate.update({
        github: {
          ...draft?.doc?.github,
          repository,
          filePath,
        },
      });
      setDialogOpen(false);
    }
  }, [repository, filePath, draft, jdocDraftMutate.update, userDefActions]);

  /*
  |------------------
  | Effects
  |------------------
  */

  // biome-ignore lint/correctness/useExhaustiveDependencies: only fire when ghRepo changes
  React.useEffect(() => {
    if (!_.isNil(repository)) {
      void fileTreeActions.execute({
        params: {
          repositoryId: repository.id.toString(),
        },
        querystring: {
          branch: repository.defaultBranch,
        },
      });
    }
  }, [repository?.id]);

  if (userDefaultGitHubRepository.loading) {
    return (
      <Skeleton
        variant='rounded'
        sx={{
          height: '36px',
          width: '124px',
        }}
      />
    );
  }

  return (
    <Box className={joggrDocFileLocationClasses.root}>
      <Tooltip
        title={
          !showHint && (
            <Stack direction='column'>
              <Typography
                variant='h6'
                sx={{
                  color: 'inherit',
                }}
              >
                File Location
              </Typography>
              <Typography
                variant='subtitle2'
                sx={{
                  color: 'inherit',
                }}
              >
                {mode !== 'create'
                  ? 'The location in GitHub where your JoggrDoc is saved (committed).'
                  : 'Select the location in GitHub where you will save (commit) your JoggrDoc.'}
              </Typography>
            </Stack>
          )
        }
        enterDelay={500}
        enterNextDelay={500}
        placement='bottom'
        arrow
      >
        <Box
          sx={{
            maxWidth: '280px',
          }}
        >
          <HintBadge
            dotSize='medium'
            hidden={!showHint}
            color='warning'
            hint={
              <React.Fragment>
                <strong>REQUIRED:</strong> You need to confirm the GitHub File
                Location, before you can commit your JoggrDoc. Click to open the
                dialog and confirm the location.
              </React.Fragment>
            }
            tooltipPlacement='right'
          >
            <JoggrDocFileLocationChip
              github={github}
              onClick={() => {
                setDialogOpen(true);
              }}
              readonly={mode === 'view'}
            />
          </HintBadge>
        </Box>
      </Tooltip>
      <Dialog
        open={dialogOpen && mode !== 'view'}
        onClose={(_e, reason) => {
          if (reason !== 'backdropClick') {
            setDialogOpen(false);
            reset();
          } else if (!modified) {
            // If no modifications, close the dialog
            setDialogOpen(false);
          }
        }}
        maxWidth='md'
        fullWidth
      >
        <DialogTitle
          startIcon={<GitHubBrandAvatar variant='rounded' />}
          title='File Location'
          subTitle='Select the location in GitHub where you will save (commit) your document.'
          onClose={() => {
            setDialogOpen(false);
          }}
        />
        <DialogContent
          sx={{
            pt: 1,
          }}
        >
          <JoggrDocFileLocationForm
            fileTree={fileTreeState.result}
            loading={fileTreeState.status === 'loading'}
            githubRepository={repository}
            directoryPath={dirPath}
            fileName={fileName}
            fileNameError={inpFileNameError}
            onChangeRepository={(value) => {
              setInpRepository(value ?? undefined);
            }}
            onChangeDirectory={(value) => {
              setInpDirPath(value);
            }}
            onChangeFilename={(value) => {
              setInpFileName(value);
              if (isValidFileName(value)) {
                setInpFileNameError(null);
              } else {
                setInpFileNameError(
                  'Filenames should be the same case (all uppercase or lowercase), alphanumeric, and dash/underscore delimited.'
                );
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Tooltip
            title={
              !saveable || !modified
                ? localz.formatMessage(
                    `features.docs.file-location.confirm.tooltip.${!saveable ? 'missing-data' : 'not-modified'}`
                  )
                : null
            }
            arrow
            placement='bottom'
          >
            <span>
              <HintBadge
                hidden={!showHint || !saveable || !modified}
                color='warning'
                hint='Click to confirm the GitHub File Location'
                dotSize='medium'
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                tooltipPlacement='top'
              >
                <Button
                  onClick={save}
                  disabled={!saveable || !modified}
                  variant='contained'
                  color='success'
                  startIcon={<RikerIcon icon='checkbox' />}
                >
                  Confirm
                </Button>
              </HintBadge>
            </span>
          </Tooltip>
          <Tooltip
            title={
              modified
                ? localz.formatMessage(
                    'features.docs.file-location.cancel.tooltip.warning'
                  )
                : null
            }
            placement='bottom'
            arrow
          >
            <Button
              onClick={() => {
                setDialogOpen(false);
                reset();
              }}
              variant='outlined'
              color='error'
              startIcon={<RikerIcon icon='door-exit' />}
            >
              Cancel
            </Button>
          </Tooltip>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

/*
|------------------
| Utils 
|------------------
*/

/**
 * Extract the directory path from a file path.
 *
 * @param filePath A file path.
 * @returns A directory path.
 */
const getDirPath = (filePath: string): string | null => {
  const parts = _.compact(filePath.split('/'));
  if (parts.length > 1) {
    return parts.slice(0, -1).join('/');
  }
  if (parts.length === 1) {
    return '/';
  }
  return null;
};

/**
 * Extract the file name from a file path.
 *
 * @param filePath A file path.
 * @returns A file name.
 */
const getFileName = (filePath: string): string | null => {
  const parts = _.compact(filePath.split('/'));
  const fileName = _.last(parts);
  if (fileName?.endsWith('.md')) {
    return fileName.slice(0, -3);
  }
  return null;
};

const DEFAULT_DIR_PATH = 'docs';

const DEFAULT_FILE_NAME = 'untitled';

/**
 * Check if a string is a valid filename.
 *
 * @param fileName The string.
 * @returns A boolean indicating if the fileName is valid.
 */
const isValidFileName = (fileName: string): boolean => {
  const result = z
    .union([
      // kebab-case, lowercase
      z
        .string()
        .regex(/^([a-z0-9]*)(-[a-z0-9]+)*$/),
      // kebab-case, uppercase
      z
        .string()
        .regex(/^([A-Z0-9]*)(-[A-Z0-9]+)*$/),
      // snake_case, lowercase
      z
        .string()
        .regex(/^([a-z0-9]*)(_[a-z0-9]+)*$/),
      // snake_case, uppercase
      z
        .string()
        .regex(/^([A-Z0-9]*)(_[A-Z0-9]+)*$/),
    ])
    .safeParse(fileName);
  return result.success;
};
