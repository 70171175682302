import { createLocale } from '../../lib/locale-factory';

/**
 * The HTTP error codes for the application.
 */
const codes = createLocale('codes', {
  DOC_NF001: 'JoggrDoc not found.',
  DOC_NF002: "JoggrDoc found but markdown file doesn't exist in GitHub.",
  IMP_001:
    'Some of the provided imports contain file paths that are associated with existing JoggrDocs.',
  IMP_002:
    'JoggrDocs are either already imported or the contents of one of the files contains JoggrDoc metadata. You can remove the metadata in the file manually and try again.',
  IMP_003:
    'JoggrDoc is missing key data such as title, description, or filePath in the import.',
  USR_LNK003:
    'Unable to link user accounts, as your email is not verified. Please verify your email and try to login again.',
  USR_INV001: 'Unable to invite user due to an unknown error.',
  USR_INV002:
    'User is already a member of the organization or has already been invited.',
  UNK_000: 'An unknown error occurred.',
});

export default createLocale('error', {
  ...codes,

  /*
  |------------------
  | Types
  |------------------
  */

  'type.unknown.title': 'Houston, we have a problem!',
  'type.unknown.description':
    'Well looks like we had an oopsie, as an "unknown error" has occurred. Please try again later, refresh the page, or shoot us an email if the problem persists.',
  'type.internal-server-error.title':
    'Our server is taking a short coffee break, be back soon! ☕',
  'type.internal-server-error.description':
    "Sorry, it looks like our server isn't doing so hot... Please try again later, refresh the page, or shoot us an email if the problem persists.",
  'type.not-found.title': "Beam me up, Scotty! This page isn't here 🖖",
  'type.not-found.description':
    'Seems that you are lost as the page you are looking for does not exist, or it moved, or you typed the wrong URL (or maybe we screwed something up). Please go back to the homepage or shoot us an email if the problem persists.',
  'type.bad-request.title': "I'm afraid I can't parse that, Dave 🤖",
  'type.bad-request.description':
    'Sorry, the request you made seems to be invalid. Please try again later, refresh the page, or shoot us an email if the problem persists.',
  'type.unauthorized.title': "D'oh! Authentication required! 🍩",
  'type.unauthorized.description': 'Sorry, looks like you need to login!',
  'type.forbidden.title': 'You shall not pass! 🧙‍♂️',
  'type.forbidden.description':
    'Sorry, you are not authorized to access this page.',
});
