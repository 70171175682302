import { Autocomplete, TextField, autocompleteClasses } from '@mui/material';
import _ from 'lodash';
import { matchSorter } from 'match-sorter';
import React from 'react';

import {
  type CodeMirrorLang,
  useCodeMirrorLangs,
} from '@stargate/lib/codemirror';

export type Language = CodeMirrorLang;

export interface CodeLanguageSelectProps {
  /**
   * Is the select disabled?
   * @default false
   */
  disabled?: boolean;

  /**
   * The language to use.
   */
  language: CodeMirrorLang;

  /**
   * Callback triggered when the language is changed.
   *
   * @param language
   */
  onChangeLanguage: (language: CodeMirrorLang) => void;
}

/**
 * A select component that allows the user to select a CodeBlock language.
 */
export const CodeLanguageSelect = React.memo<CodeLanguageSelectProps>(
  ({ onChangeLanguage, language, disabled = false }) => {
    const cmLangs = useCodeMirrorLangs();

    /*
    |------------------
    | Computed
    |------------------
    */

    const options = React.useMemo(() => {
      return cmLangs.langs.map((language) => {
        return {
          label: language.name.toString(),
          language: language.language,
          alias: _.chain([
            language.language,
            language.type,
            ...language.extensions.map((ext) => ext.replace('.', '')),
          ])
            .uniq()
            .value(),
        };
      });
    }, [cmLangs.langs]);

    const value = React.useMemo<Value | null>(() => {
      if (language) {
        const defaultDefinition = cmLangs.findDefinition(language);
        if (defaultDefinition) {
          return {
            label: defaultDefinition.name,
            language: defaultDefinition.language,
            alias: _.chain([
              defaultDefinition.language,
              defaultDefinition.type,
              ...defaultDefinition.extensions.map((ext) =>
                ext.replace('.', '')
              ),
            ])
              .uniq()
              .value(),
          };
        }
      }

      return null;
    }, [language, cmLangs.findDefinition]);

    /*
    |------------------
    | State
    |------------------
    */

    const [inputValue, setInputValue] = React.useState<string>('');

    /*
    |------------------
    | Callbacks
    |------------------
    */

    const handleLanguageChange = React.useCallback(
      (_e: unknown, value: Value) => {
        if (value.language) {
          onChangeLanguage(value.language);
        }
      },
      [onChangeLanguage]
    );

    return (
      <Autocomplete
        size='small'
        disabled={disabled}
        options={options}
        sx={{
          minWidth: '200px',
          [`& .${autocompleteClasses.inputRoot}`]: {
            bgcolor: (theme) => theme.palette.background.paper,
          },
          [`& .${autocompleteClasses.input}`]: {
            background: 'inherit !important',
            color: 'inherit',
          },
        }}
        filterOptions={(options, { inputValue }) => {
          return matchSorter(options, inputValue, {
            keys: ['language', 'alias'],
          });
        }}
        // Unknown why this is needed, but it is.
        value={value as Value}
        onChange={handleLanguageChange}
        inputValue={inputValue}
        onInputChange={(_e, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => <TextField {...params} label='Language' />}
        disablePortal
        disableClearable
        multiple={false}
      />
    );
  }
);
CodeLanguageSelect.displayName = 'CodeLanguageSelect';

/*
|------------------
| Utils
|------------------
*/

type Value = {
  label: string;
  language: Language;
  alias: string[];
};
