import {
  Box,
  Card,
  Fade,
  LinearProgress,
  ListSubheader,
  MenuList,
  Stack,
  Typography,
} from '@mui/material';
import type {
  SuggestionKeyDownProps,
  SuggestionProps,
} from '@tiptap/suggestion';
import React from 'react';

export type SuggestionMenuItem<
  I extends Record<string, unknown> = Record<string, unknown>,
  G extends string | undefined = string | undefined,
> = {
  /**
   * The unique identifier for the suggestion item.
   */
  id: string;

  /**
   * The group to which the suggestion item belongs.
   */
  group: G;
} & I;

export interface SuggestionMenuProps<
  I extends SuggestionMenuItem = SuggestionMenuItem,
> extends SuggestionProps<I, I> {
  /**
   * The ref to the menu element.
   */
  menuRef: React.Ref<unknown>;

  /**
   * The props to pass to the `MenuList` component.
   */
  menuListProps?: React.ComponentProps<typeof MenuList>;

  /**
   * Is the menu currently loading?
   *
   * @default false
   */
  loading?: boolean;

  /**
   * The max-height of the menu.
   *
   * @default 500
   */
  maxHeight?: number;

  /**
   * The width of the menu.
   *
   * @default 300
   */
  width?: number;

  /**
   * Render a single item (MUST be wrapped in a `MenuItem`).
   */
  renderItem(
    item: I & { onClick: () => unknown },
    selected: boolean
  ): React.ReactNode;

  /**
   * Render a item's group subheader, (SHOULD be wrapped in a `ListSubheader`).
   */
  renderSubheader?(group: string): React.ReactNode;

  /**
   * The React element/node to display when there are no results.
   */
  renderNoResults?(payload: { query: string }): React.ReactNode;
}

/**
 * A component that displays a list of suggestions to the user, used to build mention and suggestion menus.
 *
 * @see https://github.com/ueberdosis/tiptap/blob/main/demos/src/Nodes/Mention/React/MentionList.jsx
 */
export function SuggestionMenu<I extends SuggestionMenuItem>({
  command,
  items,
  menuRef,
  menuListProps,
  loading = false,
  maxHeight = 200,
  width = 300,
  ...props
}: SuggestionMenuProps<I>) {
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  /*
  |------------------
  | Computed
  |------------------
  */

  const groupMap = React.useMemo(() => {
    const grpMap = new Map<string, I[]>();
    for (const item of items) {
      const groupKey = item.group ?? GROUP_ROOT_ID;
      const group = grpMap.get(groupKey) ?? [];
      group.push(item);
      grpMap.set(groupKey, group);
    }
    return grpMap;
  }, [items]);

  const itemsToRender = React.useMemo(() => {
    const results = [];

    for (const [groupKey, groupItems] of groupMap.entries()) {
      results.push({
        group: groupKey,
        items: groupItems,
      });
    }

    return results;
  }, [groupMap]);

  /*
  |------------------
  | Utils
  |------------------
  */

  const getIndexOffset = (idx: number) => {
    if (idx === 0) {
      return 0;
    }

    let idxSum = 0;
    for (let i = 0; i < idx; i++) {
      idxSum += itemsToRender[i].items.length;
    }
    return idxSum;
  };

  /*
  |------------------
  | Handlers
  |------------------
  */

  const selectItem = React.useCallback(
    (index: number) => {
      const item = items[index];
      if (item) {
        command(item);
      }
    },
    [command, items]
  );

  const upHandler = React.useCallback(() => {
    setSelectedIndex((selectedIndex + items.length - 1) % items.length);
  }, [items, selectedIndex]);

  const downHandler = React.useCallback(() => {
    setSelectedIndex((selectedIndex + 1) % items.length);
  }, [items, selectedIndex]);

  const enterHandler = React.useCallback(() => {
    selectItem(selectedIndex);
  }, [selectItem, selectedIndex]);

  React.useImperativeHandle(
    menuRef,
    () => {
      return {
        onKeyDown: ({ event }: SuggestionKeyDownProps) => {
          if (event.key === 'ArrowUp') {
            upHandler();
            return true;
          }

          if (event.key === 'ArrowDown') {
            downHandler();
            return true;
          }

          if (event.key === 'Enter') {
            enterHandler();
            return true;
          }

          return false;
        },
      };
    },
    [upHandler, downHandler, enterHandler]
  );

  /*
  |------------------
  | Effects
  |------------------
  */

  // biome-ignore lint/correctness/useExhaustiveDependencies: reset ONLY when items change
  React.useEffect(() => {
    setSelectedIndex(0);
  }, [items]);

  if (props.query.length === 0) {
    return null;
  }

  return (
    <Card
      sx={(theme) => ({
        width: `${width}px`,
        overflow: 'auto',
        p: 0,
        border: `0.5px solid ${theme.palette.divider}`,
        backgroundColor: theme.palette.background.dashboardMain,
      })}
    >
      <Fade
        in={loading}
        style={{
          transitionDelay: loading ? '0ms' : '1200ms',
        }}
        unmountOnExit
      >
        <Box sx={{ width: '100%' }}>
          <LinearProgress sx={{ height: '2px' }} />
        </Box>
      </Fade>
      <MenuList
        variant='selectedMenu'
        dense
        {...menuListProps}
        sx={{
          p: 0,
          pb: 1,
          ...(menuListProps?.sx ?? {}),
        }}
      >
        {items.length === 0 && (
          <React.Fragment>
            {props.renderNoResults ? (
              props.renderNoResults({ query: props.query })
            ) : (
              <SuggestionNoResults query={props.query} />
            )}
          </React.Fragment>
        )}
        {itemsToRender.map(({ group, items }, i) => {
          return [
            group !== GROUP_ROOT_ID &&
              (props.renderSubheader ? (
                props.renderSubheader(group)
              ) : (
                <ListSubheader
                  key={group}
                  // sx={{ height: '36px', lineHeight: '36px' }}
                >
                  {group.toUpperCase()}
                </ListSubheader>
              )),
            ...items.map((item, idx) => {
              const index = getIndexOffset(i) + idx;
              return props.renderItem(
                {
                  ...item,
                  onClick: () => selectItem(index),
                },
                index === selectedIndex
              );
            }),
          ];
        })}
      </MenuList>
    </Card>
  );
}

/*
|------------------
| Utils
|------------------
*/

const GROUP_ROOT_ID = '<root>';

const SuggestionNoResults: React.FC<{ query: string }> = ({ query }) => {
  return (
    <Stack
      direction='row'
      alignItems='center'
      justifyContent='center'
      spacing={1}
      sx={{
        p: 2,
      }}
    >
      {query.length === 0 ? (
        <Typography variant='body1'>Keep typing to get a result</Typography>
      ) : (
        <Typography variant='body1'>Sorry no results</Typography>
      )}
    </Stack>
  );
};
