import _ from 'lodash';
import React from 'react';

import { Stack, type SxProps, Tooltip, useTheme } from '@mui/material';

import { createComponentClasses } from '@stargate/theme';

export const directoryBreadCrumbsContainerClasses = createComponentClasses(
  'DirectoryBreadCrumbs',
  ['root', 'crumb', 'divider', 'tooltip'] as const
);

export type DirectoryBreadCrumbsContainerClasses =
  typeof directoryBreadCrumbsContainerClasses;

export interface DirectoryBreadCrumbsContainerProps {
  /**
   * The children to render.
   */
  children: React.ReactNode;
  /**
   * The classes for the component.
   */
  classes?: Partial<DirectoryBreadCrumbsContainerClasses>;

  /**
   * The className for the component.
   */
  className?: string;

  /**
   * sx styles for the component.
   */
  sx?: SxProps;

  /**
   * The tooltip to display.
   */
  tooltip?: React.ReactNode;

  /**
   * Triggered when the component is clicked.
   */
  onClick?: () => void;
}

export const DirectoryBreadCrumbsContainer: React.FC<
  DirectoryBreadCrumbsContainerProps
> = ({ children, sx, tooltip, onClick, ...props }) => {
  const theme = useTheme();

  const element = (
    <Stack
      {...props}
      direction='row'
      spacing={0.5}
      alignItems='center'
      justifyItems='center'
      sx={{
        ...sx,
        height: '100%',
        [`& .${directoryBreadCrumbsContainerClasses.crumb}`]: {
          color: theme.palette.text.primary,
        },
        [`& .${directoryBreadCrumbsContainerClasses.divider}`]: {
          color: theme.palette.divider,
          fontSize: '24px',
        },
      }}
      divider={
        <span className={directoryBreadCrumbsContainerClasses.divider}>/</span>
      }
      onClick={onClick}
    >
      {children}
    </Stack>
  );

  if (tooltip) {
    return (
      <Tooltip placement='top' title={tooltip} arrow>
        {element}
      </Tooltip>
    );
  }

  return element;
};
