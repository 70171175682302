import { type Theme, colors } from '@mui/material';

import type { FrigadeThemeAspectColors, FrigadeThemeColor } from '../types';

/**
 * Generate a Frigade Theme Palette Aspect from a Riker theme
 *
 * @param theme A theme object
 * @returns A FrigadeThemeAspectColors object
 */
export const palette = (theme: Theme): FrigadeThemeAspectColors => {
  const utils = createUtils(theme);
  const gray = utils.colorNamed('grey');
  const blue = utils.colorNamed('blue');
  const green = utils.colorNamed('green');
  const red = utils.colorNamed('red');
  return {
    colors: {
      primary: utils.color('primary'),
      secondary: utils.color('secondary'),
      positive: utils.color('success'),
      negative: utils.color('error'),
      neutral: {
        foreground: theme.palette.text.primary,
        background: theme.palette.background.paper,
        surface: theme.palette.background.default,
        border: theme.palette.divider,
        100: theme.palette.grey[100],
        200: theme.palette.grey[200],
        300: theme.palette.grey[300],
        400: theme.palette.grey[400],
        500: theme.palette.grey[500],
        600: theme.palette.grey[600],
        700: theme.palette.grey[700],
        800: theme.palette.grey[800],
        900: theme.palette.grey[900],
        active: {
          foreground: theme.palette.text.primary,
          background: theme.palette.grey[100],
          surface: theme.palette.grey[200],
          border: theme.palette.grey[300],
        },
        hover: {
          foreground: theme.palette.text.primary,
          background: theme.palette.grey[100],
          surface: theme.palette.grey[200],
          border: theme.palette.grey[300],
        },
        focus: {
          foreground: theme.palette.text.primary,
          background: theme.palette.grey[100],
          surface: theme.palette.grey[200],
          border: theme.palette.grey[300],
        },
      },
      transparent: 'transparent',
      inherit: 'inherit',
      gray: theme.palette.grey,
      black: theme.palette.common.black,
      white: theme.palette.common.white,
      blue: colors.blue,
      green: colors.green,
      red: colors.red,
      yellow: colors.yellow,
      ...red,
      ...blue,
      ...green,
      ...gray,
    },
  };
};

type ColorVariant =
  | 'primary'
  | 'secondary'
  | 'success'
  | 'error'
  | 'warning'
  | 'info';

type Color = 'blue' | 'green' | 'red' | 'grey';
type FrigadeColor = 'blue' | 'green' | 'red' | 'gray';

const colorNumbers = [50, 100, 200, 300, 400, 500, 600, 700, 800, 900] as const;
type ColorNumber = (typeof colorNumbers)[number];

/**
 * Create utils for a theme
 *
 * @param theme A theme object
 * @returns A set of utility functions
 */
const createUtils = (theme: Theme) => {
  return {
    /**
     * Convert a Theme UI color variant to a Frigade Semantic color
     *
     * @param variant A Theme UI color variant
     * @returns A Frigade Semantic color
     */
    color(variant: ColorVariant): FrigadeThemeColor {
      const color = theme.palette[variant];
      const active = {
        foreground: color.contrastText,
        background: color.dark,
        surface: color.dark,
        border: color.dark,
      };
      return {
        foreground: color.contrastText,
        background: color.main,
        surface: color.main,
        border: color.main,
        100: color.light,
        200: color.light,
        300: color.light,
        400: color.main,
        500: color.main,
        600: color.main,
        700: color.dark,
        800: color.dark,
        900: color.dark,
        active,
        hover: active,
        focus: active,
      };
    },

    /**
     * Create a object with the color names in the format `${color}${number}` => gray100
     *
     * @param color A Riker color
     * @param includes An array of color numbers to include
     * @returns A object with the color names in the format `${color}${number}` => gray100
     */
    colorNamed(color: Color, includes?: ColorNumber[]) {
      const inc = includes ?? colorNumbers;
      const result = {} as Record<`${FrigadeColor}${ColorNumber}`, string>;

      for (const number of inc) {
        if (color === 'grey') {
          result[`gray${number}`] = theme.palette.grey[number];
        } else {
          result[`${color}${number}`] = colors[color][number];
        }
      }

      return result;
    },
  };
};
