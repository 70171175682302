import { RikerIcon } from '@joggrdocs/riker-icons';
import {
  Alert,
  AlertTitle,
  Box,
  Card,
  CardHeader,
  type CardProps,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import * as hookz from '@react-hookz/web';
import _ from 'lodash';
import React from 'react';

import api from '@stargate/api';
import { IconAvatar } from '@stargate/components/Avatars';
import { Link } from '@stargate/routes';
import { createComponentClasses } from '@stargate/theme';

/**
 * Get the text representing the time since the date
 *
 * @param date A date string
 * @returns A string representing the time since the date
 */
const getVisitedText = (date: string): string => {
  const visitedDate = new Date(date);
  const now = new Date();

  const diff = now.getTime() - visitedDate.getTime();

  const HOURS_IN_MS = 1000 * 60 * 60;
  const hours = Math.floor(diff / HOURS_IN_MS);

  if (hours < 1) {
    const MIN_IN_MS = 1000 * 60;
    const minutes = Math.floor(diff / MIN_IN_MS);
    if (minutes < 1) {
      return 'just now';
    }
    return `${minutes} minutes ago`;
  }
  if (hours < 24) {
    return `${hours} hours ago`;
  }
  return visitedDate.toLocaleDateString();
};

export const recentlyViewedDocumentsClasses = createComponentClasses(
  'RecentlyViewedDocuments',
  ['root', 'actions'] as const
);

export const RecentlyViewedDocuments: React.FC<CardProps> = (props) => {
  const theme = useTheme();
  const [statState, statActions] = api.useRequestClient(
    'POST /documents/analytics/search'
  );

  hookz.useMountEffect(() => {
    void statActions.execute({
      body: {
        endDateTime: new Date().toISOString(),
        action: 'view',
        limit: 5,
      },
    });
  });

  return (
    <Card
      {...props}
      sx={{
        ...props.sx,
        position: 'relative',
        pb: 2,
      }}
      className={recentlyViewedDocumentsClasses.root}
      elevation={0}
    >
      <CardHeader
        avatar={<IconAvatar icon='history' />}
        title={<Typography variant='h4'>Recently viewed</Typography>}
        subTitle={
          <Typography variant='body2'>
            Documents you have recently viewed or edited
          </Typography>
        }
        disableTypography
      />

      <MenuList
        sx={{
          px: 1,
        }}
      >
        {_.isNil(statState.result) &&
          _.range(3).map((index) => (
            <Skeleton
              key={index}
              component={MenuItem}
              variant='rounded'
              disableRipple
              disableTouchRipple
              sx={{
                minHeight: '64px !important',
                mx: 1,
                cursor: 'unset',
                borderRadius: '8px',
                mb: 1,
                border: `1px solid ${theme.palette.divider}`,
              }}
            />
          ))}
        {statState.result?.map((statDoc) => (
          <MenuItem
            key={statDoc.document.id}
            disableRipple
            disableTouchRipple
            sx={{
              minHeight: '64px !important',
              mx: 1,
              cursor: 'unset',
              borderRadius: '8px',
              mb: 1,
              border: `1px solid ${theme.palette.divider}`,
            }}
          >
            <ListItemIcon>
              <RikerIcon icon='file-type-doc' />
            </ListItemIcon>
            <ListItemText
              primary={statDoc.document.title}
              primaryTypographyProps={{
                sx: {
                  fontSize: '1.15rem',
                  fontWeight: 600,
                },
              }}
              secondary={statDoc.document.summary}
              secondaryTypographyProps={{
                display: 'inline',
              }}
              sx={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                mr: 1,
              }}
            />
            <Stack
              direction='row'
              spacing={1}
              alignItems='center'
              sx={{
                mr: 2,
              }}
            >
              <RikerIcon icon='clock' size={16} />
              <Typography>
                You visited {getVisitedText(statDoc.createdAt)}
              </Typography>
            </Stack>
            <Divider orientation='vertical' flexItem />
            <Stack
              direction='row'
              spacing={1}
              alignItems='center'
              sx={{
                ml: 2,
              }}
            >
              <Tooltip title='View the document'>
                <IconButton
                  component={Link}
                  to='app.documents.view'
                  params={{ id: statDoc.document.id }}
                >
                  <RikerIcon icon='eye' />
                </IconButton>
              </Tooltip>
              <Tooltip title='Edit the document'>
                <IconButton
                  component={Link}
                  to='app.documents.edit'
                  params={{ id: statDoc.document.id }}
                >
                  <RikerIcon icon='edit' />
                </IconButton>
              </Tooltip>
            </Stack>
          </MenuItem>
        ))}
      </MenuList>
      {((!_.isNil(statState.result) && statState.result.length === 0) ||
        statState.status === 'error') && (
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: 0,
            right: 0,
          }}
        >
          <Box
            sx={{
              mx: 'auto',
              maxWidth: '80%',
            }}
          >
            {statState.status === 'error' && (
              <Alert severity='error'>
                <AlertTitle>Error</AlertTitle>
                An error occurred while fetching your recently viewed documents
              </Alert>
            )}
            {statState.status === 'success' && (
              <Box
                sx={{
                  p: 2,
                  borderRadius: '8px',
                  backgroundColor: theme.palette.background.dashboardSideBar,
                }}
              >
                <Typography
                  variant='body2'
                  sx={{
                    textAlign: 'center',
                  }}
                >
                  You have not viewed any documents yet, start exploring!
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Card>
  );
};
