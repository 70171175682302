import { RikerIcon } from '@joggrdocs/riker-icons';
import { Alert, AlertTitle, Box, Button, Stack, Tooltip } from '@mui/material';
import _ from 'lodash';
import React from 'react';

import apiClient from '@stargate/api';
import { AlertHttpError } from '@stargate/components/Alerts/AlertHttpError';
import { ButtonLink } from '@stargate/components/Buttons';
import { FeedbackSuccess } from '@stargate/components/Feedback';
import { useDirectoryTree } from '@stargate/features/directories';
import {
  GitHubButton,
  type GitHubRepository,
  GitHubRepositoryAutocomplete,
  GitHubRepositoryBranchSelect,
} from '@stargate/features/github';
import { useNotify } from '@stargate/lib/notify';
import { useStorageItem } from '@stargate/lib/storage';
import { Link } from '@stargate/routes';
import { useSearchParams } from '@stargate/routes';
import { createComponentClasses } from '@stargate/theme';

import type { DocumentImportFile } from '../types';
import { DocumentImportCreatorTable } from './DocumentImportCreatorTable';

/*
|------------------
| Utils
|------------------
*/

/**
 * Generates a title for a document based on the path of the document
 *
 * @param filePath A string representing the path of the document in the GitHub repository
 * @returns A string representing the title of the document
 */
const generateTitleFromFilePath = (filePath: string): string => {
  const filePathParts = filePath.split('/');
  const fileName = filePathParts[filePathParts.length - 1];
  const fileNameWithoutExtension = fileName.split('.')[0];
  const filePathWithoutFileName =
    filePathParts.length > 1 ? filePath.replace(`/${fileName}`, '') : '<root>';

  if (fileName.toLowerCase() === 'readme.md') {
    return `Readme: ${filePathWithoutFileName}`;
  }
  return _.startCase(fileNameWithoutExtension);
};

/**
 * The maximum number of documents that can be imported at once
 */
const MAX_DOCUMENTS = 50;

/*
|------------------
| Public API
|------------------
*/

export const documentImportCreatorClasses = createComponentClasses(
  'DocumentImportCreator',
  ['root'] as const
);

export const DocumentImportCreator: React.FC = (props) => {
  const [searchParams] = useSearchParams('app.document-imports.create');
  const directoryTree = useDirectoryTree();
  const [error, setError] = React.useState<unknown | null>(null);
  const hideProTip = useStorageItem(
    'document-imports:hide-pro-tip-alert',
    false
  );
  const notify = useNotify();

  const [createDocumentImportState, createDocumentImportActions] =
    apiClient.useRequestClient('POST /documents-import/github');
  const [documentListState, documentListActions] = apiClient.useRequestClient(
    'GET /documents-import/github/:repositoryOwnerId/:repositoryId/documents'
  );
  const [documentImportList, setDocumentImportList] = React.useState<
    DocumentImportFile[]
  >([]);
  const [activeGitHubRepo, setActiveGitHubRepo] =
    React.useState<GitHubRepository | null>(null);

  /*
  |------------------
  | Computed
  |------------------
  */

  const rows = React.useMemo(() => {
    return _.chain(documentListState.result ?? [])
      .map((doc) => {
        if (!activeGitHubRepo) {
          return null;
        }

        return {
          id: [
            activeGitHubRepo.owner.id,
            activeGitHubRepo.id,
            doc.filePath,
          ].join(':'),
          filePath: doc.filePath,
          directoryId: searchParams?.rootDirId ?? null,
          title: generateTitleFromFilePath(doc.filePath),
          summary: '',
          githubRepository: activeGitHubRepo.name,
          githubRepositoryOwner: activeGitHubRepo.owner.name,
          branchName: activeGitHubRepo.defaultBranch,
        };
      })
      .compact()
      .value();
  }, [documentListState.result, activeGitHubRepo, searchParams?.rootDirId]);

  /*
  |------------------
  | Callbacks
  |------------------
  */

  const handleChangeDocumentImportList = (
    documentImportFiles: DocumentImportFile[]
  ) => {
    setDocumentImportList(documentImportFiles);
  };

  const handleGitHubRepoChange = (repo: GitHubRepository | null) => {
    setActiveGitHubRepo(repo);

    if (repo) {
      documentListActions
        .execute({
          params: {
            repositoryOwnerId: repo.owner.id.toString(),
            repositoryId: repo.id.toString(),
          },
        })
        .then(() => {
          setError(null);
        })
        .catch((e) => {
          setError(e);
        });
    } else {
      documentListActions.reset();
      setError(null);
    }
  };

  const handleCreateDocumentImport = () => {
    if (!activeGitHubRepo || documentImportList.length === 0) {
      return;
    }

    setError(null);
    hideProTip.set(true);
    void createDocumentImportActions
      .execute({
        body: {
          repositoryId: activeGitHubRepo.id.toString(),
          repositoryOwnerId: activeGitHubRepo.owner.id.toString(),
          // biome-ignore lint/style/noNonNullAssertion: We should never reach this point without a default branch
          baseBranchName: activeGitHubRepo.defaultBranch!,
          documents: documentImportList.map((doc) => ({
            filePath: doc.filePath,
            directoryId: doc.directoryId ?? undefined,
            title: doc.title,
            summary: doc.summary,
          })),
        },
      })
      .then(async (result) => {
        notify.send({
          severity: 'success',
          title: 'Import Successful',
          message: (
            <React.Fragment>
              Import was successful & pull-request{' '}
              <Link to='app.document-imports.list'>here</Link>
            </React.Fragment>
          ),
          duration: 30000,
        });
        // Refresh the directory tree to reflect the new documents
        await directoryTree.onLoad();
        setError(null);
      })
      .catch((e) => {
        setError(e);
      });
  };

  const handleCloseError = () => {
    createDocumentImportActions.reset();
    setError(null);
  };

  if (
    createDocumentImportState.status === 'success' &&
    createDocumentImportState.result
  ) {
    return (
      <FeedbackSuccess
        title='Document Import Successful'
        message='You successfully created an import. You now need to merge the pull request in GitHub to complete the import. Click the button below to view the pull request on GitHub.'
        actions={[
          <GitHubButton
            key='view-pull-request'
            href={createDocumentImportState.result.pullRequest.url}
            startIcon={<RikerIcon icon='git-pull-request' />}
          >
            View Pull Request #
            {createDocumentImportState.result.pullRequest.number}
          </GitHubButton>,
          <ButtonLink
            key='create-another-import'
            to='app.document-imports.create'
            variant='outlined'
            onClick={() => {
              createDocumentImportActions.reset();
              documentListActions.reset();
            }}
            startIcon={<RikerIcon icon='file-import' />}
          >
            Create another Import
          </ButtonLink>,
        ]}
      />
    );
  }

  return (
    <Box className={documentImportCreatorClasses.root}>
      <Stack spacing={2} direction='column' sx={{ mb: 2 }}>
        <Stack spacing={1} direction='row'>
          <GitHubRepositoryAutocomplete
            size='small'
            fullWidth={false}
            onChange={handleGitHubRepoChange}
            sx={{ minWidth: 400 }}
          />
          <Tooltip title='Only the default branch is supported at this time'>
            <GitHubRepositoryBranchSelect
              size='small'
              branches={
                activeGitHubRepo?.defaultBranch
                  ? [activeGitHubRepo.defaultBranch]
                  : []
              }
              defaultBranch={activeGitHubRepo?.defaultBranch}
              disabled
              fullWidth={false}
              sx={{ minWidth: 200 }}
              onChange={() => {
                /* DO NOTHING */
              }}
            />
          </Tooltip>
        </Stack>
        {!hideProTip.data &&
          !(!_.isNil(error) || documentImportList.length > MAX_DOCUMENTS) && (
            <Alert
              variant='outlined'
              severity='info'
              onClose={() => {
                hideProTip.set(true);
              }}
              sx={{
                maxWidth: '800px',
                mb: 3,
              }}
            >
              <AlertTitle>Pro Tip</AlertTitle>
              You can edit the "Title", "Summary", and "Directory" of each
              document before importing. You are <strong>not</strong> required
              to edit these fields to import documents.
            </Alert>
          )}
        {!_.isNil(error) && (
          <AlertHttpError
            error={error}
            onClose={handleCloseError}
            sx={{
              maxWidth: '1200px',
            }}
          />
        )}
        {documentImportList.length > MAX_DOCUMENTS && (
          <Alert
            severity='warning'
            sx={{
              maxWidth: '1248px',
            }}
          >
            <AlertTitle>Import too large</AlertTitle>
            The import is too large. Joggr can only import up to {MAX_DOCUMENTS}{' '}
            documents at a time. Please break this into multiple imports by only
            selecting up to {MAX_DOCUMENTS} documents at a time.
          </Alert>
        )}
        <DocumentImportCreatorTable
          onSelectedRowsChange={handleChangeDocumentImportList}
          loading={
            documentListState.status === 'loading' ||
            createDocumentImportState.status === 'loading'
          }
          rows={rows}
        />
      </Stack>
      <Box>
        <Tooltip
          enterDelay={documentImportList.length > 0 ? 1500 : 500}
          title={
            documentListState.result?.length === 0
              ? 'You need to select a GitHub repository that has (unimported) markdown files'
              : documentImportList.length === 0
                ? 'You need to select at least 1 document to import'
                : documentImportList.length > MAX_DOCUMENTS
                  ? `You can only import up to ${MAX_DOCUMENTS} documents at a time`
                  : 'Clicking this button will create pull request, importing the selected GitHub markdown files into the Joggr platform.'
          }
          placement='top'
        >
          <span>
            <Button
              onClick={handleCreateDocumentImport}
              loading={createDocumentImportState.status === 'loading'}
              startIcon={<RikerIcon icon='file-import' />}
              fullWidth={false}
              size='large'
              disabled={
                documentImportList.length === 0 ||
                documentImportList.length > MAX_DOCUMENTS
              }
            >
              Import {documentImportList.length} Documents
            </Button>
          </span>
        </Tooltip>
      </Box>
    </Box>
  );
};
