import { Chip, Stack, Typography, chipClasses, useTheme } from '@mui/material';
import _ from 'lodash';
import React from 'react';
import { remark } from 'remark';
import remarkStripMarkdown from 'strip-markdown';

import type { Nil } from '@stargate/lib/typist';
import { createComponentClasses } from '@stargate/theme';
import { cn } from '@stargate/utils/styles';

/*
|==========================================================================
| SearchMatches
|==========================================================================
|
| Component for rendering search text.
|
*/

export const searchMatchesClasses = createComponentClasses('SearchMatches', [
  'root',
  'chip',
  'text',
  'textHighlight',
] as const);

export interface SearchMatchesProps {
  className?: string;

  /**
   * A single or array of text to render. If array it will loop through and find the
   * first non-Nil value that has a match, if not matches are found it will return null.
   */
  text?: string | Array<string | Nil>;

  /**
   * Disable the highlight of the search text.
   */
  disableHighlight?: boolean;
}

/**
 * Search text highlighting string (from the API).
 */
const SEARCH_HIGHLIGHT_START = '{match-start}';

/**
 * Search text highlighting string (from the API).
 */
const SEARCH_HIGHLIGHT_END = '{match-end}';

/**
 * Check if the text has a match.
 *
 * @param text A text to check for a match.
 * @returns A boolean indicating if the text has a match.
 */
const hasMatch = (text: string): boolean => {
  return text.includes(SEARCH_HIGHLIGHT_START);
};

const useRenderText = (text: Array<string | Nil>): string | null => {
  const [renderText, setRenderText] = React.useState<string | null>(null);

  const handleRenderText = async (txt: Array<string | Nil>): Promise<void> => {
    const textToRender = _.reduce<Array<string | Nil>, string | null>(
      txt,
      (acc, t) => {
        if (_.isString(acc)) {
          return acc;
        }

        if (_.isString(t) && hasMatch(t)) {
          return t;
        }

        return null;
      },
      null
    );

    if (!_.isNil(textToRender)) {
      const result = (
        await remark()
          // @ vts-expect-error - this is a valid use case

          .use(remarkStripMarkdown, {
            keep: ['code', 'inlineCode'],
          })
          .process(textToRender)
      ).toString();

      if (result.length > 60) {
        const match = result.match(
          /.{0,20}(\{match-start\}.*\{match-end\}).{0,20}/g
        );

        if (!_.isNil(match)) {
          const matchText = match[0].trim();
          const content = [matchText];

          if (!matchText.startsWith(SEARCH_HIGHLIGHT_START)) {
            content.unshift('...');
          }

          if (!matchText.endsWith(SEARCH_HIGHLIGHT_END)) {
            content.push('...');
          }

          setRenderText(content.join(''));
        }
      } else {
        setRenderText(result.toString());
      }
    }
  };

  React.useEffect(() => {
    void handleRenderText(text);
  }, [text]);

  return React.useMemo(() => {
    return renderText;
  }, [renderText]);
};

export const SearchMatches: React.FC<SearchMatchesProps> = ({
  className,
  text,
  disableHighlight = false,
}) => {
  const theme = useTheme();
  const textToRender = useRenderText(_.isArray(text) ? text : [text]);

  if (_.isNil(textToRender)) {
    return null;
  }

  function renderText(text: string): React.ReactNode {
    const splits = text.split(SEARCH_HIGHLIGHT_END);
    return splits.map((split, index) => {
      // Last item we just return
      if (index === splits.length - 1) {
        return split;
      }

      const [before, highlight] = split.split(SEARCH_HIGHLIGHT_START);
      return (
        <React.Fragment key={index}>
          {before}
          <span className={searchMatchesClasses.textHighlight}>
            {highlight}
          </span>
        </React.Fragment>
      );
    });
  }

  return (
    <Stack
      className={cn([searchMatchesClasses.root, className])}
      direction='row'
      spacing={0.5}
      alignItems='center'
      sx={{
        [`&.${searchMatchesClasses.root}`]: {
          [`& .${searchMatchesClasses.chip}`]: {
            p: 0,
            height: '16px',
            fontSize: '0.75rem',
            borderRadius: '4px',
            [`& .${chipClasses.label}`]: {
              pr: '2px',
              pl: '4px',
            },
          },
          [`& .${searchMatchesClasses.text}`]: {
            fontSize: '0.75rem',
            lineHeight: 1,
            color: 'text.secondary',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            [`& .${searchMatchesClasses.textHighlight}`]: {
              color: 'text.secondary',
              fontWeight: 'bold',
            },
          },
        },
      }}
    >
      <Chip className={searchMatchesClasses.chip} label='match:' />
      <Typography className={searchMatchesClasses.text}>
        {disableHighlight ? textToRender : renderText(textToRender)}
      </Typography>
    </Stack>
  );
};
