import { RikerIcon } from '@joggrdocs/riker-icons';
import { Card, CardContent, CardHeader, Typography } from '@mui/material';
import React from 'react';

import { IconAvatar } from '@stargate/components/Avatars';
import { ButtonLink } from '@stargate/components/Buttons';
import { Page } from '@stargate/components/Utils';
import { DocumentImportCreator } from '@stargate/features/imports';

export const DocumentsImportPage: React.FC = (props) => {
  return (
    <Page
      id='document-imports'
      title='Import Documents'
      meta={{
        description:
          'Import documents from your GitHub repositories into your Joggr account.',
      }}
      render={() => (
        <Card>
          <CardHeader
            title={<Typography variant='h5'>Import Documents</Typography>}
            subheader={
              <Typography variant='subtitle2' fontSize='1rem'>
                Import Markdown files from your GitHub repositories into the
                Joggr platform. This will allow you to view and search your
                documents in the Joggr platform and start to build a knowledge
                base that is always up-to-date.
              </Typography>
            }
            avatar={<IconAvatar icon='file-import' />}
            action={
              <ButtonLink
                to='app.document-imports.list'
                variant='outlined'
                startIcon={<RikerIcon icon='list' />}
                color='primary'
                size='medium'
                sx={{
                  ml: 2,
                }}
              >
                View Imports
              </ButtonLink>
            }
          />
          <CardContent>
            <DocumentImportCreator />
          </CardContent>
        </Card>
      )}
    />
  );
};
