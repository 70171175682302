import React from 'react';

import { Dialog, DialogContent } from '@mui/material';

import { useDashDraftEditor } from '../../../hooks/use-dashdraft-editor';
import LinkForm, { type Props as LinkFormProps } from './LinkForm';

/*
|==========================================================================
| LinkDialog
|==========================================================================
|
| Edit dialog for links.
|
*/

export interface Props {
  open: boolean;
  href?: string;
  title?: string;
  onClose: () => void;
}

const LinkDialog: React.FC<Props> = ({ onClose, open, ...props }) => {
  const editor = useDashDraftEditor();

  /*
  |------------------
  | Callbacks
  |------------------
  */

  const handleRemove = React.useCallback(() => {
    editor.chain().focus().unsetLink().run();
    onClose();
  }, [editor, onClose]);

  const handleSave: LinkFormProps['onSave'] = React.useCallback(
    (link) => {
      const { from, to } = editor.state.selection;
      const content = [
        {
          type: 'text',
          text: link.text,
          marks: [
            {
              type: 'link',
              attrs: {
                href: link.url,
              },
            },
          ],
        },
      ];

      // Remove the link if it exists
      editor.chain().focus().unsetLink().run();

      // Replace the content in the selection or insert at the cursor position
      editor
        .chain()
        .focus()
        .deleteRange({ from, to })
        .insertContentAt(from, content)
        .run();

      onClose();
    },
    [editor, onClose]
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth='sm'
      sx={{
        p: 0,
      }}
      PaperProps={{
        sx: {
          p: 0,
        },
      }}
    >
      <DialogContent
        sx={{
          p: (theme) => theme.spacing(2),
        }}
      >
        <LinkForm
          defaultText={props.title}
          defaultUrl={props.href}
          onSave={handleSave}
          onRemove={handleRemove}
        />
      </DialogContent>
    </Dialog>
  );
};

const LinkDialogMemo = React.memo(LinkDialog);
LinkDialogMemo.displayName = 'LinkDialog';

export default LinkDialogMemo;
