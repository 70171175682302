import { Box, Card, LinearProgress, Stack, useTheme } from '@mui/material';
import React from 'react';

import { useResizeObserver } from '@stargate/hooks';
import { createComponentClasses } from '@stargate/theme';

import { useJDocLayout } from '../hooks/use-jdoc-layout';

export const joggrDocEditorClasses = createComponentClasses(
  'JoggrDocEditorLayout',
  ['root', 'metadata', 'actions', 'navigation', 'content'] as const
);

export interface JoggrDocLayoutProps {
  /**
   * Where the JoggrDoc is in a loading state.
   */
  loading: boolean;

  /**
   * The header of the JoggrDoc, primarily the GitHub data (left-side of the header).
   */
  metadata: React.ReactNode;

  /**
   * The actions of the JoggrDoc (right-side of the header)
   */
  actions: React.ReactNode;

  /**
   * The content of the JoggrDoc.
   */
  content: React.ReactNode;
}

/**
 * The layout for the Joggr JoggrDoc editor.
 */
export const JoggrDocLayout: React.FC<JoggrDocLayoutProps> = React.forwardRef<
  HTMLDivElement,
  JoggrDocLayoutProps
>((props, fwdRef) => {
  const theme = useTheme();
  const [{ headerPosition }, docLayoutActions] = useJDocLayout();

  const ref = useResizeObserver<HTMLDivElement>({
    ref: fwdRef,
    onResize: (size) => {
      docLayoutActions.setContainerSize({
        width: size.width ?? 0,
        height: size.height ?? 0,
      });
    },
    debounce: 100,
  });

  const headerRef = useResizeObserver<HTMLDivElement>({
    onResize: (size) => {
      docLayoutActions.setHeaderSize({
        width: size.width ?? 0,
        height: size.height ?? 0,
      });
    },
    debounce: 100,
  });

  return (
    <Card
      ref={ref}
      sx={{
        pt: '48px',
        px: 0,
        pb: 0,
        m: 0,
        border: 0,
        position: 'relative',
        borderRadius: 0,
        overflowY: 'auto',
        overflowX: 'auto',
        // We are subtracting the size of the navbar to
        // allow us a "full page" experience.
        minHeight: `calc(100vh - ${theme.measurements.topBar.height}px)`,
      }}
    >
      <Box
        ref={headerRef}
        sx={{
          position: 'fixed',
          top: headerPosition.top,
          left: headerPosition.left,
          right: 0,
          borderBottom: `1px solid ${theme.palette.divider}`,
          bgcolor: 'background.paper',
          zIndex: theme.zIndex.appBar,
        }}
      >
        <Stack
          direction='row'
          spacing={1}
          alignItems='center'
          sx={{ px: 2, py: 1 }}
        >
          {props.metadata}
          <Box sx={{ flexGrow: 1 }} />
          {props.actions}
        </Stack>
        <LinearProgress
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            height: '1px',
            visibility: props.loading ? 'visible' : 'hidden',
          }}
        />
      </Box>
      <Box
        sx={{
          height: '100%',
          maxWidth: '1200px',
          minWidth: '800px',
          ml: 'auto',
          mr: 'auto',
          [theme.breakpoints.down('xl')]: {
            maxWidth: '900px',
            minWidth: '600px',
          },
        }}
      >
        {props.content}
      </Box>
    </Card>
  );
});
