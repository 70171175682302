import { RikerIcon } from '@joggrdocs/riker-icons';
import {
  Box,
  Button,
  Divider,
  InputAdornment,
  Skeleton,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import _ from 'lodash';
import { matchSorter } from 'match-sorter';
import React from 'react';
import { z } from 'zod';

import type { APIResponse } from '@stargate/api';
import { TextSearchFilter } from '@stargate/components/FormFields';

import { useResizeObserver } from '@stargate/hooks';
import { createComponentClasses } from '@stargate/theme';
import { GitHubFileTreeView } from './GitHubFileTreeView';

export const githubDirectorySelectorClasses = createComponentClasses(
  'GitHubDirectorySelector'
);

/**
 * Select a directory from a GitHub repository.
 */
export interface GitHubDirectorySelectorProps {
  /**
   * The file tree data.
   */
  fileTree?: APIResponse<'GET /github/repositories/:repositoryId/file-tree'>;

  /**
   * The default directory, used to build the expanded tree.
   */
  defaultDirectory?: string;

  /**
   * If the component is disabled.
   *
   * @default false
   */
  disabled?: boolean;

  /**
   * Disable the ability to add a new directory.
   *
   * @default false
   */
  disableAddDirectory?: boolean;

  /**
   * If the component is read-only.
   */
  readonly: boolean;

  /**
   * If the component is loading.
   */
  loading: boolean;

  /**
   * Show the root directory.
   *
   * @default false
   */
  showRoot?: boolean;

  /**
   * Callback that triggers when the directory changes.
   *
   * @param directoryPath A directory path.
   */
  onChangeDirectory: (directoryPath: string) => void;
}

export const GitHubDirectorySelector: React.FC<
  GitHubDirectorySelectorProps
> = ({
  showRoot = false,
  disabled = false,
  disableAddDirectory = false,
  fileTree,
  readonly,
  defaultDirectory,
  loading,
  onChangeDirectory,
}) => {
  const [textFilter, setTextFilter] = React.useState('');
  const [addNewDirectory, setAddNewDirectory] = React.useState<string | null>(
    null
  );
  const [addNewDirectoryPath, setAddNewDirectoryPath] = React.useState('');
  const [selectedDirectory, setSelectedDirectory] = React.useState<
    string | null
  >(defaultDirectory ?? null);
  const [maxWidth, setMaxWidth] = React.useState<number | undefined>(undefined);
  const inputRef = React.useRef<HTMLInputElement>(null);

  /*
  |------------------
  | Computed
  |------------------
  */

  const filteredFileTree = React.useMemo(() => {
    if (_.isNil(fileTree)) {
      return null;
    }

    // We have to filter the tree and also
    // include the parent directories of the matching nodes.
    const tree = _.chain(fileTree.tree)
      .thru((nodes) => {
        if (addNewDirectory) {
          return [
            ...nodes,
            ...createFileTreeItemsFromFilePath(addNewDirectory),
          ];
        }
        return nodes;
      })
      .reject((node) => node.type === 'file')
      .map((node) => ({
        ...node,
        parentPaths: getParents(node.path),
      }))
      .thru((nodes) => {
        const matched = matchSorter(nodes, textFilter, {
          threshold: matchSorter.rankings.CONTAINS,
          keys: [(item) => item.path.replaceAll('/', ' ')],
        });
        const parentNodes = _.chain(nodes)
          .filter((node) => {
            return matched.some((match) => {
              return node.parentPaths.includes(match.path);
            });
          })
          .value();
        return [...matched, ...parentNodes];
      })
      .sortBy('path')
      .uniqBy('path')
      .value();
    return { ...fileTree, tree };
  }, [textFilter, fileTree, addNewDirectory]);

  /*
  |------------------
  | Effects
  |------------------
  */

  const ref = useResizeObserver<HTMLDivElement>({
    onResize: (size) => {
      setMaxWidth(size.width);
    },
    debounce: 100,
  });

  return (
    <Box
      className={githubDirectorySelectorClasses.root}
      ref={ref}
      sx={{
        width: '100%',
        pt: 1,
      }}
    >
      <TextSearchFilter
        disabled={disabled}
        onChange={(value) => {
          setTextFilter(value);
        }}
      />
      {filteredFileTree?.tree.length === 0 && !_.isEmpty(textFilter) && (
        <Box>Sorry no results, please update your search.</Box>
      )}
      {loading && <GitHubDirectorySelectorSkeleton />}
      {!loading &&
        !_.isNil(filteredFileTree) &&
        filteredFileTree.tree.length > 0 && (
          <GitHubFileTreeView
            fileTree={filteredFileTree}
            selectedItem={selectedDirectory}
            onSelectedItemsChange={(node) => {
              setSelectedDirectory(node?.fullPath ?? null);
              onChangeDirectory(node?.fullPath ?? '');
            }}
            showEmptyDirectories
            showRoot={showRoot}
            onAddItem={
              disableAddDirectory
                ? undefined
                : (directoryPath) => {
                    setSelectedDirectory(directoryPath);
                    setAddNewDirectoryPath(directoryPath);
                    inputRef.current?.focus();
                  }
            }
            autoExpand
            maxWidth={maxWidth}
          />
        )}
      <Divider sx={{ my: 2 }} />
      {!disableAddDirectory && !readonly && (
        <GitHubAddDirectoryTextField
          ref={inputRef}
          value={addNewDirectoryPath}
          onChange={(value) => {
            setAddNewDirectoryPath(value);
          }}
          onAddDirectory={(directoryPath) => {
            setSelectedDirectory(directoryPath);
            setAddNewDirectory(directoryPath);
            onChangeDirectory(directoryPath);
          }}
        />
      )}
    </Box>
  );
};

/*
|------------------
| Utils
|------------------
*/

type GitHubFileTreeItem =
  APIResponse<'GET /github/repositories/:repositoryId/file-tree'>['tree'][number];

/**
 * A skeleton for the directory.
 */
const GitHubDirectorySelectorSkeleton = React.memo(() => {
  return (
    <Stack
      sx={{
        mt: 1,
      }}
    >
      {_.times(6).map((n) => (
        <Box
          key={n}
          sx={{
            display: 'flex',
            alignItems: 'center',
            minWidth: '148px',
            mb: 1,
          }}
        >
          <Box sx={{ ml: 2, mr: 1 }}>
            <Skeleton variant='rectangular' width={20} height={20} />
          </Box>
          <Box sx={{ width: '100%' }}>
            <Skeleton width='100%' height={20} />
          </Box>
        </Box>
      ))}
    </Stack>
  );
});
GitHubDirectorySelectorSkeleton.displayName = 'GitHubDirectorySelectorSkeleton';

/**
 * Form for adding a new directory in the GitHub directory selector.
 */
const GitHubAddDirectoryTextField = React.forwardRef<
  HTMLInputElement,
  {
    /**
     * The value of the text field.
     */
    value: string;

    /**
     * Callback that triggers when the value changes.
     */
    onChange: (value: string) => void;

    /**
     * Callback that triggers when the action to add a new directory is triggered.
     */
    onAddDirectory: (directoryPath: string) => void;
  }
>(({ value, onChange, onAddDirectory }, ref) => {
  const [error, setError] = React.useState<string | null>(null);

  const handleAddDirectory = () => {
    if (isValidDirectoryPath(value)) {
      setError(null);
      onAddDirectory(_.chain(value).trimStart('/').trimEnd('/').value());
      onChange('');
    } else {
      setError('Sorry, you cannot add the directory until its a valid path.');
    }
  };

  return (
    <TextField
      inputRef={ref}
      size='small'
      fullWidth
      value={value}
      onChange={(event) => {
        setError(null);
        onChange(event.target.value);
      }}
      onFocus={(e) => {
        e.currentTarget.setSelectionRange(
          e.currentTarget.value.length,
          e.currentTarget.value.length
        );
      }}
      error={!!error}
      helperText={
        error ?? (
          <React.Fragment>
            Start typing to add a new directory & each part separated by a{' '}
            <code>/</code> will create a new directory.
          </React.Fragment>
        )
      }
      onKeyUp={(event) => {
        if (event.key === 'Enter') {
          handleAddDirectory();
        }
      }}
      slotProps={{
        input: {
          sx: {
            pr: 1,
          },
          startAdornment: (
            <InputAdornment position='start'>
              <RikerIcon icon='folder-plus' color='primary' />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position='end'>
              <Tooltip
                title={
                  error ??
                  'Click to add a new directory to your codebase for your JoggrDoc'
                }
                placement='right'
                arrow
              >
                <span>
                  <Button
                    size='small'
                    startIcon={<RikerIcon icon='plus' />}
                    disabled={!isValidDirectoryPath(value) || !!error}
                    onClick={handleAddDirectory}
                    sx={{
                      fontSize: '0.75rem',
                      px: 1,
                      py: 0.25,
                      minHeight: 0,
                    }}
                  >
                    Add directory
                  </Button>
                </span>
              </Tooltip>
            </InputAdornment>
          ),
        },
      }}
      placeholder='Enter directory name'
      sx={{
        mb: 1,
      }}
    />
  );
});
GitHubAddDirectoryTextField.displayName = 'GitHubAddDirectoryTextField';

/**
 * Check if a directory path is valid.
 *
 * @param directoryPath The string to check.
 * @returns A boolean indicating if the directoryPath is valid.
 */
const isValidDirectoryPath = (directoryPath: string): boolean => {
  const result = z
    .string()
    .regex(/^\/?.?([a-zA-Z0-9-_]+\/)*[a-zA-Z0-9-_]+\/?$/)
    .safeParse(directoryPath);
  return result.success;
};

/**
 * Create tree nodes from a file path.
 *
 * @param filePath A file path.
 * @returns An array of tree nodes.
 */
const createFileTreeItemsFromFilePath = (
  filePath: string
): GitHubFileTreeItem[] => {
  const splitPath = filePath.split('/').filter((x) => x !== '');
  if (splitPath.length > 1) {
    const tree: GitHubFileTreeItem[] = [];
    for (let i = 0; i < splitPath.length; i++) {
      tree.push({
        path: splitPath.slice(0, splitPath.length - i).join('/'),
        type: 'directory',
        sha: '-',
      });
    }
    return tree;
  }
  if (splitPath.length === 1) {
    return [
      {
        path: splitPath.join('/'),
        type: 'directory',
        sha: '-',
      },
    ];
  }
  return [];
};

/**
 * Get the parent directories of a path.
 *
 * @param path A path to get the parents.
 * @returns An array of parent directories.
 */
const getParents = (path: string): string[] => {
  const parents: string[] = [];
  const parts = path.split('/');

  parts.forEach((_part, index) => {
    if (index === 0) {
      return;
    }
    parents.push(parts.slice(0, index).join('/'));
  });

  return parents;
};
