import React from 'react';
import type * as TypeFest from 'type-fest';

import {
  Alert,
  type AlertProps,
  AlertTitle,
  type AlertTitleProps,
} from '@mui/material';

import { type HttpError, useHttpErrors } from '@stargate/lib/http';
import { createComponentClasses } from '@stargate/theme';
import _ from 'lodash';

export const alertHttpErrorClasses = createComponentClasses('AlertHttpError', [
  'root',
] as const);

export type AlertHttpErrorProps = TypeFest.Simplify<
  AlertProps & {
    /**
     * An HTTP error to display.
     */
    error?: HttpError | unknown;

    /**
     * Override the default title of the alert.
     */
    title?: string;

    /**
     * Override the props of the alert title.
     */
    AlertTitleProps?: Omit<AlertTitleProps, 'children'>;
  }
>;

/**
 * Component for rendering an error alert for HTTP errors.
 */
export const AlertHttpError = React.forwardRef<
  HTMLDivElement,
  AlertHttpErrorProps
>(({ severity = 'error', AlertTitleProps, onClose, ...props }, ref) => {
  const httpErrors = useHttpErrors();
  const [show, setShow] = React.useState(true);

  const handleClose = (e: React.SyntheticEvent) => {
    if (!_.isNil(onClose)) {
      onClose(e);
    }
    setShow(false);
  };

  if (!show) {
    return null;
  }

  return (
    <Alert
      ref={ref}
      classes={alertHttpErrorClasses}
      severity={severity}
      onClose={handleClose}
      {...props}
    >
      <AlertTitle {...AlertTitleProps}>
        {props.title ?? httpErrors.getUserFriendlyErrorName(props.error)}
      </AlertTitle>
      {httpErrors.getUserFriendlyErrorMessage(props.error)}
    </Alert>
  );
});
