import { RikerIcon } from '@joggrdocs/riker-icons';
import { Box, Button, Chip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React from 'react';

import type contract from '@joggrdocs/contract';
import {
  GitHubAccountChip,
  GitHubRepositoryChip,
  GitHubRepositoryOwnerChip,
} from '@stargate/features/github';
import { createComponentClasses } from '@stargate/theme';

export const documentImportCreatorTableClasses = createComponentClasses(
  'DocumentsImportCreatorTable',
  ['root'] as const
);

export type DocumentImports =
  contract.Schema['GET /document-imports']['response'];

export type DocumentImport = DocumentImports[number];

export type DocumentImportsTableProps = {
  rows: DocumentImport[];
  loading: boolean;
};

export const DocumentImportsTable: React.FC<DocumentImportsTableProps> = (
  props
) => {
  return (
    <Box>
      <DataGrid
        loading={props.loading}
        rows={props.rows.map((row) => ({
          ...row,
          pullRequestNumber: row.pullRequest.number,
          pullRequestUrl: row.pullRequest.url,
          repository: row.pullRequest.repositoryName,
          organization: row.pullRequest.repositoryOwnerName,
          id: row.id,
        }))}
        columns={[
          {
            field: 'status',
            headerName: 'Status',
            sortable: true,
            editable: false,
            filterable: true,
            type: 'singleSelect',
            valueOptions: ['pending', 'completed', 'cancelled'],
            flex: 1,
            renderCell: (params) => {
              switch (params.value) {
                case 'pending':
                  return (
                    <Chip
                      icon={<RikerIcon icon='hourglass' />}
                      label='pending'
                      color='error'
                    />
                  );
                case 'completed':
                  return (
                    <Chip
                      icon={<RikerIcon icon='checkbox' />}
                      label='completed'
                      color='success'
                    />
                  );
                case 'cancelled':
                  return (
                    <Chip
                      icon={<RikerIcon icon='archive' />}
                      label='cancelled'
                      color='warning'
                    />
                  );
              }
            },
          },
          {
            field: 'organization',
            headerName: 'GitHub Organization',
            sortable: false,
            editable: false,
            flex: 2,
            description:
              'The GitHub repository that contains the documents to import.',
            renderCell: (params) => {
              return <GitHubRepositoryOwnerChip owner={params.value} />;
            },
          },
          {
            field: 'repository',
            headerName: 'GitHub Repository',
            sortable: false,
            editable: false,
            flex: 2,
            description:
              'The GitHub repository that contains the documents to import.',
            renderCell: (params) => {
              return (
                <GitHubRepositoryChip
                  owner={params.row.organization}
                  repo={params.row.repository}
                  disableOwner
                />
              );
            },
          },
          {
            field: 'creator',
            headerName: 'Created by',
            sortable: false,
            editable: false,
            flex: 2,
            description: 'The user that created the import.',
            renderCell: (params) => {
              return (
                <GitHubAccountChip
                  account={params.row.creator?.githubUsername}
                />
              );
            },
          },
          {
            field: 'createdAt',
            headerName: 'Created At',
            sortable: true,
            editable: false,
            flex: 1,
            description: 'The date and time the import was created.',
            renderCell: (params) => {
              return new Date(params.value as string).toLocaleString();
            },
          },
          {
            field: 'updatedAt',
            headerName: 'Completed At',
            sortable: true,
            editable: false,
            flex: 1,
            description: 'The date and time the import was completed.',
            renderCell: (params) => {
              if (params.row.status === 'completed') {
                return new Date(params.value as string).toLocaleString();
              }
              return 'N/A';
            },
          },
          {
            field: 'pullRequestUrl',
            headerName: 'Actions',
            sortable: false,
            editable: true,
            disableColumnMenu: true,
            width: 200,
            renderCell: (params) => {
              return (
                <Button
                  href={params.value as string}
                  size='small'
                  startIcon={<RikerIcon icon='git-pull-request' />}
                  fullWidth
                  color='secondary'
                  rel='noopener noreferrer'
                  target='_blank'
                >
                  View Pull Request
                </Button>
              );
            },
          },
        ]}
        editMode='row'
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
          filter: {
            filterModel: {
              items: [
                {
                  field: 'status',
                  operator: 'is',
                  value: 'pending',
                },
              ],
            },
          },
          sorting: {
            sortModel: [{ field: 'status', sort: 'asc' }],
          },
        }}
        pageSizeOptions={[5, 10, 15, 25]}
        localeText={{
          noRowsLabel:
            'No imports found, you try to refresh the page or change your filters to show more imports.',
        }}
        autoHeight
        disableRowSelectionOnClick
        showCellVerticalBorder
        showColumnVerticalBorder
      />
    </Box>
  );
};
