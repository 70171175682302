import { RikerIcon, type RikerIconName } from '@joggrdocs/riker-icons';
import {
  Box,
  Card,
  Divider,
  FormControl,
  InputLabel,
  LinearProgress,
  Select,
  type SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import * as hookz from '@react-hookz/web';
import React from 'react';
import TablerIconFilePlus from '~icons/tabler/file-plus';

import api from '@stargate/api';
import { ButtonLink } from '@stargate/components/Buttons';
import {
  SelectMenuItem,
  SelectRenderValue,
} from '@stargate/components/FormFields';
import { LoadingOverlay } from '@stargate/components/Loading';
import {
  type DashDraftJSONContent,
  convertMarkdownToJSON,
} from '@stargate/dashdraft';
import { useUser } from '@stargate/features/user';
import { useDelayedState } from '@stargate/hooks';
import * as fileUtils from '@stargate/utils/files';

import type { ShortcutItem } from '../types';
import TemplateViewer from './TemplateViewer';

export type TemplateSectionProps = ShortcutItem;

const TemplateSection: React.FC<TemplateSectionProps> = (props) => {
  const [activeTemplateId, setActiveTemplateId] =
    React.useState<string>('how-to-guide');
  const authenticatedUser = useUser();
  const [listTemplatesState, listTemplatesActions] =
    api.useRequestClient('GET /templates');
  const [templateActiveState, templateRenderActions] = api.useRequestClient(
    'POST /templates/:templateId/render'
  );
  const [loadingList, setListLoading, setListDelayedLoading] =
    useDelayedState(false);
  const [loadingTemplate, setLoadingTemplate, setDelayedLoadingTemplate] =
    useDelayedState(false);
  const [content, setContent] = React.useState<DashDraftJSONContent | null>(
    null
  );

  /*
  |------------------
  | Utils
  |------------------
  */

  const loadTemplateData = async (payload: {
    id: string;
    loadList: boolean;
  }) => {
    setLoadingTemplate(true);
    if (payload.loadList) {
      setListLoading(true);
      await listTemplatesActions.execute();
    }

    const res = await templateRenderActions.execute({
      params: {
        templateId: payload.id,
      },
      body: {
        variables: [
          // We always set these for now, since we don't have a way to set them in the UI
          {
            name: 'createdAt',
            value: new Date().toISOString(),
          },
          {
            name: 'author',
            value:
              authenticatedUser.data?.githubUsername ??
              authenticatedUser.data?.email ??
              'TBD',
          },
        ],
      },
    });
    const tempContent = await convertMarkdownToJSON(
      fileUtils.decodeBase64(res.content)
    );
    setContent(tempContent);

    if (payload.loadList) {
      setListDelayedLoading(false, 500);
    }
    setDelayedLoadingTemplate(false, 500);
  };

  /*
  |------------------
  | Callbacks
  |------------------
  */

  const handleChangeTemplate = React.useCallback(
    (event: SelectChangeEvent<string>) => {
      const templateId = event.target.value as string | undefined;
      if (templateId) {
        setActiveTemplateId(templateId);

        loadTemplateData({
          id: templateId,
          loadList: false,
        });
      }
    },
    []
  );

  /*
  |------------------
  | Effects
  |------------------
  */

  hookz.useMountEffect(() => {
    loadTemplateData({
      id: activeTemplateId,
      loadList: true,
    });
  });

  return (
    <React.Fragment>
      <LinearProgress
        variant='indeterminate'
        sx={{
          visibility: loadingList ? 'visible' : 'hidden',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
        }}
      />
      <Stack spacing={2} direction='row' justifyContent='stretch'>
        <Box sx={{ width: 'calc(100% - 500px)' }}>
          <Typography variant='h3' gutterBottom>
            Create with a Template
          </Typography>
          <Typography variant='body1' gutterBottom>
            Create a document by selecting a template from below.
          </Typography>
          <Box sx={{ mb: 2 }} />
          <FormControl fullWidth>
            <InputLabel id='template-selector'>Template</InputLabel>
            <Select
              labelId='template-selector'
              id='template-selector-select'
              value={activeTemplateId}
              label='Template'
              size='small'
              disabled={loadingList || loadingTemplate}
              onChange={handleChangeTemplate}
              renderValue={(templateId) => {
                const foundItem = listTemplatesState.result?.find(
                  (t) => t.id === templateId
                );
                return (
                  <SelectRenderValue
                    icon={
                      foundItem ? (
                        <RikerIcon icon={foundItem.icon as RikerIconName} />
                      ) : undefined
                    }
                    label={foundItem?.name}
                    placeholder='Select a Template'
                  />
                );
              }}
            >
              {listTemplatesState.result?.map((t) => (
                <SelectMenuItem
                  key={t.id}
                  value={t.id}
                  label={t.name}
                  icon={<RikerIcon icon={t.icon as RikerIconName} />}
                />
              ))}
            </Select>
          </FormControl>
          <Divider sx={{ mt: 1, mb: 2 }} />
          {templateActiveState.result && (
            <React.Fragment>
              <Typography variant='h6' gutterBottom>
                {templateActiveState.result.name}
              </Typography>
              <Typography variant='body1' fontSize='14px' gutterBottom>
                {templateActiveState.result.description}
              </Typography>
              <Box sx={{ mb: 2 }} />
              <ButtonLink
                startIcon={<TablerIconFilePlus height={32} width={32} />}
                variant='contained'
                to='app.documents.create'
                size='small'
                search={{
                  template: templateActiveState.result.id,
                  dir: props.meta?.directoryId ?? 'root',
                }}
                disabled={loadingList || loadingTemplate}
                onClick={props.onClose}
              >
                Create Document
              </ButtonLink>
            </React.Fragment>
          )}
        </Box>
        <Stack
          spacing={0.5}
          direction='column'
          alignItems='center'
          justifyContent='center'
        >
          <Card
            sx={{
              position: 'relative',
              width: '500px',
              maxHeight: '548px',
              minHeight: '548px',
              overflowY: 'auto',
              p: 2,
            }}
          >
            <LoadingOverlay variant='contained' loading={loadingTemplate} />
            {templateActiveState.result &&
              templateActiveState.status === 'success' &&
              content && <TemplateViewer content={content} />}
          </Card>
          <Typography
            variant='caption'
            sx={{
              visibility:
                templateActiveState.status === 'loading' ? 'hidden' : 'visible',
            }}
          >
            <React.Fragment>
              Preview of the <strong>{templateActiveState.result?.name}</strong>{' '}
              template.
            </React.Fragment>
          </Typography>
        </Stack>
      </Stack>
    </React.Fragment>
  );
};

export default TemplateSection;
