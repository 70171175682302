import { RikerIcon, type RikerIconName } from '@joggrdocs/riker-icons';
import { Avatar, type AvatarProps, type Theme, useTheme } from '@mui/material';
import React from 'react';
import type * as TF from 'type-fest';

import {
  type ThemePaletteColorVariant,
  createComponentClasses,
  isPaletteColorVariant,
} from '@/theme';

export const iconAvatarClasses = createComponentClasses('IconAvatar', [
  'icon',
] as const);

export type IconAvatarClasses = typeof iconAvatarClasses;

export interface IconAvatarProps extends Pick<AvatarProps, 'variant' | 'sx'> {
  /**
   * The Riker icon to display.
   */
  icon: RikerIconName;
  /**
   * The color of the icon.
   *
   * @default 'contrast'
   */
  iconColor?: TF.LiteralUnion<'contrast' | keyof Theme['palette'], string>;
  /**
   * The fill color of the icon.
   *   */
  iconFill?: TF.LiteralUnion<'contrast' | keyof Theme['palette'], string>;
  /**
   * The background color of the avatar.
   *
   * @default 'primary'
   */
  backgroundColor?: TF.LiteralUnion<ThemePaletteColorVariant, string>;
  /**
   * The size of the avatar.
   */
  size?: number;
  /**
   * The gap using the Riker spacing (i.e. 1 = 8px) between the icon and the edge of the avatar.
   *
   * @default 0.5
   */
  gap?: number;
  /**
   * Override the classes applied to the component.
   */
  classes?: TF.LiteralToPrimitiveDeep<Partial<IconAvatarClasses>>;
}

/**
 * An avatar with a Riker icon.
 */
export const IconAvatar = React.forwardRef<HTMLDivElement, IconAvatarProps>(
  (
    {
      gap = 0.5,
      iconColor = 'contrast',
      iconFill,
      backgroundColor = 'primary',
      variant = 'rounded',
      size = 40,
      icon,
      ...props
    },
    ref
  ) => {
    const theme = useTheme();

    /*
  |------------------
  | Computed
  |------------------
  */

    const bgcolor = React.useMemo(() => {
      if (isPaletteColorVariant(backgroundColor)) {
        return theme.palette[backgroundColor].main;
      }
      return backgroundColor;
    }, [backgroundColor, theme]);

    const contrastText = React.useMemo(() => {
      return theme.palette.getContrastText(bgcolor);
    }, [theme, bgcolor]);

    const color = React.useMemo(() => {
      if (isPaletteColorVariant(iconColor)) {
        return theme.palette[iconColor].main;
      }

      if (iconColor === 'contrast') {
        return contrastText;
      }

      return iconColor;
    }, [iconColor, theme, contrastText]);

    const fill = React.useMemo(() => {
      if (iconFill && isPaletteColorVariant(iconFill)) {
        return theme.palette[iconFill].main;
      }

      if (iconFill === 'contrast') {
        return contrastText;
      }

      return iconFill ?? undefined;
    }, [iconFill, theme, contrastText]);

    const classes = React.useMemo(() => {
      return {
        ...iconAvatarClasses,
        ...props.classes,
      };
    }, [props.classes]);

    return (
      <Avatar
        {...props}
        ref={ref}
        variant={variant}
        classes={classes}
        sx={{
          [`&.${classes.root}`]: {
            ...props.sx,
            height: `${size}px`,
            width: `${size}px`,
            p: gap,
            backgroundColor: bgcolor,
            color,
            [`& .${classes.icon}`]: {
              fill,
              height: 'auto',
              width: '100%',
            },
          },
        }}
      >
        <RikerIcon
          icon={icon}
          classes={{
            root: props.classes?.icon ?? iconAvatarClasses.icon,
          }}
        />
      </Avatar>
    );
  }
);
IconAvatar.muiName = 'Avatar';
IconAvatar.displayName = 'IconAvatar';
