import _ from 'lodash';
import React from 'react';

import {
  Avatar,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';

import { ComingSoonChip } from '@stargate/components/Chips';

import { WorkspaceIcon } from '@stargate/features/workspaces';
import { Link } from '@stargate/routes';

import { RikerIcon } from '@joggrdocs/riker-icons';
import {
  IconFileInvoice,
  IconLogout,
  IconPlugConnected,
} from '@tabler/icons-react';
import type { User } from '../hooks/use-user';
import { UserProfileIcon } from './UserProfileIcon';

/*
|==========================================================================
| UserProfileActionsMenu
|==========================================================================
|
| Actions for the user profile.
|
*/

export interface UserProfileActionsMenuProps {
  user?: User;
}

export const UserProfileActionsMenu: React.FC<UserProfileActionsMenuProps> = (
  props
) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const open = React.useMemo(() => !!anchorEl, [anchorEl]);
  const theme = useTheme();

  /*
  |------------------
  | Callbacks
  |------------------
  */

  const handleToggle = React.useCallback(
    (event: React.SyntheticEvent<HTMLDivElement>) => {
      if (!open) {
        setAnchorEl(event.currentTarget);
      } else {
        setAnchorEl(null);
      }
    },
    [open]
  );

  const handleClose = React.useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <React.Fragment>
      <Avatar
        onClick={handleToggle}
        src={props.user?.picture}
        className={open ? 'active' : undefined}
        sx={{
          height: 'auto',
          width: `calc(${theme.measurements.topBar.height}px - 16px)`,
          cursor: 'pointer',
          border: '3px solid transparent',
          '&:hover, &.active': {
            border: `3px solid ${theme.palette.divider}`,
          },
        }}
        color='inherit'
      >
        {!_.isNil(props.user?.picture) ? null : <RikerIcon icon='user' />}
      </Avatar>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        disableScrollLock
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem component={Link} to='app.settings' onClick={handleClose}>
          <ListItemIcon>
            <UserProfileIcon />
          </ListItemIcon>
          <ListItemText>Your Profile</ListItemText>
        </MenuItem>
        <MenuItem
          component={Link}
          to='app.settings'
          search={{ activeTab: 'workspaces' }}
          onClick={handleClose}
        >
          <ListItemIcon>
            <WorkspaceIcon />
          </ListItemIcon>
          <ListItemText>Your Workspaces</ListItemText>
        </MenuItem>
        <MenuItem disabled>
          <ListItemIcon>
            <IconFileInvoice />
          </ListItemIcon>
          <ListItemText>Billing</ListItemText>
          <ComingSoonChip sx={{ ml: 1 }} />
        </MenuItem>
        <MenuItem component={Link} to='app.integrations' onClick={handleClose}>
          <ListItemIcon>
            <IconPlugConnected />
          </ListItemIcon>
          <ListItemText>Integrations</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem component={Link} to='root.logout' onClick={handleClose}>
          <ListItemIcon>
            <IconLogout />
          </ListItemIcon>
          <ListItemText>Logout</ListItemText>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};
