import * as hookz from '@react-hookz/web';
import _ from 'lodash';
import React from 'react';

import {
  Alert,
  AlertTitle,
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  type SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';

import { type User, useUser } from '@stargate/features/user';
import useSearchParams from '@stargate/hooks/use-search-params';
import { useNotify } from '@stargate/lib/notify';

import { useApp } from '@stargate/app';
import { useWorkspace, useWorkspaces } from '@stargate/features/workspaces';
import SettingsSectionHeader from '../SettingsSectionHeader';

export interface Props {
  reloadUser: () => void;
  authenticatedUser: User;
}

function Workspaces(props: Props) {
  const [searchParams] = useSearchParams<{ warning?: 'true' }>();
  const workspace = useWorkspace();
  const workspaces = useWorkspaces();
  const user = useUser();
  const notify = useNotify();
  const app = useApp();

  /*
  |------------------
  | Computed
  |------------------
  */

  const availableWorkspaces = React.useMemo(() => {
    return _.chain([...workspaces.data, workspace.data])
      .compact()
      .uniqBy('id')
      .value();
  }, [workspaces.data, workspace.data]);

  /*
  |------------------
  | Callbacks
  |------------------
  */

  const handleChange = (event: SelectChangeEvent) => {
    app.setLoading(true);
    void workspace
      .update(event.target.value)
      .then(async () => {
        await user.load({ clearCache: true });
        notify.success('Workspace set successfully');
        window.location.reload();
      })
      .catch(() => {
        notify.error('Failed to set workspace');
        app.setLoading(false);
      });
  };

  /*
  |------------------
  | Effects
  |------------------
  */

  hookz.useMountEffect(() => {
    void workspace.load();
    void workspaces.load();
  });

  if (!workspace.data || app.loading === true) {
    return <CircularProgress size={36} />;
  }

  return (
    <React.Fragment>
      <Box>
        <SettingsSectionHeader
          title='Workspaces'
          description='Manage your workspaces, including your active workspaces here.'
        />
        {searchParams.warning && !workspace && (
          <Alert severity='error' sx={{ mb: 1 }}>
            <AlertTitle>Need a Default</AlertTitle>
            Please set a default workspace below.
          </Alert>
        )}
        <Stack direction='row' spacing={1} alignItems='center'>
          <FormControl fullWidth>
            <InputLabel id='active-workspace-select-label'>
              Active Workspace
            </InputLabel>
            <Select<string>
              labelId='active-workspace-select-label'
              id='active-workspace-select'
              defaultValue={workspace.data?.id}
              label='Active Workspace'
              onChange={handleChange}
              disabled={workspaces.loading}
              renderValue={(value) => {
                const activeWorkspace = availableWorkspaces.find(
                  (workspace) => workspace.id === value
                );

                if (!activeWorkspace) {
                  return (
                    <Typography component='span'>
                      Please select a workspace
                    </Typography>
                  );
                }

                return (
                  <Typography component='span'>
                    {activeWorkspace.name}
                  </Typography>
                );
              }}
            >
              {availableWorkspaces.map((workspace) => (
                <MenuItem key={workspace.id} value={workspace.id}>
                  {workspace.name}&nbsp;
                  {workspace.description && (
                    <Typography
                      component='span'
                      noWrap
                      sx={{ color: 'text.secondary', maxWidth: '200px' }}
                    >
                      ({workspace.description})
                    </Typography>
                  )}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {(workspace.loading || workspaces.loading) && (
            <CircularProgress size={36} />
          )}
        </Stack>
      </Box>
    </React.Fragment>
  );
}

export default Workspaces;
