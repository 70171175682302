import { Box, LinearProgress, useTheme } from '@mui/material';
import * as hookz from '@react-hookz/web';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';
import React from 'react';
import type * as tf from 'type-fest';

import api from '@stargate/api';
import { jdocCodeSourcesQueryOptions } from '@stargate/api';
import { LoadingOverlay } from '@stargate/components/Loading';
import {
  isSelectionEmptyCodeSnippet,
  isSelectionEmptyLine,
  useDashDraftEditor,
} from '@stargate/dashdraft';
import {
  type JDocComponentProps,
  useJDocDraftMutate,
} from '@stargate/features/docs';
import { useUserDefaults } from '@stargate/features/user';
import { useNotify } from '@stargate/lib/notify';
import { createComponentClasses } from '@stargate/theme';
import { cn } from '@stargate/utils/styles';

import { useCodeExplorer } from '../../hooks/use-code-explorer';
import type {
  CodeExplorerCodeSourceIdQuery,
  CodeExplorerCodeSourcePathQuery,
  CodeExplorerQuery,
} from '../../types';
import { CodeExplorerFileTree } from '../explorer-file-tree/CodeExplorerFileTree';
import { CodeExplorerButton } from './CodeExplorerButton';
import {
  CodeExplorerContentFile,
  type CodeExplorerContentFileProps,
} from './CodeExplorerContentFile';
import { CodeExplorerContentPlaceholder } from './CodeExplorerContentPlaceholder';
import { CodeExplorerDrawer } from './CodeExplorerDrawer';
import { CodeExplorerHeader } from './CodeExplorerHeader';
import { CodeExplorerLayout } from './CodeExplorerLayout';

export const codeExplorerClasses = createComponentClasses('CodeExplorer', [
  'root',
  'fab',
  'drawer',
  'placement-right',
  'placement-bottom',
] as const);

export type CodeExplorerProps = JDocComponentProps<{
  /**
   * Whether the CodeExplorer is readonly.
   */
  readonly: boolean;
}>;

export const CodeExplorer = React.memo<CodeExplorerProps>(
  ({ doc, draft, readonly }) => {
    const theme = useTheme();
    const notify = useNotify();
    const editor = useDashDraftEditor();
    const [userDefaultsState, userDefaultsActions] = useUserDefaults();
    const [codeExplorerState, codeExplorerActions] = useCodeExplorer();
    const jdocDraftMutate = useJDocDraftMutate();

    /*
    |------------------
    | Queries & Mutations
    |------------------
    */

    const queryClient = useQueryClient();
    const codeSourcesQuery = useQuery({
      ...jdocCodeSourcesQueryOptions(doc?.id),
      initialData: [],
    });

    // @todo change all to ReactQuery
    const [ghFileTreeState, ghFileTreeActions] = api.useRequestClient(
      'GET /github/repositories/:repositoryId/file-tree'
    );
    const [ghFileContentState, ghFileContentActions] = api.useRequestClient(
      'GET /github/repositories/:repositoryId/file-contents/:filePath'
    );
    const [, codeSourceActions] = api.useRequestClient(
      'GET /code-sources/:codeSourceId'
    );
    const [codeSourceCreateState, codeSourceCreateActions] =
      api.useRequestClient('POST /code-sources');
    const [docCodeUpsertState, docCodeUpsertActions] = api.useRequestClient(
      'PUT /documents/:documentId/code-sources/:codeSourceId'
    );

    /*
    |------------------
    | Computed
    |------------------
    */

    const defaultGitHubRepository = React.useMemo(() => {
      return doc?.github?.repository;
    }, [doc]);

    const contentFileHasCodeLink = React.useMemo(() => {
      if (ghFileContentState.status === 'loading') {
        return true;
      }

      const contentPath = ghFileContentState.result?.path;
      if (contentPath) {
        return _.some(
          [...codeSourcesQuery.data, ...(draft?.codeSources ?? [])],
          (codeSource) => {
            return codeSource.path === contentPath;
          }
        );
      }

      return false;
    }, [codeSourcesQuery.data, draft?.codeSources, ghFileContentState]);

    const canInsertCodeSnippet = React.useMemo(() => {
      return (
        isSelectionEmptyLine(editor.state) ||
        isSelectionEmptyCodeSnippet(editor.state)
      );
    }, [editor.state]);

    /*
    |------------------
    | Handlers
    |------------------
    */

    const onCodeLinkAction = React.useCallback<
      CodeExplorerContentFileProps['onCodeLinkAction']
    >(
      async (payload) => {
        const query = codeExplorerState.query;
        if (isValidQuery(query)) {
          const codeLink = await codeSourceCreateActions.execute({
            body: {
              type: 'file',
              repositoryId: query.githubRepositoryId,
              repositoryOwnerId: query.githubOrganizationId,
              branchName: query.branch,
              path: payload.filePath,

              // @ts-expect-error - this is valid, don't now why TS API is complaining
              lineNumberEnd: null,
              // @ts-expect-error - this is valid, don't now why TS API is complaining
              lineNumberStart: null,
            },
          });

          // @todo don't do this here but AFTER commit (future us)
          if (doc?.id) {
            await docCodeUpsertActions.execute({
              params: {
                documentId: doc.id,
                codeSourceId: codeLink.id,
              },
            });
            queryClient.invalidateQueries({
              queryKey: jdocCodeSourcesQueryOptions(doc.id).queryKey,
            });
          } else {
            // Add to the local draft if we are in "create" mode (& for future where we put them through commits)
            jdocDraftMutate.addCodeSource(codeLink);
          }
        }

        notify.success('Code successfully linked to JoggrDoc.');
        codeExplorerActions.close();
      },
      [
        codeExplorerActions,
        jdocDraftMutate,
        doc,
        docCodeUpsertActions,
        codeSourceCreateActions,
        notify,
        codeExplorerState,
      ]
    );

    const onCodeSnippetAction = React.useCallback<
      CodeExplorerContentFileProps['onCodeSnippetAction']
    >(
      async (payload) => {
        const query = codeExplorerState.query;
        if (isValidQuery(query)) {
          const codeSnippet = await codeSourceCreateActions.execute({
            body: {
              type: 'file',
              repositoryId: query.githubRepositoryId,
              repositoryOwnerId: query.githubOrganizationId,
              branchName: query.branch,
              path: payload.filePath,
              lineNumberEnd: payload.endLine,
              lineNumberStart: payload.startLine,
            },
          });

          // Add to draft so we can link it to the document
          // after we save the document (commit it)
          jdocDraftMutate.addCodeSource(codeSnippet);

          editor.commands.setCodeSnippet({
            codeSnippetId: codeSnippet.id,
            code: payload.content,
            language: payload.language,
          });
        }

        notify.success('Code successfully inserted Code Snippet to JoggrDoc.');
        codeExplorerActions.close();
      },
      [
        codeSourceCreateActions,
        codeExplorerActions,
        codeExplorerState.query,
        editor.commands,
        notify,
        jdocDraftMutate,
      ]
    );

    const onChangeQuery = React.useCallback(
      (query: Partial<CodeExplorerQuery>) => {
        ghFileContentActions.reset();
        ghFileTreeActions.reset();
        codeExplorerActions.mergeQuery({
          ...query,
          code: null,
        });

        if (query?.githubOrganizationId) {
          userDefaultsActions.setRepositoryOwnerId(query.githubOrganizationId);
        }
        if (query?.githubRepositoryId) {
          userDefaultsActions.setRepositoryId(query.githubRepositoryId);
        }
      },
      [
        codeExplorerActions,
        userDefaultsActions,
        ghFileContentActions,
        ghFileTreeActions,
      ]
    );

    const onFileSelect = React.useCallback(
      (filePath: string) => {
        if (isValidQuery(codeExplorerState.query)) {
          codeExplorerActions.mergeQuery({
            code: {
              path: filePath,
            },
          });
        }
      },
      [codeExplorerState.query, codeExplorerActions]
    );

    /*
    |------------------
    | Effects
    |------------------
    */

    // If the defaultGitHubRepository is provided, set it as the default query and
    // override the user defaults
    // biome-ignore lint/correctness/useExhaustiveDependencies: We only care about the defaultGitHubRepository
    React.useEffect(() => {
      if (defaultGitHubRepository) {
        codeExplorerActions.mergeQuery({
          githubOrganizationId: defaultGitHubRepository.owner.id.toString(),
          githubRepositoryId: defaultGitHubRepository.id.toString(),
          branch: defaultGitHubRepository.defaultBranch,
        });
      }
    }, [defaultGitHubRepository]);

    // If we have a default repository, set it as the default query
    // for the initial mount.
    hookz.useMountEffect(() => {
      if (userDefaultsState.repositoryOwnerId && !defaultGitHubRepository) {
        codeExplorerActions.mergeQuery({
          githubOrganizationId: userDefaultsState.repositoryOwnerId,
        });
      }
    });

    // Every time the query changes, we need to fetch the file tree
    // this allows us to be reactive to state changes and use callbacks
    // to manage the state changes.
    const previousQuery = hookz.usePrevious(codeExplorerState.query);
    React.useEffect(() => {
      const query = codeExplorerState.query;

      // If we have a codeSourceID we reload based on
      // the code source id and branch
      if (
        isCodeSourceIdQuery(query?.code) &&
        query.code.id !== _.get(previousQuery?.code, 'id', null)
      ) {
        void codeSourceActions
          .execute({
            params: {
              codeSourceId: query.code.id,
            },
          })
          .then((codeSource) => {
            // We update the query with the code source path and branch
            codeExplorerActions.mergeQuery({
              githubRepositoryId: codeSource.repositoryId,
              githubOrganizationId: codeSource.repositoryOwnerId,
              branch: codeSource.branchName,
              code: {
                path: codeSource.path,
              },
            });
          });
        // We have to early exit or this logic breaks due to running N times
        return;
      }

      // If the query is valid and the repository id changes, we re-fetch the file tree
      if (isValidQuery(query)) {
        // If the repository id changes, we re-fetch the file tree
        if (
          previousQuery?.githubRepositoryId !== query.githubRepositoryId ||
          (_.isNil(ghFileTreeState.result) &&
            ghFileTreeState.status !== 'loading')
        ) {
          void ghFileTreeActions.execute({
            params: {
              repositoryId: query.githubRepositoryId,
            },
            querystring: {
              branch: query.branch,
            },
          });
        }

        // If the code path or repo changes, we re-fetch the file content
        if (
          isCodeSourcePathQuery(query.code) &&
          (query.code.path !== _.get(previousQuery?.code, 'path', null) ||
            previousQuery?.githubRepositoryId !== query.githubRepositoryId)
        ) {
          void ghFileContentActions.execute({
            params: {
              repositoryId: query.githubRepositoryId,
              filePath: query.code.path,
            },
            querystring: {
              branch: query.branch,
            },
          });
        }
      }
    }, [
      codeExplorerState.query,
      previousQuery,
      ghFileTreeState,
      ghFileTreeActions.execute,
      codeSourceActions.execute,
      ghFileContentActions.execute,
      codeExplorerActions.mergeQuery,
    ]);

    return (
      <Box
        component='span'
        className={cn([
          codeExplorerClasses.root,
          codeExplorerClasses[`placement-${codeExplorerState.placement}`],
        ])}
        sx={{
          [`&.${codeExplorerClasses.root}`]: {
            position: 'relative',
            [`&.${codeExplorerClasses['placement-right']} .${codeExplorerClasses.fab}`]:
              {
                position: 'fixed',
                top: '50%',
                right: 0,
                transform: 'translateY(-50%)',
                zIndex: theme.zIndex.drawer - 1,
              },
            [`&.${codeExplorerClasses['placement-bottom']} .${codeExplorerClasses.fab}`]:
              {
                position: 'absolute',
                right: '50%',
                bottom: 0,
                transform: 'translateX(-50%)',
                zIndex: theme.zIndex.drawer - 1,
              },
          },
        }}
      >
        <LinearProgress
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            visibility:
              ghFileContentState.status === 'loading' ? 'visible' : 'hidden',
          }}
        />
        {!readonly && (
          <CodeExplorerButton
            placement={codeExplorerState.placement}
            className={codeExplorerClasses.fab}
            onClick={codeExplorerActions.open}
          />
        )}
        <CodeExplorerDrawer
          placement={codeExplorerState.placement}
          open={codeExplorerState.open}
          className={codeExplorerClasses.drawer}
          onClose={codeExplorerActions.close}
        >
          <CodeExplorerLayout
            header={
              <CodeExplorerHeader
                query={codeExplorerState.query}
                onChangeQuery={onChangeQuery}
                onClose={codeExplorerActions.close}
                readonly={readonly}
              />
            }
            content={
              <React.Fragment>
                <LoadingOverlay
                  loading={
                    ghFileContentState.status === 'loading' ||
                    codeSourceCreateState.status === 'loading' ||
                    docCodeUpsertState.status === 'loading'
                  }
                  variant='contained'
                />
                {ghFileContentState.result && (
                  <CodeExplorerContentFile
                    fileContent={ghFileContentState.result}
                    onCodeLinkAction={onCodeLinkAction}
                    onCodeSnippetAction={onCodeSnippetAction}
                    readonly={readonly}
                    hasCodeLink={contentFileHasCodeLink}
                    canInsertCodeSnippet={canInsertCodeSnippet}
                  />
                )}
                {!ghFileContentState.result && (
                  <CodeExplorerContentPlaceholder
                    query={codeExplorerState.query}
                    fileTreeOpen={codeExplorerState.fileTreeOpen}
                    onOpenFileTree={codeExplorerActions.openFileTree}
                  />
                )}
              </React.Fragment>
            }
            sidebar={
              <CodeExplorerFileTree
                disabled={
                  !isValidQuery(codeExplorerState.query) || readonly === true
                }
                loading={ghFileTreeState.status === 'loading'}
                fileTree={ghFileTreeState.result}
                onFileSelect={onFileSelect}
                selected={ghFileContentState.result?.path}
                open={codeExplorerState.fileTreeOpen && readonly !== true}
                onOpen={codeExplorerActions.openFileTree}
                onClose={codeExplorerActions.closeFileTree}
              />
            }
          />
        </CodeExplorerDrawer>
      </Box>
    );
  }
);

/*
|------------------
| Utils
|------------------
*/

type ValidCodeExplorerQuery = Omit<
  tf.SetNonNullable<CodeExplorerQuery, keyof CodeExplorerQuery>,
  'code'
>;

/**
 * Check if a partial CodeExplorerQuery is valid.
 *
 * @param query A partial CodeExplorerQuery.
 * @returns A boolean indicating whether the query is valid.
 */
const isValidQuery = (
  query?: Partial<CodeExplorerQuery>
): query is ValidCodeExplorerQuery => {
  return _.every([
    !!query?.branch,
    !!query?.githubRepositoryId,
    !!query?.githubOrganizationId,
  ]);
};

/**
 * Check if an object is a CodeExplorerCodeSourcePathQuery.
 *
 * @param code The object to check.
 * @returns A boolean indicating whether the object is a CodeExplorerCodeSourcePathQuery.
 */
const isCodeSourcePathQuery = (
  code?: CodeExplorerCodeSourcePathQuery | CodeExplorerCodeSourceIdQuery | null
): code is CodeExplorerCodeSourcePathQuery => {
  return _.has(code, 'path');
};

/**
 * Check if an object is a CodeExplorerCodeSourceIdQuery.
 *
 * @param code The object to check.
 * @returns A boolean indicating whether the object is a CodeExplorerCodeSourceIdQuery.
 */
const isCodeSourceIdQuery = (
  code?: CodeExplorerCodeSourcePathQuery | CodeExplorerCodeSourceIdQuery | null
): code is CodeExplorerCodeSourceIdQuery => {
  return _.has(code, 'id');
};
