import React from 'react';
import type * as TF from 'type-fest';

import { IconAvatar, type IconAvatarProps } from '@/components/Avatars';
import { createComponentClasses } from '@/theme';

export const gitHubBrandAvatarClasses = createComponentClasses(
  'GitHubBrandAvatar',
  ['root', 'icon'] as const
);

export type GitHubBrandAvatarClasses = typeof gitHubBrandAvatarClasses;

export interface GitHubBrandAvatarProps
  extends Omit<IconAvatarProps, 'icon' | 'classes'> {
  gap?: number;
  classes?: TF.LiteralToPrimitiveDeep<Partial<GitHubBrandAvatarClasses>>;
}

/**
 * An avatar with the GitHub brand icon.
 */
export const GitHubBrandAvatar = React.forwardRef<
  HTMLDivElement,
  GitHubBrandAvatarProps
>((props, ref) => {
  return (
    <IconAvatar
      {...props}
      ref={ref}
      backgroundColor='#000000'
      icon='brand-github'
      classes={props.classes ?? gitHubBrandAvatarClasses}
    />
  );
});
GitHubBrandAvatar.muiName = 'IconAvatar';
