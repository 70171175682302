import { Box, Stack, useTheme } from '@mui/material';
import React from 'react';

import { createComponentClasses } from '@stargate/theme';

export const codeExplorerLayoutClasses = createComponentClasses(
  'CodeExplorerLayout',
  ['root', 'header', 'sidebar', 'content']
);

export interface CodeExplorerLayoutProps {
  content: React.ReactNode;
  header: React.ReactNode;
  sidebar: React.ReactNode;
}

export const CodeExplorerLayout: React.FC<CodeExplorerLayoutProps> = ({
  content,
  header,
  sidebar,
}) => {
  const theme = useTheme();

  return (
    <Box
      className={codeExplorerLayoutClasses.root}
      sx={{
        [`&.${codeExplorerLayoutClasses.root}`]: {
          height: '100%',
          pt: 0.5,
          [`& .${codeExplorerLayoutClasses.header}`]: {
            position: 'relative',
            px: 2,
            py: 1.25,
            width: '100%',
            borderBottom: '1px solid',
            borderColor: theme.palette.divider,
          },
          [`& .${codeExplorerLayoutClasses.sidebar}`]: {
            height: '100%',
            borderRight: '1px solid',
            borderColor: theme.palette.divider,
          },
          [`& .${codeExplorerLayoutClasses.content}`]: {
            p: 1,
            position: 'relative',
            width: '100%',
            overflow: 'hidden',
          },
        },
      }}
    >
      <Box className={codeExplorerLayoutClasses.header}>{header}</Box>
      <Stack
        height='100%'
        direction='row'
        justifyContent='flex-start'
        alignItems='stretch'
        sx={{ position: 'relative' }}
      >
        <Box className={codeExplorerLayoutClasses.sidebar}>{sidebar}</Box>
        <Box className={codeExplorerLayoutClasses.content}>{content}</Box>
      </Stack>
    </Box>
  );
};
