import { RikerIcon } from '@joggrdocs/riker-icons';
import { Card, CardContent, CardHeader, Typography } from '@mui/material';
import * as hookz from '@react-hookz/web';
import React from 'react';

import apiClient from '@stargate/api';
import { IconAvatar } from '@stargate/components/Avatars';
import { ButtonLink } from '@stargate/components/Buttons';
import { Page } from '@stargate/components/Utils';
import { DocumentImportsTable } from '@stargate/features/imports';

export const DocumentsImportListPage: React.FC = (props) => {
  const [existingImportsState, existingImportsActions] =
    apiClient.useRequestClient('GET /document-imports');

  hookz.useMountEffect(() => {
    void existingImportsActions.execute();
  });

  return (
    <Page
      id='document-imports'
      title='Import Documents'
      meta={{
        description:
          'Import documents from your GitHub repositories into your Joggr account.',
      }}
      render={() => (
        <Card>
          <CardHeader
            title={<Typography variant='h5'>Existing Imports</Typography>}
            subheader={
              <Typography variant='subtitle2' fontSize='1rem'>
                View existing incomplete document imports and merge the related
                pull requests in GitHub to complete the import process.
              </Typography>
            }
            avatar={<IconAvatar icon='list' />}
            action={
              <ButtonLink
                to='app.document-imports.create'
                startIcon={<RikerIcon icon='file-import' />}
                color='primary'
                size='medium'
                variant='outlined'
                sx={{
                  ml: 2,
                }}
              >
                Create Import
              </ButtonLink>
            }
          />
          <CardContent>
            <DocumentImportsTable
              rows={existingImportsState.result ?? []}
              loading={existingImportsState.status === 'loading'}
            />
          </CardContent>
        </Card>
      )}
    />
  );
};
