import { createTheme } from '@joggrdocs/riker-theme';
import { match } from 'ts-pattern';

import type { ThemeMode } from '../types';

export function createShadows(mode: ThemeMode) {
  return match(mode)
    .with('dark', () => darkShadows)
    .with('light', () => lightShadows)
    .exhaustive();
}

const darkShadows = createTheme('dark').shadows;
const lightShadows = createTheme('light').shadows;
