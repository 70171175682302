import { Box, Fade, Slide, Stack, Typography } from '@mui/material';
import { produce } from 'immer';
import React from 'react';
import { create } from 'zustand';

import { ErrorGuard } from '@stargate/components/Guards';
import {
  LoadingConsole,
  LoadingLogo,
  LoadingOverlay,
} from '@stargate/components/Loading';
import { Confetti } from '@stargate/components/Utils';
import { useDelayedState } from '@stargate/hooks';
import { useLogger } from '@stargate/logger';
import { Outlet, useNavigate } from '@stargate/routes';
import { useUIState } from '@stargate/stores';
import { useSentry } from '@stargate/vendors/sentry';
import { useInterval } from 'usehooks-ts';

/*
|==========================================================================
| App
|==========================================================================
| 
| Top Level Application Component.
|
*/

export const App: React.FC = () => {
  const navigate = useNavigate();
  const sentry = useSentry();
  const logger = useLogger();
  const app = useApp();
  const showConfetti = useUIState((state) => state.showConfetti);
  const [loading, setLoading, setLoadingDelayed] = useDelayedState(app.loading);
  const [secondsLoading, setSecondsLoading] = React.useState(0);

  // Count the seconds the app has been loading
  useInterval(
    () => {
      setSecondsLoading((seconds) => seconds + 0.25);
    },
    secondsLoading > 30 ? null : 250
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: We only care about the loading state
  React.useEffect(() => {
    if (app.loading) {
      setLoading(app.loading);
      setSecondsLoading(0);
    }

    if (!app.loading && loading) {
      // prevent snappy loading state changes
      setLoadingDelayed(false, 150);
      // reset the seconds loading
      setSecondsLoading(0);
    }
  }, [app.loading]);

  const loadingComponent = React.useMemo(() => {
    const enterConsole = 3.5;
    const enterWarn = 2.5;
    return (
      <Stack
        direction='column'
        alignItems='center'
        justifyContent='center'
        spacing={2}
      >
        <Slide direction='up' in={secondsLoading >= enterConsole}>
          <Box
            component='span'
            sx={{
              marginBottom: '300px',
            }}
          >
            <LoadingConsole
              loading={secondsLoading >= enterConsole + 0.1}
              delay={500}
            />
          </Box>
        </Slide>
        <Fade in={secondsLoading < enterConsole} exit>
          <span>
            <LoadingLogo loading />
          </span>
        </Fade>
        <Fade
          in={secondsLoading >= enterWarn && secondsLoading < enterConsole}
          exit
        >
          <Typography variant='body1' fontSize='24px' fontWeight='bold'>
            Might take a-while, opening the "Loader" console!
          </Typography>
        </Fade>
      </Stack>
    );
  }, [secondsLoading]);

  return (
    <ErrorGuard>
      <LoadingOverlay
        variant='full'
        loading={loading}
        timeout={30000}
        loadingComponent={loadingComponent}
        onTimeout={() => {
          const err = new Error('App loader timed out');
          sentry.captureException(err);
          logger.error(err.message);
          navigate('root.error');
        }}
      />
      {showConfetti && <Confetti recycle />}
      <Outlet />
    </ErrorGuard>
  );
};

export interface UseAppStateHook extends AppStateStore {}

/**
 * Hook to access the global application state and global application actions.
 */
export const useApp = (): UseAppStateHook => {
  return useAppStateStore((state) => state);
};

/*
|------------------
| Utils
|------------------
*/

interface AppStateStore {
  /**
   * Global loading state for the application.
   */
  loading: boolean;

  /**
   * Set the global loading state for the application.
   *
   * @param loading A boolean value to set the loading state.
   */
  setLoading: (loading: boolean) => void;

  /**
   * Global error state for the application.
   */
  error: Error | null;

  /**
   * Set the global error state for the application.
   *
   * @param error An error object to set.
   */
  setError: (error: Error | null) => void;
}

const useAppStateStore = create<AppStateStore>((set) => ({
  error: null,
  loading: true,
  setError: (error) => {
    set(
      produce((draft) => {
        draft.error = error;
      })
    );
  },
  setLoading: (loading) => {
    set(
      produce((draft) => {
        draft.loading = loading;
      })
    );
  },
}));
