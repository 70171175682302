import { Box, type SxProps } from '@mui/material';
import * as hookz from '@react-hookz/web';
import _ from 'lodash';
import React from 'react';
import { Helmet } from 'react-helmet-async';

import { useApp } from '@stargate/app';
import { HttpErrorFeedback } from '@stargate/components/Feedback';
import * as http from '@stargate/lib/http';
import type * as typist from '@stargate/lib/typist';

import Guardian from '../Guards/components/Guardian';

/*
|==========================================================================
| Page Component
|==========================================================================
|
| Core component for a page. This component is responsible for setting the page title, seo data
| and loading state for navigation.
|
*/

export type PageError = http.HttpError | Error;

export interface PageProps<P> {
  /**
   * The unique identifier for the page.
   */
  id: string;

  /**
   * The title of the page.
   *
   * @default _.startCase(id)
   */
  title?: string;

  /**
   * SEO meta data.
   */
  meta?: {
    description?: string;
  };

  /**
   * Whether to show the page gutter.
   */
  gutter?: boolean;

  /**
   * Whether to show a no results message.
   */
  showNoResults?: boolean;

  /**
   * Whether to show a loading spinner.
   */
  loading?: boolean | boolean[];

  /**
   * Errors to display on the page.
   */
  errors?: Array<PageError | typist.Nil>;

  /**
   * The styles for the page.
   */
  sx?: SxProps;

  /**
   * Render props for the page
   */
  renderProps?: P;

  /**
   * The render function for the, which will not fire until `renderProps`
   * is not Nil, `errors` is empty, and `loading` is false.
   */
  render: (props: typist.RequiredStrict<P>) => React.ReactNode;
}

export function Page<P extends object>(props: PageProps<P>) {
  const app = useApp();

  /*
  |------------------
  | Computed
  |------------------
  */

  const errors = React.useMemo(
    () => _.compact(props.errors ?? []),
    [props.errors]
  );

  const loading = React.useMemo(
    () => (_.isBoolean(props.loading) ? props.loading : _.some(props.loading)),
    [props.loading]
  );

  /*
  |------------------
  | Effects
  |------------------
  */

  hookz.useMountEffect(() => {
    app.setLoading(false);
  });

  return (
    <Box
      className={`page-${props.id} page`}
      sx={{
        p: props.gutter === false ? 0 : 2,
      }}
    >
      <Helmet>
        <title>{getTitle<P>(props)}</title>
        {props.meta?.description && (
          <meta name='description' content={props.meta.description} />
        )}
      </Helmet>
      <ErrorPage errors={errors} />
      <Guardian
        loading={errors.length === 0 && !!loading}
        minimumLoadTime={400}
        // Force render by passing in a valid prop
        // @ts-expect-error
        renderProps={
          errors.length > 0
            ? { __noRender: null }
            : (props.renderProps ?? { __render: true })
        }
        render={props.render}
      />
    </Box>
  );
}

/*
|------------------
| Utils
|------------------
*/

const ErrorPage: React.FC<{ errors: PageError[] }> = (props) => {
  if (props.errors.length <= 0) {
    return null;
  }
  const httpError = _.find(
    props.errors,
    (error) => error instanceof http.HttpError
  );
  return <HttpErrorFeedback error={httpError ?? props.errors[0]} />;
};

function getTitle<P extends object>(props: PageProps<P>) {
  if (props.title) return props.title;

  return _.startCase(props.id);
}
