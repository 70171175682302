import Details from '@tiptap-pro/extension-details';
import DetailsContent from '@tiptap-pro/extension-details-content';
import DetailsSummary from '@tiptap-pro/extension-details-summary';
import { Extension } from '@tiptap/core';

export default Extension.create({
  name: 'dashdraft-details',
  addExtensions() {
    return [
      Details.configure({
        persist: false,
        HTMLAttributes: {
          class: 'dashdraft-details',
        },
      }),
      DetailsSummary.configure({
        HTMLAttributes: {
          class: 'dashdraft-details-summary',
        },
      }),
      DetailsContent.configure({
        HTMLAttributes: {
          class: 'dashdraft-details-content',
        },
      }),
    ];
  },
});
