import { useMutation, useQueryClient } from '@tanstack/react-query';

import { type APIBody, apiClient } from '@stargate/api';
import {
  type DashDraftJSONContent,
  convertJSONToMarkdown,
} from '@stargate/dashdraft';
import { encodeBase64 } from '@stargate/utils/files';

import {
  jdocCodeSourcesQueryOptions,
  jdocContentQueryOptions,
  jdocQueryOptions,
  jdocTagsQueryOptions,
} from './query';

/*
|==========================================================================
| mutations
|==========================================================================
|
| Mutations for JoggrDocs, including create, update, and delete & related data.
|
*/

/*
|------------------
| JoggrDoc Create: POST /documents
|------------------
*/

export interface JDocCreateMutationPayload {
  /**
   * The data to create the JoggrDoc.
   */
  data: JDocMutationBody<APIBody<'POST /documents'>>;
}

/**
 * Create a JoggrDoc Mutation Function.
 *
 * @api `POST /documents`
 * @param payload The payload for the mutation.
 * @returns The result of the mutation.
 */
export const jdocCreateMutationFn = async (
  payload: JDocCreateMutationPayload
) => {
  return apiClient.request('POST /documents', {
    body: {
      title: payload.data.title,
      summary: payload.data.summary,
      content: await transformToAPIContent(payload.data.content),
      filePath: payload.data.filePath,
      repositoryId: payload.data.repositoryId,
      repositoryOwnerId: payload.data.repositoryOwnerId,
      branchName: payload.data.branchName,
      baseDocumentId: payload.data.baseDocumentId,
      sha: payload.data.sha,
      message: payload.data.message,
      draftPullRequest: payload.data.draftPullRequest,
      createPullRequest: payload.data.createPullRequest,
    },
  });
};

/**
 * Create a JoggrDoc Mutation shareable React Hook.
 *
 * @api `POST /documents`
 * @param options A mutation options object.
 * @returns A mutation result object.
 */
export const useJDocCreateMutation = (options?: MutationOptions) => {
  return useMutation({
    ...options,
    mutationFn: jdocCreateMutationFn,
  });
};

/*
|------------------
| JoggrDoc Update: PUT /documents/:documentId
|------------------
*/

export interface JDocUpdateMutationPayload {
  /**
   * The ID of the JoggrDoc to update.
   */
  documentId: string;

  /**
   * The data to update the JoggrDoc with.
   */
  data: JDocMutationBody<APIBody<'PUT /documents/:documentId'>>;
}

/**
 * Update a JoggrDoc Mutation Function.
 *
 * @api `PUT /documents/:documentId`
 * @param payload The payload for the mutation.
 * @returns The result of the mutation.
 */
export const jdocUpdateMutationFn = async (
  payload: JDocUpdateMutationPayload
) => {
  return apiClient.request('PUT /documents/:documentId', {
    params: { documentId: payload.documentId },
    body: {
      title: payload.data.title,
      summary: payload.data.summary,
      content: await transformToAPIContent(payload.data.content),
      message: payload.data.message,
      sha: payload.data.sha,
      filePath: payload.data.filePath,
    },
  });
};

/**
 * Update a JoggrDoc Mutation shareable React Hook.
 *
 * ⚠️ _WARNING_: This hook will invalidate the JoggrDoc query cache on success.
 *
 * @api `PUT /documents/:documentId`
 * @param options A mutation options object.
 * @returns A mutation result object.
 */
export const useJDocUpdateMutation = (options?: MutationOptions) => {
  const queryClient = useQueryClient();
  return useMutation({
    ...options,
    onSuccess: (data, payload, context) => {
      queryClient.invalidateQueries({
        queryKey: jdocQueryOptions(payload.documentId).queryKey,
      });
      queryClient.invalidateQueries({
        queryKey: jdocContentQueryOptions(payload.documentId).queryKey,
      });
      options?.onSuccess?.(data, payload, context);
    },
    mutationFn: jdocUpdateMutationFn,
  });
};

/*
|------------------
| JoggrDoc Delete: DELETE /documents/:documentId
|------------------
*/

export interface JDocDeleteMutationPayload {
  /**
   * The ID of the JoggrDoc to delete.
   */
  documentId: string;
}

/**
 * Delete a JoggrDoc Mutation Function.
 *
 * @api `DELETE /documents/:documentId`
 * @param payload The payload for the mutation.
 * @returns The result of the mutation.
 */
export const jdocDeleteMutationFn = async (
  payload: JDocDeleteMutationPayload
) => {
  return apiClient.request('DELETE /documents/:documentId', {
    params: { documentId: payload.documentId },
  });
};

/**
 * Delete a JoggrDoc Mutation.
 *
 * ⚠️ _WARNING_: This hook will invalidate the JoggrDoc query cache on success.
 *
 * @param options A mutation options object.
 * @returns A mutation result object.
 */
export const useJDocDeleteMutation = (options?: MutationOptions) => {
  const queryClient = useQueryClient();
  return useMutation({
    ...options,
    onSuccess: (data, payload, context) => {
      queryClient.invalidateQueries({
        queryKey: jdocQueryOptions(payload.documentId).queryKey,
      });
      queryClient.invalidateQueries({
        queryKey: jdocContentQueryOptions(payload.documentId).queryKey,
      });
      options?.onSuccess?.(data, payload, context);
    },
    mutationFn: jdocDeleteMutationFn,
  });
};

/*
|------------------
| JoggrDoc Update Code Sources: PUT /documents/:documentId/code-sources
|------------------
*/

export interface JDocUpdateCodeSourceMutationPayload {
  /**
   * The ID of the JoggrDoc to update.
   */
  documentId: string;

  /**
   * The code source to update the JoggrDoc with.
   */
  codeSourceId: string;
}

/**
 * Update a single JoggrDoc CodeSource Mutation Function.
 *
 * @api `PUT /documents/:documentId/code-sources/:codeSourceId`
 * @param payload The payload for the mutation.
 * @returns The result of the mutation.
 */
export const jdocUpdateCodeSourceMutation = async (
  payload: JDocUpdateCodeSourceMutationPayload
) => {
  return apiClient.request(
    'PUT /documents/:documentId/code-sources/:codeSourceId',
    {
      params: {
        documentId: payload.documentId,
        codeSourceId: payload.codeSourceId,
      },
    }
  );
};

/**
 * Update a single JoggrDoc CodeSource Mutation shareable React Hook.
 *
 * @param options A mutation options object.
 * @returns A mutation result object.
 */
export const useJDocUpdateCodeSourceMutation = (options?: MutationOptions) => {
  const queryClient = useQueryClient();
  return useMutation({
    ...options,
    onSuccess: (data, payload, context) => {
      queryClient.invalidateQueries({
        queryKey: jdocCodeSourcesQueryOptions(payload.documentId).queryKey,
      });
      options?.onSuccess?.(data, payload, context);
    },
    mutationFn: jdocUpdateCodeSourceMutation,
  });
};

export interface JDocUpdateCodeSourcesMutationPayload {
  /**
   * The ID of the JoggrDoc to update.
   */
  documentId: string;

  /**
   * The code sources to update the JoggrDoc with.
   */
  codeSourceIds: APIBody<'PUT /documents/:documentId/code-sources'>['codeSourceIds'];
}

/**
 * Update a JoggrDoc's CodeSources Mutation Function.
 *
 * @api `PUT /documents/:documentId/code-sources`
 * @param payload The payload for the mutation.
 * @returns The result of the mutation.
 */
export const jdocUpdateCodeSourcesMutationFn = async (
  payload: JDocUpdateCodeSourcesMutationPayload
) => {
  return apiClient.request('PUT /documents/:documentId/code-sources', {
    params: { documentId: payload.documentId },
    body: { codeSourceIds: payload.codeSourceIds },
  });
};

/**
 * Update a JoggrDoc's CodeSources Mutation shareable React Hook.
 *
 * @param options A mutation options object.
 * @returns A mutation result object.
 */
export const useJDocUpdateCodeSourcesMutation = (options?: MutationOptions) => {
  const queryClient = useQueryClient();
  return useMutation({
    ...options,
    onSuccess: (data, payload, context) => {
      queryClient.invalidateQueries({
        queryKey: jdocCodeSourcesQueryOptions(payload.documentId).queryKey,
      });
      options?.onSuccess?.(data, payload, context);
    },
    mutationFn: jdocUpdateCodeSourcesMutationFn,
  });
};

/*
|------------------
| JoggrDoc Update Tags: PUT /documents/:documentId/tags
|------------------
*/

export interface JDocUpdateTagsMutationPayload {
  /**
   * The ID of the JoggrDoc to update.
   */
  documentId: string;

  /**
   * The tags to update the JoggrDoc with.
   */
  tags: APIBody<'PUT /documents/:documentId/tags'>;
}

/**
 * Update a JoggrDoc's tags Mutation Function.
 *
 * @api `PUT /documents/:documentId/tags`
 * @param payload The payload for the mutation.
 * @returns The result of the mutation.
 */
export const JDocUpdateTagsMutationFn = async (
  payload: JDocUpdateTagsMutationPayload
) => {
  return apiClient.request('PUT /documents/:documentId/tags', {
    params: { documentId: payload.documentId },
    body: payload.tags,
  });
};

/**
 * Update a JoggrDoc's tags Mutation.
 *
 * ⚠️ _WARNING_: This hook will invalidate the JoggrDoc query cache on success.
 *
 * @api `PUT /documents/:documentId/tags`
 * @param options A mutation options object.
 * @returns A mutation result object.
 */
export const useJDocUpdateTagsMutation = (options?: MutationOptions) => {
  const queryClient = useQueryClient();
  return useMutation({
    ...options,
    onSuccess: (data, payload, context) => {
      queryClient.invalidateQueries({
        queryKey: jdocTagsQueryOptions(payload.documentId).queryKey,
      });
      options?.onSuccess?.(data, payload, context);
    },
    mutationFn: JDocUpdateTagsMutationFn,
  });
};

/*
|------------------
| Utils
|------------------
*/

type JDocMutationBody<Body extends object> = Omit<Body, 'content'> & {
  /**
   * The content of the JoggrDoc as a DashDraftJSONContent object.
   */
  content: DashDraftJSONContent;
};

// @todo move to reusable types
type MutationOptions = Omit<Parameters<typeof useMutation>[0], 'mutationFn'>;

/**
 * Transform a DashDraftJSONContent object into an API friendly content string.
 *
 * @param content The content to transform.
 * @returns The transformed content
 */
const transformToAPIContent = async (content: DashDraftJSONContent) => {
  return encodeBase64(await convertJSONToMarkdown(content));
};
