import type { RikerIconName } from '@joggrdocs/riker-icons';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  type DialogProps,
} from '@mui/material';
import React from 'react';

import { IconAvatar } from '@/components/Avatars';
import { DialogTitle, type DialogTitleProps } from '@/components/dialog';
import { useLocalization } from '@/localization';

export interface DialogConfirmationProps extends Omit<DialogProps, 'title'> {
  /**
   * The title to display in the dialog
   */
  title?: React.ReactNode;

  /**
   * Typography props for the title.
   */
  titleTypographyProps?: DialogTitleProps['titleTypographyProps'];

  /**
   * The subtitle to display in the dialog
   */
  subTitle?: React.ReactNode;

  /**
   * Typography props for the subtitle.
   */
  subTitleTypographyProps?: DialogTitleProps['subTitleTypographyProps'];

  /**
   * The confirmation message to display in the dialog
   *
   * @default 'alert-triangle'
   */
  confirmation: React.ReactNode;

  /**
   * Icon to display in the dialog.
   */
  icon?: RikerIconName;

  /**
   * Callback triggered when the dialog is closed.
   */
  onClose: () => void;

  /**
   * Callback triggered when the dialog is confirmed.
   */
  onConfirm: () => void;
}

export const DialogConfirmation: React.FC<DialogConfirmationProps> = ({
  icon = 'alert-triangle',
  title,
  titleTypographyProps,
  subTitle,
  subTitleTypographyProps,
  ...props
}) => {
  const localz = useLocalization();
  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle
        onClose={props.onClose}
        avatar={<IconAvatar icon={icon} />}
        title={
          title ?? localz.formatMessage('components.dialogs.confirm.title')
        }
        titleTypographyProps={titleTypographyProps}
        subTitle={
          subTitle ??
          localz.formatMessage('components.dialogs.confirm.subTitle')
        }
        subTitleTypographyProps={subTitleTypographyProps}
      />
      <DialogContent>{props.confirmation}</DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={props.onConfirm} color='error'>
          {localz.formatMessage('generic.confirm')}
        </Button>
        <Button variant='outlined' onClick={props.onClose}>
          {localz.formatMessage('generic.cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
