import { Box, Paper, Popover, Stack, darken, useTheme } from '@mui/material';
import React from 'react';
import TablerIconSearch from '~icons/tabler/search';

import { useSearch } from '../hooks/use-search';
import { SearchBar } from './SearchBar';

/*
|==========================================================================
| Search
|==========================================================================
|
| Main search component, that is used in the TopBar, but accessible from everywhere in the app.
|
*/

export const Search: React.FC = () => {
  const theme = useTheme();
  const [searchBarState, searchBarActions] = useSearch();
  const anchorEl = React.useRef<HTMLDivElement>(null);
  return (
    <React.Fragment>
      <Stack
        ref={anchorEl}
        direction='row'
        alignItems='center'
        justifyContent='center'
        spacing={1}
        onClick={searchBarActions.openDialog}
        sx={{
          cursor: 'pointer',
          borderRadius: '8px',
          border: `1px solid ${theme.palette.divider}`,
          width: '25%',
          minHeight: '36px',
          minWidth: '280px',
          maxWidth: '480px',
          bgcolor: theme.palette.background.paper,
          '&:hover, &:active': {
            bgcolor: darken(theme.palette.background.paper, 0.05),
          },
        }}
      >
        <TablerIconSearch />
        <Box>Search your documents</Box>
      </Stack>
      <Popover
        anchorEl={anchorEl.current}
        open={searchBarState.dialogOpen}
        onClose={searchBarActions.closeDialog}
        sx={{
          mt: '-8px', // hack to force the popover to over top of the SearchBar
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Paper
          sx={{
            pt: 1,
            pb: 2,
          }}
        >
          <SearchBar />
        </Paper>
      </Popover>
    </React.Fragment>
  );
};
