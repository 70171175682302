import { Box, Stack, type SxProps, Typography, useTheme } from '@mui/material';
import React from 'react';

import type { RikerIconName } from '@joggrdocs/riker-icons';
import { IconAvatar } from '@stargate/components/Avatars';
import { useLocalization } from '@stargate/localization';
import { createComponentClasses } from '@stargate/theme';
import { cn } from '@stargate/utils/styles';

export const imgPlaceholderClasses = createComponentClasses('ImgPlaceholder', [
  'root',
  'severity-info',
  'severity-error',
  'responsive',
] as const);

export interface ImgPlaceholderProps extends BaseProps {
  /**
   * The width of the placeholder, used to calculate the proportions of the placeholder.
   */
  width: number;

  /**
   * The height of the placeholder, used to calculate the proportions of the placeholder.
   */
  height: number;

  /**
   * The severity of the placeholder.
   *
   * @default 'info'
   */
  severity?: 'info' | 'error';

  /**
   * The shape of the placeholder.
   */
  shape?: 'circle' | 'square' | 'rounded';

  /**
   * Should the placeholder be responsive?
   *
   * @default true
   */
  responsive?: boolean;

  /**
   * The sx prop for styling the image.
   */
  sx?: SxProps;
}

/**
 * A HTML&CSS only placeholder for images.
 */
export const ImgPlaceholder: React.FC<ImgPlaceholderProps> = ({
  severity = 'info',
  height,
  width,
  shape = 'rounded',
  responsive = true,
  sx,
}) => {
  const theme = useTheme();
  const localz = useLocalization();

  const icon = React.useMemo<RikerIconName>(() => {
    switch (severity) {
      case 'error':
        return 'photo-exclamation';
      case 'info':
        return 'photo';
      default:
        return 'photo';
    }
  }, [severity]);

  const borderRadius = React.useMemo(() => {
    switch (shape) {
      case 'circle':
        return '50%';
      case 'square':
        return '0';
      case 'rounded':
        return '8px';
      default:
        return '8px';
    }
  }, [shape]);

  const color = React.useMemo(() => {
    switch (severity) {
      case 'error':
        return theme.palette.error.main;
      case 'info':
        return theme.palette.divider;
      default:
        return theme.palette.divider;
    }
  }, [severity, theme.palette.divider, theme.palette.error.main]);

  return (
    <Box
      className={cn(
        imgPlaceholderClasses.root,
        imgPlaceholderClasses[`severity-${severity}`],
        {
          [imgPlaceholderClasses.responsive]: responsive,
        }
      )}
      sx={{
        [`&.${imgPlaceholderClasses.root}`]: {
          /**
           * Simulates an image
           * @see https://stackoverflow.com/questions/2402761/is-img-element-block-level-or-inline-level
           */
          display: 'inline-block',
          position: 'static',
          boxSizing: 'border-box',
          float: 'none',
          zIndex: 'auto',
          backgroundColor:
            theme.palette.mode === 'dark'
              ? theme.palette.grey[800]
              : theme.palette.grey[300],
          borderRadius,
          border: '1px solid',
          width,
          height,
          p: 4,
          [`&.${imgPlaceholderClasses.responsive}`]: {
            width: '100%',
          },
          [`&.${imgPlaceholderClasses['severity-info']}`]: {
            borderColor: theme.palette.divider,
          },
          [`&.${imgPlaceholderClasses['severity-error']}`]: {
            borderColor: theme.palette.error.main,
          },
          ...sx,
        },
      }}
    >
      <Stack
        direction='column'
        spacing={2}
        alignContent='center'
        alignItems='center'
        height='100%'
        sx={{
          pt: `${height * 0.25}px`,
        }}
      >
        <IconAvatar
          iconColor={theme.palette.text.primary}
          backgroundColor={color}
          icon={icon}
        />
        <Typography variant='h5'>
          {localz.formatMessage(
            `components.images.placeholder.${severity}.title`
          )}
        </Typography>
        <Typography variant='body1'>
          {localz.formatMessage(
            `components.images.placeholder.${severity}.description`
          )}
        </Typography>
      </Stack>
    </Box>
  );
};

/*
|------------------
| Utils
|------------------
*/

type BaseProps = Omit<
  React.ImgHTMLAttributes<HTMLImageElement>,
  'style' | 'src' | 'alt'
>;
