import { RikerIcon } from '@joggrdocs/riker-icons';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import * as hookz from '@react-hookz/web';
import _ from 'lodash';
import React from 'react';

import { IconAvatar } from '@stargate/components/Avatars';
import { Page } from '@stargate/components/Utils';
import { DirectoryActionDialog } from '@stargate/features/directories';
import { useShortcuts } from '@stargate/features/shortcuts';
import { useUser } from '@stargate/features/user';
import { useSideBar } from '@stargate/layouts';
import { useLocalization } from '@stargate/localization';

import CallToActionCard from '../components/CallToActionCard';
import FAQAccordion from '../components/FAQAccordion';
import { RecentlyViewedDocuments } from '../components/RecentlyViewedDocuments';

export const HomePage: React.FC = () => {
  const authenticatedUser = useUser();
  const [, shortcutActions] = useShortcuts();
  const [, sidebarActions] = useSideBar();
  const localz = useLocalization();
  const [measure, ref] = hookz.useMeasure<HTMLDivElement>();
  const theme = useTheme();
  const [showDialog, setShowDialog] = React.useState(false);

  /*
  |------------------
  | Computed
  |------------------
  */

  const welcomeMessage = React.useMemo(() => {
    if (!authenticatedUser.data) {
      return 'Welcome back!';
    }
    const name =
      authenticatedUser.data.nickname ??
      authenticatedUser.data.name ??
      authenticatedUser.data.email;
    return `Welcome back ${name}!`;
  }, [authenticatedUser.data]);

  const actionCardsData = React.useMemo(() => {
    return actionCards
      .filter((item) => item && typeof item.order === 'number')
      .sort((a, b) => a.order - b.order);
  }, []);

  const faqData = React.useMemo(() => {
    return faqs
      .filter((item) => item && typeof item.order === 'number')
      .sort((a, b) => a.order - b.order);
  }, []);

  return (
    <Page
      id='home'
      gutter
      render={() => (
        <Box ref={ref}>
          <DirectoryActionDialog
            open={showDialog}
            action='create'
            onClose={() => setShowDialog(false)}
          />
          {!_.isNil(measure) && (
            <React.Fragment>
              <Card
                sx={{
                  mb: 2,
                  pb: 0.5,
                }}
              >
                <CardHeader
                  avatar={<IconAvatar icon='confetti' size={64} gap={1.25} />}
                  title={<Typography variant='h1'>{welcomeMessage}</Typography>}
                  subheader={
                    <Typography
                      variant='body2'
                      color='text.secondary'
                      fontSize='18px'
                    >
                      Get started by creating a document, viewing the documents
                      you & your team already created, or connecting your
                      favorite tools.
                    </Typography>
                  }
                  disableTypography
                />
                {actionCardsData.length > 0 && (
                  <CardContent>
                    <Stack
                      spacing={1}
                      direction={measure.width < 976 ? 'column' : 'row'}
                      alignItems='center'
                      useFlexGap
                    >
                      {actionCardsData.map((item) => (
                        <CallToActionCard
                          key={item.id}
                          id={item.id}
                          flowId={item.id}
                          title={localz.formatMessage(
                            `pages.home.action-cards.${item.id}.title`
                          )}
                          description={localz.formatMessage(
                            `pages.home.action-cards.${item.id}.description`
                          )}
                          loom={item.loom}
                          action={localz.formatMessage(
                            `pages.home.action-cards.${item.id}.cta`
                          )}
                          icon={
                            <IconAvatar
                              icon={item.icon}
                              backgroundColor='secondary'
                            />
                          }
                          onClick={() => {
                            if (item.cta.action === 'create-directory') {
                              setShowDialog(true);
                              sidebarActions.openDrawer({
                                drawerContent: 'explorer',
                              });
                            } else if (item.cta.action === 'shortcut') {
                              shortcutActions.onOpen(item.cta.tab);
                            }
                          }}
                        />
                      ))}
                    </Stack>
                  </CardContent>
                )}
              </Card>
              <Stack
                direction='row'
                alignContent='center'
                alignItems='start'
                spacing={1}
              >
                {faqData.length > 0 && (
                  <Card
                    sx={{
                      width:
                        measure.width < 976
                          ? '100%'
                          : `calc(50% - ${theme.spacing(0.5)})`,
                    }}
                  >
                    <CardHeader
                      avatar={<IconAvatar icon='help-hexagon' />}
                      title={
                        <Typography variant='h4'>
                          Frequently Asked Questions
                        </Typography>
                      }
                      subTitle={
                        <Typography variant='body2'>
                          Get answers to your common questions.
                        </Typography>
                      }
                      disableTypography
                    />
                    <Stack direction='column'>
                      {faqData.map((flow) => (
                        <FAQAccordion
                          key={_.snakeCase(flow.question)}
                          id={_.snakeCase(flow.question)}
                          question={flow.question}
                          answer={flow.answer}
                        />
                      ))}
                    </Stack>
                    <Divider
                      sx={{
                        mt: 2,
                        mb: 2,
                      }}
                    />
                    <CardContent
                      sx={{
                        textAlign: 'center',
                      }}
                    >
                      <Typography
                        variant='body2'
                        color='text.secondary'
                        sx={{
                          mb: 2,
                        }}
                      >
                        {localz.formatMessage('pages.home.faqs.more')}
                      </Typography>
                      <Button
                        startIcon={<RikerIcon icon='books' />}
                        size='small'
                        component='a'
                        href='https://docs.joggr.io/faqs'
                        target='_blank'
                        rel='noreferrer'
                        sx={{
                          mx: 'auto',
                        }}
                      >
                        {localz.formatMessage('pages.home.faqs.cta')}
                      </Button>
                    </CardContent>
                  </Card>
                )}
                <RecentlyViewedDocuments
                  sx={{
                    width:
                      measure.width < 976
                        ? '100%'
                        : `calc(50% - ${theme.spacing(0.5)})`,
                    minHeight: '364px',
                  }}
                />
              </Stack>
            </React.Fragment>
          )}
        </Box>
      )}
    />
  );
};

/*
|------------------
| Utils
|------------------
*/

/**
 * The action cards that are displayed on the home page.
 */
const actionCards = [
  {
    id: 'create-doc',
    order: 1,
    title: 'Create a Doc',
    icon: 'file-plus',
    loom: 'https://www.loom.com/share/0f2809f337c1428c9ba623dc79a1e7bb',
    cta: {
      action: 'shortcut', // opens the command palette
      tab: 'blank', // 'blank'| 'templates'| 'import'| 'generate'| 'analyze'| 'pull-request'
    },
  },
  {
    id: 'create-directory',
    order: 2,
    icon: 'template',
    loom: 'https://www.loom.com/share/bc69947b2446491bb11d17efaf7b4a21',
    cta: {
      action: 'create-directory',
    },
  },
  {
    id: 'import-docs',
    order: 3,
    icon: 'file-import',
    loom: 'https://www.loom.com/share/d03d211fc2e941d392d3899931e60ddc',
    cta: {
      action: 'shortcut', // opens the command palette
      tab: 'import', // 'blank'| 'templates'| 'import'| 'generate'| 'analyze'| 'pull-request'
    },
  },
] as const;

// @todo move to localization
const faqs = [
  {
    order: 1,
    question: 'Where in GitHub is the document saved?',
    answer: `
You can save the document wherever you like in GitHub. In the top left of a document, next to the GitHub icon, you will see where the document is stored in GitHub.

Some developers prefer to save the document as close to the code it's describing (e.g. save the "How to add a new route" guide under /routes folder). Other developers prefer to create a separate repository to store all of the documentation there. You can also do a combination of both.
    `.trim(),
  },
  {
    order: 2,
    question: 'How does Joggr maintain the document?',
    answer: `
When a developer adds a code snippet to a document, Joggr stores a reference to this code snippet. If a developer opens up a pull request and changes code that is referenced in a document, Joggr sends a pull request message telling the developer that the proposed changes will cause the document to go out of date.

For example, let's say that a developer adds a code snippet like the following to a document:

\`\`\`typescript
function findUserById(id: string) { 
  return prisma.users.findUnique({
    where: {
      id,
    },
  });
}
\`\`\`

Let's say that two days later another developer opens a pull request to change this function in the codebase. In this case, Joggr would send a pull request message that tells this developer that the document that referenced this function is going to go out of date with the proposed changes. From there, the developer can update the referenced code snippet to ensure the document remains up to date.
    `.trim(),
  },
  {
    order: 3,
    question: 'Can you update the markdown file in GitHub?',
    answer: `
You can change the markdown file in your IDE, GitHub, or Joggr.

If you are making edits to the document in your IDE or GitHub, do not alter the header, footer, or any code snippets that were added to the document using Joggr's Code Snippet Explorer. You should see the additions Joggr makes to the header and footer, this is how Joggr tracks the document. If you change any of those pieces of the document, it will break Joggr's connection to this document and Joggr will be unable to maintain the document for you automatically.
    `.trim(),
  },
  {
    order: 4,
    question: 'Is there an audit history of changes made to a document?',
    answer: `
Yes. Joggr is built on top of git and GitHub. Every JoggrDoc created is committed to GitHub as a markdown file. Therefore, you can manage the audit history just like you do with your codebase 😀.
    `.trim(),
  },
  {
    order: 5,
    question: 'What happens when I move documents in Joggr or in GitHub?',
    answer: `
In Joggr, feel free to move documents into any directory that you desire. Moving documents between directories in Joggr has zero impact on where it's stored in GitHub.

If you move a document (.md) file in GitHub that also exists in Joggr, there will be no issue. However, there is one edge case we currently do not support. If you move a GitHub .md file to another location in GitHub AND rename it at the same time, this will cause the Joggr "link" to the document to break. We use this link to help maintain the document.

In this case, please move the document, confirm in Joggr that the location was successfully updated, **then** re-name the document.
    `.trim(),
  },
];
