import config from '@stargate/config';

/*
|==========================================================================
| useConfig
|==========================================================================
|
| A wrapper around the config object. This is a hook because we will load this or a portion of it from the server
| in the future.
|
*/

export type UseConfigHook = typeof config;

/**
 * Access the application configuration object.
 */
export function useConfig() {
  return config;
}

export default useConfig;
