import { useTheme } from '@mui/material';
import React from 'react';

import { IconAvatar, type IconAvatarProps } from '@stargate/components/Avatars';

import { createComponentClasses } from '@stargate/theme';

/*
|==========================================================================
| OnboardingStepLabelAvatar
|==========================================================================
|
| Component for OnboardingStepLabelAvatar 
|
*/

export const onboardingStepLabelAvatarClasses = createComponentClasses(
  'OnboardingStepLabelAvatar',
  ['root'] as const
);

export interface OnboardingStepLabelAvatarProps
  extends Omit<IconAvatarProps, 'iconColor' | 'backgroundColor'> {
  completed: boolean;
  active: boolean;
}

/**
 * Component for OnboardingStepLabelAvatar
 */
export const OnboardingStepLabelAvatar: React.FC<
  OnboardingStepLabelAvatarProps
> = (props) => {
  const theme = useTheme();

  const bgColor = React.useMemo(() => {
    if (props.completed) {
      return theme.palette.success.main;
    }
    if (props.active) {
      return theme.palette.primary.main;
    }
    return theme.palette.grey[500];
  }, [props.completed, props.active, theme]);

  const icon = React.useMemo(() => {
    if (props.completed) {
      return 'circle-check-filled';
    }
    return props.icon;
  }, [props.completed, props.icon]);

  return (
    <IconAvatar
      {...props}
      icon={icon}
      variant='circular'
      gap={0.75}
      iconColor={theme.palette.common.white}
      backgroundColor={bgColor}
      classes={onboardingStepLabelAvatarClasses}
    />
  );
};
