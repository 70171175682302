import { Skeleton, TextField, Typography, inputClasses } from '@mui/material';
import _ from 'lodash';
import React from 'react';
import * as usehooks from 'usehooks-ts';

import { useJDocDraftMutate } from '@/features/docs/hooks/use-jdoc-draft-mutate';
import type { JDocComponentProps } from '@/features/docs/types';

export type JoggrDocSummaryProps = JDocComponentProps<{
  /**
   * Loading state of the component.
   * @default false
   */
  loading?: boolean;
}>;

export const JoggrDocSummary: React.FC<JoggrDocSummaryProps> = ({
  doc,
  draft,
  mode,
  loading = false,
}) => {
  const jdocMutateDraft = useJDocDraftMutate();
  const handleChange = usehooks.useDebounceCallback((summary: string) => {
    jdocMutateDraft.update({
      summary,
    });
  }, 300);

  if (loading) {
    return (
      <Skeleton variant='text'>
        <Typography
          variant='body1'
          fontSize={SUMMARY_FONT_SIZE}
          sx={TYPOGRAPHY_SX}
        >
          Loading...
        </Typography>
      </Skeleton>
    );
  }

  if (mode !== 'view') {
    return (
      <TextField
        defaultValue={draft?.doc?.summary}
        placeholder='Add a short summary of the document...'
        multiline
        maxRows={3}
        onChange={(e) => handleChange(e.target.value)}
        fullWidth
        sx={{
          fontSize: SUMMARY_FONT_SIZE,
          [`& .${inputClasses.root}`]: {
            fontSize: SUMMARY_FONT_SIZE,
            '&:before, :after, :hover:not(.Mui-disabled):before': {
              borderBottom: 0,
            },
            '& input::placeholder, & textarea::placeholder': {
              fontSize: SUMMARY_FONT_SIZE,
            },
            '& textarea': {
              marginTop: '2px',
            },
          },
        }}
        variant='standard'
      />
    );
  }

  if (!_.isNil(doc?.summary)) {
    return (
      <Typography
        variant='body1'
        fontSize={SUMMARY_FONT_SIZE}
        sx={TYPOGRAPHY_SX}
      >
        {doc.summary}
      </Typography>
    );
  }

  return null;
};

const SUMMARY_FONT_SIZE = '1.15rem';

const TYPOGRAPHY_SX = {
  // Match the TextAreas padding/margin
  padding: '4px 0 5px',
  mt: '2px',
};
