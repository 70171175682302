import {
  Avatar,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Grid2 as Grid,
  Stack,
  Typography,
} from '@mui/material';
import * as hookz from '@react-hookz/web';
import React from 'react';
import TablerIconPlug from '~icons/tabler/plug';

import api from '@stargate/api';
import { Page } from '@stargate/components/Utils';
import { useGitHubUrls } from '@stargate/features/github';
import { useUser } from '@stargate/features/user';
import { useConfig } from '@stargate/hooks';
import { useNightingale } from '@stargate/lib/nightingale';

function IntegrationsPage() {
  const [selectedType, setSelectedType] =
    React.useState<IntegrationType | null>(null);
  const authenticatedUser = useUser();
  const [ghInstallsState, ghInstallsActions] = api.useRequestClient(
    'GET /github/installations'
  );
  const nightingale = useNightingale();
  const githubUrls = useGitHubUrls();
  const config = useConfig();

  /*
  |------------------
  | Computed
  |------------------
  */

  const ghAppInstallUrl = React.useMemo(() => {
    if (ghInstallsState.result) {
      if (ghInstallsState.result.length === 1) {
        const [ghInstall] = ghInstallsState.result;
        return githubUrls.root(
          `/organizations/${ghInstall.account.login}/settings/installations/${ghInstall.id}`
        );
      }
    }

    return config.githubApp.installUrl;
  }, [ghInstallsState.result, config.githubApp.installUrl]);

  /*
  |------------------
  | Handlers
  |------------------
  */

  function handleTypeChange(type: IntegrationType | null) {
    setSelectedType(type);
    nightingale.capture(['filter', 'integration']);
  }

  function generateClickHandler(integration: Integration) {
    return () => {
      nightingale.capture(['click', 'integration'], {
        integration: integration.name,
      });
    };
  }

  /*
  |------------------
  | Effects
  |------------------
  */

  hookz.useMountEffect(() => {
    nightingale.capture(['list', 'integration']);
    void ghInstallsActions.execute();
  });

  return (
    <Page
      id='integrations'
      loading={authenticatedUser.loading}
      renderProps={{
        user: authenticatedUser.data,
      }}
      render={(props) => (
        <Stack spacing={1}>
          <Card>
            <CardHeader
              avatar={<TablerIconPlug />}
              title='Integrations'
              titleTypographyProps={{ variant: 'h5' }}
              sx={{ py: 2 }}
              action={
                <ButtonGroup>
                  <Button
                    variant={selectedType === null ? 'contained' : 'outlined'}
                    color='primary'
                    onClick={() => {
                      handleTypeChange(null);
                    }}
                  >
                    All
                  </Button>
                  <Button
                    variant={
                      selectedType === IntegrationType.vcs
                        ? 'contained'
                        : 'outlined'
                    }
                    color='primary'
                    onClick={() => {
                      handleTypeChange(IntegrationType.vcs);
                    }}
                  >
                    VCS
                  </Button>
                  <Button
                    variant={
                      selectedType === IntegrationType.doc
                        ? 'contained'
                        : 'outlined'
                    }
                    color='primary'
                    onClick={() => {
                      handleTypeChange(IntegrationType.doc);
                    }}
                  >
                    Docs
                  </Button>
                  <Button
                    variant={
                      selectedType === IntegrationType.chat
                        ? 'contained'
                        : 'outlined'
                    }
                    color='primary'
                    onClick={() => {
                      handleTypeChange(IntegrationType.chat);
                    }}
                  >
                    Chat
                  </Button>
                  <Button
                    variant={
                      selectedType === IntegrationType.ide
                        ? 'contained'
                        : 'outlined'
                    }
                    color='primary'
                    onClick={() => {
                      handleTypeChange(IntegrationType.ide);
                    }}
                  >
                    IDE
                  </Button>
                </ButtonGroup>
              }
            />
          </Card>
          <Grid
            container
            spacing={2}
            alignItems='stretch'
            sx={{
              marginLeft: '-8px !important',
              marginRight: '-8px !important',
            }}
          >
            {integrations
              .filter((integration) => {
                if (selectedType === null) {
                  return true;
                }
                return integration.type === selectedType;
              })
              .map((integration) => (
                <Grid
                  key={integration.name}
                  alignItems='stretch'
                  size={{
                    sm: 6,
                    md: 4,
                    lg: 3,
                  }}
                >
                  <Card sx={{ width: '100%', height: '100%' }}>
                    <Stack sx={{ height: '100%' }}>
                      <CardHeader
                        title={integration.name}
                        titleTypographyProps={{ variant: 'h5' }}
                        avatar={
                          <Avatar
                            src={`/assets/images/integrations/${integration.logo}`}
                            alt={integration.name}
                            sx={{
                              height: '50px',
                              width: '50px',
                              p: 1,
                              bgcolor: (theme) => theme.palette.grey[200],
                            }}
                            variant='rounded'
                          />
                        }
                        action={<IntegrationTypeChip type={integration.type} />}
                      />
                      <CardContent>
                        <Typography variant='body1'>
                          {integration.description}
                        </Typography>
                      </CardContent>
                      <CardActions sx={{ mt: 'auto' }}>
                        <Button
                          LinkComponent='a'
                          fullWidth
                          href={
                            integration.url === 'replace-github-url'
                              ? ghAppInstallUrl
                              : integration.url
                          }
                          color={
                            integration.name === 'GitHub' &&
                            props.user.metadata.githubAppInstalled
                              ? 'primary'
                              : 'secondary'
                          }
                          onClick={generateClickHandler(integration)}
                          rel='noopener noreferrer'
                          target='_blank'
                        >
                          {Object.hasOwn(integration, 'action')
                            ? props.user.metadata.githubAppInstalled
                              ? 'Configure'
                              : integration.action
                            : 'Learn More'}
                        </Button>
                      </CardActions>
                    </Stack>
                  </Card>
                </Grid>
              ))}
          </Grid>
        </Stack>
      )}
    />
  );
}

export default IntegrationsPage;

/*
|------------------
| Utils
|------------------
*/

enum IntegrationType {
  vcs = 'vcs',
  doc = 'doc',
  chat = 'chat',
  ide = 'ide',
}

function IntegrationTypeChip(props: { type: IntegrationType }) {
  const { type } = props;
  let label = '';
  let color = 'primary';

  switch (type) {
    case IntegrationType.vcs:
      label = 'VCS';
      color = 'primary';
      break;
    case IntegrationType.doc:
      label = 'Doc';
      color = 'error';
      break;
    case IntegrationType.chat:
      label = 'Chat';
      color = 'success';
      break;
    case IntegrationType.ide:
      label = 'IDE';
      color = 'warning';
      break;
    default:
      throw Error('Oops - no integration type found');
  }

  return <Chip label={label} color={color as any} size='small' />;
}

const integrations = [
  {
    name: 'GitHub',
    url: 'replace-github-url',
    type: IntegrationType.vcs,
    description:
      'Integrate with Github, to search & automatically maintain markdown documentation.',
    logo: 'github.png',
    action: 'Install Github App',
  },
  {
    name: 'VSCode',
    url: 'https://docs.google.com/forms/d/e/1FAIpQLScaccU_W6xB5j9LpoQBIKkVGUUIcNsynkyHrOIGz2ItyTdIcQ/viewform?usp=pp_url&entry.784863175=VSCode',
    type: IntegrationType.ide,
    description: 'Search your documentation directly in your VSCode IDE.',
    logo: 'vscode.png',
  },
  {
    name: 'Slack',
    url: 'https://docs.google.com/forms/d/e/1FAIpQLScaccU_W6xB5j9LpoQBIKkVGUUIcNsynkyHrOIGz2ItyTdIcQ/viewform?usp=pp_url&entry.784863175=Slack',
    type: IntegrationType.chat,
    description:
      'Add a Bot that can search your documentation directly in Slack.',
    logo: 'slack.png',
  },
  {
    name: 'Confluence',
    url: 'https://docs.google.com/forms/d/e/1FAIpQLScaccU_W6xB5j9LpoQBIKkVGUUIcNsynkyHrOIGz2ItyTdIcQ/viewform?usp=pp_url&entry.784863175=Confluence',
    type: IntegrationType.doc,
    description:
      'Integrate with Confluence, to search & automatically maintain documentation',
    logo: 'confluence.webp',
  },
  {
    name: 'Notion',
    url: 'https://docs.google.com/forms/d/e/1FAIpQLScaccU_W6xB5j9LpoQBIKkVGUUIcNsynkyHrOIGz2ItyTdIcQ/viewform?usp=pp_url&entry.784863175=Notion',
    type: IntegrationType.doc,
    description:
      'Integrate with Notion, to search & automatically maintain documentation',
    logo: 'notion.png',
  },
  {
    name: 'JetBrains',
    url: 'https://docs.google.com/forms/d/e/1FAIpQLScaccU_W6xB5j9LpoQBIKkVGUUIcNsynkyHrOIGz2ItyTdIcQ/viewform?usp=pp_url&entry.784863175=JetBrains',
    type: IntegrationType.ide,
    description: 'Search your documentation directly in your JetBrains IDE.',
    logo: 'jetbrains.png',
  },
  {
    name: 'Microsoft Teams',
    url: 'https://docs.google.com/forms/d/e/1FAIpQLScaccU_W6xB5j9LpoQBIKkVGUUIcNsynkyHrOIGz2ItyTdIcQ/viewform?usp=pp_url&entry.784863175=Microsoft+Teams',
    type: IntegrationType.chat,
    description:
      'Add a Bot that can search your documentation directly in Teams.',
    logo: 'msft-teams.png',
  },
  {
    name: 'GitLab',
    url: 'https://docs.google.com/forms/d/e/1FAIpQLScaccU_W6xB5j9LpoQBIKkVGUUIcNsynkyHrOIGz2ItyTdIcQ/viewform?usp=pp_url&entry.784863175=Gitlab',
    type: IntegrationType.vcs,
    description:
      'Integrate with GitLab, to search & automatically maintain markdown documentation.',
    logo: 'gitlab.png',
  },
] as const;
type Integration = (typeof integrations)[number];
