import { Button, type ButtonProps } from '@mui/material';
import React from 'react';

import { Link, type LinkProps, type RouteNames } from '@stargate/routes';

export type ButtonLinkProps<Name extends RouteNames> = ButtonProps &
  LinkProps<Name>;

const ButtonLinkInner = <Name extends RouteNames>(
  _props: ButtonLinkProps<Name>,
  ref: React.Ref<HTMLButtonElement>
) => {
  const {
    color = 'primary',
    size = 'medium',
    variant = 'contained',
    children,
    ...props
  } = _props;
  return (
    <Button
      {...props}
      ref={ref}
      LinkComponent={Link}
      variant={variant}
      color={color}
      size={size}
    >
      {children}
    </Button>
  );
};
export const ButtonLink = React.forwardRef(ButtonLinkInner);
ButtonLink.displayName = 'ButtonLink';
