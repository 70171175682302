import { Stack, Tooltip, useTheme } from '@mui/material';
import {
  TreeItem,
  type TreeItemProps,
  treeItemClasses,
} from '@mui/x-tree-view';
import React from 'react';
import TablerIconChevronUp from '~icons/tabler/chevron-up';

import { createComponentClasses } from '@stargate/theme';
import { cn } from '@stargate/utils/styles';

import { GitHubDirectoryIcon, GitHubFileIcon } from '../Icons';

export const githubFileTreeItemClasses = createComponentClasses(
  'GitHubFileTreeItem',
  ['root', 'hovered', 'actions']
);

export type GitHubFileTreeItemType = 'directory' | 'file';

export interface GitHubFileTreeItemProps extends Omit<TreeItemProps, 'label'> {
  /**
   * The type of the file tree item.
   */
  type: GitHubFileTreeItemType;

  /**
   * The directory or filename.
   */
  name: string;

  /**
   * The "actions" to add to the end of the label.
   */
  actions?: React.ReactNode;

  /**
   * The maximum width of the label.
   */
  maxWidth: number;

  /**
   * Whether the item has children.
   */
  hasChildren?: boolean;

  /**
   * Whether to hide the hint.
   */
  hideHint?: boolean;

  /**
   * The direction of the hint.
   */
  hintDirection?: 'left' | 'right';
}

export const GitHubFileTreeItem: React.FC<GitHubFileTreeItemProps> = ({
  type,
  itemId,
  name,
  actions,
  maxWidth,
  hasChildren = false,
  hintDirection = 'right',
  hideHint = false,
  className,
  ...props
}) => {
  const [showHint, setShowHint] = React.useState(false);
  const [hovered, setHovered] = React.useState(false);
  const theme = useTheme();

  /*
  |------------------
  | Computed
  |------------------
  */

  // We do this so the directories are not offset
  const showExpandIconFiller = React.useMemo(() => {
    return !hasChildren && type === 'directory';
  }, [hasChildren, type]);

  const fileExtension = React.useMemo(() => {
    if (type === 'file') {
      const split = itemId.split('.');
      if (split.length > 1) {
        return split[split.length - 1];
      }
      // We attempt the file if there is no extension
      return split.join('.');
    }
    return '';
  }, [itemId, type]);

  /*
  |------------------
  | Callbacks
  |------------------
  */

  const onOpen = React.useCallback((e: React.SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();
    e.nativeEvent.stopImmediatePropagation();
    setShowHint(true);
  }, []);

  const onClose = React.useCallback((e: Event | React.SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();
    if (Object.hasOwn(e, 'nativeEvent')) {
      e.nativeEvent.stopImmediatePropagation();
    } else {
      e.stopImmediatePropagation();
    }
    setShowHint(false);
  }, []);

  return (
    <Tooltip
      title={name}
      enterDelay={500}
      placement={hintDirection}
      onOpen={onOpen}
      onClose={onClose}
      open={!hideHint && showHint && type === 'file'}
    >
      <TreeItem
        {...props}
        className={cn([
          className,
          hovered ? githubFileTreeItemClasses.hovered : undefined,
        ])}
        onMouseEnter={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setHovered(true);
        }}
        onMouseLeave={() => {
          setHovered(false);
        }}
        classes={{
          ...props.classes,
          root: githubFileTreeItemClasses.root,
        }}
        itemId={itemId}
        label={
          <React.Fragment>
            {name}
            <span className={githubFileTreeItemClasses.actions}>{actions}</span>
          </React.Fragment>
        }
        sx={{
          ...props.sx,
          [`& .${treeItemClasses.content}`]: {
            [`& .${githubFileTreeItemClasses.actions}`]: {
              visibility: 'hidden',
              opacity: 0,
              transition: 'opacity 0.2s ease-in-out',
            },
            [`&:hover .${githubFileTreeItemClasses.actions}`]: {
              visibility: 'visible',
              opacity: 1,
            },
          },
          [`& .${treeItemClasses.label}`]:
            type === 'file'
              ? {
                  // maxWidth + padding + icon width
                  maxWidth: `${maxWidth + 16 + 36}px`,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }
              : undefined,
        }}
        slots={{
          expandIcon: showExpandIconFiller
            ? () => <div style={{ width: 24 }} />
            : (props.slots?.expandIcon ?? undefined),
          endIcon: () => {
            if (type === 'file') {
              return <GitHubFileIcon fileExtension={fileExtension} />;
            }

            if (showExpandIconFiller) {
              return (
                <Stack
                  direction={'row'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  spacing={0.5}
                >
                  <TablerIconChevronUp
                    width={14}
                    height={14}
                    // We are hiding the icon, but we need to keep it in the DOM
                    // so it doesn't shift the other icons
                    style={{ visibility: 'hidden' }}
                  />
                  <GitHubDirectoryIcon />
                </Stack>
              );
            }

            return <GitHubDirectoryIcon />;
          },
        }}
      />
    </Tooltip>
  );
};
