import React from 'react';
import type * as TypeFest from 'type-fest';

import {
  Box,
  type BoxProps,
  Card,
  CardActions,
  type CardActionsProps,
  CardContent,
  type CardContentProps,
  type CardProps,
  Stack,
  Theme,
  lighten,
  useTheme,
} from '@mui/material';
import { createComponentClasses } from '@stargate/theme';
import { cn } from '@stargate/utils/styles';

/*
|==========================================================================
| CodeBlocksCards
|==========================================================================
|
| Cards for CodeBlock. 
|
*/

export const codeBlockCardClasses = createComponentClasses('CodeBlockCard');

export type CodeBlockCardProps = CardProps;

/**
 * Card component for code blocks.
 */
export const CodeBlockCard: React.FC<CodeBlockCardProps> = (props) => {
  const theme = useTheme();
  return (
    <Card
      {...props}
      classes={codeBlockCardClasses}
      sx={{
        ...props.sx,
        // We need to allow DropdownMenu to overflow
        overflow: 'unset',
        padding: '2px', // removes the overlay since we want to round the border
        position: 'relative',
        width: '100%',
        border: `1px solid ${theme.palette.divider}`,
        backgroundColor: theme.palette.codeEditor.background,
      }}
    />
  );
};

export const codeBlockCardHeaderClasses = createComponentClasses(
  'CodeBlockCardHeader'
);

export interface CodeBlockCardHeaderProps {
  leftAction?: React.ReactNode;
  rightAction?: React.ReactNode;
}

/**
 * CardHeader component for code blocks.
 */
export const CodeBlockCardHeader: React.FC<CodeBlockCardHeaderProps> = ({
  leftAction,
  rightAction,
}) => {
  return (
    <Box
      className={codeBlockCardHeaderClasses.root}
      sx={{
        [`&.${codeBlockCardHeaderClasses.root}`]: {
          borderBottom: (theme) => `0.5px solid ${theme.palette.divider}`,
          py: 1,
          px: 1,
          bgcolor: (theme) =>
            theme.palette.mode === 'dark'
              ? theme.palette.grey[900]
              : theme.palette.grey[200],
        },
      }}
    >
      <Stack direction='row' spacing={1} alignItems='center' flexWrap={'wrap'}>
        {leftAction}
        <Box sx={{ flexGrow: 1 }} />
        {rightAction}
      </Stack>
    </Box>
  );
};

export const codeBlockCardContentClasses = createComponentClasses(
  'CodeBlockCardContent'
);

export type CodeBlockCardContentProps = CardContentProps;

/**
 * CardContent component for code blocks.
 */
export const CodeBlockCardContent: React.FC<CodeBlockCardContentProps> = (
  props
) => {
  const theme = useTheme();
  return (
    <CardContent
      {...props}
      classes={codeBlockCardContentClasses}
      sx={{
        ...props.sx,
        width: '100%',
        paddingLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(0.5),
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(2),
        background: 'transparent',
        position: 'relative',
      }}
    />
  );
};

export const codeBlockCardFooterClasses = createComponentClasses(
  'CodeBlockCardFooter'
);

export type CodeBlockCardFooterProps = CardActionsProps;

/**
 * CardFooter component for code blocks.
 */
export const CodeBlockCardFooter: React.FC<CodeBlockCardFooterProps> = (
  props
) => {
  const theme = useTheme();
  return (
    <CardActions
      {...props}
      classes={codeBlockCardFooterClasses}
      sx={{
        ...props.sx,
        width: '100%',
        padding: theme.spacing(1),
        borderTop: `0.5px solid ${theme.palette.divider}`,
        background:
          theme.palette.mode === 'dark'
            ? lighten(theme.palette.grey[900], 0.05)
            : theme.palette.grey[100],
      }}
    />
  );
};

export const codeBlockCardActionsClasses = createComponentClasses(
  'CodeBlockCardActions'
);

export type CodeBlockCardContentActionsProps = TypeFest.Simplify<
  {
    anchorOrigin: {
      horizontal: 'left' | 'right';
      vertical: 'top' | 'bottom';
    };
  } & BoxProps
>;

/**
 * CardContentActions (floating) component for code blocks.
 */
export const CodeBlockCardContentActions: React.FC<
  CodeBlockCardContentActionsProps
> = ({ anchorOrigin, ...props }) => {
  const theme = useTheme();
  const spacingVertical = theme.spacing(1);
  const spacingHorizontal = theme.spacing(1);
  return (
    <Box
      {...props}
      className={cn(codeBlockCardActionsClasses.root, props.className)}
      sx={{
        ...props.sx,
        position: 'absolute',
        top: anchorOrigin.vertical === 'top' ? spacingVertical : undefined,
        bottom:
          anchorOrigin.vertical === 'bottom' ? spacingVertical : undefined,
        left:
          anchorOrigin.horizontal === 'left' ? spacingHorizontal : undefined,
        right:
          anchorOrigin.horizontal === 'right' ? spacingHorizontal : undefined,
      }}
    />
  );
};
