import { Drawer, type DrawerProps, useTheme } from '@mui/material';
import React from 'react';

import { createComponentClasses } from '@stargate/theme';
import { cn } from '@stargate/utils/styles';

import type { CodeExplorerPlacement } from '../../types';

export const codeExplorerDrawerClasses = createComponentClasses(
  'CodeExplorerDrawer',
  ['root', 'paper', 'placement-right', 'placement-bottom'] as const
);

export interface CodeExplorerDrawerProps extends BaseProps {
  /**
   * The placement of the drawer.
   */
  placement: CodeExplorerPlacement;

  /**
   * The open state of the drawer.
   */
  open: boolean;

  /**
   * Callback for when the drawer is closed.
   */
  onClose: () => void;
}

export const CodeExplorerDrawer: React.FC<CodeExplorerDrawerProps> = ({
  placement,
  open,
  onClose,
  children,
  ...props
}) => {
  const theme = useTheme();
  return (
    <Drawer
      {...props}
      classes={{
        root: codeExplorerDrawerClasses.root,
        paper: codeExplorerDrawerClasses.paper,
      }}
      className={cn([
        codeExplorerDrawerClasses[`placement-${placement}`],
        props.className,
      ])}
      anchor={placement}
      open={open}
      onClose={onClose}
      hideBackdrop={false}
      keepMounted
      sx={{
        ...props.sx,
        overflow: 'hidden',
        flexShrink: 0,
        [`& .${codeExplorerDrawerClasses.paper}`]: {
          boxSizing: 'border-box',
          zIndex: theme.zIndex.drawer + 1,
          overflow: 'hidden',
        },
        [`&.${codeExplorerDrawerClasses['placement-right']} .${codeExplorerDrawerClasses.paper}`]:
          {
            width: '70%',
            [theme.breakpoints.down('lg')]: {
              width: '100%',
            },
            [theme.breakpoints.down('md')]: {
              width: '100%',
            },
          },
        [`&.${codeExplorerDrawerClasses['placement-bottom']} .${codeExplorerDrawerClasses.paper}`]:
          {
            height: '75vh',
            [theme.breakpoints.down('lg')]: {
              height: '85vh',
            },
            [theme.breakpoints.down('md')]: {
              height: '100vh',
            },
          },
      }}
    >
      {children}
    </Drawer>
  );
};

/*
|------------------
| Utils
|------------------
*/

type BaseProps = Omit<
  DrawerProps,
  'hideBackdrop' | 'anchor' | 'open' | 'onClose' | 'classes' | 'content'
>;
