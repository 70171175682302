import { Stack, Typography } from '@mui/material';
import { FloatingMenu } from '@tiptap/react';
import _ from 'lodash';
import React from 'react';

import { placeholderClasses } from '@dashdraft/extensions/utilities/extensions/placeholder';
import type { DashDraftEditor } from '@dashdraft/types';

import type { ShouldShowFloatingMenuFunc } from '../types';
import { PlaceholderButton } from './PlaceholderButton';

export interface PlaceholderProps {
  editor: DashDraftEditor;
  content?: React.ReactNode;
}

/**
 * The Placeholder text and menu.
 */
export const PlaceholderMenu: React.FC<PlaceholderProps> = ({
  editor,
  content = "Press '/' for commands",
}) => {
  const handleClick = React.useCallback(
    (e: React.SyntheticEvent) => {
      e.preventDefault();
      editor.commands.focus();
    },
    [editor]
  );

  const handleCodeSnippetClick = React.useCallback(() => {
    editor.chain().focus().setEmptyCodeSnippet().run();
  }, [editor]);

  const handleDiagramClick = React.useCallback(() => {
    editor.chain().focus().setCodeBlock({ language: 'mermaid' }).run();
  }, [editor]);

  const handleImageClick = React.useCallback(() => {
    editor.chain().focus().setEmptyImage({ forceOpen: true }).run();
  }, [editor]);

  const handleTableClick = React.useCallback(() => {
    editor.chain().focus().insertTable().run();
  }, [editor]);

  return (
    <FloatingMenu
      editor={editor}
      tippyOptions={{
        hideOnClick: true,
        duration: [100, 200],
        offset: [0, 0],
      }}
      shouldShow={(payload) => {
        return _.some([shouldShowEmptyParagraphNode(payload)]);
      }}
    >
      <Stack
        component='span'
        spellCheck={false}
        contentEditable={false}
        direction='row'
        spacing={0.75}
        sx={{
          ml: '1px',
          pl: 1,
        }}
        alignItems='center'
        className='dashdraft-placeholder'
        onClick={handleClick}
      >
        <Typography
          spellCheck={false}
          contentEditable={false}
          component='span'
          variant='body1'
          className='dashdraft-placeholder-content'
        >
          {content}
        </Typography>
        <Stack
          contentEditable={false}
          className='dashdraft-placeholder-actions'
          direction='row'
          spacing={0}
          alignItems='center'
        >
          <PlaceholderButton
            icon='file-code-2'
            hint="Insert a code snippet directly from your codebase, using Joggr's GitHub integration."
            onClick={handleCodeSnippetClick}
          />
          <PlaceholderButton
            icon='hierarchy-2'
            hint="Create a diagram using Sirena, Joggr's custom built Mermaid editor."
            onClick={handleDiagramClick}
          />
          <PlaceholderButton
            icon='photo'
            hint='Insert a link to an image or upload an image to the Joggr secured CDN.'
            onClick={handleImageClick}
          />
          <PlaceholderButton
            icon='table'
            hint='Create a table to organize your content.'
            onClick={handleTableClick}
          />
        </Stack>
      </Stack>
    </FloatingMenu>
  );
};

/*
|------------------
| Utils
|------------------
*/

/**
 * If the active item is a paragraph, show the floating menu.
 *
 * @param payload
 * @returns A boolean indicating if the floating menu should be shown
 */
const shouldShowEmptyParagraphNode: ShouldShowFloatingMenuFunc = ({
  editor,
  state,
}) => {
  const { selection } = state;
  const { $anchor, empty } = selection;
  const isRootDepth = $anchor.depth === 1;
  const isEmptyTextBlock =
    $anchor.parent.isTextblock &&
    !$anchor.parent.type.spec.code &&
    !$anchor.parent.textContent;

  if (
    _.some([
      !editor.isFocused,
      !editor.isActive('paragraph'),
      // We do NOT allow bold, italic, underline, or strikethrough in the placeholder
      _.some(['bold', 'italic', 'underline', 'strikethrough'], (mark) =>
        editor.isActive(mark)
      ),
      !empty,
      !isEmptyTextBlock,
      !isRootDepth,
      !editor.isEditable,
    ])
  ) {
    return false;
  }

  return true;
};
