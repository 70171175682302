import _ from 'lodash';
import React from 'react';
import type * as TypeFest from 'type-fest';

import { useGitHubUrls } from '../../hooks/use-github-urls';
import { GitHubRepositoryIcon } from '../Icons/GitHubRepositoryIcon';
import { GitHubChip, type GitHubChipProps } from './GitHubChip';

export type GitHubRepositoryChipProps<
  RootComponent extends React.ElementType = 'div',
> = TypeFest.Simplify<
  Omit<GitHubChipProps<RootComponent>, 'renderAvatar' | 'renderIcon'> & {
    repo: string;
    disableLink?: boolean;
    disableOwner?: boolean;
    owner?: string;
    extra?: string;
  }
>;

export const GitHubRepositoryChip = React.forwardRef<
  HTMLDivElement,
  GitHubRepositoryChipProps
>(
  (
    {
      repo,
      owner,
      extra = '',
      disableLink = false,
      disableOwner = false,
      ...props
    },
    ref
  ) => {
    const githubUrls = useGitHubUrls();
    return (
      <GitHubChip
        {...props}
        url={
          owner && disableLink === false
            ? githubUrls.repo({ owner: owner, repo: repo })
            : undefined
        }
        renderIcon={(p) => (
          <GitHubRepositoryIcon
            height={p.size}
            width={p.size}
            color={p.color}
            fill={p.backgroundColor}
          />
        )}
        label={`${owner && !disableOwner ? `@${owner}/` : ''}${repo}${extra}`}
      />
    );
  }
);
GitHubRepositoryChip.displayName = 'GitHubRepositoryChip';
GitHubRepositoryChip.muiName = 'Chip';
