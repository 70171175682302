import { RikerIcon } from '@joggrdocs/riker-icons';
import {
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  type MenuProps,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import React from 'react';

import { useDashDraftEditor } from '@stargate/lib/dashdraft';
import { useNavigate } from '@stargate/routes';
import { createComponentClasses } from '@stargate/theme';

import type { JDocVersion } from '../../types';

export const joggrDocVersionMenuClasses = createComponentClasses(
  'JoggrDocVersionMenu',
  ['root'] as const
);

export interface JoggrDocVersionMenuProps extends BaseProps {
  /**
   * The list of document versions.
   */
  versions: JDocVersion[];

  /**
   * Callback when the menu is closed.
   */
  onClose: () => void;
}

export const JoggrDocVersionMenu: React.FC<JoggrDocVersionMenuProps> = ({
  versions,
  anchorEl,
  open,
  onClose,
  ...props
}) => {
  const navigate = useNavigate();
  const editor = useDashDraftEditor();

  const createClickHandler = (id: string) => (): void => {
    navigate(`app.documents.${editor?.isEditable === true ? 'edit' : 'view'}`, {
      params: {
        id,
      },
    });
    onClose();
  };

  return (
    <Menu
      {...props}
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      disablePortal
    >
      {versions.map((v) => (
        <MenuItem key={v.id} onClick={createClickHandler(v.id)}>
          <ListItemIcon>
            <RikerIcon icon='git-branch' />
          </ListItemIcon>
          {v.branchName}
        </MenuItem>
      ))}
    </Menu>
  );
};

/*
|------------------
| Utils
|------------------
*/

type BaseProps = Omit<MenuProps, 'children' | 'onClose'>;
