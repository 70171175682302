import { useFrigade, useUser } from '@frigade/react';
import React from 'react';

import { useLogger } from '@stargate/logger';
import { useSentry } from '@stargate/vendors/sentry';

import { useFrigadeAPI } from './use-frigade-api';
import { useFrigadeStore } from './use-frigade-store';

export interface UseFrigadeUserHook {
  /**
   * The user id.
   */
  userId: string | null;

  /**
   * The guest user id.
   */
  guestUserId: string | null;

  /**
   * Set the user id.
   * @param userId The user id.
   * @param properties (optional) The properties to set.
   */
  identify: (
    userId: string,
    properties?: Record<string, unknown>
  ) => Promise<void>;

  /**
   * Reset the user.
   */
  reset: () => Promise<void>;

  /**
   * Merge the current user with the guest user (if we have one).
   */
  mergeWithGuestUser: (userId: string) => Promise<void>;

  /**
   * Capture an event for the user.
   */
  track: (event: string, properties: Record<string, unknown>) => Promise<void>;

  /**
   * Capture an event for a user with a specific user id.
   */
  trackWithUserId: (
    userId: string,
    event: string,
    properties: Record<string, unknown>
  ) => Promise<void>;
}

/**
 * A wrapper around the Frigade user hook, to prevent re-rendering and tracking of guest users.
 */
export const useFrigadeUser = (): UseFrigadeUserHook => {
  const frigadeStore = useFrigadeStore();
  const frigadeUser = useUser();
  const { frigade: frigadeSDK } = useFrigade();
  const logger = useLogger();
  const sentry = useSentry();
  const frigadeAPI = useFrigadeAPI();

  /*
  |------------------
  | Data
  |------------------
  */

  const guestUserId = React.useMemo(() => {
    return frigadeUser.userId?.includes('guest') ? frigadeUser.userId : null;
  }, [frigadeUser.userId]);

  const userId = React.useMemo(() => {
    return frigadeUser.userId;
  }, [frigadeUser.userId]);

  /*
  |------------------
  | Callbacks
  |------------------
  */

  const mergeWithGuestUser = React.useCallback(
    async (userId: string) => {
      sentry.addBreadcrumb({
        category: 'frigade',
        level: 'info',
        message: 'Merging guest user',
        data: {
          userId,
          guestUserId,
        },
      });

      if (guestUserId) {
        logger.debug({
          message: 'Merging guest user',
          data: {
            userId,
            guestUserId,
          },
        });

        await frigadeAPI.request('POST /v1/public/users', {
          userId: userId,
          linkGuestId: guestUserId,
        });
      } else {
        logger.debug({
          message: 'Attempted to merge guest user, but no guest user found',
          data: {
            userId,
            guestUserId,
          },
        });
      }
    },
    [frigadeAPI, guestUserId, logger, sentry]
  );

  const identify = React.useCallback(
    async (userId: string, properties?: Record<string, unknown>) => {
      frigadeStore.setUserId(userId);
      await frigadeSDK.identify(userId, properties);
    },
    [frigadeStore, frigadeSDK.identify]
  );

  const reset = React.useCallback(async () => {
    frigadeStore.setUserId(null);
  }, [frigadeStore]);

  const track = React.useCallback(
    async (event: string, properties: Record<string, unknown>) => {
      if (userId) {
        await frigadeUser.track(event, properties);
      }
    },
    [userId, frigadeUser.track]
  );

  const trackWithUserId = React.useCallback(
    async (
      userId: string,
      event: string,
      properties: Record<string, unknown>
    ) => {
      // We need to use the API directly here, as the Frigade user hook does not support tracking with a specific user id.
      await frigadeAPI.request('POST /v1/public/sessions', {
        userId,
        userEvents: [
          {
            event,
            properties,
          },
        ],
      });
    },
    [frigadeAPI]
  );

  return {
    userId,
    guestUserId,
    identify,
    reset,
    mergeWithGuestUser,
    track,
    trackWithUserId,
  };
};
