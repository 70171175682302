import { useTheme } from '@mui/material';
import React from 'react';
import TablerIconBook2 from '~icons/tabler/book-2';

export type GitHubRepositoryIconProps = React.ComponentProps<
  typeof TablerIconBook2
>;

/**
 * A component that displays a repository icon.
 */
export const GitHubRepositoryIcon = React.forwardRef<
  SVGSVGElement,
  GitHubRepositoryIconProps
>((props, ref) => {
  const theme = useTheme();
  return (
    <TablerIconBook2
      {...props}
      ref={ref}
      fill={props.fill ?? theme.palette.primary.light}
      color={props.color ?? theme.palette.primary.dark}
    />
  );
});
GitHubRepositoryIcon.displayName = 'GitHubRepositoryIcon';
