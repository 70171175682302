import React from 'react';
import type * as TypeFest from 'type-fest';
import TablerIconFileTypeDoc from '~icons/tabler/file-type-doc';

/*
|==========================================================================
| DocumentIcon 
|==========================================================================
|
| Proxy component for the document icon.
|
*/

export type DocumentIconProps = TypeFest.Simplify<
  Omit<React.ComponentProps<typeof TablerIconFileTypeDoc>, 'ref'>
>;

/**
 * Icon for a document.
 */
export const DocumentIcon = React.forwardRef<SVGSVGElement, DocumentIconProps>(
  (props, ref) => <TablerIconFileTypeDoc {...props} ref={ref} />
);
