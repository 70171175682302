import _ from 'lodash';

import type { NightingaleEventName } from '../types';

/*
|==========================================================================
| utils
|==========================================================================
|
| Simple utility functions that are used in the Nightingale library.
|
*/

/**
 * Gets the event name as a string that can be sent to our servers.
 *
 * @param eventName A tuple that represents an event, in the format of [Verb, Noun] or a special event name, in the format of '$pageview' or '$pageleave'.
 * @returns A string that represents the event name.
 */
export const serializeEventName = (eventName: NightingaleEventName): string => {
  if (typeof eventName === 'string') return eventName;

  const [verb, noun] = eventName;
  return [noun, verb].join('_');
};

/**
 * Gets the full URL from a location object.
 *
 * @param location A location object from the react-router-dom.
 * @returns A full URL string that represents the location.
 */
export const serializePageUrl = (
  location: Pick<Location, 'pathname' | 'hash' | 'search'>
): string => {
  return [
    window.location.origin,
    location.pathname,
    location.hash,
    location.search,
  ].join('');
};
