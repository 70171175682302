import { Button, type ButtonProps } from '@mui/material';
import React from 'react';
import TablerIconBrandGithubFilled from '~icons/tabler/brand-github-filled';

/*
|==========================================================================
| GitHubButton
|==========================================================================
|
| 
|
*/

export type GitHubButtonProps = ButtonProps<'a'> & {
  openInNewTab?: boolean;
};

/**
 * GitHubButton is a Button with a GitHub icon and the text
 */
export const GitHubButton = React.forwardRef<HTMLElement, GitHubButtonProps>(
  (
    {
      color = 'primary',
      children = 'View on GitHub',
      openInNewTab = true,
      href,
      ...restProps
    },
    ref
  ) => {
    const openInNewTabProps = React.useMemo(() => {
      if (openInNewTab) {
        return {
          rel: 'noopener noreferrer',
          target: '_blank',
        };
      }
      return {};
    }, [openInNewTab]);
    return (
      <Button
        {...restProps}
        LinkComponent='a'
        href={href}
        // @ts-expect-error - Bad types, need to fix
        ref={ref}
        startIcon={<TablerIconBrandGithubFilled />}
        color={color}
        {...openInNewTabProps}
      >
        {children}
      </Button>
    );
  }
);
GitHubButton.displayName = 'GitHubButton';
GitHubButton.muiName = 'Button';
