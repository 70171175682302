import _ from 'lodash';

import logger from '@stargate/lib/logger';

export interface LocalStorageRecord<T> {
  key: string;
  value: T;
  createdAt: number;
  updatedAt: number;

  /** @deprecated */
  lastUpdated: number;
}

/**
 * A collection of utilities for interacting with local storage.
 */
export const LocalStorage = {
  /**
   * Create a key to be used in localStorage.
   *
   * @param key A string to be used as the key
   * @returns A string to be used as the key in localStorage, that is easily identifiable as a stargate key
   */
  createKey<Key extends string>(key: Key): `${typeof KEY_PREFIX}:${Key}` {
    return `${KEY_PREFIX}:${cleanKey(key)}`;
  },

  /**
   * Get a record from browser storage (including metadata)
   *
   * @param key A string to be used as the key
   * @returns The value stored in the key or null
   */
  getRecord<T>(key: string): LocalStorageRecord<T> | null {
    const val = JSON.parse(
      window.localStorage.getItem(LocalStorage.createKey(key)) || 'null'
    ) as LocalStorageRecord<T> | null;
    return _.isNil(val)
      ? null
      : {
          ...val,
          updatedAt: val.lastUpdated ?? 0,
          key,
        };
  },

  /**
   * Get a value from browser storage.
   *
   * @param key A string to be used as the key
   * @param defaultValue A value to return if the key is not found
   * @returns The value stored in the key or the default value
   */
  get<T>(key: string, defaultValue?: T): T | null {
    try {
      const parsedValue = LocalStorage.getRecord<T>(key);
      return parsedValue?.value ?? defaultValue ?? null;
    } catch (error) {
      logger.warn({ error, key }, 'Failed to get local storage value');
      return defaultValue ?? null;
    }
  },

  /**
   * Set a value in browser storage.
   *
   * @param key A string to be used as the key
   * @param value A value to be stored
   */
  set<T>(key: string, value: T) {
    try {
      const updatedAt = Date.now();
      const createdAt = LocalStorage.getRecord<T>(key)?.createdAt ?? updatedAt;
      window.localStorage.setItem(
        LocalStorage.createKey(key),
        JSON.stringify({
          value,
          createdAt,
          updatedAt,
          lastUpdated: updatedAt,
        })
      );
    } catch (error) {
      logger.warn({ error, key }, 'Failed to save local storage value');
    }
  },

  /**
   * Check if a value is expired based on the lastUpdated timestamp and a ttl.
   *
   * @param key A string to be used as the key
   * @param ttl A number of milliseconds to consider the value expired
   * @returns A boolean indicating if the value is expired
   */
  expired<T>(key: string, ttl: number): boolean {
    const parsedValue = LocalStorage.getRecord<T>(key);

    if (!parsedValue) {
      throw new Error('No value found');
    }

    const updatedAt = parsedValue.updatedAt ?? parsedValue.lastUpdated;
    return Date.now() - updatedAt > ttl;
  },

  /**
   * Remove a value from browser storage.
   *
   * @param key A string to be used as the key
   */
  remove(key: string) {
    window.localStorage.removeItem(LocalStorage.createKey(key));
  },

  /**
   * List all (Joggr) values in browser storage.
   *
   * @returns An array of objects with the key, value, and updatedAt timestamp
   */
  list<T>(): LocalStorageRecord<T>[] {
    return _.chain(listKeys())
      .map((key) => key.replace(`${KEY_PREFIX}:`, ''))
      .map((key) => {
        const record = LocalStorage.getRecord<T>(key);
        if (!record) {
          return null;
        }

        return {
          ...record,
          key,
        };
      })
      .compact()
      .value();
  },

  /**
   * Clear all (Joggr) values in browser storage.
   */
  clear() {
    for (const key of listKeys()) {
      LocalStorage.remove(key);
    }
  },

  /**
   * Return a key-value object of all (Joggr) values in browser storage.
   */
  export: () => {
    const data: Record<string, string | null> = {};

    for (const key of listKeys()) {
      const record = window.localStorage.getItem(key);
      if (!_.isNil(record)) {
        data[key] = record;
      }
    }

    return data;
  },
};

/*
|------------------
| Utils
|------------------
*/

const KEY_PREFIX = '@@joggr:stargate:';

/**
 * List all keys in browser storage.
 * @returns An array of keys
 */
function listKeys() {
  const keys = [];

  for (let i = 0; i < window.localStorage.length; i++) {
    const key = window.localStorage.key(i);
    if (key?.startsWith(KEY_PREFIX)) {
      keys.push(key);
    }
  }

  return keys;
}

/**
 * Clean a key from the KEY_PREFIX.
 * @param key A string to be used as the key
 * @returns A string without the KEY_PREFIX
 */
function cleanKey<Key extends string>(key: Key): Key {
  return key.replace(`${KEY_PREFIX}:`, '') as Key;
}
