import { Box, useTheme } from '@mui/material';
import * as hookz from '@react-hookz/web';
import { EditorContent as TipTapEditorContent } from '@tiptap/react';
import _ from 'lodash';
import React from 'react';

import { DragHandle } from '@dashdraft/components/DragHandle';
import TextMenu from '@dashdraft/components/Menus/TextMenu';
import { PlaceholderMenu } from '@dashdraft/components/Menus/placeholder/PlaceholderMenu';
import { TableColumnMenu } from '@dashdraft/components/Menus/tables/TableColumnMenu';
import { TableMenu } from '@dashdraft/components/Menus/tables/TableMenu';
import { TableRowMenu } from '@dashdraft/components/Menus/tables/TableRowMenu';
import { useDashDraftEditor } from '@dashdraft/hooks/use-dashdraft-editor';
import type { DashDraftJSONContent } from '@dashdraft/types';
import { cn } from '@stargate/utils/styles';

export interface DashDraftContentProps {
  /**
   * Optional id used for Frigade targeting
   */
  id?: string;

  /**
   * Whether the editor is read-only or not.
   */
  readonly?: boolean;

  /**
   * HTML content to render in the editor.
   */
  defaultContent?: DashDraftJSONContent;
}

/**
 * DashDraftContent is a React component that renders the content
 * of the editor, aka the editor's document/text.
 */
export const DashDraftContent = React.memo<DashDraftContentProps>(
  ({ id, readonly, defaultContent }) => {
    const editor = useDashDraftEditor();
    const theme = useTheme();

    /*
    |------------------
    | Effects
    |------------------
    */

    // Set the default content on mount
    hookz.useMountEffect(() => {
      if (!_.isNil(defaultContent)) {
        editor.commands.setContent(defaultContent, false);
      }
    });

    // Handle switching between read-only and editable
    // editor states.
    React.useEffect(() => {
      if (readonly === true && editor.isEditable) {
        editor.setEditable(false);
      } else if (readonly === false && !editor.isEditable) {
        editor.setEditable(true);
      }
    }, [readonly, editor]);

    return (
      <Box
        id={id}
        className={cn(
          'dashdraft',
          `dashdraft-theme-${theme.palette.mode}`,
          (readonly ?? false) && 'dashdraft-readonly'
        )}
      >
        {/* EditorContent */}
        <DashDraftContentWrapper />

        {/* Menus */}
        {!_.isNil(editor) && (
          <React.Fragment>
            <TextMenu editor={editor} />
            <TableRowMenu editor={editor} />
            <TableColumnMenu editor={editor} />
            <TableMenu editor={editor} />
            <DragHandle editor={editor} />
            <PlaceholderMenu editor={editor} />

            {/* Markdown does not support Merge/Split cells, disabled those for now */}
            {/* {<TableCellMenu editor={editor} />} */}
          </React.Fragment>
        )}
      </Box>
    );
  }
);

/*
|------------------
| Utils
|------------------
*/

/**
 * Wrapped component to prevent re-renders due to other components
 *
 * @see https://tiptap.dev/docs/guides/performance#react-tiptap-editor-integration
 */
const DashDraftContentWrapper = React.memo(() => {
  const editor = useDashDraftEditor();
  return <TipTapEditorContent editor={editor} className='dashdraft-content' />;
});
