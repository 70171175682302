import React from 'react';

import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  type SxProps,
  darken,
  useTheme,
} from '@mui/material';

/*
|==========================================================================
| SettingsActionCard
|==========================================================================
|
| Provide Settings Actions such as Configuration, Security, and more.
|
*/

export interface SettingsActionCardProps {
  title: string;
  icon?: React.ReactNode;
  content: React.ReactNode;
  actions?: React.ReactNode;
  sx?: SxProps;
  key?: string | number;
  item?: boolean;
}

export const SettingsActionCard: React.FC<SettingsActionCardProps> = (
  props
) => {
  const theme = useTheme();

  const gridItemSx = (cols: number, spacing: number) => {
    const calcPercent = cols / 12;
    const spacingDiff = spacing - 0;
    return {
      maxWidth: `calc(${calcPercent * 100}% - ${theme.spacing(
        spacingDiff
      )}) !important`,
      flexBasis: `calc(${calcPercent * 100}% - ${theme.spacing(
        spacingDiff
      )}) !important`,
      ml: spacing,
      mt: spacing,
    };
  };

  const cardProps = props.item
    ? {
        component: Grid,
        md: 6,
        item: true,
      }
    : {};

  const cardSx: SxProps = props.item
    ? {
        ...props.sx,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        [theme.breakpoints.up('md')]: gridItemSx(6, 1),
        [theme.breakpoints.down('md')]: gridItemSx(12, 1),
      }
    : { ...props.sx };

  return (
    <Card {...cardProps} sx={cardSx} key={props.key}>
      <CardHeader
        title={props.title}
        titleTypographyProps={{
          variant: 'h5',
        }}
        avatar={
          <Avatar
            variant='rounded'
            sx={{
              ...theme.applyStyles('dark', {
                bgcolor: darken(theme.palette.background.paper, 0.4),
              }),
              ...theme.applyStyles('light', {
                bgcolor: darken(theme.palette.background.paper, 0.1),
              }),
              color: theme.palette.text.primary,
            }}
          >
            {props.icon}
          </Avatar>
        }
      />
      <CardContent>{props.content}</CardContent>
      {props.actions && <CardActions>{props.actions}</CardActions>}
    </Card>
  );
};

export default SettingsActionCard;
