import { Box } from '@mui/material';
import * as hookz from '@react-hookz/web';
import React from 'react';

import {
  DashDraftContent,
  type DashDraftJSONContent,
  DashDraftProvider,
} from '@stargate/lib/dashdraft';

export interface TemplateViewerProps {
  content: DashDraftJSONContent;
}

/**
 * A component for viewing a template.
 */
const TemplateViewer: React.FC<TemplateViewerProps> = (props) => {
  const rerender = hookz.useRerender();

  // We force this to rerender when the content changes
  // biome-ignore lint/correctness/useExhaustiveDependencies: only rerender when content changes
  React.useEffect(() => {
    rerender();
  }, [props.content]);

  return (
    <Box
      component='span'
      sx={{
        // Override base styles to help with viewing
        '.dashdraft.dashdraft.dashdraft.dashdraft.dashdraft': {
          marginRight: '0px',
          marginLeft: '0px',
          '& > .dashdraft-content > .ProseMirror > *': {
            marginRight: '0px',
            marginLeft: '0px',
          },
          '& h1, & h2, & h3': {
            mb: '4px',
            mt: '16px',
            pb: '4px',
          },
          '& h1': {
            fontSize: '26px',
          },
          '& h2': {
            fontSize: '22px',
          },
          '& h3': {
            fontSize: '18px',
          },
          '& h4': {
            fontSize: '16px',
          },
          '& p': {
            fontSize: '14px',
            my: '6px',
          },
          '& ul': {
            fontSize: '14px',
            my: '6px',
          },
          '& ol': {
            fontSize: '14px',
            my: '6px',
          },
        },
      }}
    >
      <DashDraftProvider
        extensionsConfig={{
          tableOfContents: false,
          utilities: false,
        }}
      >
        <DashDraftContent defaultContent={props.content} readonly />
      </DashDraftProvider>
    </Box>
  );
};

export default TemplateViewer;
