import React from 'react';
import TablerIconFolder from '~icons/tabler/folder';
import TablerIconFolderOpen from '~icons/tabler/folder-open';

export interface GitHubDirectoryIconProps
  extends React.ComponentProps<typeof TablerIconFolder> {
  open?: boolean;
}

/**
 * A component that displays a directory icon, allowing for an open or closed state.
 */
export const GitHubDirectoryIcon = React.forwardRef<
  SVGSVGElement,
  GitHubDirectoryIconProps
>(({ open = false, ...props }, ref) => {
  const classNames =
    'fill-primary-light text-primary-dark [&_path]:fill-primary-light';

  if (open) {
    return <TablerIconFolderOpen {...props} ref={ref} className={classNames} />;
  }

  return <TablerIconFolder {...props} ref={ref} className={classNames} />;
});
GitHubDirectoryIcon.displayName = 'GitHubDirectoryIcon';
