import { gitHubEmojis } from '@tiptap-pro/extension-emoji';
import _ from 'lodash';
import { P, match } from 'ts-pattern';
import type * as TF from 'type-fest';

import { type DashDraftJSONContent, generateHTML } from '@stargate/dashdraft';
import type { JDocDraft, JDocMode } from '@stargate/features/docs/types';

import { fields } from './config';
import type { JDocModifiableField } from './types';

/**
 * Compare a JoggrDoc and a JoggrDocDraft to find the modified fields
 * @param doc A JoggrDoc
 * @param draft A JoggrDocDraft
 * @param mode A JoggrDocMode
 * @returns An array of modified fields with the two values being compared
 */
export function getModifiedFields(
  mode: JDocMode,
  doc?: JDocDraft | null,
  draft?: JDocDraft | null
): ModifiedFieldResult[] {
  const modified = [];

  try {
    if (!_.isNil(draft)) {
      for (const docField of fields.modifiable) {
        // View will NEVER have modified fields
        if (mode === 'view') {
          continue;
        }

        // If the doc is Nil than we will NEVER have modified fields
        if (mode === 'edit' && _.isNil(doc)) {
          continue;
        }

        const existingValue = _.get(doc, docField);
        const draftValue = _.get(draft, docField);
        if (
          mode === 'edit' &&
          _.some([
            _.isNil(draftValue),
            _.isNil(existingValue),
            compareFields(docField, existingValue, draftValue),
          ])
        ) {
          continue;
        }

        // For create we just need to make sure the field is not nil
        if (mode === 'create' && _.isNil(draftValue)) {
          continue;
        }

        // If we get here then the field was modified
        modified.push({
          field: docField,
          currentValue: existingValue,
          newValue: draftValue,
        });
      }
    }
    return modified;
  } catch (error) {
    console.error('Error getting modified fields', error);
    return [];
  }
}

export type ModifiedFieldResult<
  F extends JDocModifiableField = JDocModifiableField,
> = {
  field: F;
  currentValue: TF.Get<JDocDraft, F>;
  newValue: TF.Get<JDocDraft, F>;
};

/*
|------------------
| Utils
|------------------
*/

/**
 * Compare two values to see if they are equal
 * @param a A string or number
 * @param b A string or number
 * @returns A boolean if the two values are equal
 */
function compareFields(
  field: JDocModifiableField,
  a: string | number | DashDraftJSONContent | undefined,
  b: string | number | DashDraftJSONContent | undefined
) {
  if (field === 'content') {
    return isContentEqual(a as DashDraftJSONContent, b as DashDraftJSONContent);
  }

  return a?.toString() === b?.toString();
}

/**
 * Compare two DashDraftJSONContent objects to see if they are equal (we remove empty paragraphs at end, since its due to an extension)
 * @param a A DashDraftJSONContent to compare
 * @param b A DashDraftJSONContent to compare
 * @returns True if the content is equal
 */
function isContentEqual(a?: DashDraftJSONContent, b?: DashDraftJSONContent) {
  const aContentClean = _.isNil(a?.content) ? [] : a.content;
  const bContentClean = _.isNil(b?.content) ? [] : b.content;

  const aContent = cleanContent(removeTrailingParagraphs(aContentClean));
  const bContent = cleanContent(removeTrailingParagraphs(bContentClean));
  const aText = !_.isNil(a) ? generateHTML({ ...a, content: aContent }) : '';
  const bText = !_.isNil(b) ? generateHTML({ ...b, content: bContent }) : '';

  return _.isEqual(aText, bText);
}

/**
 * Clean the content of the JoggrDoc
 * @param content A DashDraftJSONContent array
 * @returns A DashDraftJSONContent array with the content cleaned
 */
function cleanContent(content: DashDraftJSONContent[]): DashDraftJSONContent[] {
  return (
    _.chain(content)
      // Handle cleaning IDs
      .map((node) => {
        return (
          match(node)
            // We ignore the attributes around TOC Ids
            .with({ type: 'heading' }, () => ({
              ...node,
              attrs: { level: node.attrs?.level ?? 1 },
            }))
            // We generally ignore the attributes around ids (as they are not deterministic)
            .with({ attrs: { id: P.string } }, () => ({
              ...node,
              attrs: { id: undefined },
            }))
            .otherwise(() => node)
        );
      })
      // Handle cleaning the content of emojis
      .map((node) => {
        return match(node)
          .with({ type: 'paragraph' }, () => cleanTextContent(node))
          .with({ type: 'heading' }, () => cleanTextContent(node))
          .otherwise(() => node);
      })
      // Handle cleaning content recursively
      .map((node) => {
        return match(node)
          .with({ content: P.array(P.any) }, () => ({
            ...node,
            content: cleanContent(node.content ?? []),
          }))
          .otherwise(() => node);
      })
      .value()
  );
}

/**
 * Remove trailing paragraphs from a DashDraftJSONContent array
 * @param content A DashDraftJSONContent array
 * @returns A DashDraftJSONContent array with trailing paragraphs removed
 */
function removeTrailingParagraphs(content: DashDraftJSONContent[]) {
  const [last, ...rest] = _.reverse([...content]);
  if (last.type === 'paragraph' && _.isNil(last.content)) {
    return _.reverse([...rest]);
  }
  return content;
}

/**
 * Clean the content of the JoggrDoc for emojis. We have to do this because the emoji extension
 * will update the doc after loading the content.
 * @param content A DashDraftJSONContent array
 * @returns A DashDraftJSONContent array with the content cleaned
 */
function cleanTextContent(node: DashDraftJSONContent) {
  if (node.content) {
    return {
      ...node,
      content: _.flatten((node.content ?? []).map(cleanEmojiContent)),
    };
  }

  return node;
}

/**
 * Clean the content of the JoggrDoc for emojis. We have to do this because the emoji extension
 * will update the doc after loading the content.
 * @param content A DashDraftJSONContent array
 * @returns A DashDraftJSONContent array with the content cleaned
 */
function cleanEmojiContent(
  content: DashDraftJSONContent
): DashDraftJSONContent[] {
  const { type, text } = content;

  if (type === 'text' && text) {
    const words =
      text.split(
        /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g
      ) ?? [];
    const results: DashDraftJSONContent[] = [];

    for (const word of words) {
      const foundEmoji = findEmoji(word);
      if (foundEmoji) {
        results.push({ type: 'emoji', attrs: { name: foundEmoji?.name } });
      } else if (word) {
        results.push({ type: 'text', text: word });
      }
    }

    if (results.length > 0) {
      return results;
    }
  }

  return [content];
}

/**
 * Find an emoji in the gitHubEmojis array
 * @param text A string
 * @returns An emoji object
 */
function findEmoji(text: string) {
  return gitHubEmojis.find(
    (ghEmoji) => ghEmoji.emoji === text || `:${ghEmoji.name}:` === text
  );
}
