import { type ThemePaletteColor, createTheme } from '@joggrdocs/riker-theme';
import { colors } from '@mui/material';
import {
  type PaletteOptions as MuiPalette,
  type TypeBackground as MuiThemePaletteBackground,
  darken,
} from '@mui/material/styles';
import { match } from 'ts-pattern';

import type { ThemeMode } from '../types';

export interface ThemeBackground {
  dashboardTopBar: string;
  dashboardSideBar: string;
  dashboardMain: string;
}

export interface ThemeCodeEditorColorPalette {
  background: string;
  textSelection: string;
}

export interface ThemeCustomPalette extends Omit<MuiPalette, 'background'> {
  generate: ThemePaletteColor;
  codeEditor: ThemeCodeEditorColorPalette;
  background: ThemeBackground & MuiThemePaletteBackground;
}

/**
 * Create a palette for a given theme mode, that supports CSS variables.
 *
 * @param mode A theme mode.
 * @returns A valid palette, that supports CSS variables.
 */
export function createPalette(mode: ThemeMode): ThemeCustomPalette {
  const { palette } = createTheme(mode);

  const customPalette = match(mode)
    .with('dark', () => ({
      generate: {
        light: '#ED5FE3',
        main: '#e937dc',
        dark: '#A3269A',
        200: '#f180e7',
        800: '#9800bb',
        contrastText: palette.primary.contrastText,
      },
      codeEditor: {
        background: colors.blueGrey[900],
        textSelection: colors.blueGrey[700],
      },
      background: {
        dashboardTopBar: '#111936',
        dashboardSideBar: '#111936',
        dashboardMain: palette.background.paper,
        ...palette.background,
      },
    }))
    .with('light', () => ({
      generate: {
        light: '#E733DE',
        main: '#e100d6',
        dark: '#9D0095',
        200: '#f08de7',
        800: '#a000c1',
        contrastText: palette.background.paper,
      },
      codeEditor: {
        background: palette.background.paper,
        textSelection: palette.grey[200],
      },
      background: {
        dashboardTopBar: darken(palette.grey[50], 0.025),
        dashboardSideBar: darken(palette.grey[50], 0.025),
        dashboardMain: palette.background.paper,
        ...palette.background,
      },
    }))
    .exhaustive();

  return {
    mode,
    common: palette.common,
    primary: palette.primary,
    secondary: palette.secondary,
    error: palette.error,
    warning: palette.warning,
    info: palette.info,
    success: palette.success,
    grey: palette.grey,
    text: palette.text,
    divider: palette.divider,
    action: palette.action,
    ...customPalette,
  };
}

declare module '@mui/material/styles' {
  interface Palette {
    generate: ThemePaletteColor;
    codeEditor: ThemeCodeEditorColorPalette;
  }
  interface TypeBackground extends ThemeBackground {}
}
