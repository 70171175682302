import { Card, type CardProps, useTheme } from '@mui/material';
import React from 'react';

import { createComponentClasses } from '@stargate/theme';

export const codeCardClasses = createComponentClasses('CodeCard');

export type CodeCardProps = CardProps;

/**
 * Card component for code.
 */
export const CodeCard = React.forwardRef<HTMLDivElement, CodeCardProps>(
  (props, ref) => {
    const theme = useTheme();
    return (
      <Card
        {...props}
        ref={ref}
        classes={codeCardClasses}
        sx={{
          ...props.sx,
          // We need to allow DropdownMenu to overflow
          overflow: 'unset',
          padding: '2px', // removes the overlay since we want to round the border
          position: 'relative',
          width: '100%',
          border: `1px solid ${theme.palette.divider}`,
          backgroundColor: theme.palette.codeEditor.background,
        }}
      />
    );
  }
);
CodeCard.displayName = 'CodeCard';
