import { BubbleMenu as BaseBubbleMenu, type Editor } from '@tiptap/react';
import _ from 'lodash';
import React from 'react';
import TablerIconTrash from '~icons/tabler/trash';

import { isTableSelected } from '@stargate/lib/dashdraft/extensions/table/extensions/utils';
import {
  BubbleMenuIconButtonGroup,
  type BubbleMenuIconButtonGroupProps,
} from '../helpers/BubbleMenuIconButtonGroup';
import type { ShouldShowBubbleMenuFunc } from '../types';
import { isColumnGripSelected, isRowGripSelected } from './utils';

export const TableMenu = React.memo(
  ({ editor }: { editor: Editor }): JSX.Element => {
    /*
    |------------------
    | Handlers
    |------------------
   */

    const shouldShow: ShouldShowBubbleMenuFunc = ({ view, state, from }) => {
      if (_.isNil(state) || _.isNil(from)) {
        return false;
      }

      return _.every([
        isTableSelected(state.selection),
        editor.isEditable,
        !isRowGripSelected({ editor, view, state, from }),
        !isColumnGripSelected({ editor, view, state, from }),
      ]);
    };

    /*
    |------------------
    | Computed
    |------------------
    */

    const actions = React.useMemo<
      BubbleMenuIconButtonGroupProps['buttons']
    >(() => {
      return [
        {
          description: 'Delete Table',
          icon: <TablerIconTrash />,
          color: 'error',
          onClick: () => editor.chain().focus().deleteTable().run(),
        },
      ];
    }, [editor]);

    return (
      <BaseBubbleMenu
        pluginKey='dashdraft-table-menu'
        editor={editor}
        shouldShow={shouldShow}
        updateDelay={0}
        tippyOptions={{
          placement: 'bottom',
          offset: [0, 16],
        }}
      >
        <BubbleMenuIconButtonGroup buttons={actions} />
      </BaseBubbleMenu>
    );
  }
);
TableMenu.displayName = 'TableMenu';
