import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  type DialogProps,
  Divider,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';

import { IconAvatar } from '@/components/Avatars';
import { DialogTitle } from '@/components/dialog';
import { FormattedMessage } from '@/localization';

import type { JDocComponentProps } from '../../types';

export type JoggrDocDeleteDialogProps = JDocComponentProps<
  DialogProps & {
    /**
     * The loading state of the delete action.
     */
    loading?: boolean;

    /**
     * The error state of the delete action.
     */
    error?: Error;

    /**
     * Callback to close the dialog.
     */
    onClose: () => void;

    /**
     * Callback to delete the document.
     */
    onDelete: () => void;
  }
>;

/**
 * Confirmation dialog for deleting a document.
 */
export const JoggrDocDeleteDialog: React.FC<JoggrDocDeleteDialogProps> = (
  props
) => {
  const [deleteConfirmation, setDeleteConfirmation] = React.useState('');

  /*
  |------------------
  | Callbacks
  |------------------
  */

  const handleConfirmationChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDeleteConfirmation(event.target.value);
  };

  const handleDelete = () => {
    setDeleteConfirmation('');
    props.onDelete();
  };

  const handleClose = () => {
    setDeleteConfirmation('');
    props.onClose();
  };

  if (!props.doc?.title || !props.doc?.id) {
    return null;
  }

  return (
    <Dialog open={props.open} onClose={handleClose}>
      <DialogTitle
        avatar={<IconAvatar icon='file-x' />}
        title='Delete JoggrDoc'
        onClose={handleClose}
      />
      {props.error && (
        <React.Fragment>
          <DialogContent>
            <Alert severity='error'>
              <Typography variant='body1'>
                Sorry we were unable to delete the JoggrDoc:{' '}
                <strong>{props.doc.title}</strong>. Please contact support for
                assistance.
              </Typography>
            </Alert>
          </DialogContent>
          <DialogActions sx={{ px: 3 }}>
            <Button onClick={handleClose} fullWidth>
              <FormattedMessage id='generic.close' />
            </Button>
          </DialogActions>
        </React.Fragment>
      )}
      {!props.error && (
        <React.Fragment>
          <Alert severity='warning' sx={{ borderRadius: 0, mb: 2 }}>
            <Typography variant='body1'>
              This action cannot be undone, please read the following carefully.
            </Typography>
          </Alert>
          <DialogContent sx={{ pt: 0 }}>
            <Typography variant='body1' gutterBottom>
              Are you sure you want to permanently delete the JoggrDoc:{' '}
              <strong>{props.doc.title}</strong>?
            </Typography>
            <Typography variant='body1' gutterBottom>
              You will still have access to the Markdown file in the repository,
              but it will no longer be accessible from Joggr.
            </Typography>
            <Divider sx={{ my: 1 }} />
            <Typography variant='body1' sx={{ mb: 1.5 }}>
              Please type DELETE to confirm you want to delete this JoggrDoc.
            </Typography>
            <TextField
              fullWidth
              placeholder='Type DELETE to confirm'
              onChange={handleConfirmationChange}
            />
          </DialogContent>
          <DialogActions sx={{ px: 3, pt: 0, pb: 2 }}>
            <Button
              disabled={deleteConfirmation !== 'DELETE'}
              loading={props.loading}
              sx={{
                textTransform: 'none',
              }}
              onClick={handleDelete}
              fullWidth
            >
              <React.Fragment>
                Delete:&nbsp;<strong>{props.doc.title}</strong>
              </React.Fragment>
            </Button>
          </DialogActions>
        </React.Fragment>
      )}
    </Dialog>
  );
};
