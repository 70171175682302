import { Box, Popper, useTheme } from '@mui/material';
import React from 'react';
import type { SetOptional, Simplify } from 'type-fest';

import { useMountEffect } from '@react-hookz/web';
import { useQuackr } from '../hooks/use-quackr';
import type { QuackrConfig } from '../types';
import { QuackrButton, quackrButtonClassNames } from './quackr-button';
import { QuackrConfiguration } from './quackr-configuration';

export const quackrClassNames = {
  root: 'Quackr-root',
  popper: 'Quackr-popper',
};

export type QuackrRequiredTool = 'sentry' | 'frigade' | 'tanstack';

export type QuackrProps<K extends string = QuackrRequiredTool> = Simplify<
  {
    /**
     * If the Quackr component is enabled by default
     */
    defaultEnabled?: boolean;
  } & Omit<SetOptional<QuackrConfig<K>, 'placement' | 'enabled'>, 'enabled'>
>;

export const Quackr: React.FC<QuackrProps> = (config) => {
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [state, actions] = useQuackr();
  const theme = useTheme();

  const popperPlacement = React.useMemo(() => {
    switch (state.placement) {
      case 'top-left':
        return 'top-start';
      case 'top-right':
        return 'top-end';
      case 'bottom-left':
        return 'bottom-start';
      case 'bottom-right':
        return 'bottom-end';
      default:
        return 'bottom-end';
    }
  }, [state.placement]);

  useMountEffect(() => {
    if (config.placement && config.placement !== state.placement) {
      actions.setPlacement(config.placement);
    }

    if (config.devtools !== null && state.devtools === null) {
      actions.setDevtoolConfig(config.devtools);
    }

    if (config.defaultEnabled === true && state.enabled === false) {
      actions.enable('all');
    } else if (config.defaultEnabled === false && state.enabled === true) {
      setOpen(false);
      setAnchorEl(null);
      actions.disable('all');
    }
  });

  React.useEffect(() => {
    if (state.enabled === false) {
      setOpen(false);
      setAnchorEl(null);
    }
  }, [state.enabled]);

  if (state.enabled !== true) {
    return null;
  }

  return (
    <Box
      className={quackrClassNames.root}
      sx={{
        [`&.${quackrClassNames.root}`]: {
          [`& .${quackrButtonClassNames.root}`]: {
            zIndex: theme.zIndex.drawer + 1,
          },
          [`& .${quackrClassNames.popper}`]: {
            zIndex: theme.zIndex.drawer + 1,
          },
        },
      }}
    >
      <QuackrButton
        placement={state.placement ?? 'bottom-right'}
        onClick={(e) => {
          setOpen((prev) => !prev);
          setAnchorEl(e.currentTarget as HTMLElement);
        }}
      />
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement={popperPlacement}
        className={quackrClassNames.popper}
        disablePortal
      >
        <QuackrConfiguration
          config={{
            placement: state.placement ?? 'bottom-right',
            devtools: config.devtools,
            actionLink: config.actionLink,
            quickLinks: config.quickLinks,
          }}
        />
      </Popper>
    </Box>
  );
};
