import { Button, Tooltip } from '@mui/material';
import React from 'react';

import type { QuackrQuickLinkConfig } from '../types';
import { QuackrAvatar } from './quackr-avatar';

export type QuackrActionLinkProps = Required<QuackrQuickLinkConfig>;

export const QuackrActionLink: React.FC<QuackrActionLinkProps> = ({
  title,
  description,
  avatar,
  url,
}) => {
  return (
    <Tooltip title={description} placement='bottom-start' arrow>
      <Button
        startIcon={<QuackrAvatar avatar={avatar} size={24} />}
        href={url}
        variant='contained'
        color='primary'
        size='small'
        rel='noopener noreferrer'
        target='_blank'
      >
        {title}
      </Button>
    </Tooltip>
  );
};
