import { Box, Stack } from '@mui/material';
import _ from 'lodash';
import React from 'react';

import {
  GitHubBrandAvatar,
  GitHubChip,
  useGitHubUrls,
} from '@stargate/features/github';
import { createComponentClasses } from '@stargate/theme';

import type { JDocGitHub } from '../../types';

export const joggrDocFileLocationChipClasses = createComponentClasses(
  'JoggrDocFileLocationChip',
  [
    'root',
    'disabled',
    'content',
    'iconAvatar',
    'icon',
    'titleContainer',
    'title',
    'subtitle',
  ] as const
);

export interface JoggrDocFileLocationChipProps {
  /**
   * The JoggrDoc's GitHub metadata.
   */
  github?: Partial<JDocGitHub>;

  /**
   * If the chip is read-only.
   */
  readonly: boolean;

  /**
   * Callback that triggers when the chip is clicked.
   */
  onClick?: () => void;

  /**
   * Override the content of the label.
   */
  overrideLabel?: string;
}

export const JoggrDocFileLocationChip: React.FC<
  JoggrDocFileLocationChipProps
> = ({ onClick, readonly, github }) => {
  const ghUrls = useGitHubUrls();

  const githubUrl = React.useMemo(() => {
    if (
      _.isNil(github) ||
      _.isNil(github.repository) ||
      _.isNil(github.filePath) ||
      _.isNil(github.branch)
    ) {
      return null;
    }

    return ghUrls.file({
      repo: github.repository.name,
      owner: github.repository.owner.login,
      path: github.filePath,
      branch: github.branch,
    });
  }, [github, ghUrls]);

  const filePath = React.useMemo(() => {
    if (_.isNil(github) || _.isNil(github.filePath)) {
      return null;
    }

    const parts = _.trimStart(github.filePath, '/').split('/');
    if (parts.length <= 2) {
      return github.filePath;
    }

    const first = _.first(parts);
    const last = _.last(parts);
    return [first, '...', last].join('/');
  }, [github]);

  const handleClick = React.useCallback(
    (e: React.SyntheticEvent) => {
      if (!readonly && onClick) {
        e.preventDefault();
        onClick();
      }
    },
    [onClick, readonly]
  );

  return (
    <GitHubChip
      shape='rounded'
      url={githubUrl ?? undefined}
      onClick={handleClick}
      renderAvatar={() => (
        <GitHubBrandAvatar
          classes={{
            root: joggrDocFileLocationChipClasses.iconAvatar,
            icon: joggrDocFileLocationChipClasses.icon,
          }}
          variant='rounded'
        />
      )}
      classes={{
        root: joggrDocFileLocationChipClasses.root,
      }}
      sx={{
        [`&.${joggrDocFileLocationChipClasses.root}`]: {
          width: '100%',
          height: '36px',
          [`& .${joggrDocFileLocationChipClasses.iconAvatar}`]: {
            width: '28px',
            height: '28px',
            fontSize: '16px',
            bgcolor: 'black',
            color: 'white',
          },
          [`& .${joggrDocFileLocationChipClasses.titleContainer}`]: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            width: '100%',
          },
          [`& .${joggrDocFileLocationChipClasses.titleContainer}`]: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            width: '100%',
          },
          [`& .${joggrDocFileLocationChipClasses.title}`]: {
            fontWeight: 'bold',
            fontSize: '16px',
            lineHeight: '1.15',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            width: '100%',
          },
          [`& .${joggrDocFileLocationChipClasses.subtitle}`]: {
            fontSize: '12px',
            lineHeight: '1.15',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            width: '100%',
          },
        },
      }}
      label={
        <Stack
          className={joggrDocFileLocationChipClasses.titleContainer}
          direction='column'
          spacing={0}
          alignItems='flex-start'
          justifyContent='center'
        >
          {github?.repository ? (
            <React.Fragment>
              <Box className={joggrDocFileLocationChipClasses.title}>
                {github.repository.name}
              </Box>
              <Box className={joggrDocFileLocationChipClasses.subtitle}>
                {filePath}
              </Box>
            </React.Fragment>
          ) : (
            <Box className={joggrDocFileLocationChipClasses.title}>
              No GitHub File Location (repository & filepath) selected
            </Box>
          )}
        </Stack>
      }
    />
  );
};
