import { RikerIcon, type RikerIconName } from '@joggrdocs/riker-icons';
import {
  Box,
  Card,
  InputAdornment,
  MenuItem,
  Stack,
  Tab,
  Tabs,
  TextField,
  type TextFieldProps,
  Tooltip,
  Typography,
  darken,
  lighten,
  tabClasses,
  useTheme,
} from '@mui/material';
import * as hookz from '@react-hookz/web';
import _ from 'lodash';
import React from 'react';

import { createComponentClasses } from '@stargate/theme';
import { cn } from '@stargate/utils/styles';

import {
  emojiIcons,
  emojiIconsByCategory,
  rikerIcons,
  rikerIconsByCategory,
} from '../data';
import type { IconDefinition, IconType } from '../types';
import { IconEmoji } from './IconPickerIcon/IconEmoji';
import { IconRiker } from './IconPickerIcon/IconRiker';

/*
|==========================================================================
| IconPicker
|==========================================================================
|
| Pick an icon.
|
*/

export const iconPickerClasses = createComponentClasses('IconPicker', [
  'root',
  'filters',
  'list',
  'listRow',
  'listRowVisible',
  'listCategoryRow',
  'listCategoryTitle',
  'listIconRow',
  'listIcon',
  'listIconEmoji',
  'listIconRiker',
  'listIconDisabled',
]);

/*
|------------------
| Utils
|------------------
*/

/**
 * Filter the icons based on the filter string.
 *
 * @param icons A list of icons.
 * @param filter A filter string.
 * @returns A list of icons that match the filter.
 */
const filterIcons = (
  icons: IconDefinition[],
  filter: string
): IconDefinition[] => {
  if (!filter) return icons;

  return _.filter(icons, (icon) => {
    const filterValue = filter.toLowerCase();
    return _.some([
      icon.name.toLowerCase().includes(filterValue),
      _.some(icon.tags, (tag) => tag.toLowerCase().includes(filterValue)),
    ]);
  });
};

/*
|------------------
| Components
|------------------
*/

type IconRow =
  | {
      type: 'icon';
      icons: IconDefinition[];
    }
  | {
      type: 'title';
      category: string;
    };

const IconPickerRow: React.FC<{
  viewPortEl: HTMLDivElement;
  children: () => React.ReactNode;
}> = (props) => {
  const theme = useTheme();
  const elementRef = React.useRef<HTMLDivElement>(null);
  const intersection = hookz.useIntersectionObserver(elementRef, {
    root: props.viewPortEl,
  });
  const [isIntersecting, setIsIntersecting] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (intersection?.isIntersecting === true) {
      setIsIntersecting(true);
    }
  }, [intersection?.isIntersecting]);

  return (
    <Box
      ref={elementRef}
      sx={{
        minHeight: '36px',
        width: '100%',
      }}
      className={cn(iconPickerClasses.listRow, {
        [iconPickerClasses.listRowVisible]: isIntersecting,
      })}
    >
      {isIntersecting && props.children()}
    </Box>
  );
};

/*
|------------------
| Public API
|------------------
*/

export interface IconPayload {
  icon: string;
  iconType: IconType;
}

export interface IconPickerProps {
  /**
   * The pre-selected icon.
   */
  icon?: string;

  /**
   * The pre-selected icon type.
   */
  iconType?: IconType;

  /**
   * Callback for when an icon is selected.
   *
   * @param data The icon data.
   */
  onSelect: (data: IconPayload) => void;

  /**
   * Callback for when the icon is cleared.
   */
  onClear: () => void;
}

export const IconPicker: React.FC<IconPickerProps> = ({
  onSelect,
  ...props
}) => {
  const theme = useTheme();
  const [viewPortEl, setViewPortEl] = React.useState<HTMLDivElement>();
  const [iconType, setIconType] = React.useState<IconType>(
    props.iconType ?? 'emoji'
  );
  const [textFilter, setTextFilter] = React.useState<string>('');
  const [categoryFilter, setCategoryFilter] = React.useState<string>('All');

  /*
  |------------------
  | Computed
  |------------------
  */

  const iconData = React.useMemo(() => {
    const rowCount = 11;
    const icons = iconType === 'emoji' ? emojiIcons : rikerIcons;
    const iconsByCategory =
      iconType === 'emoji' ? emojiIconsByCategory : rikerIconsByCategory;

    return {
      icons,
      iconRows: _.reduce(
        iconsByCategory,
        (result, value, key) => {
          // Skip if the category filter is not 'All' and the category does not match.
          if (categoryFilter !== 'All' && categoryFilter !== value.category)
            return result;
          const filteredIcons = filterIcons(value.icons, textFilter);

          if (filteredIcons.length !== 0) {
            result.push(
              {
                type: 'title',
                category: value.category,
              },
              ...(_.chunk(filteredIcons, rowCount).map((icons) => ({
                type: 'icon',
                icons,
              })) as IconRow[])
            );
          }

          return result;
        },
        [] as IconRow[]
      ),
      categories: _.map(iconsByCategory, 'category'),
    };
  }, [iconType, textFilter, categoryFilter]);

  /*
  |------------------
  | Handlers
  |------------------
  */

  const handleTextFilterChange: Required<TextFieldProps>['onChange'] = (e) => {
    setTextFilter(e.target.value);
  };

  const handleClear = () => {
    props.onClear();
  };

  const handleCategoryFilterChange: Required<TextFieldProps>['onChange'] = (
    e
  ) => {
    setCategoryFilter(e.target.value);
  };

  const handleIconTypeChange = (e: any, iconType: IconType) => {
    viewPortEl?.scrollTo(0, 0);
    setIconType(iconType);
  };

  const createIconClickHandler = (icon: string, iconType: IconType) => {
    return () => {
      onSelect({
        icon,
        iconType,
      });
    };
  };

  return (
    <Card
      className={iconPickerClasses.root}
      sx={{
        maxWidth: '480px',
        bgcolor: theme.palette.background.paper,
        [`&.${iconPickerClasses.root} .${tabClasses.root}`]: {
          fontSize: '16px',
          textTransform: 'none',
          px: 2,
          py: 1,
        },
        [`&.${iconPickerClasses.root} .${iconPickerClasses.filters}`]: {
          px: 1.5,
          pt: 2,
          pb: 1,
        },
        [`&.${iconPickerClasses.root} .${iconPickerClasses.list}`]: {
          maxWidth: '480px',
          height: '364px',
          overflowY: 'auto',
          py: 2,
          px: 1.5,
        },
        [`&.${iconPickerClasses.root} .${iconPickerClasses.listCategoryRow}`]: {
          pt: 2,
          pb: 1,
        },
        [`&.${iconPickerClasses.root} .${iconPickerClasses.listCategoryTitle}`]:
          {
            fontWeight: 'bold',
            color: theme.palette.text.secondary,
          },
        [`&.${iconPickerClasses.root} .${iconPickerClasses.listIconRow}`]: {
          width: '100%',
        },
        [`&.${iconPickerClasses.root} .${iconPickerClasses.listIcon}`]: {
          px: 0.5,
          py: 1,
          width: '40px',
          textAlign: 'center',
          borderRadius: 1,
          fontSize: theme.spacing(2.5),
          '&:hover': {
            cursor: 'pointer',
            color: theme.palette.primary.main,
            bgcolor:
              theme.palette.mode === 'dark'
                ? lighten(theme.palette.background.paper, 0.2)
                : darken(theme.palette.background.paper, 0.2),
          },
        },
        [`&.${iconPickerClasses.root} .${iconPickerClasses.listIconDisabled}`]:
          {
            bgcolor: theme.palette.action.disabledBackground,
          },
      }}
    >
      <Stack
        direction='row'
        alignItems='center'
        sx={{
          borderBottom: `1px solid ${theme.palette.divider}`,
        }}
      >
        <Tabs value={iconType} onChange={handleIconTypeChange}>
          <Tab label='Emojis' value='emoji' />
          <Tab label='Icons' value='riker' />
        </Tabs>
        <Box sx={{ flexGrow: 1 }} />
        <Box
          onClick={handleClear}
          sx={{
            px: 2,
            py: 1,
            '&:hover': {
              cursor: 'pointer',
              color: theme.palette.primary.main,
            },
          }}
        >
          Remove
        </Box>
      </Stack>
      <Stack
        direction='row'
        alignItems={'center'}
        spacing={1}
        className={iconPickerClasses.filters}
      >
        <TextField
          size='small'
          onChange={handleTextFilterChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <RikerIcon icon='filter-search' />
              </InputAdornment>
            ),
          }}
          placeholder={`Search ${iconType === 'emoji' ? 'emoji' : 'icon'}s...`}
          fullWidth
        />
        <TextField
          select
          onChange={handleCategoryFilterChange}
          size='small'
          defaultValue='All'
          label='Category'
          sx={{ minWidth: '180px' }}
        >
          {_.map(['All', ...iconData.categories], (category) => (
            <MenuItem key={category} value={category}>
              {category}
            </MenuItem>
          ))}
        </TextField>
      </Stack>
      <Stack
        ref={(el) => {
          if (!el) return;
          setViewPortEl(el);
        }}
        className={iconPickerClasses.list}
        direction='row'
        alignItems='flex-start'
        alignContent='flex-start'
        justifyContent='flex-start'
        spacing={0}
        flexWrap='wrap'
        useFlexGap
      >
        {viewPortEl &&
          iconData.iconRows.map((row, index) => {
            if (row.type === 'title') {
              return (
                <IconPickerRow key={row.category} viewPortEl={viewPortEl}>
                  {() => (
                    <Box className={iconPickerClasses.listCategoryRow}>
                      <Typography
                        variant='h6'
                        className={iconPickerClasses.listCategoryTitle}
                        gutterBottom
                      >
                        {row.category}
                      </Typography>
                    </Box>
                  )}
                </IconPickerRow>
              );
            }
            if (row.type === 'icon') {
              return (
                <IconPickerRow key={index} viewPortEl={viewPortEl}>
                  {() => (
                    <Stack
                      direction='row'
                      className={iconPickerClasses.listIconRow}
                    >
                      {row.icons.map((icon) => (
                        <Tooltip
                          key={icon.id}
                          title={icon.name}
                          enterDelay={500}
                          enterNextDelay={500}
                        >
                          <Box
                            data-icon={icon.value}
                            className={cn({
                              [iconPickerClasses.listIcon]: true,
                              [iconPickerClasses.listIconEmoji]:
                                iconType === 'emoji',
                              [iconPickerClasses.listIconRiker]:
                                iconType === 'riker',
                              [iconPickerClasses.listIconDisabled]:
                                iconType === props.iconType &&
                                icon.value === props.icon,
                            })}
                            onClick={createIconClickHandler(
                              icon.value,
                              iconType
                            )}
                          >
                            {iconType === 'emoji' ? (
                              <IconEmoji emoji={icon.value} />
                            ) : (
                              <IconRiker icon={icon.value as RikerIconName} />
                            )}
                          </Box>
                        </Tooltip>
                      ))}
                    </Stack>
                  )}
                </IconPickerRow>
              );
            }
            return null;
          })}

        {viewPortEl && iconData.iconRows.length === 0 && (
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='center'
            spacing={1}
            sx={{
              p: 1,
            }}
          >
            <RikerIcon
              icon='mood-sad'
              fill={lighten(theme.palette.primary.dark, 0.5)}
              color={theme.palette.primary.dark}
              stroke={2}
              size={24}
            />
            <Typography variant='body1'>
              Sorry no {iconType === 'emoji' ? 'emojis' : 'icons'} found.
            </Typography>
          </Stack>
        )}
      </Stack>
    </Card>
  );
};
