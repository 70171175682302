import React from 'react';

import {
  Button,
  type ButtonProps,
  type SxProps,
  Tooltip,
  darken,
  useTheme,
} from '@mui/material';

export interface BubbleMenuIconButtonProps {
  selected: boolean;
  description: string;
  icon: React.ReactNode;
  color?: ButtonProps['color'];
  onClick: () => void;
  disabled?: boolean;
  sx?: SxProps;
}

export const BubbleMenuIconButton: React.FC<BubbleMenuIconButtonProps> = ({
  selected = false,
  icon,
  description,
  onClick,
  disabled = false,
  color = 'primary',
  sx,
}) => {
  const theme = useTheme();

  return (
    <Tooltip title={description} placement='top' enterDelay={750}>
      <Button
        size='small'
        variant='outlined'
        color={color}
        sx={{
          ...sx,
          '&:hover, &:active, &:focus': {
            ...theme.applyStyles('light', {
              bgcolor: darken(theme.palette.background.paper, 0.125),
            }),
            ...theme.applyStyles('dark', {
              bgcolor: theme.palette.action.hover,
            }),
          },
          '&.selected': {
            ...theme.applyStyles('light', {
              bgcolor: darken(theme.palette.background.paper, 0.15),
            }),
            ...theme.applyStyles('dark', {
              bgcolor: theme.palette.action.selected,
            }),
          },
        }}
        onClick={onClick}
        disabled={disabled}
        className={selected ? 'selected' : ''}
      >
        {icon}
      </Button>
    </Tooltip>
  );
};
BubbleMenuIconButton.displayName = 'BubbleMenuIconButton';
