import { RikerIcon } from '@joggrdocs/riker-icons';
import {
  Avatar,
  Badge,
  Box,
  Card,
  CardHeader,
  Divider,
  Link,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import _ from 'lodash';
import React from 'react';
import TablerIconCircleCheck from '~icons/tabler/circle-check';
import TablerIconCircleX from '~icons/tabler/circle-x';

import { IconAvatar } from '@stargate/components/Avatars';
import type { User } from '@stargate/features/user';

export interface UserProfileProps {
  authenticatedUser: User;
}

function UserProfile(props: UserProfileProps) {
  const { authenticatedUser } = props;
  const theme = useTheme();
  return (
    <React.Fragment>
      <Box sx={{ flexGrow: 1, bgcolor: 'transparent', display: 'flex' }}>
        <Avatar
          alt={authenticatedUser.name}
          src={authenticatedUser.picture}
          sx={{
            width: 80,
            height: 80,
          }}
        />
        <Stack sx={{ ml: 1, mt: 'auto', mb: 'auto' }}>
          <Typography variant='h3' gutterBottom>
            {authenticatedUser.name}
          </Typography>
          <Stack
            direction='row'
            spacing={1}
            divider={<Divider orientation='vertical' flexItem />}
          >
            <Stack direction='row' spacing={0.5} alignItems='center'>
              {authenticatedUser.emailVerified && (
                <TablerIconCircleCheck
                  style={{
                    verticalAlign: 'bottom',
                    color: theme.palette.success.main,
                    fontSize: '16px',
                  }}
                />
              )}
              {!authenticatedUser.emailVerified && (
                <TablerIconCircleX style={{ verticalAlign: 'bottom' }} />
              )}
              <Typography>
                {authenticatedUser.emailVerified ? 'Verified' : 'Not Verified'}
              </Typography>
            </Stack>
            <Stack direction='row' spacing={0.5}>
              <RikerIcon icon='mail' />
              <span>{authenticatedUser.email}</span>
            </Stack>
            {authenticatedUser.githubUsername && (
              <Stack direction='row' spacing={0.5}>
                <RikerIcon icon='brand-github' />
                <Link
                  href={`https://github.com/${authenticatedUser.githubUsername}`}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {authenticatedUser.githubUsername}
                </Link>
              </Stack>
            )}
          </Stack>
        </Stack>
      </Box>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Box sx={{ mb: 2 }} />
      <UserIdentities authenticatedUser={authenticatedUser} />
    </React.Fragment>
  );
}

export default UserProfile;

/*
|------------------
| Internals
|------------------
*/

const UserIdentities: React.FC<{ authenticatedUser: User }> = ({
  authenticatedUser,
}) => {
  return (
    <React.Fragment>
      <Typography variant='h5' sx={{ mb: 1.5 }}>
        <Stack direction='row' spacing={1} alignItems='center' component='span'>
          <RikerIcon icon='shield-checkered-filled' />{' '}
          <span>Authentication Profiles</span>
        </Stack>
      </Typography>
      <Stack direction='row' spacing={1}>
        {_.chain(authenticatedUser.auth0Ids)
          .map((auth0Id) => {
            const [provider] = auth0Id.split('|');
            const [primaryProvider] = authenticatedUser.auth0Id.split('|');
            return {
              auth0Id,
              primary: primaryProvider === provider,
            };
          })
          .sortBy(({ primary }) => (primary ? -1 : 1))
          .map(({ auth0Id, primary }) => {
            return (
              <UserIdentity
                key={auth0Id}
                auth0Id={auth0Id}
                primary={primary}
                authenticatedUser={authenticatedUser}
              />
            );
          })
          .value()}
      </Stack>
    </React.Fragment>
  );
};

const UserIdentity: React.FC<{
  authenticatedUser: User;
  primary: boolean;
  auth0Id: string;
}> = ({ authenticatedUser, auth0Id, primary }) => {
  const theme = useTheme();
  const [provider] = auth0Id.split('|');
  const icon = provider === 'github' ? 'brand-github' : 'mail';
  const title = provider === 'github' ? 'GitHub' : 'Email';
  const subheader =
    provider === 'github'
      ? (authenticatedUser.githubUsername ?? 'Need to authorize with GitHub')
      : authenticatedUser.email;
  const description =
    provider === 'github'
      ? 'Your account is linked to a authentication profiles using your GitHub Account'
      : 'Your account is linked to a authentication profile using your email address';

  return (
    <Tooltip
      title={_.compact([
        primary ? (
          <React.Fragment key='primary'>
            <strong>Primary Profile</strong>
            <br />
          </React.Fragment>
        ) : (
          <React.Fragment key='primary'>
            <strong>Linked (secondary) Profile</strong>
            <br />
          </React.Fragment>
        ),
        description,
      ])}
      arrow
    >
      <Card
        sx={{
          backgroundColor: theme.palette.background.default,
          borderColor: theme.palette.divider,
        }}
      >
        <CardHeader
          sx={{ px: 1, py: 0.5 }}
          avatar={
            <Badge
              variant='standard'
              badgeContent={<RikerIcon icon='star-filled' size={14} />}
              invisible={!primary}
            >
              <IconAvatar icon={icon} size={28} />
            </Badge>
          }
          titleTypographyProps={{ variant: 'body1' }}
          title={title}
          subheader={subheader}
          subheaderTypographyProps={{ variant: 'caption' }}
        />
      </Card>
    </Tooltip>
  );
};
