import { match } from 'ts-pattern';
import type { DirectoryTree, DirectoryTreeNode } from '../types';

/**
 * Recursively find a node in a tree, by id.
 * @param tree A tree, to search for the node.
 * @param id The id of the node to find.
 * @returns The first node with a matching id, or null if no node was found.
 */
export const findNode = (
  tree: DirectoryTree,
  id: string
): DirectoryTreeNode | null => {
  const node = tree.find((item) => item.id === id);

  return node ?? null;
};

/**
 * Extract all node ids from a tree, recursively.
 * @param tree A tree.
 * @returns A list of node ids.
 */
export const extractNodeIds = (tree: DirectoryTree): string[] => {
  return tree.map((item) => item.id);
};

/**
 * Check if a node is a draft node.
 * @param node A node.
 * @returns Whether the node is a draft node.
 */
export function isDraftNode(node: DirectoryTreeNode) {
  return match(node)
    .with({ status: 'draft' }, () => true)
    .with({ status: 'local-draft' }, () => true)
    .otherwise(() => false);
}

/**
 * Check if a node is a draft PR node.
 * @param node A node.
 * @returns Whether the node is a draft PR node.
 */
export function isDraftPRNode(node: DirectoryTreeNode) {
  return node.status === 'draft';
}

/**
 * Check if a node is a local draft node.
 * @param node A node.
 * @returns Whether the node is a local draft node.
 */
export function isLocalDraftNode(node: DirectoryTreeNode) {
  return node.status === 'local-draft';
}
