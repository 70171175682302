import {
  Button,
  type ButtonProps,
  CircularProgress,
  buttonClasses,
} from '@mui/material';
import React from 'react';
import type * as tf from 'type-fest';
import TablerIconLayoutSidebarLeftExpand from '~icons/tabler/layout-sidebar-left-expand';
import TablerIconLayoutSidebarRightExpand from '~icons/tabler/layout-sidebar-right-expand';

export interface CodeExplorerFileTreeToggleProps extends BaseProps {
  /**
   * The open/close status of the file tree.
   */
  status: 'open' | 'close';
}

export const CodeExplorerFileTreeToggle: React.FC<
  CodeExplorerFileTreeToggleProps
> = ({ loading = false, onClick, status, disabled, ...props }) => {
  return (
    <Button
      {...props}
      disabled={loading || disabled}
      onClick={onClick}
      variant='contained'
      size='small'
      sx={{
        [`&.${buttonClasses.root}`]: {
          minWidth: '0 !important',
          px: 0.5,
        },
      }}
    >
      {loading && (
        <CircularProgress
          size={24}
          sx={{ color: (theme) => theme.palette.primary.contrastText }}
        />
      )}
      {!loading && (
        <React.Fragment>
          {status === 'close' && <TablerIconLayoutSidebarLeftExpand />}
          {status === 'open' && <TablerIconLayoutSidebarRightExpand />}
        </React.Fragment>
      )}
    </Button>
  );
};

/*
|------------------
| Utils
|------------------
*/

type BaseProps = tf.SetRequired<ButtonProps, 'onClick'>;
