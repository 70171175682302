import type {
  RikerIconComponent,
  RikerIconName,
  RikerIconProps,
} from '@joggrdocs/riker-icons';
import { Box, type BoxProps } from '@mui/material';
import React from 'react';
import type * as TypeFest from 'type-fest';

import { createComponentClasses } from '@/theme';
import { cn } from '@/utils/styles';

import type { IconType } from '../../types';
import { IconEmoji } from './IconEmoji';
import { IconRiker } from './IconRiker';

export const iconPickerIconClasses = createComponentClasses('IconPickerIcon', [
  'root',
  'emoji',
  'riker',
  'unknown',
] as const);

export type IconPickerIconProps = TypeFest.Simplify<
  Pick<BoxProps, 'sx' | 'className'> & {
    iconType: IconType;
    icon: string;
  } & Pick<RikerIconProps, 'size'>
>;

/**
 * Component for a IconPickerIcon that switches between icons based on the data passed to it.
 */
export const IconPickerIcon = React.forwardRef<
  HTMLDivElement | RikerIconComponent,
  IconPickerIconProps
>(({ icon, iconType, ...props }, ref) => {
  if (iconType === 'riker') {
    return (
      <IconRiker
        {...props}
        className={cn(
          iconPickerIconClasses.root,
          iconPickerIconClasses.riker,
          props.className
        )}
        ref={ref as React.RefObject<RikerIconComponent>}
        icon={icon as RikerIconName}
      />
    );
  }

  if (iconType === 'emoji') {
    return (
      <IconEmoji
        {...props}
        className={cn(
          iconPickerIconClasses.root,
          iconPickerIconClasses.emoji,
          props.className
        )}
        ref={ref as React.RefObject<HTMLDivElement>}
        emoji={icon}
      />
    );
  }

  return (
    <Box
      {...props}
      className={cn(
        iconPickerIconClasses.root,
        iconPickerIconClasses.unknown,
        props.className
      )}
      component='span'
      ref={ref as React.RefObject<HTMLDivElement>}
    >
      {icon}
    </Box>
  );
});
