import React from 'react';
import { mergeRefs } from 'react-merge-refs';
import * as usehooks from 'usehooks-ts';

export type Size = {
  /** The width of the observed element. */
  width: number | undefined;
  /** The height of the observed element. */
  height: number | undefined;
};

export interface UseResizeObserverHookOptions<
  Element extends HTMLElement = HTMLElement,
> {
  /**
   * The ref to the element to observe.
   */
  ref?: React.RefObject<Element> | React.ForwardedRef<Element>;

  /**
   * The callback to invoke when the element resizes.
   */
  onResize: (size: Size) => void;

  /**
   * The debounce time in milliseconds.
   *
   * @default 500
   */
  debounce?: number;
}

export type UseResizeObserverHook<Element extends HTMLElement> =
  React.RefCallback<Element>;

/**
 * A hook to observe the resize of an element and debounce the callback invocation for resizing.
 *
 * @param options A set of options for the hook.
 * @returns A ref callback to attach to the element to observe.
 */
export const useResizeObserver = <Element extends HTMLElement = HTMLElement>(
  options: UseResizeObserverHookOptions<Element>
): UseResizeObserverHook<Element> => {
  const ref = React.useRef<Element>(null);

  const onResize = usehooks.useDebounceCallback(
    options.onResize,
    options.debounce ?? 500
  );

  usehooks.useResizeObserver({
    ref: ref as unknown as React.RefObject<Element>,
    onResize,
  });

  return mergeRefs([ref, options.ref]);
};
