import type { Theme as MuiTheme } from '@mui/material';

export function createMixins(theme: MuiTheme) {
  return {
    // MuiDataGrid: {
    //   pinnedBackground: theme.palette.grey[800],
    //   containerBackground: theme.palette.background.paper,
    // },
  };
}
