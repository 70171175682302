import { Box, Button, Link, Stack } from '@mui/material';
import React from 'react';

import { RikerIcon } from '@joggrdocs/riker-icons';
import { useStaticUrls } from '@stargate/hooks';

import { OnboardingStep, type OnboardingStepItem } from '../OnboardingStep';

/*
|==========================================================================
| Welcome
|==========================================================================
|
| Provide a welcome message to the user and a button to start the onboarding, also
| provide links to the terms and conditions and privacy policy as they MUST accept it
| before continuing.
|
*/

export type OnboardingStepWelcomeProps = OnboardingStepItem;

export const OnboardingStepWelcome: React.FC<OnboardingStepWelcomeProps> = (
  props
) => {
  const staticUrls = useStaticUrls();
  return (
    <OnboardingStep
      {...props}
      title='Welcome to Joggr'
      icon='rocket'
      contentTitle="We're excited to have you on board."
      contentDescription='We will take you through a series of steps that will onboard you to the platform and allow you to start creating and viewing Joggr docs.'
      action={
        <Box>
          <Button
            onClick={() => {
              props.onContinue();
            }}
            variant='contained'
            startIcon={<RikerIcon icon='rocket' />}
            size='large'
            sx={{
              mb: 2,
            }}
          >
            Get Started
          </Button>
        </Box>
      }
      footer={
        <Stack
          direction='row'
          spacing={0.5}
          alignItems='center'
          justifyContent='center'
        >
          <Box>By clicking "Get Started" you agree to our</Box>
          <Link
            href={staticUrls.termsOfService}
            target='_blank'
            rel='noopener noreferrer'
          >
            Terms
          </Link>
          <Box>and</Box>
          <Link
            href={staticUrls.privacyPolicy}
            target='_blank'
            rel='noopener noreferrer'
          >
            Privacy Policy
          </Link>
        </Stack>
      }
    />
  );
};
