import React from 'react';

import { Box, type SxProps, useTheme } from '@mui/material';

import { LoadingOverlay } from '@stargate/components/Loading';
import PanZoom from '@stargate/components/Utils/PanZoom';
import RawHtml from '@stargate/components/Utils/RawHtml';

import type { MermaidGraphType } from '../lib/mermaidUtils';
import SirenaViewerFeedback from './SirenaViewerFeedback';

/*
|==========================================================================
| MermaidViewer
|==========================================================================
|
| This component renders a mermaid graph.
|
*/

/*
|------------------
| Public API
|------------------
*/

export interface SirenaViewerProps {
  graphType: MermaidGraphType | null;
  svg: string | null;
  error?: Error | null;
  loading?: boolean;
  sx?: SxProps;
  disablePanZoom?: boolean;
}

export const SirenaViewer = (
  props: SirenaViewerProps,
  ref: React.Ref<HTMLDivElement>
) => {
  const theme = useTheme();

  return (
    <Box
      ref={ref}
      className='sirena-viewer'
      sx={{
        bgcolor: 'background.paper',
        display: 'flex',
        flexGrow: 1,
        position: 'relative',
        height: '100%',
        width: '100%',
        flexDirection: 'column',
        p: props.disablePanZoom !== true ? 0 : 2,
        ...props.sx,
      }}
    >
      <LoadingOverlay loading={props.loading ?? false} variant='contained' />
      <SirenaViewerFeedback
        error={props.error ?? null}
        svg={props.svg ?? null}
        loading={props.loading ?? false}
        graphType={props.graphType}
      />
      {props.svg && (
        <React.Fragment>
          {props.disablePanZoom !== true && (
            <Box
              className='sirena-viewer-internal sirena-view-panzoom'
              sx={{
                textAlign: 'center',
                width: '100%',
                display: 'flex',
                flexGrow: 1,
                flex: 1,
              }}
            >
              <PanZoom
                contentStyle={{
                  padding: theme.spacing(2),
                  display: 'flex',
                  width: '100%',
                  height: '100%',
                  flex: 1,
                }}
                wrapperStyle={{
                  display: 'flex',
                  width: '100%',
                  height: '100%',
                  flex: 1,
                }}
              >
                <RawHtml
                  rawHtml={props.svg}
                  sx={{
                    width: '100%',
                    height: '100%',
                  }}
                  variant='block'
                />
              </PanZoom>
            </Box>
          )}
          {props.disablePanZoom === true && (
            <Box
              className='sirena-viewer-internal'
              sx={{
                textAlign: 'center',
                width: '100%',
              }}
            >
              <RawHtml rawHtml={props.svg} variant='inline' />
            </Box>
          )}
        </React.Fragment>
      )}
    </Box>
  );
};

const WrappedSirenaViewer = React.memo(
  React.forwardRef<HTMLDivElement, SirenaViewerProps>(SirenaViewer)
);
WrappedSirenaViewer.displayName = 'SirenaViewer';

export default WrappedSirenaViewer;
