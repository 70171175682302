import {
  Alert,
  AlertTitle,
  Dialog,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import React from 'react';

import { IconAvatar } from '@stargate/components/Avatars';
import { DialogTitle } from '@stargate/components/dialog';
import { useUIState } from '@stargate/stores';

import useInvite from '../hooks/use-invite';
import { SendInviteForm } from './SendInviteForm';

export const SendInviteDialog: React.FC = () => {
  const inviteUserModal = useUIState((state) => state.userInvitesDialog);
  const setInviteUserModal = useUIState((state) => state.setUserInvitesDialog);
  const invite = useInvite();

  /*
  |------------------
  | Handlers
  |------------------
  */

  const handleResetErrors = () => {
    invite.reset();
  };

  const handleClose = () => {
    setInviteUserModal({
      open: false,
    });
    invite.reset();
  };

  return (
    <Dialog open={inviteUserModal.open} onClose={handleClose}>
      <DialogTitle
        onClose={handleClose}
        startIcon={<IconAvatar icon={'send'} />}
        title='Invite your team'
        subTitle='Invite your team to join your workspace.'
      />
      <DialogContent>
        <DialogContentText>
          To send an invite, please enter the email address of the person you
          would like to invite.
        </DialogContentText>
        {invite.errors.length > 0 && (
          <Alert severity='error' sx={{ mt: 1 }}>
            <AlertTitle>Failed to send invites</AlertTitle>
            {invite.errors.join(', ')}
          </Alert>
        )}
        {invite.status === 'success' && invite.errors.length === 0 && (
          <Alert severity='success' sx={{ mt: 1 }}>
            <AlertTitle>Invites sent</AlertTitle>
            Your invites have been sent successfully.
          </Alert>
        )}
        <SendInviteForm
          onSendInvite={invite.submit}
          onReset={handleResetErrors}
          loading={invite.status === 'loading'}
        />
      </DialogContent>
    </Dialog>
  );
};

export default SendInviteDialog;
