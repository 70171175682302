import {
  RikerIcon,
  type RikerIconComponent,
  type RikerIconName,
  type RikerIconProps,
} from '@joggrdocs/riker-icons';
import { Tooltip, useTheme } from '@mui/material';
import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';
import React from 'react';
import { match } from 'ts-pattern';
import type * as TypeFest from 'type-fest';
import TablerIconExclamationCircle from '~icons/tabler/exclamation-circle';

import { createComponentClasses } from '@/theme';

export const iconRikerClasses = createComponentClasses('IconRiker', [
  'root',
] as const);

export type IconRikerProps = TypeFest.Simplify<
  Omit<RikerIconProps, 'icon'> & {
    icon: RikerIconName;
  }
>;

/**
 * Component for a Riker Icon
 */
export const IconRiker = React.forwardRef<RikerIconComponent, IconRikerProps>(
  ({ icon, ...props }, ref) => {
    const theme = useTheme();
    const iconProps = React.useMemo(
      () => ({
        stroke: '2.1',
        color: match(theme.palette.mode)
          .with('dark', () => theme.palette.primary.light)
          .with('light', () => theme.palette.primary.dark)
          .exhaustive(),
      }),
      [theme.palette]
    );
    return (
      <SentryErrorBoundary
        fallback={
          <Tooltip title='Unable to load the Icon'>
            <TablerIconExclamationCircle
              {...props}
              {...iconProps}
              ref={ref as unknown as React.RefObject<SVGSVGElement>}
            />
          </Tooltip>
        }
      >
        <RikerIcon
          {...props}
          {...iconProps}
          classes={iconRikerClasses}
          ref={ref}
          icon={icon}
        />
      </SentryErrorBoundary>
    );
  }
);
IconRiker.displayName = 'IconRiker';
