import _ from 'lodash';

import DashDraftAI, {
  type DashDraftExtensionsAIConfig,
} from '@dashdraft/extensions/ai';
import DashDraftAlertsExtension from '@dashdraft/extensions/alerts';
import DashDraftCodeBlockExtension from '@dashdraft/extensions/code-block';
import DashDraftCodeInlineExtension from '@dashdraft/extensions/code-inline';
import DashDraftCoreExtension from '@dashdraft/extensions/core';
import DashDraftDetailsExtension from '@dashdraft/extensions/details';
import DashDraftLinkExtension from '@dashdraft/extensions/link';
import DashDraftListsExtension from '@dashdraft/extensions/lists';
import DashDraftMediaExtension from '@dashdraft/extensions/media';
import DashDraftEmojiExtension from '@dashdraft/extensions/suggestions';
import DashDraftTableExtension from '@dashdraft/extensions/table';
import DashDraftTableOfContentsExtension from '@dashdraft/extensions/table-of-contents';
import DashDraftTypographyExtension from '@dashdraft/extensions/typography';
import DashDraftUtilitiesExtension from '@dashdraft/extensions/utilities';

export type DashDraftExtensionConfig =
  | boolean
  | DashDraftExtensionsConfigObject;

export type DashDraftExtensionsConfigObject<Options = unknown> = {
  /**
   * Whether the extension is enabled.
   *
   * @default true
   */
  enabled?: boolean;

  /**
   * Options to pass to the extensions.
   */
  options?: Options;
};

export interface DashDraftExtensionsConfig {
  /**
   * "Alerts" extension configuration.
   *
   * @default true
   */
  alerts?: DashDraftExtensionConfig;

  /**
   * "Code Block" extension configuration.
   *
   * @default true
   */
  codeBlock?: DashDraftExtensionConfig;

  /**
   * "Code Inline" extension configuration.
   *
   * @default true
   */
  codeInline?: DashDraftExtensionConfig;

  /**
   * "Core" extension configuration.
   *
   * @default true
   */
  core?: DashDraftExtensionConfig;

  /**
   * "Link" extension configuration.
   *
   * @default true
   */
  link?: DashDraftExtensionConfig;

  /**
   * "Lists" extension configuration.
   *
   * @default true
   */
  lists?: DashDraftExtensionConfig;

  /**
   * "Media" extension configuration.
   *
   * @default true
   */
  media?: DashDraftExtensionConfig;

  /**
   * "Emoji" extension configuration.
   *
   * @default true
   */
  emoji?: DashDraftExtensionConfig;

  /**
   * "Table" extension configuration.
   *
   * @default true
   */
  table?: DashDraftExtensionConfig;

  /**
   * "Table of Contents" extension configuration.
   *
   * @default true
   */
  tableOfContents?: DashDraftExtensionConfig;

  /**
   * "Typography" extension configuration.
   *
   * @default true
   */
  typography?: DashDraftExtensionConfig;

  /**
   * "Utilities" extension configuration.
   *
   * @default true
   */
  utilities?: DashDraftExtensionConfig;

  /**
   * "AI" extension configuration.
   *
   * @default true
   */
  ai?: DashDraftExtensionsConfigObject<DashDraftExtensionsAIConfig>;
}

/**
 * DashDraftExtensions is a utility object for managing the extensions to use in the editor.
 */
export const DashDraftExtensions = {
  /**
   * List all available extensions.
   *
   * @returns A list of all available extensions.
   */
  list() {
    return [
      DashDraftAlertsExtension,
      DashDraftCodeBlockExtension,
      DashDraftCodeInlineExtension,
      DashDraftCoreExtension,
      DashDraftLinkExtension,
      DashDraftListsExtension,
      DashDraftMediaExtension,
      DashDraftEmojiExtension,
      DashDraftTableExtension,
      DashDraftTableOfContentsExtension,
      DashDraftTypographyExtension,
      DashDraftUtilitiesExtension,
      DashDraftDetailsExtension,
      // AI is omitted from the list of extensions as we don't want to include it by default
      // DashDraftAI,
    ];
  },

  /**
   * Configure the extensions to use in the editor.
   *
   * @param config A configuration object for the extensions
   * @returns The list of configured extensions to use in the editor.
   */
  configure(config?: DashDraftExtensionsConfig) {
    const extList = [];

    if (isEnabled(config?.alerts)) {
      extList.push(DashDraftAlertsExtension);
    }

    if (isEnabled(config?.codeBlock)) {
      extList.push(DashDraftCodeBlockExtension);
    }

    if (isEnabled(config?.codeInline)) {
      extList.push(DashDraftCodeInlineExtension);
    }

    if (isEnabled(config?.core)) {
      extList.push(DashDraftCoreExtension);
    }

    if (isEnabled(config?.link)) {
      extList.push(DashDraftLinkExtension);
    }

    if (isEnabled(config?.lists)) {
      extList.push(DashDraftListsExtension);
    }

    if (isEnabled(config?.media)) {
      extList.push(DashDraftMediaExtension);
    }

    if (isEnabled(config?.emoji)) {
      extList.push(DashDraftEmojiExtension);
    }

    if (isEnabled(config?.table)) {
      extList.push(DashDraftTableExtension);
    }

    if (isEnabled(config?.tableOfContents)) {
      extList.push(DashDraftTableOfContentsExtension);
    }

    if (isEnabled(config?.typography)) {
      extList.push(DashDraftTypographyExtension);
    }

    if (isEnabled(config?.utilities)) {
      extList.push(DashDraftUtilitiesExtension);
    }

    if (isEnabled(config?.ai) && config?.ai?.options) {
      extList.push(DashDraftAI(config.ai.options));
    }

    return [...extList, DashDraftDetailsExtension];
  },
};

export default DashDraftExtensions;

/*
|------------------
| Utils
|------------------
*/

/**
 * Check if an extension is enabled
 *
 * @param config A configuration object for the extension
 * @returns A boolean indicating whether the extension is enabled
 */
const isEnabled = (config?: DashDraftExtensionConfig) => {
  if (_.isBoolean(config)) {
    return config;
  }

  if (_.isBoolean(config?.enabled)) {
    return config?.enabled;
  }

  return true;
};
