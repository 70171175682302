import { RikerIcon } from '@joggrdocs/riker-icons';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Popover,
  Stack,
  TextField,
  Typography,
  inputClasses,
  useTheme,
} from '@mui/material';
import React from 'react';

import { DialogTitle } from '@/components/dialog';
import { IconPicker } from '@stargate/components/IconPicker';
import { useNightingale } from '@stargate/lib/nightingale';
import { useNotify } from '@stargate/lib/notify';

import useDirectoryTree from '../../hooks/use-directory-tree';
import type * as types from '../../types';
import { DirectoryIcon } from '../Icons';

/*
|==========================================================================
| DirectoryActionDialog
|==========================================================================
|
| Action dialog for handling create/edit of directories.
|
*/

const getDefaults = (
  action: 'create' | 'edit',
  directory?: types.DirectoryTreeNode
) => {
  if (action === 'edit') {
    const iconDefaults =
      directory && 'icon' in directory && directory?.icon && directory.iconType
        ? {
            icon: directory.icon,
            iconType: directory.iconType,
          }
        : null;

    return {
      id: directory?.id || null,
      icon: iconDefaults,
      title: directory?.title || '',
      summary: directory?.summary || '',
      parentId: directory?.parentId || null,
    };
  }
  if (action === 'create') {
    return {
      title: '',
      summary: '',
      icon: null,
      parentId: directory?.id || null,
    };
  }
  return {
    id: null,
    title: '',
    summary: '',
    icon: null,
    parentId: null,
  };
};

export interface DirectoryActionDialogProps {
  onClose: () => void;
  action: 'create' | 'edit';
  open?: boolean;
  directory?: types.DirectoryTreeNode;
}

export const DirectoryActionDialog: React.FC<DirectoryActionDialogProps> = ({
  onClose,
  // We have to default for legacy reasons
  // @todo default false
  open = true,
  ...props
}) => {
  const defaults = getDefaults(props.action, props.directory);
  const theme = useTheme();
  const directoryTree = useDirectoryTree();
  const notify = useNotify();
  const [saving, setSaving] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const [title, setTitle] = React.useState(defaults.title);
  const [summary, setSummary] = React.useState(defaults.summary);
  const [icon, setIcon] = React.useState(defaults.icon);
  const nightingale = useNightingale();

  /*
  |------------------
  | Computed
  |------------------
  */

  const titleSx = {
    fontSize: '3rem',
  };

  const summarySx = {
    fontSize: '1.15rem',
  };

  /*
  |------------------
  | Callbacks: State
  |------------------
  */

  const handleOpenIconPicker = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      setAnchorEl(event.currentTarget);
    },
    []
  );

  const handleCloseIconPicker = React.useCallback(() => {
    setAnchorEl(null);
  }, []);

  /*
  |------------------
  | Callbacks: Form
  |------------------
  */

  const handleNameChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setTitle(event.target.value);
    },
    []
  );

  const handleSummaryChange = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      setSummary(event.target.value);
    },
    []
  );

  const handleIconChange = React.useCallback(
    (icon?: types.DirectoryIconConfig) => {
      if (icon) {
        setIcon(icon);
      } else {
        setIcon(null);
      }

      handleCloseIconPicker();
    },
    [handleCloseIconPicker]
  );

  const handleSave = React.useCallback(() => {
    setSaving(true);
    if (props.action === 'create') {
      directoryTree
        .onCreateDirectory({
          title,
          summary,
          // @todo fix this once we can fix the types in the API ts generator
          parentId: defaults.parentId ?? undefined,
          ...icon,
        })
        .then(() => {
          notify.send({
            message: 'Successfully created directory',
            severity: 'success',
          });
          directoryTree.onLoad();
          nightingale.capture(['create', 'directory'], {
            title,
            summary,
            parentId: defaults.parentId,
            hasCustomIcon: !!icon,
          });
        })
        .catch((err) => {
          notify.error(err.message);
        })
        .finally(() => {
          onClose();
          setSaving(false);
        });
    } else if (props.action === 'edit') {
      directoryTree

        // biome-ignore lint/style/noNonNullAssertion: This will ALWAYS have a value
        .onUpdateDirectory(props.directory?.id!, {
          ...icon,
          title,
          summary,
          // @todo fix this once we can fix the types in the API ts generator
          parentId: defaults.parentId ?? undefined,
          order: props.directory?.order,
        })
        .then(() => {
          notify.send({
            message: 'Successfully updated directory',
            severity: 'success',
          });
          directoryTree.onLoad();
          nightingale.capture(['update', 'directory'], {
            title,
            summary,
            hasCustomIcon: !!icon,
            parentId: defaults.parentId,
          });
        })
        .catch((err) => {
          notify.error(err.message);
        })
        .finally(() => {
          onClose();
          setSaving(false);
        });
    }
  }, [
    nightingale,
    props.action,
    props.directory?.id,
    props.directory?.order,
    directoryTree,
    title,
    summary,
    defaults.parentId,
    icon,
    notify,
    onClose,
  ]);

  return (
    <Dialog onClose={onClose} maxWidth='sm' open={open} fullWidth>
      <DialogTitle
        title={
          props.action === 'create' ? 'Create Directory' : 'Edit Directory'
        }
        onClose={onClose}
        startIcon={
          <React.Fragment>
            {props.action === 'create' && <RikerIcon icon='folder-plus' />}
            {props.action === 'edit' && <DirectoryIcon />}
          </React.Fragment>
        }
      />
      <DialogContent>
        <Stack direction='column' spacing={3}>
          <Stack direction='row' spacing={3} alignItems='center'>
            <Box
              sx={{
                textAlign: 'center',
                position: 'relative',
              }}
            >
              <Stack
                ref={null}
                direction='row'
                alignItems='center'
                justifyContent='center'
                onClick={handleOpenIconPicker}
                sx={{
                  width: '72px',
                  height: '72px',
                  borderRadius: '8px',
                  border: `1px solid ${theme.palette.divider}`,
                  '&:hover': {
                    cursor: 'pointer',
                    boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.25)',
                    bgcolor: theme.palette.action.hover,
                  },
                }}
              >
                <DirectoryIcon {...icon} size={48} />
              </Stack>
              <Typography
                variant='caption'
                sx={{
                  fontSize: '10px',
                  position: 'absolute',
                  left: '0',
                  right: '0',
                  bottom: '-16px',
                }}
              >
                edit icon
              </Typography>
            </Box>
            <Popover
              open={!!anchorEl}
              anchorEl={anchorEl}
              onClose={handleCloseIconPicker}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <IconPicker
                {...icon}
                onSelect={handleIconChange}
                onClear={handleIconChange}
              />
            </Popover>
            <TextField
              defaultValue={title}
              placeholder='Untitled'
              multiline
              autoFocus
              maxRows={3}
              onChange={handleNameChange}
              sx={{
                fontSize: titleSx.fontSize,
                borderBottom: `1px solid ${theme.palette.divider}`,
                [`& .${inputClasses.root}`]: {
                  ...theme.typography.h2,
                  fontSize: titleSx.fontSize,
                  '&:before, :after, :hover:not(.Mui-disabled):before': {
                    borderBottom: 0,
                  },
                  '& input::placeholder': {
                    fontSize: titleSx.fontSize,
                  },
                  // We force View and Edit to have the same height
                  '& input': {
                    height: 'auto',
                  },
                },
              }}
              variant='standard'
            />
          </Stack>
          <TextField
            defaultValue={summary}
            placeholder='Add a short description of the directory...'
            multiline
            maxRows={3}
            onChange={handleSummaryChange}
            fullWidth
            sx={{
              fontSize: summarySx.fontSize,
              borderBottom: `1px solid ${theme.palette.divider}`,
              [`& .${inputClasses.root}`]: {
                fontSize: summarySx.fontSize,
                '&:before, :after, :hover:not(.Mui-disabled):before': {
                  borderBottom: 0,
                },
                '& input::placeholder, & textarea::placeholder': {
                  fontSize: summarySx.fontSize,
                },
                '& textarea': {
                  marginTop: '2px',
                },
              },
            }}
            variant='standard'
          />
          {/* {directoryTree.tree && (
            <DirectoryParentSelect
              directoryTree={directoryTree.tree}
              onChangeParent={handleParentChange}
              defaultParentId={defaults.parentId ?? 'root'}
              defaultId={defaults.id ?? undefined}
            />
          )} */}
        </Stack>
      </DialogContent>
      <Divider sx={{ pt: 2, pb: 1 }} />
      <DialogActions
        sx={{
          p: 2,
        }}
      >
        <Button
          variant='contained'
          onClick={handleSave}
          disabled={!title}
          startIcon={<RikerIcon icon='device-floppy' />}
          loading={saving}
        >
          Save
        </Button>
        <Button variant='outlined' onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
