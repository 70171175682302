import { useMountEffect } from '@react-hookz/web';
import { useNavigate } from 'react-router-dom';

import { useJDocContext, useJDocDrafts } from '@stargate/features/docs';

export function DocCreatePage() {
  const nav = useNavigate();
  const ctx = useJDocContext();
  const drafts = useJDocDrafts();

  useMountEffect(() => {
    drafts
      .create({
        parentId: ctx.dirId && ctx.dirId !== 'root' ? ctx.dirId : undefined,
        templateId: ctx.templateId ?? undefined,
      })
      .then((draft) => {
        nav(`/app/documents/${draft.id}/draft`, { replace: true });
      })
      .catch((err) => {
        throw err;
      });
  });

  return null;
}
