import { ShortcutDialog } from '@/features/shortcuts';
import { SendInviteDialog } from '@/features/user-invites';
import { SupportWidget } from '@/features/user-support';

import MainContent from './MainContent';
import Sidebar from './SideBar';
import TopBar from './TopBar';
import MobileWarningFeedback from './Utils/MobileWarningFeedback';

/**
 * The DashboardLayout is the main layout for the application.
 */
const DashboardLayout = () => {
  return (
    <div className='flex'>
      <TopBar />
      <Sidebar />
      <MainContent>
        <SupportWidget />
        <SendInviteDialog />
        <ShortcutDialog />
      </MainContent>
      <MobileWarningFeedback />
    </div>
  );
};

export default DashboardLayout;
