import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

/**
 * Merge the CSS class names together, using Tailwind CSS to merge the class names.
 *
 * @param args A list of class names to merge.
 * @returns Creates a new class name by merging the given class names.
 */
export function cn(...args: ClassValue[]) {
  return twMerge(clsx(args));
}
