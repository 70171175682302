import * as hookz from '@react-hookz/web';
import _ from 'lodash';
import React from 'react';

import { Alert, AlertTitle } from '@mui/material';

import { LoadingOverlay } from '@stargate/components/Loading';
import { useSentry } from '@stargate/vendors/sentry';

export type MediaIframeSupportedAttributes =
  | 'allowFullScreen'
  | 'modestBranding';

export interface MediaIframeProps {
  /**
   * The source URL for the iframe.
   */
  src: string;

  /**
   * Use modest branding vs full branding (used for Media i.e. YouTube, Vimeo, etc).
   */
  modestBranding?: boolean;

  /**
   * Allow a video to be played in fullscreen (used for Media i.e. YouTube, Vimeo, etc).
   */
  allowFullScreen?: boolean;

  /**
   * Callback for when the iframe has loaded.
   */
  onLoad?: () => void;

  /**
   * Callback for when the iframe has errored.
   */
  onError?: (error: Error) => void;

  /**
   * Disable validation for the iframe content.
   */
  disableValidation?: boolean;
}

export const MediaIframe: React.FC<MediaIframeProps> = ({
  src,
  modestBranding = false,
  allowFullScreen = true,
  disableValidation = false,
  onLoad,
  onError,
}) => {
  const [loading, setLoading] = React.useState(true);
  const [ready, setReady] = React.useState<boolean>(false);
  const [error, setError] = React.useState<Error | null>(null);
  const previousSrc = hookz.usePrevious(src);
  const sentry = useSentry();

  const validateSrc = React.useCallback(
    (src: string) => {
      if (disableValidation) {
        setReady(true);
      } else {
        fetch(src)
          .then((res) => {
            if (res.status <= 299 && res.status >= 200) {
              setReady(true);
            } else {
              throw new Error('Failed to load iframe content');
            }
          })
          .catch((err: Error) => {
            setError(err);
            setReady(true);
            setLoading(false);
            sentry.captureException(err, {
              extra: {
                message: 'Failed to load iframe content',
                src,
              },
            });
          });
      }
    },
    [disableValidation]
  );

  /*
  |------------------
  | Handlers
  |------------------
  */

  const handleLoad = React.useCallback(() => {
    setLoading(false);
    onLoad?.();
  }, [onLoad]);

  const handleError = React.useCallback(() => {
    const error = new Error('Unknown failure');
    setError(error);
    setLoading(false);
    onError?.(error);
  }, [onError]);

  // On src change, reset loading state
  React.useEffect(() => {
    if (!_.isNil(previousSrc) && src !== previousSrc) {
      setLoading(true);
      setError(null);
      setReady(false);
      validateSrc(src);
    }
  }, [src, previousSrc, validateSrc]);

  // On mount, fetch the iframe content
  // and validate it's a valid URL & ready to load
  hookz.useMountEffect(() => {
    validateSrc(src);
  });

  return (
    <div className='dashdraft-media-iframe-container'>
      <LoadingOverlay variant='contained' loading={loading} />
      {ready &&
        (_.isNil(error) ? (
          <iframe
            title='Media Content'
            className='dashdraft-media-iframe'
            src={src}
            allowFullScreen={allowFullScreen}
            // @ts-expect-error - `modestBranding` is not a valid attribute for an iframe but used in the context of a media iframe.

            modestBranding={modestBranding}
            onLoad={handleLoad}
            onError={handleError}
          />
        ) : (
          <div className='dashdraft-media-iframe dashdraft-media-error'>
            <Alert severity='error'>
              <AlertTitle>Uh oh! Something went wrong.</AlertTitle>
              We were unable to load the media content, please try again later.
            </Alert>
          </div>
        ))}
    </div>
  );
};
