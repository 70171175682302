import { useTheme } from '@mui/material';
import * as hookz from '@react-hookz/web';
import React from 'react';
import ReactConfetti from 'react-confetti';

type ReactConfettiProps = React.ComponentProps<typeof ReactConfetti>;

export type ConfettiProps = ReactConfettiProps;

export const Confetti: React.FC<ConfettiProps> = (props) => {
  const { width, height } = hookz.useWindowSize();
  const theme = useTheme();
  return (
    <ReactConfetti
      width={width}
      height={height}
      gravity={0.2}
      style={{
        ...props.style,
        zIndex: theme.zIndex.appBar + 1,
      }}
      {...props}
    />
  );
};
