import type { RikerIconName } from '@joggrdocs/riker-icons';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';

import { IconAvatar } from '@/components/Avatars';
import { DialogTitle } from '@/components/dialog';
import { useLocalization } from '@/localization';
import { createComponentClasses } from '@stargate/theme';

export const codeLinkDeleteDialogClasses = createComponentClasses(
  'DialogDeleteConfirmation',
  ['root'] as const
);

export interface DialogDeleteConfirmationProps {
  /**
   * The dialog title.
   */
  title?: React.ReactNode;

  /**
   * The dialog subtitle.
   */
  subTitle?: React.ReactNode;

  /**
   * The dialog question.
   */
  question?: React.ReactNode;

  /**
   * The dialog task.
   */
  task?: React.ReactNode;

  /**
   * The text for the user to confirm the deletion.
   *
   * @default 'DELETE'
   */
  confirmation?: string;

  /**
   * The dialog icon.
   *
   * @default 'trash'
   */
  icon?: RikerIconName;

  /**
   * The dialog open state.
   */
  open: boolean;

  /**
   * Close the dialog.
   */
  onClose: () => void;

  /**
   * A callback to delete the code link.
   *
   * @returns A promise that resolves when the code link is deleted.
   */
  onDelete: () => Promise<void>;
}

export const DialogDeleteConfirmation: React.FC<
  DialogDeleteConfirmationProps
> = ({
  icon = 'trash',
  title,
  subTitle,
  question,
  task,
  confirmation = 'DELETE',
  open,
  onClose,
  onDelete,
}) => {
  const localz = useLocalization();
  const [deleteText, setDeleteText] = React.useState('');
  const [deleting, setDeleting] = React.useState(false);

  const handleDelete = async () => {
    setDeleting(true);
    await onDelete();
    setDeleting(false);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle
        onClose={onDelete}
        startIcon={<IconAvatar backgroundColor='error' icon={icon} />}
        title={
          title ||
          localz.formatMessage('components.dialogs.confirm-delete.title')
        }
        subTitle={
          subTitle ||
          localz.formatMessage('components.dialogs.confirm-delete.subtitle')
        }
      />
      <DialogContent>
        <Typography
          sx={{
            whiteSpace: 'pre-wrap',
            wordBreak: 'break-word',
          }}
          variant='body1'
          gutterBottom
        >
          {question ||
            localz.formatMessage('components.dialogs.confirm-delete.question')}
        </Typography>
        <Divider sx={{ my: 1 }} />
        <Typography variant='body1' sx={{ mb: 1.5 }}>
          {task ||
            localz.formatMessage('components.dialogs.confirm-delete.task', {
              word: confirmation,
              code: (chunks) => <code>{chunks}</code>,
            })}
        </Typography>
        <TextField
          value={deleteText}
          placeholder={localz.formatMessage(
            'components.dialogs.confirm-delete.task',
            {
              word: confirmation,
              code: (chunks) => chunks,
            }
          )}
          onChange={(e) => {
            setDeleteText(e.target.value);
          }}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          color='error'
          loading={deleting}
          disabled={deleteText !== confirmation || deleting}
          onClick={handleDelete}
        >
          Confirm
        </Button>
        <Button variant='outlined' onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
