import { Box, Link, Stack, useTheme } from '@mui/material';
import React from 'react';

import { ImgLogo } from '@stargate/components/Images';
import * as hooks from '@stargate/hooks';
import { Link as RouterLink } from '@stargate/routes';

/**
 * FullWidthLayout is the main layout for public pages & certain utility pages.
 */
const FullWidthLayout: React.FC<React.PropsWithChildren> = (props) => {
  const theme = useTheme();
  const staticUrls = hooks.useStaticUrls();
  return (
    <Box>
      <Box>{props.children}</Box>
      <Box
        sx={{
          position: 'fixed',
          bottom: '12px',
          left: '12px',
          right: '12px',
        }}
      >
        <Stack direction='row' alignItems='center' spacing={1}>
          <ImgLogo
            logo='full'
            contrast={theme.palette.mode === 'dark'}
            sx={{
              maxHeight: '24px',
              width: 'auto',
            }}
            responsive={false}
          />
          <Box sx={{ flexGrow: 1 }} />
          <Link component={RouterLink} to='app.root'>
            Home
          </Link>
          <Link href={staticUrls.supportEmail}>Support</Link>
          <Link href={staticUrls.statusPageSite}>Status Page</Link>
          <Link component={RouterLink} to='root.logout'>
            Log out
          </Link>
        </Stack>
      </Box>
    </Box>
  );
};

export default FullWidthLayout;
