import { RikerIcon } from '@joggrdocs/riker-icons';
import { Box, Stack, Tooltip, darken, useTheme } from '@mui/material';
import React from 'react';

import { useLocalization } from '@stargate/localization';
import { createComponentClasses } from '@stargate/theme';

export const codeExplorerFileTreeDrawerClasses = createComponentClasses(
  'CodeExplorerFileTreeDrawer',
  ['root', 'button', 'content']
);

export type CodeExplorerFileTreeDrawerProps = React.PropsWithChildren<{
  /**
   * Whether the drawer is open.
   */
  open: boolean;

  /**
   * Whether the file tree is disabled.
   */
  disabled: boolean;

  /**
   * Callback when the drawer is toggled.
   */
  onToggle: () => void;
}>;

export const CodeExplorerFileTreeDrawer: React.FC<
  CodeExplorerFileTreeDrawerProps
> = ({ open, disabled, onToggle, children }) => {
  return (
    <Stack
      classes={{
        root: codeExplorerFileTreeDrawerClasses.root,
      }}
      direction='column'
      spacing={0}
      sx={{
        height: '100%',
      }}
    >
      {!disabled && (
        <ToggleHelperButton
          className={codeExplorerFileTreeDrawerClasses.button}
          open={open}
          onClick={onToggle}
        />
      )}
      <Box
        className={codeExplorerFileTreeDrawerClasses.content}
        sx={{
          [`&.${codeExplorerFileTreeDrawerClasses.content}`]: {
            display: open ? undefined : 'none',
            p: 1,
            height: '100%',
          },
        }}
      >
        {children}
      </Box>
    </Stack>
  );
};

/*
|------------------
| Utils
|------------------
*/

type ToggleHelperButtonProps = React.ComponentProps<'button'> & {
  /**
   * Whether the drawer is open.
   */
  open: boolean;
};

const ToggleHelperButton = React.forwardRef<
  HTMLButtonElement,
  ToggleHelperButtonProps
>(({ open, ...props }, ref) => {
  const theme = useTheme();
  const localz = useLocalization();
  return (
    <Tooltip
      title={localz.formatMessage('features.code.tree.drawer.toggle.tooltip')}
      placement='right'
      arrow
    >
      <Box
        component='button'
        ref={ref}
        {...props}
        sx={{
          position: 'absolute',
          top: '50%',
          right: '-12px',
          cursor: 'pointer',
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          alignContent: 'center',
          justifyContent: 'center',
          width: '24px',
          height: '24px',
          textAlign: 'center',
          zIndex: 1,
          backgroundColor: theme.palette.background.dashboardSideBar,
          ...theme.applyStyles('dark', {
            border: `1px solid ${theme.palette.divider}`,
          }),
          ...theme.applyStyles('light', {
            border: `1px solid ${darken(theme.palette.divider, 0.1)}`,
          }),
          '&:hover': {
            borderSize: '2px',
            ...theme.applyStyles('dark', {
              border: `2px solid ${theme.palette.background.paper}`,
            }),
            ...theme.applyStyles('light', {
              border: `2px solid ${darken(theme.palette.background.paper, 0.1)}`,
            }),
          },
        }}
      >
        <RikerIcon
          icon={open ? 'chevron-left' : 'chevron-right'}
          size={16}
          color={theme.palette.text.primary}
        />
      </Box>
    </Tooltip>
  );
});
