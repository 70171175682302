import _ from 'lodash';
import React from 'react';

import { Box, Stack } from '@mui/material';

import { SirenaLogo } from './SirenaLogo';
import { SirenaModeSelect } from './SirenaModeSelect';

/*
|==========================================================================
| SirenaHeader
|==========================================================================
|
| The header for the Sirena split editor.
|
*/

export const SirenaHeader: React.FC<{ rightAction?: React.ReactNode }> = ({
  rightAction,
}) => {
  return (
    <Box
      sx={{
        border: (theme) => `1px solid ${theme.palette.divider}`,
        bgcolor: (theme) =>
          theme.palette.mode === 'dark'
            ? theme.palette.background.default
            : theme.palette.grey[100],
        px: 2,
        py: 1,
        mb: 1.5,
        borderRadius: 2,
      }}
    >
      <Stack direction='row' alignItems='center' spacing={1}>
        <SirenaLogo size='medium' />
        <Box flexGrow={1} />
        <SirenaModeSelect size='small' />
        {rightAction}
      </Stack>
    </Box>
  );
};

const MemoizedSirenaHeader = React.memo(SirenaHeader);
MemoizedSirenaHeader.displayName = 'SirenaHeader';

export default MemoizedSirenaHeader;
