import {
  getYoutubeEmbedUrl,
  getYoutubeImageUrl,
  getYoutubeShareUrl,
  getYoutubeVideoId,
} from '@joggrdocs/bumblebee';
import { RikerIcon } from '@joggrdocs/riker-icons';
import { NodeViewContent, NodeViewWrapper } from '@tiptap/react';
import _ from 'lodash';
import React from 'react';

import BlockEmpty from '@dashdraft/components/BlockEmpty';
import { type ActionItem, Actions } from '@stargate/components/Actions';
import type { DashDraftNodeViewProps } from '@stargate/lib/dashdraft/types';

import type { YoutubeNodeAttributes } from '../../types';
import { MediaBlock } from '../MediaBlock';
import { MediaIframe } from '../MediaIframe';
import { YoutubeForm, type YoutubeFormProps } from './YoutubeForm';

export type YoutubeAction = 'edit' | 'open' | 'delete';

export type YoutubeNodeViewProps =
  DashDraftNodeViewProps<YoutubeNodeAttributes>;

export const YoutubeNodeView = React.memo<YoutubeNodeViewProps>(
  ({ deleteNode, editor, updateAttributes, node }) => {
    const { src, options } = node.attrs;
    const [popoverOpen, setPopoverOpen] = React.useState(
      options?.forceOpen ?? false
    );

    /*
    |------------------
    | Computed
    |------------------
    */

    const videoId = React.useMemo(() => {
      return getYoutubeVideoId(src);
    }, [src]);

    const urls = React.useMemo<{
      share: string;
      embed: string;
      gif: string;
    } | null>(() => {
      if (_.isNil(videoId)) return null;

      return {
        share: getYoutubeShareUrl(videoId),
        embed: getYoutubeEmbedUrl(videoId),
        gif: getYoutubeImageUrl(videoId),
      };
    }, [videoId]);

    const ready = !_.isNil(urls) && !_.isNil(urls.embed);

    const actions = React.useMemo<Array<ActionItem<YoutubeAction>>>(() => {
      const actions: Array<ActionItem<YoutubeAction>> = [];

      if (editor.isEditable) {
        actions.push({
          icon: 'edit',
          primaryLabel: 'Edit',
          action: 'edit',
          type: 'button',
        });
      }

      if (!_.isNil(videoId)) {
        actions.push({
          icon: 'arrow-up-right',
          primaryLabel: 'View on Youtube',
          action: 'open',
          type: 'button',
        });
      }

      if (editor.isEditable) {
        actions.push(
          {
            type: 'divider',
          },
          {
            icon: 'trash',
            primaryLabel: 'Delete',
            action: 'delete',
            type: 'button',
          }
        );
      }

      return actions;
    }, [videoId, editor.isEditable]);

    /*
    |------------------
    | Handlers
    |------------------
    */

    const handleOpen = React.useCallback(() => {
      setPopoverOpen(true);
    }, []);

    const handleClose = React.useCallback(() => {
      setPopoverOpen(false);
    }, []);

    const handleAction = React.useCallback(
      (action: YoutubeAction) => {
        if (action === 'delete') {
          deleteNode();
        } else if (action === 'edit') {
          handleOpen();
        } else if (action === 'open' && !_.isNil(urls)) {
          window.open(urls.share, '_blank');
        }
      },
      [handleOpen, deleteNode, urls]
    );

    const handleSave = React.useCallback<YoutubeFormProps['onSave']>(
      (payload) => {
        updateAttributes({
          src: payload.url,
        });
        setPopoverOpen(false);
      },
      [updateAttributes]
    );

    return (
      <NodeViewWrapper>
        <MediaBlock
          mediaType='iframe'
          mediaCategory='youtube'
          open={popoverOpen}
          onClose={handleClose}
          actions={
            <Actions<YoutubeAction>
              size='small'
              iconColor='dark'
              onAction={handleAction}
              actions={actions}
            />
          }
          popoverContent={<YoutubeForm defaultSrc={src} onSave={handleSave} />}
        >
          {ready && (
            <MediaIframe
              src={urls.embed}
              allowFullScreen
              modestBranding
              disableValidation
            />
          )}
          {!ready && (
            <BlockEmpty
              helperIcon={<RikerIcon icon='brand-youtube' />}
              helperText={'Click to add a Youtube video'}
              onClick={handleOpen}
            />
          )}
        </MediaBlock>
        <div style={{ display: 'none' }}>
          <NodeViewContent />
        </div>
      </NodeViewWrapper>
    );
  }
);
YoutubeNodeView.displayName = 'YoutubeNodeView';
