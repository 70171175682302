import _ from 'lodash';

import type { JDocDraft } from '@stargate/features/docs/types';

import { fields } from './config';
import type { JDocRequiredField } from './types';

/**
 * Returns a list of missing fields needed to save a JoggrDoc, based on the draft.
 *
 * @param draft A JoggrDocDraft
 * @returns A list of missing fields needed to save a JoggrDoc
 */
export const getMissingFields = (
  draft?: JDocDraft | null
): JDocRequiredField[] => {
  const missingFields: JDocRequiredField[] = [];

  if (_.isNil(draft)) {
    return [];
  }

  for (const requireField of fields.required) {
    const val = _.get(draft, requireField, null);
    if (_.isNil(val)) {
      missingFields.push(requireField);
    } else if (_.isString(val) && val.trim() === '') {
      missingFields.push(requireField);
    } else if (_.isObject(val) && _.isEmpty(val)) {
      missingFields.push(requireField);
    } else if (_.isArray(val) && val.length === 0) {
      missingFields.push(requireField);
    } else if (_.isNumber(val) && _.isNaN(val)) {
      missingFields.push(requireField);
    }
  }

  return missingFields;
};
