import { Stack, Typography } from '@mui/material';
import React from 'react';

export interface SelectRenderValueProps {
  // @todo fix type
  icon?: any;
  label?: string;
  placeholder: string;
  disabled?: boolean;
}

export const SelectRenderValue = React.forwardRef<
  HTMLDivElement,
  SelectRenderValueProps
>(({ disabled = false, icon, placeholder, label }, ref) => {
  if (!label) {
    return <RenderValue ref={ref} icon={icon} text={placeholder} />;
  }
  return <RenderValue ref={ref} icon={icon} text={label} />;
});
SelectRenderValue.displayName = 'SelectRenderValue';
SelectRenderValue.muiName = 'MenuItem';

export default SelectRenderValue;

/*
|------------------
| Utils
|------------------
*/

const RenderValue = React.forwardRef<
  HTMLDivElement,
  { icon: React.ReactNode; text: React.ReactNode }
>((props, ref) => {
  return (
    <Stack
      ref={ref}
      direction='row'
      spacing={1}
      sx={{
        p: 0,
      }}
    >
      {props.icon}
      <Typography>{props.text}</Typography>
    </Stack>
  );
});
