import {
  RikerIcon,
  type RikerIconComponent,
  type RikerIconName,
  type RikerIconProps,
} from '@joggrdocs/riker-icons';
import React from 'react';
import type * as TypeFest from 'type-fest';

import { createComponentClasses } from '@stargate/theme';

export const codeSnippetIconClasses = createComponentClasses(
  'CodeSnippetIcon',
  ['root'] as const
);

export type CodeSnippetIconProps = TypeFest.Simplify<
  Omit<RikerIconProps, 'icon'>
>;

/**
 * The name of the code snippet icon.
 */
export const codeSnippetIconName: RikerIconName = 'code-asterisk';

/**
 * CodeSnippetIcon
 */
export const CodeSnippetIcon = React.forwardRef<
  RikerIconComponent,
  CodeSnippetIconProps
>((props, ref) => {
  return (
    <RikerIcon
      {...props}
      classes={props.classes ?? codeSnippetIconClasses}
      ref={ref}
      icon={codeSnippetIconName}
    />
  );
});
