import { Box, Fade, useTheme } from '@mui/material';
import { TreeItem, treeItemClasses } from '@mui/x-tree-view/TreeItem';
import React from 'react';
import { useDrop } from 'react-dnd';

import { createComponentClasses } from '@stargate/theme';

import useDirectoryTree from '../../hooks/use-directory-tree';
import { ACCEPT } from '../../hooks/use-drag-and-drop';
import type { DirectoryTreeNode } from '../../types';

export const directoryTreeItemGapClasses = createComponentClasses(
  'DirectoryTreeItemGap',
  ['root', 'divider', 'overlay'] as const
);

export interface DirectoryTreeItemGapProps {
  /**
   * Add a gutter to the top of the gap.
   */
  gutterTop?: boolean;

  /**
   * Add a gutter to the bottom of the gap.
   */
  gutterBottom?: boolean;

  /**
   * The "parentId" for the Node that is above the gap aka the "parentId" that will be used when moving a node to the gap.
   * If the parent is "null" then the gap is at the "root" level.
   */
  parentNodeId: string | null;

  /**
   * The "itemId" for the Node that is above the gap aka the "itemId" that will be used when moving a node to the gap.
   * If the gap is at the top of the tree, then this will be null.
   */
  previousNodeId: string | null;

  /**
   * A unique identifier for the node, not used in the UI or in calculations, only for React/MUI.
   */
  itemId: string;
}

/**
 * A component used to create a gap between two nodes in the DirectoryTreeView, that can be used to move nodes around & reorder them.
 */
export const DirectoryTreeItemGap: React.FC<DirectoryTreeItemGapProps> = ({
  gutterBottom = true,
  gutterTop = true,
  parentNodeId,
  previousNodeId,
  itemId,
}) => {
  const theme = useTheme();
  const directoryTree = useDirectoryTree();

  const [{ isOver }, drop] = useDrop({
    accept: ACCEPT,
    drop: (dragItem: DirectoryTreeNode) => {
      void directoryTree.onReorderNode({
        previousNodeId,
        parentNodeId,
        itemId: dragItem.id,
      });
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  return (
    <TreeItem
      itemId={itemId}
      disabled
      classes={{
        root: directoryTreeItemGapClasses.root,
      }}
      sx={{
        zIndex: theme.zIndex.drawer + 1,
        width: '100%',
        [`& .${treeItemClasses.content} .${treeItemClasses.iconContainer}`]: {
          width: '0',
        },
        [`& .${treeItemClasses.content}`]: {
          p: 0,
        },
        [`& .${treeItemClasses.content}.${treeItemClasses.disabled}`]: {
          // override disabled styles
          opacity: 1,
        },
      }}
      label={
        <React.Fragment>
          <Box
            className={directoryTreeItemGapClasses.overlay}
            ref={drop as unknown as React.RefObject<HTMLDivElement>}
            sx={{
              [`&.${directoryTreeItemGapClasses.overlay}`]: {
                zIndex: theme.zIndex.drawer + 2,
                position: 'absolute',
                top: '-6px',
                left: 0,
                right: 0,
                bottom: '-6px',
                width: '100%',
              },
            }}
          />
          <Fade in={isOver} timeout={{ exit: 300 }}>
            <Box
              className={directoryTreeItemGapClasses.divider}
              sx={{
                [`&.${directoryTreeItemGapClasses.divider}`]: {
                  height: '3px',
                  my: '2px',
                  borderRadius: '1px',
                  ml: 0.5,
                  mr: 0.5,
                  background: theme.palette.divider,
                },
              }}
            />
          </Fade>
        </React.Fragment>
      }
    />
  );
};
