import React from 'react';
import type * as TypeFest from 'type-fest';
import TablerIconFileCode from '~icons/tabler/file-code';
import TablerIconFileTypeCss from '~icons/tabler/file-type-css';
import TablerIconFileTypeCsv from '~icons/tabler/file-type-csv';
import TablerIconFileTypeDoc from '~icons/tabler/file-type-doc';
import TablerIconFileTypeHtml from '~icons/tabler/file-type-html';
import TablerIconFileTypeJpg from '~icons/tabler/file-type-jpg';
import TablerIconFileTypeJs from '~icons/tabler/file-type-js';
import TablerIconFileTypeJsx from '~icons/tabler/file-type-jsx';
import TablerIconFileTypePdf from '~icons/tabler/file-type-pdf';
import TablerIconFileTypePhp from '~icons/tabler/file-type-php';
import TablerIconFileTypePng from '~icons/tabler/file-type-png';
import TablerIconFileTypeRs from '~icons/tabler/file-type-rs';
import TablerIconFileTypeSql from '~icons/tabler/file-type-sql';
import TablerIconFileTypeSvg from '~icons/tabler/file-type-svg';
import TablerIconFileTypeTs from '~icons/tabler/file-type-ts';
import TablerIconFileTypeTsx from '~icons/tabler/file-type-tsx';
import TablerIconFileTypeTxt from '~icons/tabler/file-type-txt';
import TablerIconFileTypeVue from '~icons/tabler/file-type-vue';
import TablerIconFileTypeXml from '~icons/tabler/file-type-xml';
import TablerIconFileTypeZip from '~icons/tabler/file-type-zip';

import {
  type CodeMirrorLang,
  useCodeMirrorLangs,
} from '@stargate/lib/codemirror';

export type GitHubFileIconLanguage = TypeFest.Simplify<CodeMirrorLang | 'csv'>;

export interface GitHubFileIconProps
  extends React.ComponentProps<typeof TablerIconFileCode> {
  fileExtension?: string;
}

/**
 * A component that displays a file icon based on the file extension.
 */
export const GitHubFileIcon = React.forwardRef<
  SVGSVGElement,
  GitHubFileIconProps
>(({ fileExtension = '', ...props }, ref) => {
  const supportedLanguages = useCodeMirrorLangs();

  const Icon = React.useMemo(() => {
    const cleanFileExtension = fileExtension.replace('.', '');

    // Support Special Cases
    if (cleanFileExtension === 'csv') {
      return TablerIconFileTypeCsv;
    }
    if (cleanFileExtension === 'zip') {
      return TablerIconFileTypeZip;
    }
    if (cleanFileExtension === 'png') {
      return TablerIconFileTypePng;
    }
    if (cleanFileExtension === 'jpg') {
      return TablerIconFileTypeJpg;
    }
    if (cleanFileExtension === 'svg') {
      return TablerIconFileTypeSvg;
    }
    if (cleanFileExtension === 'pdf') {
      return TablerIconFileTypePdf;
    }

    // Support Core Languages
    const language = supportedLanguages.findByFileExtension(fileExtension);
    switch (language) {
      case 'css':
        return TablerIconFileTypeCss;
      case 'rust':
        return TablerIconFileTypeRs;
      case 'typescript':
        return TablerIconFileTypeTs;
      case 'tsx':
        return TablerIconFileTypeTsx;
      case 'javascript':
        return TablerIconFileTypeJs;
      case 'jsx':
        return TablerIconFileTypeJsx;
      case 'html':
        return TablerIconFileTypeHtml;
      case 'xml':
        return TablerIconFileTypeXml;
      case 'php':
        return TablerIconFileTypePhp;
      case 'sql':
        return TablerIconFileTypeSql;
      case 'markdown':
        return TablerIconFileTypeDoc;
      case 'plaintext':
        return TablerIconFileTypeTxt;
      case 'vue':
        return TablerIconFileTypeVue;
      default:
        return TablerIconFileCode;
    }
  }, [fileExtension, supportedLanguages]);

  return <Icon ref={ref} {...props} />;
});
GitHubFileIcon.displayName = 'GitHubFileIcon';
