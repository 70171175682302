import {
  Box,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  type DialogProps,
  Typography,
  useTheme,
} from '@mui/material';
import _ from 'lodash';
import React from 'react';
import TablerIconFileTypeDoc from '~icons/tabler/file-type-doc';

import { DialogTitle } from '@/components/dialog';
import {
  GitHubBrandAvatar,
  type GitHubRepository,
} from '@stargate/features/github';
import { createComponentClasses } from '@stargate/theme';

export const joggrDocSaveDialogClasses = createComponentClasses(
  'JoggrDocSaveDialog',
  ['root'] as const
);

export interface JoggrDocSaveDialogProps extends BaseProps {
  /**
   * The file name & directory path of the JoggrDoc in the GitHub repository
   */
  filePath: string;

  /**
   * The GitHub repository to save (commit) the JoggrDoc to
   */
  githubRepository: GitHubRepository;

  /**
   * The content of the dialog
   */
  content: React.ReactNode;

  /**
   * The actions of the dialog
   */
  actions: React.ReactNode;

  /**
   * The callback to handle the close
   */
  onClose: () => void;
}

/**
 * Component for
 */
export const JoggrDocSaveDialog: React.FC<JoggrDocSaveDialogProps> = ({
  open,
  filePath,
  content,
  actions,
  githubRepository,
  onClose,
}) => {
  const theme = useTheme();
  return (
    <Dialog open={open} onClose={onClose} maxWidth='md' fullWidth>
      <DialogTitle
        onClose={onClose}
        avatar={<GitHubBrandAvatar gap={1} size={52} />}
        title={
          <Typography variant='h5' fontSize='22px'>
            Commit to{' '}
            {!_.isNil(githubRepository?.name) && (
              <Box
                component='code'
                sx={{
                  border: `1px solid ${theme.palette.divider}`,
                  borderRadius: '4px',
                  px: 0.5,
                }}
              >
                {githubRepository.name}
              </Box>
            )}
            {_.isNil(githubRepository?.name) && 'GitHub'}
          </Typography>
        }
        subTitle={
          <Chip
            shape='rounded'
            sx={{
              mt: 0.5,
              py: '1px',
              height: '100%',
              fontSize: '12px',
              borderRadius: '2px',
            }}
            icon={<TablerIconFileTypeDoc height={16} width={16} />}
            label={filePath}
            size='small'
          />
        }
      />
      <DialogContent>{content}</DialogContent>
      <DialogActions>{actions}</DialogActions>
    </Dialog>
  );
};

/*
|------------------
| Utils
|------------------
*/

type BaseProps = Omit<DialogProps, 'children' | 'content'>;
