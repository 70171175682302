import { RikerIcon } from '@joggrdocs/riker-icons';
import { IconButton, Stack, useTheme } from '@mui/material';
import {
  SimpleTreeView,
  type SingleSelectTreeViewProps,
  treeItemClasses,
} from '@mui/x-tree-view';
import _ from 'lodash';
import React from 'react';

import type { APIResponse } from '@stargate/api';
import { useDevLogger } from '@stargate/logger';
import { createComponentClasses } from '@stargate/theme';
import { cn } from '@stargate/utils/styles';

import { GitHubDirectoryIcon } from '../Icons/GitHubDirectoryIcon';
import {
  GitHubFileTreeItem,
  type GitHubFileTreeItemType,
} from './GitHubFileTreeItem';

export const githubFileTreeViewClasses = createComponentClasses(
  'GitHubFileTreeView',
  ['root', 'item', 'itemAction', 'itemId']
);

/**
 * The ID used for the root node.
 */
export const ROOT_ID = '<root>';

export interface GitHubFileTreeViewProps extends BaseProps {
  /**
   * The file tree data.
   */
  fileTree: APIResponse<'GET /github/repositories/:repositoryId/file-tree'>;

  /**
   * The default selected item.
   */
  defaultSelectedItem?: string;

  /**
   * The default expanded items.
   */
  defaultExpandedItems?: string[];

  /**
   * The selected item. This prop allows this component to be controlled, if undefined it will be uncontrolled.
   */
  selectedItem?: string | null;

  /**
   * The expanded items. This prop allows this component to be controlled, if undefined it will be uncontrolled.
   */
  expandedItems?: string[];

  /**
   * The maximum width of the tree.
   *
   * @default 320
   */
  maxWidth?: number;

  /**
   * Flag to show the root node.
   *
   * @default false
   */
  showRoot?: boolean;

  /**
   * Flag to show empty directories.
   *
   * @default false
   */
  showEmptyDirectories?: boolean;

  /**
   * Flag to auto expand based on the selected item.
   */
  autoExpand?: boolean;

  /**
   * Callback for when an item is selected.
   *
   * @param fileTreeNode A GitHubFileTreeNode
   */
  onSelectedItemsChange: (fileTreeNode: GitHubFileTreeNode | null) => void;

  /**
   * Callback for when an item is expanded.
   */
  onExpandedItemsChange?: (filePaths: string[]) => void;

  /**
   * Callback for when a new item is added.
   */
  onAddItem?: (filePath: string) => void;
}

export const GitHubFileTreeView: React.FC<GitHubFileTreeViewProps> = ({
  fileTree: _fileTree,
  showRoot = false,
  showEmptyDirectories = false,
  maxWidth = 320,
  autoExpand = false,
  defaultSelectedItem,
  defaultExpandedItems,
  expandedItems,
  selectedItem,
  onSelectedItemsChange,
  onExpandedItemsChange,
  onAddItem,
  ...props
}) => {
  const theme = useTheme();
  const devLogger = useDevLogger();
  const [selected, setSelected] = React.useState<string | null>(
    defaultSelectedItem ?? null
  );
  const [expanded, setExpanded] = React.useState<string[]>(
    getExpandedFromSelected(defaultSelectedItem) ?? []
  );

  /*
  |------------------
  | Developer Warnings
  |------------------
  */

  if (!_.isUndefined(selectedItem) && _.isUndefined(onSelectedItemsChange)) {
    devLogger.warn(
      'GitHubFileTreeView',
      'You are providing a `selectedItem` prop, but you are not providing an `onSelectedItemsChange` prop. This will make the component uncontrolled.'
    );
  }

  if (!_.isUndefined(expandedItems) && _.isUndefined(onExpandedItemsChange)) {
    devLogger.warn(
      'GitHubFileTreeView',
      'You are providing an `expandedItems` prop, but you are not providing an `onExpandedItemsChange` prop. This will make the component uncontrolled.'
    );
  }

  if (defaultSelectedItem && !_.isUndefined(selectedItem)) {
    devLogger.warn(
      'GitHubFileTreeView',
      'You are providing a `defaultSelectedItem` prop, but you are also providing a `selectedItem` or `expandedItems` prop. This is switching the component between a controlled and uncontrolled state.'
    );
  }

  if (defaultExpandedItems && !_.isUndefined(expandedItems)) {
    devLogger.warn(
      'GitHubFileTreeView',
      'You are providing a `defaultExpandedItems` prop, but you are also providing a `expandedItems` prop. This is switching the component between a controlled and uncontrolled state.'
    );
  }

  /*
  |------------------
  | Computed
  |------------------
  */

  const computedSelectedItems = React.useMemo(() => {
    // If we have a selected item, we use that (controlled)
    if (!_.isUndefined(selectedItem)) {
      return selectedItem;
    }

    // If we have a default selected item, we use that (uncontrolled)
    if (!selected && defaultSelectedItem) {
      return defaultSelectedItem;
    }

    // If we have a selected item, we use that (controlled)
    return selected;
  }, [selected, defaultSelectedItem, selectedItem]);

  const computedExpandedItems = React.useMemo(() => {
    // If we are in a controlled state, we use the expanded items
    if (!_.isUndefined(expandedItems)) {
      return showRoot === true
        ? // We uniq the expanded items to ensure that the root node is always expanded but not duplicated
          _.uniq([ROOT_ID, ...expandedItems])
        : expandedItems;
    }

    // If we are auto expanding, we use the expanded items from the selected item
    if (autoExpand && !_.isNil(computedSelectedItems)) {
      const calculatedExpandedFromSelected =
        getExpandedFromSelected(computedSelectedItems) ?? [];
      return showRoot === true
        ? [ROOT_ID, ...calculatedExpandedFromSelected]
        : calculatedExpandedFromSelected;
    }

    // uncontrolled state, we use the expanded state
    return showRoot === true ? [ROOT_ID, ...expanded] : expanded;
  }, [expanded, showRoot, computedSelectedItems, expandedItems, autoExpand]);

  const fileTree = React.useMemo(() => formatFileTree(_fileTree), [_fileTree]);

  const hierarchialFileTree = React.useMemo(() => {
    const formattedFileTree = formatFileTree(_fileTree);
    return buildFileTree(formattedFileTree, showEmptyDirectories);
  }, [_fileTree, showEmptyDirectories]);

  /*
  |------------------
  | Utils
  |------------------
  */

  function renderTreeNodes(
    _parentNode: GitHubFileTreeNodeWithChildren | null,
    nodes: GitHubFileTreeNodeWithChildren[],
    levelsDeep: number,
    maxWidth: number
  ): React.ReactNode {
    return [
      ...nodes
        .filter((node) => {
          if (node.type === 'file' && node.fullPath) {
            return true;
          }
          if (node.type === 'directory' && node.fullPath) {
            return true;
          }
          return false;
        })
        .map((node) => (
          <GitHubFileTreeItem
            className={cn([
              githubFileTreeViewClasses.item,
              `${githubFileTreeViewClasses.itemId}-${node.fullPath}`,
            ])}
            key={node.fullPath}
            itemId={node.fullPath}
            name={node.name}
            actions={
              !!onAddItem && (
                <GitHubFileTreeItemActionAddButton
                  onClick={() => {
                    onAddItem?.(`${node.fullPath}/`);
                  }}
                />
              )
            }
            type={node.type}
            hasChildren={!_.isNil(node.children) && node.children.length > 0}
            maxWidth={maxWidth - levelsDeep * GROUP_MARGIN}
          >
            {node.children &&
              renderTreeNodes(node, node.children, levelsDeep + 1, maxWidth)}
          </GitHubFileTreeItem>
        )),
    ];
  }

  /*
  |------------------
  | Handlers
  |------------------
  */

  const handleExpandedItemsChange = React.useCallback(
    (_e: React.SyntheticEvent, itemIds: string[]) => {
      if (_.isUndefined(expandedItems)) {
        setExpanded(itemIds);
      }

      if (onExpandedItemsChange) {
        onExpandedItemsChange(itemIds);
      }
    },
    [onExpandedItemsChange, expandedItems]
  );

  const handleSelectedItemsChange = React.useCallback(
    (_e: React.SyntheticEvent, itemId: string | null) => {
      if (showRoot && itemId === ROOT_ID) {
        const node = {
          fullPath: '/',
          parentDirectory: '/',
          name: '/',
          type: 'directory',
          children: null,
        };

        // If not controlled, update the selected item
        if (_.isUndefined(selectedItem)) {
          setSelected(node.fullPath);
        }

        if (_.isUndefined(expandedItems) && autoExpand) {
          setExpanded([ROOT_ID]);
        }

        if (onSelectedItemsChange) {
          onSelectedItemsChange(node as GitHubFileTreeNode);
        }
      } else {
        const node = fileTree.find((node) => node.fullPath === itemId);
        if (node) {
          // If not controlled, update the selected item
          if (_.isUndefined(selectedItem)) {
            setSelected(node.fullPath);
          }

          // If we are auto expanding, we expand the selected item
          if (_.isUndefined(expandedItems) && autoExpand) {
            setExpanded(getExpandedFromSelected(node.fullPath) ?? []);
          }
        }

        // We allow upstream to manage the selected item including null
        if (onSelectedItemsChange) {
          onSelectedItemsChange(node ?? null);
        }
      }
    },
    [
      fileTree,
      onSelectedItemsChange,
      showRoot,
      selectedItem,
      expandedItems,
      autoExpand,
    ]
  );

  return (
    <SimpleTreeView
      {...props}
      classes={{
        ...props.classes,
        root: githubFileTreeViewClasses.root,
      }}
      slots={{
        collapseIcon: () => <GitHubFileTreeIcon open={true} />,
        expandIcon: () => <GitHubFileTreeIcon open={false} />,
      }}
      multiSelect={false}
      selectedItems={computedSelectedItems}
      onSelectedItemsChange={handleSelectedItemsChange}
      expandedItems={computedExpandedItems}
      onExpandedItemsChange={handleExpandedItemsChange}
      sx={{
        ...props.sx,
        maxWidth: `${maxWidth}px`,
        overflowX: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        [`& .${treeItemClasses.content}`]: {
          marginRight: '0px !important',
          borderRadius: '4px',
          fontSize: '14px',
        },
        [`& .${treeItemClasses.iconContainer}.${treeItemClasses.iconContainer}`]:
          {
            alignItems: 'center',
            width: '32px',
            height: '28px',
          },
        [`& .${githubFileTreeViewClasses.item}`]: {
          [`& .${githubFileTreeViewClasses.itemAction}`]: {
            position: 'absolute',
            right: 0,
            top: '-2px',
          },
        },
      }}
    >
      {showRoot === true ? (
        <GitHubFileTreeItem
          key={ROOT_ID}
          itemId={ROOT_ID}
          name={ROOT_NAME}
          className={githubFileTreeViewClasses.item}
          hasChildren={true}
          actions={
            !!onAddItem && (
              <GitHubFileTreeItemActionAddButton
                onClick={() => {
                  onAddItem?.('');
                }}
              />
            )
          }
          type={'directory'}
          maxWidth={maxWidth}
        >
          {renderTreeNodes(null, hierarchialFileTree, 0, maxWidth)}
        </GitHubFileTreeItem>
      ) : (
        renderTreeNodes(null, hierarchialFileTree, 0, maxWidth)
      )}
    </SimpleTreeView>
  );
};

/*
|------------------
| Types
|------------------
*/

type BaseProps = Omit<
  SingleSelectTreeViewProps,
  | 'onExpandedItemsChange'
  | 'onSelectedItemsChange'
  | 'selectedItems'
  | 'defaultSelectedItems'
  | 'expandedItems'
  | 'defaultExpandedItems'
>;

interface GitHubFileTreeNode<C extends boolean = false> {
  fullPath: string;
  parentDirectory: string;
  name: string;
  type: GitHubFileTreeItemType;
  children: C extends true ? GitHubFileTreeNode[] : null;
}

interface GitHubFileTreeNodeWithChildren
  extends Omit<GitHubFileTreeNode, 'children'> {
  children: GitHubFileTreeNodeWithChildren[] | null;
}

/*
|------------------
| Components
|------------------
*/

/**
 * A button to add a new item.
 */
const GitHubFileTreeItemActionAddButton: React.FC<{
  /**
   * Callback for when the action is clicked.
   */
  onClick?: () => void;
}> = ({ onClick }) => {
  if (!onClick) {
    return null;
  }
  return (
    <IconButton
      className={githubFileTreeViewClasses.itemAction}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        onClick();
      }}
      size='small'
    >
      <RikerIcon icon='plus' size={16} />
    </IconButton>
  );
};

const GitHubFileTreeIcon: React.FC<{
  open: boolean;
}> = ({ open }) => {
  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      justifyContent={'center'}
      spacing={0.5}
    >
      <RikerIcon icon={open ? 'chevron-down' : 'chevron-right'} size={14} />
      <GitHubDirectoryIcon open={open} />
    </Stack>
  );
};

/*
|------------------
| Utils
|------------------
*/

/**
 * Gets the default expanded file tree items based on the selected file path.
 *
 * @param filePath A string representing the file path.
 * @returns An array of strings representing the default expanded file tree items.
 */
const getExpandedFromSelected = (filePath?: string) => {
  if (!filePath) {
    return undefined;
  }
  const filePathParts = filePath.split('/');
  const defaultExpanded: string[] = [];

  for (let i = 0; i < filePathParts.length - 1; i++) {
    defaultExpanded.push(filePathParts.slice(0, i + 1).join('/'));
  }

  return [...defaultExpanded, filePath];
};

/*
|------------------
| Tree Utils
|------------------
*/

const GROUP_MARGIN = 12;
const ROOT_NAME = '/';

/**
 * Formats a file tree response from the API.
 *
 * @param fileTree The file tree response.
 * @returns A list of file tree nodes.
 */
function formatFileTree(
  fileTree: APIResponse<'GET /github/repositories/:repositoryId/file-tree'>
): GitHubFileTreeNode[] {
  return _.chain(fileTree.tree)
    .map((node) => {
      const pathItems = node.path.split('/');
      const name = _.last(pathItems);
      const parentDirectory =
        pathItems.length > 1 ? _.dropRight(pathItems).join('/') : ROOT_ID;

      if (!name) return null;
      return {
        fullPath: node.path,
        parentDirectory,
        name,
        type: node.type,
        children: null,
      };
    })
    .compact()
    .value();
}

/**
 * Sorts file tree nodes.
 *
 * @param a A GitHubFileTreeNodeWithChildren
 * @param b A GitHubFileTreeNodeWithChildren
 * @returns A number indicating the sort order.
 */
function sortFileTreeNodes(
  a: GitHubFileTreeNodeWithChildren,
  b: GitHubFileTreeNodeWithChildren
) {
  // We sort directories first, then files, than we sort alphabetically
  if (a.type === 'directory' && b.type === 'file') {
    return -1;
  }
  if (a.type === 'file' && b.type === 'directory') {
    return 1;
  }
  if (a.type === b.type) {
    return a.fullPath.localeCompare(b.fullPath);
  }
  return 0;
}

/**
 * Build a file tree from a list of file tree nodes.
 *
 * @param fileTreeNodes The list of file tree nodes.
 * @param keepEmptyDirectories Whether to keep empty directories.
 * @returns A list of file tree nodes.
 */
function buildFileTree(
  fileTreeNodes: GitHubFileTreeNode[],
  keepEmptyDirectories = false
): GitHubFileTreeNodeWithChildren[] {
  const map: Record<string, number> = {};
  // biome-ignore lint/suspicious/noImplicitAnyLet: Allow let for this algorithm
  let node;
  const roots: GitHubFileTreeNodeWithChildren[] = [];
  let i: number;
  const list: GitHubFileTreeNodeWithChildren[] = [...fileTreeNodes];

  for (i = 0; i < list.length; i += 1) {
    map[list[i].fullPath] = i; // initialize the map
    list[i].children = []; // initialize the children
  }

  for (i = 0; i < list.length; i += 1) {
    node = list[i];
    if (node.parentDirectory !== ROOT_ID) {
      // if you have dangling branches check that map[node.parentDirectory] exists
      list[map[node.parentDirectory]]?.children?.push(node);
      list[map[node.parentDirectory]]?.children?.sort(sortFileTreeNodes);
    } else {
      roots.push(node);
    }
  }

  return roots.sort(sortFileTreeNodes).filter((x) => {
    // Filter empty directories
    if (
      !keepEmptyDirectories &&
      x.type === 'directory' &&
      x.children?.length === 0
    ) {
      return false;
    }
    return true;
  });
}

const getChildFiles = (
  node: GitHubFileTreeNodeWithChildren
): GitHubFileTreeNodeWithChildren[] => {
  if (node.type === 'file') {
    return [];
  }
  if (node.children?.every((child) => child.type === 'file')) {
    return node.children;
  }
  return [
    ...(node.children
      ?.filter((node) => node.type === 'directory')
      .flatMap((child) => getChildFiles(child)) ?? []),
    ...(node.children?.filter((node) => node.type === 'file') ?? []),
  ];
};
