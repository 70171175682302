import { useTheme } from '@mui/material';
import * as hookz from '@react-hookz/web';
import ReactCodeMirror, {
  type Extension,
  type ReactCodeMirrorProps,
} from '@uiw/react-codemirror';
import _ from 'lodash';
import React from 'react';

import { cn } from '@stargate/utils/styles';
import * as extensions from '../extensions';
import { useCodeMirrorDefaults } from '../hooks/use-codemirror-defaults';
import { useCodeMirrorTheme } from '../hooks/use-codemirror-theme';
import { getTopDownSelectionLines } from '../lib/helpers';
import { getLangType } from '../lib/langs';
import type { CodeMirrorLang } from '../types';

/*
|==========================================================================
| CodeMirror
|==========================================================================
|
| A wrapper around the ReactCodeMirror component that provides a default
| configuration and some additional features + a cleaner API.
|
*/

export const codeMirrorClassNames = {
  root: 'cm-root',
  line: 'cm-line',
  themeDark: 'cm-dark-mode',
  themeLight: 'cm-light-mode',
};

export interface CodeMirrorProps {
  /**
   * The code snippet to render
   */
  code: string;

  /**
   * The language of the code snippet
   */
  lang: CodeMirrorLang;

  /**
   * Mark the editor as read-only
   */
  readonly?: boolean;

  /**
   * Offset the line numbers by a certain amount, this is used
   * to render partial code snippets.
   */
  lineNumberOffset?: number;

  /**
   * Select the entire line when the cursor is on it
   */
  selectEntireLines?: boolean;

  /**
   * Callback for when the user selects a range of code
   */
  onCodeSelection?: (lineNumbers: { start: number; end: number }) => void;

  /**
   * Callback for when the code has changed
   */
  onCodeChange?: (code: string) => void;

  /**
   * Callback for when the user presses a key
   */
  onKeyUp?: extensions.EventHandlers['keyup'];

  /**
   * Override the props of the underlying ReactCodeMirror component
   */
  ReactCodeMirrorProps?: ReactCodeMirrorProps;
}

export const CodeMirror = React.memo<CodeMirrorProps>(
  ({ onCodeSelection, onCodeChange, onKeyUp, ...props }) => {
    const codeMirrorTheme = useCodeMirrorTheme();
    const defaultCodeMirrorProps = useCodeMirrorDefaults(
      props.ReactCodeMirrorProps
    );
    const theme = useTheme();

    /*
    |------------------
    | Computed
    |------------------
    */

    const allExtensions = React.useMemo(() => {
      const exts: Extension[] = [
        extensions.core,
        extensions.language(getLangType(props.lang)),
      ];

      if (props.lineNumberOffset !== undefined) {
        exts.push(extensions.lineNumberOffset(props.lineNumberOffset));
      }

      if (props.selectEntireLines) {
        exts.push(extensions.highlightEntireLine());
      }

      if (onKeyUp) {
        exts.push(
          extensions.events({
            keyup: onKeyUp,
          })
        );
      }

      return exts;
    }, [props.lang, onKeyUp, props.selectEntireLines, props.lineNumberOffset]);

    /*
    |------------------
    | Handlers
    |------------------
    */

    /**
     * Handles changes to the CodeMirror value and fires the
     * onCodeChange callback if the code has changed.
     */
    const handleChange = hookz.useDebouncedCallback<OnChangeFunc>(
      (value) => {
        if (!_.isNil(onCodeChange)) {
          onCodeChange(value);
        }
      },
      [onCodeChange],
      500
    );

    /**
     * Handles updates to the CodeMirror view and fires the
     * onCodeSelection callback if the selection has changed.
     */
    const handleUpdate = hookz.useDebouncedCallback<OnUpdateFunc>(
      (updateView) => {
        if (!_.isNil(onCodeSelection)) {
          // 1. If the selection has been set, fire the callback
          // 2. If the focus has changed and the selection is empty, fire the callback (this is a workaround for mounting an editor as it automatically has a "activeLine")
          const [range] = updateView.state.selection.ranges;
          if (
            updateView.selectionSet ||
            (updateView.focusChanged && range && range.empty)
          ) {
            const { start, end } = getTopDownSelectionLines(updateView);

            onCodeSelection({
              start: start.number,
              end: end.number,
            });
          }
        }
      },
      [onCodeSelection],
      0
    );

    return (
      <ReactCodeMirror
        className={cn(codeMirrorClassNames.root, {
          [codeMirrorClassNames.themeDark]: theme.palette.mode === 'dark',
          [codeMirrorClassNames.themeLight]: theme.palette.mode === 'light',
        })}
        {...defaultCodeMirrorProps}
        readOnly={props.readonly ?? false}
        value={props.code}
        theme={codeMirrorTheme}
        onChange={handleChange}
        onUpdate={handleUpdate}
        extensions={allExtensions}
      />
    );
  }
);
CodeMirror.displayName = 'CodeMirror';

/*
|------------------
| Utils
|------------------
*/

type OnChangeFunc = Exclude<ReactCodeMirrorProps['onChange'], undefined>;

type OnUpdateFunc = Exclude<ReactCodeMirrorProps['onUpdate'], undefined>;
