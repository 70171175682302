import { Box } from '@mui/material';
import React from 'react';

import { createComponentClasses } from '@/theme';
import { cn } from '@/utils/styles';

export const iconEmojiClasses = createComponentClasses('IconEmoji', [
  'root',
] as const);

export interface IconEmojiProps {
  emoji: string;
  size?: number | string;
  className?: string;
}

/**
 * Component for an Emoji Icon
 */
export const IconEmoji = React.forwardRef<HTMLDivElement, IconEmojiProps>(
  ({ size = 24, className, emoji }, ref) => {
    return (
      <Box
        ref={ref}
        className={cn(iconEmojiClasses.root, className)}
        sx={{
          [`&.${iconEmojiClasses.root}`]: {
            fontSize: `${size}px !important`,
          },
        }}
      >
        {emoji}
      </Box>
    );
  }
);
IconEmoji.displayName = 'IconEmoji';
