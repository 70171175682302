import { Box, useTheme } from '@mui/material';
import React from 'react';

import { Outlet } from '@stargate/routes';

import { ErrorGuard } from '@stargate/components/Guards';
import useSideBar from '../hooks/use-side-bar';

export interface MainContentProps {
  children?: React.ReactNode;
}

/**
 * Main content section for the dashboard layout.
 */
export const MainContent: React.FC<MainContentProps> = (props) => {
  const theme = useTheme();
  const [sidebarState] = useSideBar();

  const sideBarWidth = React.useMemo(() => {
    if (sidebarState.drawerOpen) {
      return theme.measurements.sideBar.width + sidebarState.drawerWidth;
    }
    return theme.measurements.sideBar.width;
  }, [
    sidebarState.drawerOpen,
    sidebarState.drawerWidth,
    theme.measurements.sideBar.width,
  ]);

  return (
    <Box
      component='main'
      sx={{
        minHeight: `calc(100vh - ${theme.measurements.topBar.height}px)`,
        width: `calc(100vw - ${sideBarWidth}px)`,
        ml: sidebarState.drawerOpen ? `${sidebarState.drawerWidth}px` : 0,
        mt: `${theme.measurements.topBar.height}px`,
        p: 0,
      }}
    >
      <ErrorGuard>
        <Outlet />
      </ErrorGuard>
      {props.children}
    </Box>
  );
};

export default MainContent;
