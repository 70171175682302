import api, { createClientCacheStore } from '@stargate/api';
import type { HttpError } from '@stargate/lib/http';
import React from 'react';

import { useUser } from '@stargate/features/user';

import type { ActiveWorkspace } from '../types';

export type UseWorkspaceHook = {
  /**
   * Load the state from the API.
   */
  load: () => Promise<ActiveWorkspace | null>;

  /**
   * Refresh the state from the API.
   */
  refresh: () => Promise<ActiveWorkspace | null>;

  /**
   * Update the state from the API.
   */
  update: (workspaceId: string) => Promise<ActiveWorkspace | null>;

  /**
   * Reset the state.
   */
  reset: () => void;

  /**
   * The loading state.
   */
  loading: boolean;

  /**
   * The error state.
   */
  error: HttpError | Error | null;

  /**
   * The data from the API.
   */
  data: ActiveWorkspace | null;
};

const useApiClientStore = createClientCacheStore('GET /workspace');

export function useWorkspace(): UseWorkspaceHook {
  const apiStore = useApiClientStore();
  const [, apiActions] = api.useRequestClient('GET /workspace');
  const [, updateActions] = api.useRequestClient('PATCH /workspace');

  const update = React.useCallback(
    async (workspaceId: string) => {
      try {
        apiStore.setStatus('loading');
        const result = await updateActions.execute({
          body: {
            workspaceId,
          },
        });
        apiStore.setData(result);
        apiStore.setStatus('success');
        return result;
      } catch (error) {
        apiStore.setError(
          error instanceof Error ? error : new Error('Unknown error')
        );
        throw error;
      }
    },
    [apiStore, updateActions]
  );

  const refresh = React.useCallback(async () => {
    try {
      const result = await apiActions.execute();
      apiStore.setData(result);
      return result;
    } catch (error) {
      apiStore.setError(
        error instanceof Error ? error : new Error('Unknown error')
      );
      throw error;
    }
  }, [apiActions, apiStore]);

  const load = React.useCallback(async () => {
    try {
      apiStore.setStatus('loading');
      const result = await apiActions.execute();
      apiStore.setData(result);
      return result;
    } catch (error) {
      apiStore.setError(
        error instanceof Error ? error : new Error('Unknown error')
      );
      throw error;
    }
  }, [apiActions, apiStore]);

  const reset = React.useCallback(() => {
    apiStore.clearData();
  }, [apiStore]);

  return React.useMemo(
    () => ({
      data: apiStore.data ?? null,
      error: apiStore.error,
      loading: apiStore.status === 'loading',
      load,
      refresh,
      reset,
      update,
    }),
    [
      apiStore.data,
      apiStore.error,
      apiStore.status,
      load,
      refresh,
      reset,
      update,
    ]
  );
}
