import React from 'react';

import { Link, type SxProps, useTheme } from '@mui/material';

import { ImgLogo } from '@stargate/components/Images';
import { Link as RouterLink } from '@stargate/routes';
import { createComponentClasses } from '@stargate/theme';

export const logoAnchorClasses = createComponentClasses('LogoAnchor', [
  'root',
  'img',
] as const);

export interface LogoAnchorProps {
  sx?: SxProps;
}

const LogoAnchor: React.FC<LogoAnchorProps> = (props) => {
  const theme = useTheme();
  return (
    <Link
      className={logoAnchorClasses.root}
      component={RouterLink}
      to='app.root'
      sx={props.sx}
    >
      <ImgLogo
        className={logoAnchorClasses.img}
        logo='full'
        contrast={theme.palette.mode === 'dark'}
        responsive
      />
    </Link>
  );
};

export default LogoAnchor;
