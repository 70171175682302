import type { Theme as MuiTheme } from '@mui/material';
import { type Components, darken } from '@mui/material/styles';

import type {} from '@mui/lab/themeAugmentation';

export function createComponents(baseTheme: MuiTheme): Components {
  return {
    MuiButton: {
      defaultProps: {
        variant: 'contained',
      },
      styleOverrides: {
        root: {
          fontWeight: 500,
          borderRadius: '4px',
          textTransform: 'none',
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          backgroundImage: 'none',
        },
        rounded: {
          borderRadius: baseTheme.spacing(1),
        },
      },
    },
    MuiPopover: {
      defaultProps: {
        slotProps: {
          paper: {
            variant: 'outlined',
          },
        },
      },
    },
    MuiCard: {
      defaultProps: {
        elevation: 0,
        variant: 'outlined',
      },
      styleOverrides: {
        root: {
          borderColor:
            baseTheme.palette.mode === 'dark'
              ? darken(baseTheme.palette.divider, 0.5)
              : baseTheme.palette.divider,
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          paddingTop: baseTheme.spacing(1),
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          paddingLeft: baseTheme.spacing(2),
          paddingRight: baseTheme.spacing(2),
          paddingBottom: baseTheme.spacing(2),
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: baseTheme.palette.text.primary,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          variants: [
            {
              props: { shape: 'rounded' },
              style: {
                borderRadius: '6px',
              },
            },
          ],
        },
      },
    },
  };
}

export type ChipShape = 'rounded' | 'circular';

declare module '@mui/material/Chip' {
  interface ChipOwnProps {
    shape?: ChipShape;
  }
}
