import type { Theme } from '@mui/material';

export interface ThemeMeasurements {
  topBar: {
    height: number;
  };
  sideBar: {
    width: number;
    widthClosed: number;
  };
}

export function createMeasurements(baseTheme: Theme): ThemeMeasurements {
  return {
    topBar: {
      height: 56,
    },
    sideBar: {
      width: 64,
      widthClosed: 64,
    },
  };
}

declare module '@mui/material' {
  interface Theme {
    measurements: ThemeMeasurements;
  }
}
