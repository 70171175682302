import { useTheme } from '@mui/material';
import React from 'react';
import type * as TypeFest from 'type-fest';
import TablerIconDragDrop from '~icons/tabler/drag-drop';

import { createComponentClasses } from '@/theme';
import { ACCEPT, type DndAccept } from '../../hooks/use-drag-and-drop';
import type { DirectoryTreeNode } from '../../types';
import { DirectoryTreeItem } from './DirectoryTreeItem';

/*
|==========================================================================
| DirectoryTreeItemEmpty
|==========================================================================
|
| A tree view item for the child of a directory that has no children.
|
*/

export const directoryTreeItemEmptyClasses = createComponentClasses(
  'DirectoryTreeItemEmpty',
  ['root', 'iconContainer', 'group', 'label', 'content'] as const
);

export type DirectoryTreeItemEmptyProps = TypeFest.Simplify<{
  directory: DirectoryTreeNode;
  accept?: DndAccept[];

  /**
   * A ref to the TreeView
   */
  treeViewRef?: React.RefObject<HTMLUListElement>;
}>;

export const DirectoryTreeItemEmpty: React.FC<DirectoryTreeItemEmptyProps> = ({
  directory,
  treeViewRef,
  accept = ACCEPT,
}) => {
  const theme = useTheme();

  /*
  |------------------
  | Computed
  |------------------
  */

  const label = React.useMemo(() => {
    if (accept.includes('directory') && accept.includes('document')) {
      return 'Drag & drop directories and documents here';
    }
    return 'Drag & drop documents here';
  }, [accept]);

  const dndNode = React.useMemo(() => {
    return {
      id: directory.id,
      title: 'empty',
      nodeType: 'directory',
      ancestors: [],
      status: 'published',
    } as const satisfies DirectoryTreeNode;
  }, [directory]);

  return (
    <DirectoryTreeItem
      node={dndNode}
      classes={directoryTreeItemEmptyClasses}
      disabled
      itemId={`${directory.id}-empty`}
      treeViewRef={treeViewRef}
      slots={{
        icon: () => <TablerIconDragDrop width={16} height={16} />,
      }}
      hideDroppableArea
      label={label}
      sx={{
        border: '1px dashed',
        borderColor: theme.palette.divider,
        borderRadius: '8px',
        m: 0.5,
        [`& .${directoryTreeItemEmptyClasses.label}`]: {
          fontStyle: 'italic',
          fontSize: '12px',
        },
        [`& .${directoryTreeItemEmptyClasses.content}`]: {
          pl: 2,
          pt: 1,
          pb: 1,

          // Active States
          [['&:hover', '&:action', '&:focus'].join(', ')]: {
            bgcolor: theme.palette.action.hover,
          },
        },
      }}
    />
  );
};
