import { Box, Tooltip, useTheme } from '@mui/material';
import React from 'react';

import { useQuackrPlacement } from '../hooks/use-quackr-placement';
import type { QuackrPlacement } from '../types';

export const quackrButtonClassNames = {
  root: 'QuackrButton-root',
  img: 'QuackrButton-img',
};

export interface QuackrButtonProps {
  /**
   * The placement of the Quackr button.
   */
  placement: QuackrPlacement;

  /**
   * A callback for when the button is clicked.
   */
  onClick: (e: React.SyntheticEvent) => void;
}

/**
 * A button for opening/closing the Quackr devtools.
 */
export const QuackrButton: React.FC<QuackrButtonProps> = ({
  placement,
  onClick,
}) => {
  const theme = useTheme();
  const placementStyles = useQuackrPlacement(placement);

  const tooltipPlacement = React.useMemo(() => {
    if (placement.includes('right')) {
      return 'left';
    }

    return 'right';
  }, [placement]);

  return (
    <Tooltip
      title='Quackr Dev Tools'
      placement={tooltipPlacement}
      enterDelay={2000}
      arrow
    >
      <Box
        className={quackrButtonClassNames.root}
        onClick={onClick}
        onKeyDown={onClick}
        sx={{
          [`&.${quackrButtonClassNames.root}`]: {
            ...placementStyles,
            zIndex: 9999,
            cursor: 'pointer',
            backgroundColor: theme.palette.divider,
            p: 1,
            borderRadius: '4px',
            width: '64px',
            height: '64px',
            '&:hover': {
              backgroundColor: theme.palette.action.hover,
            },
            [`& .${quackrButtonClassNames.img}`]: {
              width: '100%',
              height: 'auto',
            },
          },
        }}
      >
        <img
          src='https://storage.googleapis.com/joggr-public-assets/quackr/quackr-icon-rounded.png'
          alt='Quackr Logo Button'
          className={quackrButtonClassNames.img}
        />
      </Box>
    </Tooltip>
  );
};
