import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  type PaletteMode,
  Select,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import React from 'react';

import { ModeIcon, useUserSettings } from '@stargate/features/user';

import SettingsSectionHeader from '../SettingsSectionHeader';

function Appearance() {
  const userSettings = useUserSettings();
  return (
    <Box>
      <SettingsSectionHeader
        title='Appearance'
        description='Choose how Joggr appears to you, such as changing the interface theme.'
      />
      <Stack direction='column' spacing={2}>
        <FormControl>
          <InputLabel id='appearance-select-theme'>Theme</InputLabel>
          <Select
            value={userSettings.data.theme}
            onChange={(e) => {
              userSettings.onUpdate({
                theme: e.target.value as PaletteMode,
              });
            }}
            sx={{
              maxWidth: '200px',
            }}
            label='Theme'
            size='medium'
            labelId='appearance-select-theme'
          >
            <MenuItem value='light'>
              <Stack direction='row' spacing={1} alignItems='center'>
                <ModeIcon mode='light' size={18} />
                <Typography variant='body1'>Joggr Light</Typography>
              </Stack>
            </MenuItem>
            <MenuItem value='dark'>
              <Stack direction='row' spacing={1} alignItems='center'>
                <ModeIcon mode='dark' size={18} />
                <Typography variant='body1'>Joggr Dark</Typography>
              </Stack>
            </MenuItem>
          </Select>
          <FormHelperText variant='standard'>
            Automatically set based on your system's preference.
          </FormHelperText>
        </FormControl>
        <FormControl>
          <FormControlLabel
            control={
              <Switch
                checked={userSettings.data.editorStyle === 'markdown'}
                onChange={(e, checked) => {
                  userSettings.onUpdate({
                    editorStyle: checked ? 'markdown' : 'wysiwyg',
                  });
                }}
              />
            }
            label='Show Markdown Styles'
            disabled
          />
          <FormHelperText variant='standard'>
            Toggle showing the markdown language styles in the editor.
          </FormHelperText>
        </FormControl>
      </Stack>
    </Box>
  );
}

export default Appearance;
