import { RikerIcon } from '@joggrdocs/riker-icons';
import React from 'react';

import { ButtonLink } from '@stargate/components/Buttons';
import { createComponentClasses } from '@stargate/theme';

import type { JDocComponentProps } from '../types';

export const joggrDocModeSwitchClasses = createComponentClasses(
  'JoggrDocModeSwitch',
  ['root'] as const
);

export type JoggrDocModeSwitchProps = JDocComponentProps;

export const JoggrDocModeSwitch: React.FC<JoggrDocModeSwitchProps> = ({
  mode,
  doc,
}) => {
  if (mode === 'create' || !doc) {
    return null;
  }

  return (
    <ButtonLink
      size='small'
      variant='text'
      to={`app.documents.${mode === 'view' ? 'edit' : 'view'}`}
      params={{ id: doc.id }}
      sx={(theme) => ({
        color: theme.palette.text.primary,
      })}
      startIcon={<RikerIcon icon={mode === 'view' ? 'pencil' : 'arrow-up'} />}
    >
      {mode === 'view' ? 'Edit' : 'View'}
    </ButtonLink>
  );
};
