import { Box, MenuList, Typography } from '@mui/material';
import React from 'react';

import { useLocation, useNavigate } from '@stargate/routes';

import type { SearchResults } from '../types';
import { SearchBarResultItem } from './SearchBarResultItem';

export interface SearchResultsProps {
  searchResults: SearchResults;
  focusIndex: number;
  onItemKeyDown: (e: React.KeyboardEvent<HTMLAnchorElement>) => void;
  onItemClick: (id: string) => void;
}

export const SearchBarResults = React.forwardRef<
  HTMLUListElement,
  SearchResultsProps
>((props, ref) => {
  const { documents = [] } = props.searchResults;
  const navigate = useNavigate();
  const location = useLocation();

  /*
  |------------------
  | Compute
  |------------------
  */

  const isActive = React.useCallback(
    (documentId: string) => {
      return (
        location.active('app.documents.view', { id: documentId }) ||
        location.active('app.documents.edit', { id: documentId })
      );
    },
    [location]
  );

  // Sort documents so the active one is first
  const sortedDocuments = React.useMemo(() => {
    return documents.sort((a, b) => {
      const aActive = isActive(a.id) ? 1 : 0;
      const bActive = isActive(b.id) ? 1 : 0;
      return bActive - aActive;
    });
  }, [documents, isActive]);

  /*
  |------------------
  | Handlers
  |------------------
  */

  const createClickHandler = (id: string) => () => {
    props.onItemClick(id);
  };

  /*
  |------------------
  | Computed
  |------------------
  */

  if (documents.length === 0) {
    return (
      <Box sx={{ p: 2 }}>
        <Typography variant='body2' color='text.secondary'>
          Sorry, no results found. Please try another search.
        </Typography>
      </Box>
    );
  }
  return (
    <MenuList ref={ref} dense>
      {sortedDocuments.map((document, i) => {
        return (
          <SearchBarResultItem
            key={document.id}
            item={document}
            autoFocus={i === props.focusIndex}
            onItemKeyDown={props.onItemKeyDown}
            onItemClick={createClickHandler(document.id)}
            active={isActive(document.id)}
          />
        );
      })}
    </MenuList>
  );
});
SearchBarResults.displayName = 'SearchBarResults';
