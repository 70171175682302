import { Chip, type ChipProps, type SxProps } from '@mui/material';
import React from 'react';
import TablerIconBarrierBlock from '~icons/tabler/barrier-block';

export interface ComingSoonChipProps extends ChipProps {
  label?: string;
  size?: 'small' | 'medium';
  sx?: SxProps;
  showIcon?: boolean;
}

const ComingSoonChip = React.forwardRef<HTMLDivElement, ComingSoonChipProps>(
  (props, ref) => {
    const {
      label = 'Coming Soon',
      size = 'small',
      showIcon = false,
      ...restProps
    } = props;
    return (
      <Chip
        {...restProps}
        avatar={showIcon ? <TablerIconBarrierBlock /> : undefined}
        ref={ref}
        label={label}
        size={size}
        variant='outlined'
        color='primary'
      />
    );
  }
);
ComingSoonChip.displayName = 'ComingSoonChip';
ComingSoonChip.muiName = 'Chip';

export default ComingSoonChip;
