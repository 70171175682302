import * as hookz from '@react-hookz/web';
import React from 'react';

import { Box, type BoxProps } from '@mui/material';

import { createComponentClasses } from '@stargate/theme';

import { useLoomGifEmbed } from '../hooks/use-loom-gif-embed';
import type { LoomOembed } from '../hooks/use-loom-oembed';

/*
|==========================================================================
| LoomGif
|==========================================================================
|
| Component for rendering a LoomGif image.
|
*/

export const loomGifClasses = createComponentClasses('LoomGif', [
  'root',
] as const);

export interface LoomGifProps {
  loom: string | LoomOembed;
  sx?: BoxProps<'img'>['sx'];
}

/**
 * Component for rendering a LoomGif image.
 */
export const LoomGif: React.FC<LoomGifProps> = (props) => {
  const [loomGifState, loomGifActions] = useLoomGifEmbed();

  const src = React.useMemo(() => {
    if (typeof props.loom === 'string') {
      return loomGifState.result;
    }
    return props.loom?.thumbnail_url;
  }, [props.loom, loomGifState.result]);

  hookz.useMountEffect(() => {
    if (typeof props.loom === 'string') {
      void loomGifActions.execute(props.loom);
    }
  });

  return (
    <Box {...props} component='img' className={loomGifClasses.root} src={src} />
  );
};
