import {
  type DashDraftJSONContent,
  convertJSONToMarkdown,
  convertMarkdownToJSON,
} from '@stargate/dashdraft';
import { decodeBase64, encodeBase64 } from '@stargate/utils/files';

/**
 * Transform a DashDraftJSONContent object into an API friendly content string.
 *
 * @param content The content to transform.
 * @returns The transformed content
 */
export const transformToAPIContent = async (content: DashDraftJSONContent) => {
  return encodeBase64(await convertJSONToMarkdown(content));
};

/**
 * Transform an API markdown content string into a DashDraftJSONContent object.
 *
 * @param content The content to transform.
 * @returns The transformed content
 */
export const transformFromAPIContent = async (content: string) => {
  return convertMarkdownToJSON(decodeBase64(content));
};
