import { Box, Button, Link, Stack, Typography, useTheme } from '@mui/material';
import React from 'react';
import { match } from 'ts-pattern';
import TablerIconArrowBack from '~icons/tabler/arrow-back';
import TablerIconHome from '~icons/tabler/home';
import TablerIconRefresh from '~icons/tabler/refresh';

import { ImgError } from '@stargate/components/Images';
import { DebugId } from '@stargate/features/user-support';
import { FormattedMessage } from '@stargate/localization';

import { Feedback, type FeedbackProps } from './Feedback';

/*
|==========================================================================
| ErrorFeedback
|==========================================================================
|
| Provide Feedback to Users for Errors.
|
*/

export interface ErrorFeedbackProps extends Partial<FeedbackProps> {
  /**
   * Type of error.
   */
  type:
    | 'unknown'
    | 'not-found'
    | 'bad-request'
    | 'unauthorized'
    | 'forbidden'
    | 'internal-server-error';

  /**
   * URL to reload the page.
   */
  reloadUrl?: string;

  /**
   * Callback to reload the page.
   */
  onReload?: (e: React.SyntheticEvent) => void;
}

function ErrorFeedback(
  props: ErrorFeedbackProps,
  ref: React.Ref<HTMLDivElement>
) {
  const { type = 'unknown', reloadUrl, onReload, ...restProps } = props;
  const theme = useTheme();

  /*
  |------------------
  | Callbacks
  |------------------
  */

  const handleBackClick = React.useCallback(() => {
    window.history.back();
  }, []);

  return (
    <Feedback
      ref={ref}
      title={<FormattedMessage id={`error.type.${type}.title`} />}
      message={
        props.message ?? (
          <FormattedMessage id={`error.type.${type}.description`} />
        )
      }
      imagery={
        <ImgError type={type} sx={{ width: '100%', maxWidth: '400px' }} />
      }
      actions={
        <Box>
          <Stack direction='row' spacing={1} justifyContent='center'>
            <Button
              LinkComponent='a'
              size='large'
              variant='contained'
              startIcon={<TablerIconHome />}
              sx={{ marginLeft: '8px' }}
              href={`${window.location.origin}/app/home`}
              fullWidth
            >
              Go to Home Page
            </Button>
            {reloadUrl && (
              <Button
                LinkComponent='a'
                size='large'
                variant='outlined'
                startIcon={<TablerIconRefresh />}
                href={reloadUrl}
                onClick={onReload}
                fullWidth
              >
                Reload Page
              </Button>
            )}
            {!reloadUrl && window.history.length > 0 && (
              <Button
                size='large'
                variant='outlined'
                startIcon={<TablerIconArrowBack />}
                onClick={handleBackClick}
                fullWidth
              >
                Go Back
              </Button>
            )}
          </Stack>
          <Typography
            variant='caption'
            textAlign='center'
            sx={{
              mt: 2,
              color: theme.palette.text.primary,
            }}
          >
            If the problem persists, please contact support at{' '}
            <Link href='mailto:support@joggr.io'>support@joggr.io</Link> & share
            your Session ID (below).
          </Typography>
          <Box
            sx={{
              px: 1,
              mt: 1,
              mr: 'auto',
              ml: 'auto',
              width: 'fit-content',
              borderRadius: '4px',
              bgcolor: match(theme.palette.mode)
                .with('light', () => theme.palette.grey[100])
                .with('dark', () => theme.palette.grey[900])
                .exhaustive(),
            }}
          >
            <DebugId />
          </Box>
        </Box>
      }
      {...restProps}
    />
  );
}

export default React.forwardRef(ErrorFeedback);
