import { Button } from '@mui/material';
import React from 'react';
import TablerIconEdit from '~icons/tabler/edit';

import { Actions } from '@stargate/components/Actions';
import { useNotify } from '@stargate/lib/notify';
import {
  SirenaLogo,
  SirenaViewer,
  useLegacyMermaidRender,
  useMermaidDocs,
  useMermaidDownload,
} from '@stargate/lib/sirena';

import {
  CodeBlockCard,
  CodeBlockCardContent,
  CodeBlockCardHeader,
} from '../../../../components/CodeBlockCards';
import MermaidViewDialog from './MermaidViewDialog';

/*
|==========================================================================
| CodeBlockMermaid
|==========================================================================
|
| Render the mermaid "CodeBlock" component, that shows the mermaid graph & allows editing.
|
*/

type Action = 'download' | 'delete' | 'view-docs' | 'full-screen' | 'edit';

/*
|------------------
| Public API
|------------------
*/

export interface MermaidCodeBlockProps {
  readOnly?: boolean;
  graphCode?: string;
  onDelete?: () => void;
  onOpenEditor?: () => void;
}

export const MermaidCodeBlock: React.FC<MermaidCodeBlockProps> = ({
  onDelete,
  onOpenEditor,
  readOnly = false,
  ...props
}) => {
  const mermaidRender = useLegacyMermaidRender();
  const mermaidDownload = useMermaidDownload();
  const { getUrlFromType } = useMermaidDocs();
  const notify = useNotify();
  const [openFullScreenDialog, setFullScreenOpenDialog] = React.useState(false);

  /*
  |------------------
  | Callbacks
  |------------------
  */

  const handleViewFullScreen = React.useCallback(() => {
    setFullScreenOpenDialog(true);
  }, []);

  const handleCloseFullScreen = React.useCallback(() => {
    setFullScreenOpenDialog(false);
  }, []);

  const handleDownload = React.useCallback(() => {
    if (mermaidRender.graphSvg) {
      if (
        mermaidDownload(
          mermaidRender.graphSvg,
          mermaidRender.graphType ?? undefined
        )
      ) {
        notify.send({
          severity: 'success',
          message: 'Downloaded Mermaid Graph as SVG',
        });
      }
    }
  }, [
    mermaidDownload,
    mermaidRender.graphSvg,
    mermaidRender.graphType,
    notify,
  ]);

  const handleDelete = React.useCallback(() => {
    if (onDelete) {
      onDelete();
    }
  }, [onDelete]);

  const handleViewDocs = React.useCallback(() => {
    if (mermaidRender.graphType) {
      window.open(getUrlFromType(mermaidRender.graphType), '_blank');
    }
  }, [getUrlFromType, mermaidRender.graphType]);

  const handleAction = React.useCallback(
    (action: Action) => {
      switch (action) {
        case 'download':
          handleDownload();
          break;
        case 'delete':
          handleDelete();
          break;
        case 'edit':
          if (onOpenEditor) {
            onOpenEditor();
          }
          break;
        case 'full-screen':
          handleViewFullScreen();
          break;
        case 'view-docs':
          handleViewDocs();
          break;
      }
    },
    [
      handleDelete,
      handleViewFullScreen,
      handleDownload,
      handleViewDocs,
      onOpenEditor,
    ]
  );

  /*
  |------------------
  | Effects
  |------------------
  */

  React.useEffect(() => {
    if (props.graphCode) {
      mermaidRender.update(props.graphCode);
    }
  }, [props.graphCode]);

  return (
    <React.Fragment>
      <MermaidViewDialog
        svg={mermaidRender.graphSvg}
        graphType={mermaidRender.graphType}
        open={openFullScreenDialog}
        onClose={handleCloseFullScreen}
      />
      <CodeBlockCard className='dashdraft-diagram'>
        <CodeBlockCardHeader
          leftAction={<SirenaLogo size='medium' />}
          rightAction={
            <React.Fragment>
              {!readOnly && (
                <Button
                  startIcon={<TablerIconEdit />}
                  onClick={onOpenEditor}
                  size='small'
                >
                  Edit
                </Button>
              )}
              <Actions
                size='small'
                actions={[
                  {
                    type: 'button',
                    action: 'full-screen',
                    primaryLabel: 'Full Screen',
                    icon: 'arrows-maximize',
                    disabled: mermaidRender.graphType === null,
                  },
                  {
                    type: 'button',
                    action: 'view-docs',
                    primaryLabel: 'View Docs',
                    icon: 'arrow-up-right',
                    disabled: mermaidRender.graphType === null,
                  },
                  {
                    type: 'button',
                    action: 'download',
                    primaryLabel: 'Download',
                    icon: 'download',
                    disabled: !mermaidRender.graphSvg || !!mermaidRender.error,
                  },
                  { type: 'divider' },
                  {
                    type: 'button',
                    action: 'delete',
                    primaryLabel: 'Delete',
                    icon: 'trash',
                  },
                ]}
                onAction={handleAction}
              />
            </React.Fragment>
          }
        />
        <CodeBlockCardContent
          sx={{
            // bgcolor: theme.palette.background.paper,
            position: 'relative',
            flexGrow: 1,
            display: 'flex',
            // Override the default padding of the CardContent
            padding: '0 !important',
          }}
        >
          <SirenaViewer
            graphType={mermaidRender.graphType}
            svg={mermaidRender.graphSvg}
            loading={
              mermaidRender.status === 'loading' ||
              mermaidRender.status === 'idle'
            }
            error={mermaidRender.error}
          />
        </CodeBlockCardContent>
      </CodeBlockCard>
    </React.Fragment>
  );
};

const MemoizedMermaidCodeBlock = React.memo(MermaidCodeBlock);
MemoizedMermaidCodeBlock.displayName = 'MermaidCodeBlock';

export default MemoizedMermaidCodeBlock;
