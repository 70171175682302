import { createTheme as createMuiTheme } from '@mui/material';

import { createComponents } from './aspects/components';
import { createMeasurements } from './aspects/measurements';
import { createMixins } from './aspects/mixins';
import { createPalette } from './aspects/palette';
import { createShadows } from './aspects/shadows';
import { createTypography } from './aspects/typography';
import type { Theme, ThemeMode } from './types';

/**
 * Create a theme.
 * @param mode The theme mode.
 * @returns The theme.
 */
export function createTheme(mode: ThemeMode): Theme {
  const palette = createPalette(mode);
  const shadows = createShadows(mode);
  const baseTheme = createMuiTheme({ palette, shadows }) as unknown as Theme;
  const typography = createTypography(baseTheme);
  const components = createComponents(baseTheme);
  const mixins = createMixins(baseTheme);
  const measurements = createMeasurements(baseTheme);
  return {
    ...createMuiTheme({
      direction: 'ltr',
      palette,
      typography,
      components,
      mixins,
      shadows,
    }),
    measurements,
  };
}
