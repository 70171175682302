import { Backdrop, Box, Stack, Typography, useTheme } from '@mui/material';
import React from 'react';

import { IconAvatar } from '@stargate/components/Avatars';
import { createComponentClasses } from '@stargate/theme';
import { cn } from '@stargate/utils/styles';

import BlockEmpty from '@dashdraft/components/BlockEmpty';

export const codeBlockErrorClasses = createComponentClasses('CodeBlockError', [
  'root',
  'overlay',
] as const);

export interface CodeBlockErrorProps {
  /**
   * Whether or not to display as an overlay.
   *
   * @default false
   */
  overlay?: boolean;

  /**
   * The error message to display
   *
   * @default 'Unknown error, unable to render code block.'
   */
  message?: string;

  /**
   * Callback for when the user deletes the code block.
   */
  onDelete?: () => void;
}

export const CodeBlockError = React.memo<CodeBlockErrorProps>(
  ({
    message = 'Unknown error, unable to render code block.',
    overlay = false,
    onDelete,
  }) => {
    const theme = useTheme();
    const helperText = React.useMemo(() => {
      return (
        <Stack
          direction='column'
          alignContent='center'
          alignItems='center'
          sx={{ p: 4 }}
          spacing={2}
        >
          <Stack spacing={1} direction='row' alignItems='center'>
            <IconAvatar backgroundColor='error' icon='mood-sad' />
            <Typography variant='h4'>Codeblock Error</Typography>
          </Stack>
          <Typography variant='subtitle1'>{message}</Typography>
        </Stack>
      );
    }, [message]);

    if (overlay) {
      return (
        <Backdrop
          open
          sx={{
            zIndex: theme.zIndex.drawer - 1,
            position: 'absolute',
            inset: 0,
            overflow: 'hidden',
          }}
        >
          <BlockEmpty
            className={cn(
              codeBlockErrorClasses.root,
              codeBlockErrorClasses.overlay
            )}
            sx={{
              [`&.${codeBlockErrorClasses.overlay}`]: {
                maxWidth: '80%',
                borderRadius: theme.shape.borderRadius,
              },
            }}
            onClick={onDelete}
            helperText={helperText}
          />
        </Backdrop>
      );
    }

    return (
      <BlockEmpty
        className={codeBlockErrorClasses.root}
        onClick={onDelete}
        helperText={helperText}
      />
    );
  }
);
