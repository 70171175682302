import { RikerIcon } from '@joggrdocs/riker-icons';
import {
  Box,
  Card,
  IconButton,
  Slide,
  Tooltip,
  Typography,
  darken,
  lighten,
  useTheme,
} from '@mui/material';
import * as hookz from '@react-hookz/web';
import _ from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';

import { useResizeObserver } from '@stargate/hooks';
import type { DashDraftEditorStorage } from '@stargate/lib/dashdraft';
import { useLocation } from '@stargate/routes';
import { createComponentClasses } from '@stargate/theme';

import { useJDocLayout } from '../hooks/use-jdoc-layout';
import { useJDocTableOfContents } from '../hooks/use-jdoc-toc';
import type { JDocComponentProps } from '../types';

export const joggrDocTableOfContentsClasses = createComponentClasses(
  'JoggrDocTableOfContents',
  ['root'] as const
);

export type JoggrDocTableOfContentsProps = JDocComponentProps<{
  /**
   * Whether the table of contents is reloading
   */
  loading: boolean;
}>;

export const JoggrDocTableOfContents = React.memo<JoggrDocTableOfContentsProps>(
  ({ loading }) => {
    const tocState = useJDocTableOfContents();
    const [layout] = useJDocLayout();
    const { hash } = useLocation();

    // Get the width & innerWidth of the table of contents &
    // if we are in popover mode
    const { popover, width, innerWidth } = React.useMemo(() => {
      const popoverTriggerWidth = 200;
      const calculatedWidth = calculateMaxWidth(
        layout.containerSize.width,
        layout.contentSize.width
      );
      const popover = calculatedWidth <= popoverTriggerWidth;
      const width = Math.min(
        popover ? popoverTriggerWidth : calculatedWidth,
        // Set a limit so we don't have SUPER wide TOCs
        400
      );
      const innerWidth = width - CONTENT_PADDING * 2;
      return {
        popover,
        width,
        innerWidth,
      };
    }, [layout.containerSize.width, layout.contentSize.width]);

    const [open, setOpen] = React.useState<boolean>(!popover);
    const previousPopover = hookz.usePrevious(popover);
    const theme = useTheme();

    /*
    |------------------
    | Computed
    |------------------
    */

    // Automatically get the active item from the URL hash.
    const activeItem = React.useMemo(() => {
      const tocId = hash.replace('#', '');
      return (
        tocState.items.find((item) => item.node.attrs.id === tocId) ?? null
      );
    }, [tocState.items, hash]);

    const position = React.useMemo(() => {
      return {
        top:
          layout.headerPosition.top + layout.headerSize.height + BUTTON_PADDING,
        left: layout.headerPosition.left + BUTTON_PADDING,
      };
    }, [layout.headerPosition, layout.headerSize]);

    const highestLevel = React.useMemo(() => {
      return _.minBy(tocState.items, 'level')?.level ?? 1;
    }, [tocState.items]);

    const loadingSize = React.useMemo(() => {
      return _.some(
        [
          layout.contentSize.width,
          layout.containerSize.width,
          layout.headerSize.height,
          layout.headerPosition.top,
          layout.headerPosition.left,
        ],
        (val) => val === 0
      );
    }, [
      layout.contentSize.width,
      layout.containerSize.width,
      layout.headerSize.height,
      layout.headerPosition,
    ]);

    /*
    |------------------
    | Utils 
    |------------------
    */

    const getRelativeLevel = (level: number): number => {
      if (highestLevel === 1) {
        return level;
      }
      return Math.abs(highestLevel - level) + 1;
    };

    /*
    |------------------
    | Handlers
    |------------------
    */

    const handleToggle = (): void => {
      setOpen((prev) => !prev);
    };

    /*
    |------------------
    | Effects
    |------------------
    */

    const [buttonSize, setButtonSize] = React.useState({ width: 0, height: 0 });
    const buttonRef = useResizeObserver<HTMLButtonElement>({
      onResize: (size) => {
        setButtonSize({
          width: size.width ?? 0,
          height: size.height ?? 0,
        });
      },
    });

    // Close the table of contents if the sidebar switches to popover
    // or force it back open if the sidebar switches to large
    React.useEffect(() => {
      if (!_.isNil(previousPopover) && popover !== previousPopover) {
        setOpen(!popover);
      }
    }, [popover, previousPopover]);

    if (loading || loadingSize) {
      return null;
    }

    const heightOffset =
      layout.headerSize.height +
      layout.headerPosition.top +
      CONTENT_PADDING +
      buttonSize.height +
      CONTENT_TOP_MARGIN;

    return (
      <Box
        className={joggrDocTableOfContentsClasses.root}
        sx={{
          position: 'fixed',
          top: position.top,
          left: position.left,
          overflow: 'hidden',
          zIndex: popover && open ? theme.zIndex.drawer - 1 : 1,
        }}
      >
        {activeItem && <NavToActiveItem {...activeItem} />}
        <Tooltip
          title={open ? 'Close Document Outline' : 'Open Document Outline'}
          placement='right'
        >
          <IconButton
            ref={buttonRef}
            aria-owns={open ? 'table-of-contents-menu' : undefined}
            aria-haspopup='true'
            onClick={handleToggle}
            sx={{
              borderRadius: '4px',
              zIndex: theme.zIndex.modal - 1,
            }}
          >
            {open ? <RikerIcon icon='arrow-left' /> : <RikerIcon icon='list' />}
          </IconButton>
        </Tooltip>
        <Slide direction='right' in={open} unmountOnExit>
          <Card
            sx={{
              border: popover ? `1px solid ${theme.palette.divider}` : 'none',
              width: popover ? '100%' : `${width}px`,
              maxWidth: popover ? `${POPOVER_CONTENT_MAX_WIDTH}px` : undefined,
              minHeight: '200px',
              maxHeight: `calc(100vh - ${heightOffset + 40}px)`,
              overflowX: 'hidden',
              overflowY: 'auto',
              textOverflow: 'ellipsis',
              textWrap: 'nowrap',
              px: `${CONTENT_PADDING}px`,
              py: 1,
              mt: `${CONTENT_TOP_MARGIN}px`,
              mb: 1,
              '&&::-webkit-scrollbar': {
                width: '6px',
              },
            }}
          >
            <Typography
              variant='subtitle2'
              sx={{
                mb: 1,
                textTransform: 'uppercase',
                fontWeight: 700,
              }}
            >
              Outline
            </Typography>
            {tocState.items.length > 0 ? (
              tocState.items.map((item) => (
                <TOCItem
                  {...item}
                  key={item.node.attrs.id}
                  popover={popover}
                  width={innerWidth}
                  level={getRelativeLevel(item.level)}
                />
              ))
            ) : (
              <Box>Start adding headings …</Box>
            )}
          </Card>
        </Slide>
      </Box>
    );
  }
);

/*
|------------------
| Utils
|------------------
*/

type EditorTOCItem =
  DashDraftEditorStorage['tableOfContents']['content'][number];

/**
 * The padding around the button in pixels.
 */
const BUTTON_PADDING = 8;

/**
 * The top margin of the content of the table of contents in pixels.
 */
const CONTENT_TOP_MARGIN = 8;

/**
 * The padding around the content of the table of contents in pixels.
 */
const CONTENT_PADDING = 16;

/**
 * The maximum width in popover mode of the content of the table of contents in pixels.
 */
const POPOVER_CONTENT_MAX_WIDTH = 360;

/**
 * Get the font size of the heading based on the level.
 *
 * @param level A number representing the level of the heading
 * @returns A string representing the font size
 */
const getFontSize = (level: number): string => {
  if (level === 1) return '1.25rem';
  if (level === 2) return '1.125rem';
  if (level === 3) return '1.115rem';
  if (level === 4) return '0.95rem';
  return '0.875rem';
};

/**
 * Get the padding of the heading based on the level.
 *
 * @param level A number representing the level of the heading
 * @returns A string representing the padding
 */
const getPadding = (level: number): string => {
  if (level === 1) {
    return '0';
  }
  return `${1 * level - 1}rem`;
};

/**
 * Get the maximum width of the table of contents, by calculating the margin
 * and padding of the container and content.
 *
 * @param containerWidth A number representing the width of the container
 * @param contentWidth A number representing the width of the content
 * @returns A number representing the maximum width
 */
const calculateMaxWidth = (
  containerWidth: number,
  contentWidth: number
): number => {
  const totalMargin = containerWidth - contentWidth;
  return _.round(totalMargin / 2);
};

// We are using react here to scroll to the active item
// ONLY on mount.
const NavToActiveItem: React.FC<EditorTOCItem> = ({ dom }) => {
  hookz.useMountEffect(() => {
    dom.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  });
  return null;
};

/**
 * A single item in the table of contents.
 */
const TOCItem: React.FC<
  EditorTOCItem & {
    popover: boolean;
    width: number;
  }
> = ({ textContent, level, dom, popover, width, node }) => {
  const theme = useTheme();
  const { hash, search } = useLocation();
  const [innerMeasure, innerMeasureRef] = hookz.useMeasure<HTMLAnchorElement>();
  const [topMeasure, topMeasureRef] = hookz.useMeasure<HTMLDivElement>();
  const id = node.attrs.id;

  /*
  |------------------
  | Computed
  |------------------
  */

  const active = React.useMemo(() => {
    return hash.replace('#', '') === id;
  }, [hash, id]);

  const showTooltip = React.useMemo(() => {
    return (
      _.isNil(innerMeasure) ||
      _.isNil(topMeasure) ||
      innerMeasure.width >= topMeasure.width
    );
  }, [innerMeasure, topMeasure]);

  /*
  |------------------
  | Handlers
  |------------------
  */

  const handleClick = (): void => {
    dom.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  return (
    <React.Fragment key={id}>
      <Tooltip
        title={showTooltip ? textContent : undefined}
        placement='right'
        enterDelay={300}
      >
        <Box
          ref={topMeasureRef}
          sx={{
            pl: getPadding(level),
            borderRadius: '4px',
            textOverflow: 'ellipsis',
            textWrap: 'nowrap',
            width: popover ? undefined : `${width}px`,
            maxWidth: popover
              ? `calc(${POPOVER_CONTENT_MAX_WIDTH}px - ${CONTENT_PADDING * 2}px)`
              : undefined,
            overflow: 'hidden',
          }}
        >
          <Box
            component={Link}
            className={active ? 'active' : ''}
            to={{
              hash: id,
              search,
            }}
            onClick={handleClick}
            sx={{
              fontSize: getFontSize(level),
              textDecoration: 'none',
              display: 'inline',
              color: theme.palette.text.primary,
              '&:hover': {
                ...theme.applyStyles('light', {
                  color: lighten(theme.palette.text.primary, 0.25),
                }),
                ...theme.applyStyles('dark', {
                  color: darken(theme.palette.text.primary, 0.25),
                }),
              },
              '&.active': {
                color: theme.palette.primary.main,
              },
            }}
          >
            {textContent}
          </Box>
          <Box
            // Trick to allow us to get the width of the text content
            ref={innerMeasureRef}
            sx={{
              fontSize: getFontSize(level),
              display: 'inline-block',
              height: 0,
              float: 'left',
              clear: 'left',
              visibility: 'hidden',
            }}
          >
            {textContent}
          </Box>
        </Box>
      </Tooltip>
      <Box sx={{ mb: 1 }} />
    </React.Fragment>
  );
};
