import React from 'react';
import type * as TypeFest from 'type-fest';
import TablerIconUser from '~icons/tabler/user';

/*
|==========================================================================
| UserProfileIcon 
|==========================================================================
|
| Proxy component for the user icon.
|
*/

export type UserProfileIconProps = TypeFest.Simplify<
  Omit<React.ComponentProps<typeof TablerIconUser>, 'ref'>
>;

/**
 * Icon for user profile.
 */
export const UserProfileIcon = React.forwardRef<
  SVGSVGElement,
  UserProfileIconProps
>((props, ref) => <TablerIconUser {...props} ref={ref} />);
UserProfileIcon.displayName = 'UserProfileIcon';
