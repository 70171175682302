import {
  RikerIcon,
  type RikerIconComponent,
  type RikerIconName,
  type RikerIconProps,
} from '@joggrdocs/riker-icons';
import {} from '@mui/material';
import React from 'react';
import type * as TypeFest from 'type-fest';

import { createComponentClasses } from '@stargate/theme';

export const codeLinkIconClasses = createComponentClasses('CodeLinkIcon', [
  'root',
] as const);

export type CodeLinkIconProps = TypeFest.Simplify<Omit<RikerIconProps, 'icon'>>;

/**
 * The name of the code link icon.
 */
export const codeLinkIconName: RikerIconName = 'file-symlink';

/**
 * CodeLinkIcon
 */
export const CodeLinkIcon = React.forwardRef<
  RikerIconComponent,
  CodeLinkIconProps
>((props, ref) => {
  return (
    <RikerIcon
      {...props}
      classes={props.classes ?? codeLinkIconClasses}
      ref={ref}
      icon={codeLinkIconName}
    />
  );
});
