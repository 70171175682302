import _ from 'lodash';
import React from 'react';

import {
  Avatar,
  Box,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  MenuItem,
  MenuList,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';

import { ComingSoonChip } from '@stargate/components/Chips';
import { cn } from '@stargate/utils/styles';

import type { SlashCommandItem } from '../types';

/*
|==========================================================================
| SlashMenuCommandsList
|==========================================================================
|
| A list of commands in the Editor. 
|
*/

export interface SlashMenuCommandsListProps {
  title: string;
  items: SlashCommandItem[];
  selected?: SlashCommandItem['id'];
  onClick: (item: SlashCommandItem) => void;
}

export const SlashMenuCommandsList = React.memo<SlashMenuCommandsListProps>(
  (props) => {
    const theme = useTheme();

    const createClickHandler = (item: SlashCommandItem) => {
      return () => {
        props.onClick(item);
      };
    };

    if (props.items.length === 0) {
      return null;
    }

    return (
      <MenuList
        className='dashdraft-slashmenu-commands-list'
        subheader={<ListSubheader>{props.title}</ListSubheader>}
      >
        {props.items.map((item) => (
          <MenuItem
            key={item.title}
            selected={item.id === props.selected}
            onClick={item.comingSoon ? undefined : createClickHandler(item)}
            className={cn('dashdraft-slashmenu-commands-list-item', {
              selected: item.id === props.selected,
            })}
            sx={{
              cursor: item.comingSoon ? 'not-allowed' : undefined,
            }}
            disableRipple={item.comingSoon}
            disableTouchRipple={item.comingSoon}
          >
            <ListItemAvatar>
              <Avatar
                variant='rounded'
                sx={{
                  bgcolor: (theme) => {
                    if (item.comingSoon) {
                      return theme.palette.divider;
                    }
                    return theme.palette.primary.main;
                  },
                  color: (theme) => theme.palette.common.white,
                }}
              >
                <item.icon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <Stack direction='row' alignItems='center' spacing={1}>
                  <Typography
                    variant='body2'
                    sx={{
                      fontWeight: 600,
                    }}
                  >
                    {item.title}
                  </Typography>
                  {item.comingSoon && (
                    <ComingSoonChip
                      sx={{
                        fontSize: '12px',
                      }}
                    />
                  )}
                </Stack>
              }
              secondary={
                <Typography variant='body2' fontSize='14px'>
                  {item.description}
                </Typography>
              }
              disableTypography
            />
          </MenuItem>
        ))}
      </MenuList>
    );
  }
);
SlashMenuCommandsList.displayName = 'SlashMenuCommandsList';
