import _ from 'lodash';
import React from 'react';

import type { RikerIconName } from '@joggrdocs/riker-icons';
import { Box, Step, Stepper } from '@mui/material';

import { createComponentClasses } from '@stargate/theme';

import {
  type OnboardingStep,
  type OnboardingStepId,
  onboardingSteps,
} from '../hooks/use-user-onboarding';
import { type OnboardingStepItem, OnboardingStepLabel } from './OnboardingStep';
import {
  OnboardingStepAuthorizeGithub,
  OnboardingStepCompleted,
  OnboardingStepGithubApp,
  OnboardingStepInviteTeam,
  OnboardingStepWelcome,
} from './OnboardingSteps';

/*
|==========================================================================
| OnboardingStepper
|==========================================================================
|
| Component for OnboardingStepper
|
*/

const CONTAINER_WIDTH_MAX = 1000;

export interface OnboardingStepConfig extends OnboardingStep {
  allowSkip: boolean;
  icon: RikerIconName;
  label: React.ReactNode;
  component: React.ComponentType<OnboardingStepItem>;
}

/**
 * The steps in the onboarding process, in order (using the order property).
 */
export const steps = _.chain(onboardingSteps)
  .map((step) => {
    switch (step.id) {
      case 'welcome':
        return {
          ...step,
          label: 'Welcome',
          allowSkip: false,
          icon: 'rocket',
          component: OnboardingStepWelcome,
        } satisfies OnboardingStepConfig;
      case 'github-authorize':
        return {
          ...step,
          label: 'Authorize GitHub',
          allowSkip: false,
          icon: 'brand-github-filled',
          component: OnboardingStepAuthorizeGithub,
        } satisfies OnboardingStepConfig;
      case 'github-install-app':
        return {
          ...step,
          label: 'Install GitHub App',
          allowSkip: false,
          icon: 'apps-filled',
          component: OnboardingStepGithubApp,
        } satisfies OnboardingStepConfig;
      case 'invite-team-members':
        return {
          ...step,
          label: 'Invite Team',
          icon: 'send',
          allowSkip: true,
          component: OnboardingStepInviteTeam,
        } satisfies OnboardingStepConfig;
      case 'complete':
        return {
          ...step,
          label: 'Start Jogging!',
          allowSkip: false,
          icon: 'run',
          component: OnboardingStepCompleted,
        } satisfies OnboardingStepConfig;
      default:
        throw new Error('Unable to build steps');
    }
  })
  .sortBy('order')
  .value();

/*
|------------------
| Public API
|------------------
*/

export const onboardingStepperClasses = createComponentClasses(
  'OnboardingStepper',
  ['root', 'steps', 'content'] as const
);

export interface OnboardingStepperProps {
  /**
   * Active step id
   */
  activeStepId: OnboardingStepId;

  /**
   * List of completed step ids
   */
  completedStepIds: OnboardingStepId[];

  /**
   * Continue to the next step in the onboarding process.
   */
  onContinue: () => Promise<void>;

  /**
   * Skip the onboarding process step.
   */
  onSkip?: () => Promise<void>;
}

/**
 * Component for OnboardingStepper
 */
export const OnboardingStepper: React.FC<OnboardingStepperProps> = ({
  activeStepId,
  completedStepIds,
  onContinue,
  onSkip,
}) => {
  const orderedSteps = React.useMemo(() => {
    return _.chain(steps)
      .sortBy('order')
      .map((step) => ({
        ...step,
        active: step.id === activeStepId,
        completed: completedStepIds.includes(step.id),
      }))
      .value();
  }, [activeStepId, completedStepIds]);

  return (
    <Box
      className={onboardingStepperClasses.root}
      sx={{
        [`&.${onboardingStepperClasses.root}`]: {
          ml: 'auto',
          mr: 'auto',
          mt: 2,
          maxWidth: `${CONTAINER_WIDTH_MAX}px`,

          [`& .${onboardingStepperClasses.steps}`]: {
            mb: 4,
          },

          [`& .${onboardingStepperClasses.content}`]: {
            maxWidth: `${CONTAINER_WIDTH_MAX * 0.8}px`,
            m: 'auto',
          },
        },
      }}
    >
      <Stepper
        classes={{
          root: onboardingStepperClasses.steps,
        }}
      >
        {orderedSteps.map((step) => (
          <Step key={step.id}>
            <OnboardingStepLabel
              label={step.label}
              active={step.active}
              completed={step.completed}
              icon={step.icon}
            />
          </Step>
        ))}
      </Stepper>
      <Box className={onboardingStepperClasses.content}>
        {orderedSteps.map((step) => {
          const StepComponent = step.component;
          return step.active ? (
            <StepComponent
              key={step.id}
              active={step.active}
              completed={step.completed}
              onContinue={onContinue}
              onSkip={step.allowSkip ? onSkip : undefined}
            />
          ) : null;
        })}
      </Box>
    </Box>
  );
};
