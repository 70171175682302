import { RikerIcon } from '@joggrdocs/riker-icons';
import { Box, Chip, Skeleton, Stack, Tooltip } from '@mui/material';
import type { APIResponse } from '@stargate/api';
import { FilePathTruncate } from '@stargate/components/Utils';
import { CodeSnippetRange } from '@stargate/features/code';
import { GitHubRepositoryChip } from '@stargate/features/github';
import { createComponentClasses } from '@stargate/theme';
import React from 'react';
import type * as TypeFest from 'type-fest';

export const codeSnippetLocationClasses = createComponentClasses(
  'CodeSnippetLocation',
  ['root'] as const
);

export interface CodeSnippetLocationProps {
  /**
   * The code snippet to display.
   */
  codeSnippet: CodeSnippetData | null;
}

/**
 * Component for showing the location of a code snippet in the codebase.
 */
export const CodeSnippetLocation: React.FC<CodeSnippetLocationProps> =
  React.memo(({ codeSnippet }) => {
    return (
      <Stack
        direction='row'
        spacing={1}
        alignItems='center'
        sx={{
          width: '100%',
        }}
      >
        {codeSnippet && (
          <Tooltip
            arrow
            title={
              codeSnippet.access !== 'full'
                ? 'You do not have access to the GitHub Repository, that contains this Code Snippet'
                : null
            }
          >
            <Chip
              shape='rounded'
              icon={<RikerIcon icon='code-circle-2-filled' />}
              label={
                <Stack direction='row' spacing={0} alignItems='center'>
                  <Box>
                    <FilePathTruncate
                      maxCharacters={300}
                      filePath={codeSnippet.path}
                    />
                  </Box>
                  {codeSnippet.access === 'full' && (
                    <CodeSnippetRange
                      start={codeSnippet.lineNumberStart}
                      end={codeSnippet.lineNumberEnd}
                    />
                  )}
                </Stack>
              }
            />
          </Tooltip>
        )}
        {!codeSnippet && <Skeleton width='200px' height='36px' />}
        <Box sx={{ flexGrow: 1 }} />
        {codeSnippet && (
          <Tooltip
            arrow
            title={
              codeSnippet.access === 'full'
                ? 'View on GitHub'
                : 'You do not have access to the GitHub Repository or GitHub Organization, that contains this Code Snippet'
            }
          >
            <span>
              <GitHubRepositoryChip
                disableLink={codeSnippet.access !== 'full'}
                repo={codeSnippet.repository}
                owner={codeSnippet.repositoryOwner}
                shape='rounded'
              />
            </span>
          </Tooltip>
        )}
        {!codeSnippet && <Skeleton width='200px' height='36px' />}
      </Stack>
    );
  });
CodeSnippetLocation.displayName = 'CodeSnippetLocation';

type CodeSnippetData = TypeFest.SetRequired<
  APIResponse<'GET /code-sources/:codeSourceId'>,
  'lineNumberStart' | 'lineNumberEnd'
> | null;
