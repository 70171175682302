import { IconButton, useTheme } from '@mui/material';
import { DragHandle as TipTapDragHandle } from '@tiptap-pro/extension-drag-handle-react';
import type { Editor } from '@tiptap/react';
import React from 'react';
import TablerIconGripVertical from '~icons/tabler/grip-vertical';

export interface DragHandleProps {
  editor: Editor;
}

export const DragHandle: React.FC<DragHandleProps> = ({ editor }) => {
  const theme = useTheme();
  return (
    <TipTapDragHandle
      pluginKey='dashdraft-drag-handle'
      editor={editor}
      tippyOptions={{
        offset: [
          -3, // offset padding of 6px
          16,
        ],
        interactive: true,
        appendTo: () => document.body,
        zIndex: theme.zIndex.appBar - 2,
      }}
    >
      <IconButton
        size='small'
        sx={{
          cursor: 'grab',
          p: '6px',
          borderRadius: '4px',
          display: !editor.isEditable ? 'none' : undefined,
        }}
      >
        <TablerIconGripVertical width={22} height={22} />
      </IconButton>
    </TipTapDragHandle>
  );
};
