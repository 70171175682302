import _ from 'lodash';
import pino from 'pino';

import config, { type Config } from '@stargate/config';
import { debugSession } from '@stargate/lib/debug-session';
import { fetchUnauthorized } from '@stargate/lib/http';

import { createDevLogger, createDevLoggerFunc } from './dev-logger';

/*
|==========================================================================
| Logger
|==========================================================================
|
| Pino logger configuration & a custom transport to log to an API endpoint.
|
*/

export interface LogArg extends Record<string, unknown> {
  time: number;
  msg: string;
  level: number;
  data: Record<string, unknown> & {
    message?: string;
    label?: string;
  };
}

export type Logger = pino.BaseLogger;

/**
 * The logger instance, that logs to the console in development and to the API in production (unless otherwise configured).
 */
export const createLogger = (config: Config): Logger => {
  const devLogger = createDevLogger(config, '-http-');
  return pino({
    level: config.logging.level,
    browser: {
      asObject: true,
      write: (log) => {
        const { msg, level, time, data, ...restData } = log as LogArg;
        const logData = {
          ...data,
          ...restData,
        };
        const message = data?.message ?? msg ?? 'message not provided';
        if (config.logging.type === 'http') {
          try {
            fetchUnauthorized('/api/cs/l', {
              method: 'POST',
              headers: {
                'x-jgr-session': debugSession.getId(),
              },
              data: {
                l: level,
                m: message,
                t: time,
                i: debugSession.getId(),
                d: logData,
              },
              host: config.url,
            }).catch((error) => {
              if (config.environment !== 'production') {
                devLogger.error(
                  {
                    err: error,
                  },
                  'Failed to log'
                );
              }
            });
          } catch (error) {
            if (config.environment !== 'production') {
              devLogger.error(
                {
                  err: error,
                },
                'Failed to log'
              );
            }
          }
        } else if (config.logging.type === 'console') {
          const devLogger = createDevLoggerFunc(config, level);
          if (level >= pino.levels.values.error) {
            devLogger(logData, message);
          } else {
            devLogger(logData, message);
          }
        }
      },
    },
  });
};

/**
 * A pre-configured logger instance.
 */
export const logger = createLogger(config);
