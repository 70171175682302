import React from 'react';

import {
  buildJDocDraftStorageKey,
  getJDocDraftIdFromStorageKey,
  isJDocDraftStorageKey,
} from '@/features/docs/lib/draft-cache';
import type { JDocDraftLocalCacheData } from '@/features/docs/types';
import { useStorage } from '@/lib/storage';
import { useMountEffect } from '@react-hookz/web';
import { useRenderTemplateMutation } from '@stargate/api';

export interface UseJDocDraftsHookResult {
  /**
   * All cached drafts.
   */
  data: JDocDraftLocalCacheData[];
  /**
   * Find a draft by ID.
   */
  find: (id: string) => JDocDraftLocalCacheData | undefined;
  /**
   * Create a new draft.
   */
  create: (
    payload?: Partial<JDocDraftLocalCacheData>
  ) => Promise<JDocDraftLocalCacheData>;
  /**
   * Update a draft.
   */
  update: (id: string, partial: Partial<JDocDraftLocalCacheData>) => void;
}

/**
 * @returns A cache item based on the current active JoggrDoc (mode + template + doc)
 */
export const useJDocDrafts = (): UseJDocDraftsHookResult => {
  const storage = useStorage<JDocDraftLocalCacheData>();
  const renderTemplateMutation = useRenderTemplateMutation();

  // Migrate drafts
  useMigrateDrafts();

  const drafts = React.useMemo(() => {
    return storage
      .list()
      .filter((record) => {
        return isJDocDraftStorageKey(record.key, 'create');
      })
      .map((record) => {
        const result = record.value;
        return {
          ...result,
          parentId: result.parentId ?? null,
          id: getJDocDraftIdFromStorageKey(record.key),
        };
      });
  }, [storage]);

  const find = React.useCallback(
    (id: string) => drafts.find((draft) => draft.id === id),
    [drafts]
  );

  const create = React.useCallback<UseJDocDraftsHookResult['create']>(
    async ({ templateId, parentId, title, summary, content, ...rest } = {}) => {
      const template =
        templateId && templateId !== 'blank'
          ? await renderTemplateMutation.mutateAsync({
              templateId: templateId ?? 'blank',
              variables: [],
            })
          : null;

      const id = window.crypto.randomUUID();
      const data = {
        ...rest,
        id,
        title: title ?? template?.name,
        summary: summary ?? template?.description ?? undefined,
        content: content ?? template?.content ?? undefined,
        dir: parentId ?? null,
        templateId: templateId !== 'blank' ? templateId : null,
      };
      storage.set(buildJDocDraftStorageKey(id, 'create'), data);
      return data;
    },
    [storage, renderTemplateMutation]
  );

  const update = React.useCallback(
    (id: string, partial: Partial<JDocDraftLocalCacheData>) => {
      const draft = find(id);

      if (draft) {
        storage.set(buildJDocDraftStorageKey(draft.id, 'create'), {
          ...draft,
          ...partial,
        });
      }
    },
    [storage, find]
  );

  return { data: drafts, find, update, create };
};

/*
|------------------
| Migration
|------------------
*/

function useMigrateDrafts() {
  const storage = useStorage<JDocDraftLocalCacheData>();

  useMountEffect(() => {
    const drafts = storage.list().filter((record) => {
      const id = getJDocDraftIdFromStorageKey(record.key);
      return isJDocDraftStorageKey(record.key, 'create') && !isUUID(id);
    });

    for (const draft of drafts) {
      storage.remove(draft.key);
      const data = {
        ...draft.value,
        title: draft.value.title ?? 'Untitled',
        id: window.crypto.randomUUID(),
      };
      storage.set(buildJDocDraftStorageKey(data.id, 'create'), data);
    }
  });
}

function isUUID(id: string) {
  return /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(
    id
  );
}
