import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { RouterProvider } from 'react-router-dom';

import { App } from '@stargate/app/main';
import { ErrorGuard } from '@stargate/components/Guards';
import { Devtools } from '@stargate/devtools';
import { NotifyProvider } from '@stargate/lib/notify';
import { QueryClientProvider } from '@stargate/lib/query';
import { LocalizationProvider } from '@stargate/localization';
import { routes } from '@stargate/routes';
import { StargateThemeProvider } from '@stargate/theme';
import { Auth0Provider } from '@stargate/vendors/auth0';
import { FrigadeProvider } from '@stargate/vendors/frigade';
import { PostHogProvider } from '@stargate/vendors/posthog';
import { createBrowserRouterWithSentry } from '@stargate/vendors/sentry';

import './index.css';

/*
|==========================================================================
| Entry Point
|==========================================================================
|
| The entry point for the application. This is where we render the application and add
| the providers & other high-level configurations.
|
*/

import './instrumentation';

const Root = () => (
  <HelmetProvider>
    <QueryClientProvider>
      <Auth0Provider>
        <LocalizationProvider>
          <StargateThemeProvider>
            <PostHogProvider>
              <ErrorGuard>
                <FrigadeProvider>
                  <NotifyProvider>
                    <App />
                    <Devtools />
                  </NotifyProvider>
                </FrigadeProvider>
              </ErrorGuard>
            </PostHogProvider>
          </StargateThemeProvider>
        </LocalizationProvider>
      </Auth0Provider>
    </QueryClientProvider>
  </HelmetProvider>
);
const router = createBrowserRouterWithSentry([
  { element: <Root />, children: routes },
]);

// biome-ignore lint/style/noNonNullAssertion: This will always be defined (see index.html)
ReactDOM.createRoot(document.getElementById('root')!).render(
  <RouterProvider router={router} />
);
