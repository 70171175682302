import {
  Box,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Typography,
  useTheme,
} from '@mui/material';
import React from 'react';

import { IconAvatar } from '@stargate/components/Avatars';
import { createComponentClasses } from '@stargate/theme';

import { Link } from '@stargate/routes';
import type { SearchResultsDocument } from '../types';
import { SearchMatches } from './SearchMatches';

export const searchBarResultItemClasses = createComponentClasses(
  'SearchBarResultItem',
  [
    'root',
    'iconAvatar',
    'title',
    'summary',
    'tags',
    'activeContainer',
    'activeBackground',
    'activeText',
    'matchesContainer',
  ] as const
);

export interface SearchBarResultItemProps {
  /**
   * The item to render.
   */
  item: SearchResultsDocument;

  /**
   * If the item should be active.
   */
  active?: boolean;

  /**
   * If the item should be focused.
   */
  autoFocus?: boolean;

  /**
   * Callback for when an item is focused.
   *
   * @param e A keyboard event.
   */
  onItemKeyDown: (e: React.KeyboardEvent<HTMLAnchorElement>) => void;

  /**
   * Callback for when an item is clicked.
   */
  onItemClick: () => void;
}

/**
 * Component for
 */
export const SearchBarResultItem: React.FC<SearchBarResultItemProps> = ({
  item,
  onItemClick,
  onItemKeyDown,
  active = false,
  autoFocus = false,
}) => {
  const theme = useTheme();

  const handleItemClick = (e: React.MouseEvent<HTMLElement>): void => {
    if (active) {
      e.preventDefault();
    } else {
      onItemClick();
    }
  };

  return (
    <MenuItem<typeof Link>
      key={item.id}
      component={Link}
      dense
      to='app.documents.view'
      params={{ id: item.id }}
      className={searchBarResultItemClasses.root}
      autoFocus={autoFocus && !active}
      disableRipple={active}
      disableTouchRipple={active}
      disabled={active}
      onClick={handleItemClick}
      onKeyDown={onItemKeyDown}
      sx={{
        [`&.${searchBarResultItemClasses.root}`]: {
          position: 'relative',
          pb: 4.25,
          opacity: active ? 0.5 : 1,

          [`& .${searchBarResultItemClasses.activeContainer}`]: {
            display: active ? 'flex' : 'none',
            position: 'absolute',
            inset: 0,
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: theme.zIndex.appBar + 1,
            cursor: 'default',

            [`& .${searchBarResultItemClasses.activeText}`]: {
              zIndex: 1,
              bgcolor: theme.palette.background.paper,
              border: `1px solid ${theme.palette.divider}`,
              borderRadius: '8px',
              px: '8px',
              py: '2px',
            },

            [`& .${searchBarResultItemClasses.activeBackground}`]: {
              position: 'absolute',
              inset: 0,
              bgcolor: theme.palette.background.paper,
              opacity: 0.7,
              zIndex: 0,
            },
          },
          [`& .${searchBarResultItemClasses.matchesContainer}`]: {
            position: 'absolute',
            right: theme.spacing(2),
            bottom: theme.spacing(1.75),
            left: theme.spacing(2),
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          },
        },
      }}
    >
      <Box className={searchBarResultItemClasses.activeContainer}>
        <Box className={searchBarResultItemClasses.activeText}>
          <Typography variant='body2'>
            You are currently viewing this document
          </Typography>
        </Box>
        <Box className={searchBarResultItemClasses.activeBackground} />
      </Box>
      <Box className={searchBarResultItemClasses.matchesContainer}>
        <SearchMatches
          text={[
            item._matches.title,
            item._matches.summary,
            item._matches.content,
          ]}
        />
      </Box>
      <ListItemAvatar
        classes={{
          root: searchBarResultItemClasses.iconAvatar,
        }}
        sx={{
          minWidth: 0,
          pr: 1.5,
        }}
      >
        <IconAvatar icon='file-type-doc' size={36} />
      </ListItemAvatar>
      <ListItemText
        classes={{
          primary: searchBarResultItemClasses.title,
          secondary: searchBarResultItemClasses.summary,
        }}
        primary={item.title}
        primaryTypographyProps={{
          fontSize: '1.15rem',
          fontWeight: '700',
          component: 'span',
        }}
        secondary={item.summary ?? ''}
        secondaryTypographyProps={{
          noWrap: true,
          lineHeight: 1.1,
        }}
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      />
    </MenuItem>
  );
};
