import { Box, Stack } from '@mui/material';
import _ from 'lodash';
import React from 'react';

import type { APIResponse } from '@stargate/api';
import { TextSearchFilter } from '@stargate/components/FormFields';
import { GitHubFileTreeView } from '@stargate/features/github';
import { createComponentClasses } from '@stargate/theme';

import { CodeExplorerFileTreeDrawer } from './CodeExplorerFileTreeDrawer';
import { CodeExplorerFileTreeHeader } from './CodeExplorerFileTreeHeader';
import { CodeExplorerFileTreeSkeleton } from './CodeExplorerFileTreeSkeleton';

export const codeExplorerFileTreeClasses = createComponentClasses(
  'CodeExplorerFileTree',
  ['root', 'drawer', 'content']
);

export interface CodeExplorerFileTreeProps {
  /**
   * Whether the file tree is open.
   */
  open: boolean;

  /**
   * Whether the file tree is disabled.
   */
  disabled: boolean;

  /**
   * Whether the file tree is loading.
   */
  loading: boolean;

  /**
   * The file tree data.
   */
  fileTree?: APIResponse<'GET /github/repositories/:repositoryId/file-tree'>;

  /**
   * The default selected file path
   */
  selected?: string;

  /**
   * Callback for when a file is selected.
   *
   * @param filePath A string representing the file path.
   */
  onFileSelect: (filePath: string) => void;

  /**
   * Callback for when the file tree opens.
   */
  onOpen: () => void;

  /**
   * Callback for when the file tree closes.
   */
  onClose: () => void;
}

export const CodeExplorerFileTree: React.FC<CodeExplorerFileTreeProps> = ({
  disabled,
  loading,
  fileTree,
  selected: defaultSelectedItems,
  open,
  onClose,
  onOpen,
  onFileSelect,
}) => {
  const [textFilter, setTextFilter] = React.useState('');
  const [expandedItems, setExpandedItemsState] = React.useState<string[]>([]);
  const [selectedItem, setSelectedItemState] = React.useState<
    string | undefined
  >(defaultSelectedItems);

  const fileTreeFiltered = React.useMemo(() => {
    if (!fileTree || !textFilter) {
      return fileTree;
    }

    const files = fileTree.tree.filter(
      (fileTreeNode) => fileTreeNode.type === 'file'
    );
    const dirs = fileTree.tree.filter(
      (fileTreeNode) => fileTreeNode.type === 'directory'
    );

    const filteredFiles = files.filter((fileTreeNode) => {
      if (textFilter.length > 0) {
        return fileTreeNode.path
          .toLowerCase()
          .includes(textFilter.toLowerCase());
      }

      return true;
    });

    const requiredDirs = _.chain(filteredFiles)
      .map((file) => {
        const path = file.path.split('/').slice(0, -1);
        return path.map((_, index) => path.slice(0, index + 1).join('/'));
      })
      .flatten()
      .uniq()
      .value();

    // We base the dirs OFF of the files, so we can ensure that we show all ancestor directories
    const filteredDirs = dirs.filter((fileTreeNode) => {
      const checks = [requiredDirs.includes(fileTreeNode.path)];
      if (textFilter.length > 0) {
        checks.push(
          fileTreeNode.path.toLowerCase().includes(textFilter.toLowerCase())
        );
      }
      return _.some(checks);
    });

    return {
      ...fileTree,
      tree: [...filteredDirs, ...filteredFiles],
    };
  }, [fileTree, textFilter]);

  const toggleOpen = React.useCallback(() => {
    if (open === true) {
      onClose();
    } else {
      onOpen();
    }
  }, [open, onOpen, onClose]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: we only want to listen to changes to `fileTree`
  React.useEffect(() => {
    if (!_.isEmpty(fileTree)) {
      onOpen();
    }
  }, [fileTree]);

  // If we get new default selected items, update the selected item state
  React.useEffect(() => {
    if (defaultSelectedItems) {
      setSelectedItemState(defaultSelectedItems);
      setExpandedItemsState((prevExpandedItems) => {
        return _.uniq([
          ...prevExpandedItems,
          ...getExpandedFromSelected(defaultSelectedItems),
          defaultSelectedItems,
        ]);
      });
    }
  }, [defaultSelectedItems]);

  return (
    <Stack
      className={codeExplorerFileTreeClasses.root}
      direction='column'
      spacing={0}
      sx={{
        [`&.${codeExplorerFileTreeClasses.root}`]: {
          width: open ? `${WIDTH_PX}px` : 'auto',
          height: '100%',
          position: 'relative',
        },
      }}
    >
      <CodeExplorerFileTreeHeader
        open={open}
        loading={loading}
        onToggle={toggleOpen}
        disabled={disabled}
      />
      <CodeExplorerFileTreeDrawer
        open={open}
        onToggle={toggleOpen}
        disabled={disabled}
      >
        <TextSearchFilter onChange={(text) => setTextFilter(text)} />
        <Box
          className={codeExplorerFileTreeClasses.content}
          sx={{
            [`&.${codeExplorerFileTreeClasses.content}`]: {
              height: 'calc(100% - 164px)',
              overflowX: 'auto',
            },
          }}
        >
          {fileTreeFiltered && (
            <GitHubFileTreeView
              disableSelection={disabled || loading}
              sx={{
                width: '100%',
                height: '100%',
              }}
              selectedItem={selectedItem}
              fileTree={fileTreeFiltered}
              onSelectedItemsChange={(fileTreeNode) => {
                if (fileTreeNode?.type === 'file') {
                  onFileSelect(fileTreeNode.fullPath);
                }
              }}
              expandedItems={expandedItems}
              onExpandedItemsChange={(filePaths) => {
                setExpandedItemsState(filePaths);
              }}
              autoExpand={false}
            />
          )}
          {loading && !fileTreeFiltered && <CodeExplorerFileTreeSkeleton />}
        </Box>
      </CodeExplorerFileTreeDrawer>
    </Stack>
  );
};

/*
|------------------
| Utils
|------------------
*/

const WIDTH_PX = 336;

/**
 * Gets the default expanded file tree items based on the selected file path.
 *
 * @param filePath A string representing the file path.
 * @returns An array of strings representing the default expanded file tree items.
 */
const getExpandedFromSelected = (filePath?: string) => {
  if (!filePath) {
    return [];
  }
  const filePathParts = filePath.split('/');
  const defaultExpanded: string[] = [];

  for (let i = 0; i < filePathParts.length - 1; i++) {
    defaultExpanded.push(filePathParts.slice(0, i + 1).join('/'));
  }

  return [...defaultExpanded, filePath];
};
