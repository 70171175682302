import { RikerIcon } from '@joggrdocs/riker-icons';
import { Box, Tooltip } from '@mui/material';
import {
  DataGrid,
  GridActionsCellItem as DataGridActionsCellItemButton,
  type GridEventListener as DataGridEventListener,
  type GridRenderEditCellParams as DataGridRenderEditCellParams,
  GridRowEditStopReasons as DataGridRowEditStopReasons,
  type GridRowId as DataGridRowId,
  type GridRowModel as DataGridRowModel,
  GridRowModes as DataGridRowModes,
  type GridRowModesModel as DataGridRowModesModel,
  type GridRowSelectionModel as DataGridRowSelectionModel,
} from '@mui/x-data-grid';
import * as hookz from '@react-hookz/web';
import _ from 'lodash';
import React from 'react';

import {
  DirectoryBreadCrumbs,
  DirectorySelect,
  type DirectorySelectProps,
  type DirectoryTree,
  directorySelectClasses,
  useDirectoryTree,
} from '@stargate/features/directories';
import { createComponentClasses } from '@stargate/theme';

import {
  GitHubChip,
  GitHubFileIcon,
  useGitHubUrls,
} from '@stargate/features/github';
import { useNotify } from '@stargate/lib/notify';
import type { DocumentImportFileRow } from '../types';

/*
|------------------
| Public API
|------------------
*/

export const documentImportCreatorTableClasses = createComponentClasses(
  'DocumentsImportCreatorTable',
  ['root'] as const
);

export type DocumentImportCreatorTableProps = {
  rows: DocumentImportFileRow[];
  loading: boolean;
  onSelectedRowsChange: (rows: DocumentImportFileRow[]) => void;
};

export const DocumentImportCreatorTable: React.FC<
  DocumentImportCreatorTableProps
> = ({ onSelectedRowsChange, ...props }) => {
  const directoryTree = useDirectoryTree();
  const githubUrls = useGitHubUrls();
  const notify = useNotify();
  const [rows, setRows] = React.useState(props.rows);
  const [rowModesModel, setRowModesModel] =
    React.useState<DataGridRowModesModel>({});
  const [rowSelectionModel, setRowSelectionModel] =
    React.useState<DataGridRowSelectionModel>([]);

  /*
  |------------------
  | Utils
  |------------------
  */

  const computeRowDiff = (newRow: DataGridRowModel<DocumentImportFileRow>) => {
    const updatedRow = { ...newRow };
    // biome-ignore lint/style/noNonNullAssertion: This MUST be defined
    const oldRow = rows.find((row) => row.id === newRow.id)!;
    const changedFields = _.chain([
      updatedRow.directoryId !== oldRow?.directoryId ? 'directory' : null,
      updatedRow.title !== oldRow?.title ? 'title' : null,
      updatedRow.summary !== oldRow?.summary ? 'summary' : null,
    ])
      .compact()
      .value();
    return {
      updated: updatedRow,
      previous: oldRow,
      changedFields,
    };
  };

  /*
  |------------------
  | Handlers
  |------------------
  */

  const handleRowEditStop: DataGridEventListener<'rowEditStop'> = (
    params,
    event
  ) => {
    if (params.reason === DataGridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
      setRowModesModel({
        ...rowModesModel,
        [params.id]: { mode: DataGridRowModes.View },
      });
    }
  };

  const handleEditClick = (id: DataGridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: DataGridRowModes.Edit },
    });
  };

  const handleSaveClick = (id: DataGridRowId) => () => {
    setRowModesModel({
      ..._.mapValues(rowModesModel, () => ({ mode: DataGridRowModes.View })),
      [id]: { mode: DataGridRowModes.View },
    });
  };

  const handleCancelClick = (id: DataGridRowId) => () => {
    setRowModesModel({
      ..._.mapValues(rowModesModel, () => ({ mode: DataGridRowModes.View })),
      [id]: { mode: DataGridRowModes.View, ignoreModifications: true },
    });
  };

  const handleRowSelectionChange = (
    newSelectionModel: DataGridRowSelectionModel
  ) => {
    setRowSelectionModel(newSelectionModel);
    onSelectedRowsChange(
      rows.filter((row) => newSelectionModel.includes(row.id))
    );
  };

  const processRowUpdate = (
    updatedRow: DataGridRowModel<DocumentImportFileRow>
  ) => {
    const rowDiff = computeRowDiff(updatedRow);

    if (rowDiff.changedFields.length > 0) {
      notify.info(
        `Changed ${rowDiff.changedFields.map((c) => `"${c}"`).join(', ')} for "${rowDiff.previous.filePath}"`
      );
    }

    const updatedRows = rows.map((row) =>
      row.id === rowDiff.updated.id ? rowDiff.updated : row
    );
    setRows(updatedRows);
    onSelectedRowsChange(
      updatedRows.filter((row) => rowSelectionModel.includes(row.id))
    );
    return rowDiff.updated;
  };

  const handleRowModesModelChange = (
    newRowModesModel: DataGridRowModesModel
  ) => {
    setRowModesModel(newRowModesModel);
  };

  /*
  |------------------
  | Effects
  |------------------
  */

  // We reset the rows when the loading state changes (aka loading new data)
  const previousRows = hookz.usePrevious(props.rows);
  React.useEffect(() => {
    const currentRowIds = props.rows.map((row) => row.id).sort();
    const previousRowIds = (previousRows?.map((row) => row.id) ?? []).sort();

    if (!_.isEqual(currentRowIds, previousRowIds)) {
      setRows(props.rows);
      setRowSelectionModel(props.rows.map((row) => row.id));
      onSelectedRowsChange(props.rows);
    }
  }, [previousRows, props.rows, onSelectedRowsChange]);

  return (
    <Box
      sx={{
        // Override the default styles for the DataGrid component
        // and remove the border around the selects
        [`& .${directorySelectClasses.root} .MuiOutlinedInput-notchedOutline`]:
          {
            border: 'none',
          },
      }}
    >
      <DataGrid
        loading={props.loading}
        rows={rows}
        columns={[
          {
            field: 'filePath',
            headerName: 'File Path',
            sortable: true,
            editable: false,
            flex: 2,
            cellClassName: 'disabled',
            description:
              'The file path where a JoggrDoc is stored in your code base (in GitHub).',
            renderCell: (params) => {
              const disabled = params.api.getRowMode(params.id) === 'edit';
              return (
                <Tooltip
                  enterDelay={500}
                  title={!disabled ? 'Click to view file on GitHub' : undefined}
                  placement='top'
                  arrow
                >
                  <GitHubChip
                    url={githubUrls.file({
                      repo: params.row.githubRepository,
                      owner: params.row.githubRepositoryOwner,
                      branch: params.row.branchName,
                      path: params.value as string,
                    })}
                    renderIcon={(iconProps) => (
                      <GitHubFileIcon {...iconProps} fileExtension='md' />
                    )}
                    label={params.value}
                    disabled={disabled}
                  />
                </Tooltip>
              );
            },
          },
          {
            field: 'directoryId',
            headerName: 'Directory',
            sortable: false,
            editable: true,
            flex: 2,
            description:
              'Directories are used to organize JoggrDocs in one central location across all of your repositories.',
            renderCell: (params) => {
              const directory = directoryTree.tree?.find(
                (dir) => dir.id === params.value
              ) ?? {
                id: 'root',
                title: '<root>',
                icon: 'folder-root',
                iconType: 'riker',
                ancestors: [],
                children: [],
              };
              return (
                <DirectoryBreadCrumbs
                  directoryBreadCrumbs={_.compact([
                    ..._.chain(directory?.ancestors ?? [])
                      .map((ancestor) =>
                        directoryTree.tree?.find(
                          (dir) => dir.id === ancestor.id
                        )
                      )
                      .compact()
                      .value(),
                    directory,
                  ])}
                />
              );
            },
            renderEditCell: (params) => (
              <DirectoryDataGridItem
                {...params}
                value={params.value ?? 'root'}
                directoryTree={directoryTree.tree}
              />
            ),
          },
          {
            field: 'title',
            headerName: 'Title',
            sortable: false,
            filterable: false,
            editable: true,
            flex: 2,
            description:
              'The title of the JoggrDoc as shown in the Directory Explorer, Document Search, and other parts of the Joggr platform.',
            renderCell: (params) => {
              return params.value;
            },
          },
          {
            field: 'summary',
            headerName: 'Summary',
            sortable: false,
            filterable: false,
            editable: true,
            flex: 3,
            description:
              'The summary of the JoggrDoc as shown in the Directory Explorer, and other parts of the Joggr platform.',
            renderCell: (params) => {
              if (params.value === '') {
                return (
                  <Tooltip
                    enterDelay={500}
                    title='No summary available'
                    placement='top'
                    arrow
                  >
                    <div style={{ width: '100%' }}>-</div>
                  </Tooltip>
                );
              }

              return params.value;
            },
          },
          {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            getActions: ({ id }) => {
              const isInEditMode =
                rowModesModel[id]?.mode === DataGridRowModes.Edit;

              if (isInEditMode) {
                return [
                  <DataGridActionsCellItemButton
                    key='save'
                    icon={<RikerIcon icon='device-floppy' />}
                    label='Save'
                    onClick={handleSaveClick(id)}
                  />,
                  <DataGridActionsCellItemButton
                    key='cancel'
                    icon={<RikerIcon icon='cancel' />}
                    label='Cancel'
                    onClick={handleCancelClick(id)}
                  />,
                ];
              }

              return [
                <DataGridActionsCellItemButton
                  key='edit'
                  icon={<RikerIcon icon='edit' />}
                  label='Edit'
                  onClick={handleEditClick(id)}
                />,
              ];
            },
          },
        ]}
        editMode='row'
        rowSelectionModel={rowSelectionModel}
        rowModesModel={rowModesModel}
        onRowSelectionModelChange={handleRowSelectionChange}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 25 },
          },
        }}
        pageSizeOptions={[5, 10, 25, 50, 100]}
        localeText={{
          noRowsLabel:
            'Please select Github Repository that contains documents to import',
        }}
        disableColumnFilter
        autoHeight
        checkboxSelection
        disableRowSelectionOnClick
        showCellVerticalBorder
        showColumnVerticalBorder
      />
    </Box>
  );
};

/*
|------------------
| Util Components
|------------------
*/

const DirectoryDataGridItem = React.forwardRef<
  HTMLDivElement,
  DataGridRenderEditCellParams & { directoryTree: DirectoryTree }
>(({ directoryTree, ...props }, ref) => {
  const handleParentChange: DirectorySelectProps['onChangeDirectory'] = (
    parent
  ) => {
    props.api.setEditCellValue({
      id: props.id,
      field: 'directoryId',
      value: parent?.id ?? 'root',
    });
  };

  if (!directoryTree) {
    return null;
  }

  return (
    <DirectorySelect
      ref={ref}
      label={null}
      directoryTree={directoryTree}
      defaultDirectoryId={props.value as string}
      onChangeDirectory={handleParentChange}
    />
  );
});
