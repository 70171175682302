import {
  DialogTitle as BaseDialogTitle,
  Box,
  IconButton,
  Stack,
  type SxProps,
  type Theme,
  Typography,
  dialogTitleClasses as muiDialogTitleClasses,
  useTheme,
} from '@mui/material';
import _ from 'lodash';
import React from 'react';
import TablerIconX from '~icons/tabler/x';

import { cloneMuiComponentClasses } from '@/theme';

/**
 * The classes to apply for styles.
 */
export const dialogTitleClasses = cloneMuiComponentClasses(
  muiDialogTitleClasses
);

export type DialogTitleClasses = typeof dialogTitleClasses;

export interface DialogTitleProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'title'> {
  /**
   * The icon to display in the DialogTitle.
   */
  startIcon?: React.ReactNode;
  /**
   * The icon to display in the DialogTitle.
   */
  avatar?: React.ReactNode;
  /**
   * The action to display in the DialogTitle, remove if `onClose` is provided.
   */
  action?: React.ReactNode;
  /**
   * The classes to apply to the component.
   */
  classes?: Partial<DialogTitleClasses>;
  /**
   * The title to display in the DialogTitle.
   */
  title?: React.ReactNode;
  /**
   * The props of the title Typography component.
   */
  titleTypographyProps?: React.ComponentProps<typeof Typography>;
  /**
   * The subtitle to display in the DialogTitle.
   */
  subTitle?: React.ReactNode;
  /**
   * The props of the subtitle Typography component.
   */
  subTitleTypographyProps?: React.ComponentProps<typeof Typography>;
  /**
   * Callback fired when the close icon is clicked, automatically adds close icon.
   */
  onClose?: () => void;
  /**
   * The content of the component.
   */
  children?: React.ReactNode;
  /**
   * The sx prop to apply to the component.
   */
  sx?: SxProps<Theme>;
}

/**
 * A customized DialogTitle component.
 */
export const DialogTitle = React.forwardRef<HTMLDivElement, DialogTitleProps>(
  (props, ref) => {
    const {
      onClose,
      startIcon,
      avatar,
      action,
      title,
      titleTypographyProps,
      subTitle,
      subTitleTypographyProps,
      ...dialogTitleProps
    } = props;
    const theme = useTheme();

    return (
      <React.Fragment>
        <BaseDialogTitle {...dialogTitleProps} ref={ref}>
          {title && (
            <Stack direction='row' alignItems='center' spacing={1}>
              {startIcon || avatar}
              <Stack direction='column' alignItems='flex-start' spacing={0}>
                {_.isString(title) ? (
                  <Typography variant='h5' {...titleTypographyProps}>
                    {title}
                  </Typography>
                ) : (
                  title
                )}
                {_.isString(subTitle) ? (
                  <Typography
                    variant='body1'
                    fontSize={theme.typography.pxToRem(12)}
                    color={theme.palette.text.secondary}
                    {...subTitleTypographyProps}
                  >
                    {subTitle}
                  </Typography>
                ) : (
                  subTitle
                )}
              </Stack>
              {/* Provide space for the close */}
              {onClose && <Box sx={{ width: '16px' }} />}
            </Stack>
          )}
        </BaseDialogTitle>
        {onClose && (
          <IconButton
            onClick={onClose}
            className='absolute top-[8px] right-[8px]'
          >
            <TablerIconX className='text-primary-contrast' />
          </IconButton>
        )}
        {!onClose && action}
      </React.Fragment>
    );
  }
);
DialogTitle.displayName = 'DialogTitle';
