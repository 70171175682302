import type { RikerIconName } from '@joggrdocs/riker-icons';
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  type MenuItemProps,
} from '@mui/material';
import _ from 'lodash';
import React from 'react';

export interface SelectMenuItemProps extends MenuItemProps {
  icon?: React.ReactNode;
  value: string;
  label: string;
}

/**
 * A menu item for a select component
 */
export const SelectMenuItem = React.forwardRef<
  HTMLLIElement,
  SelectMenuItemProps
>(({ value, icon, label, ...props }, ref) => {
  return (
    <MenuItem ref={ref} {...props} value={value}>
      {!_.isNil(icon) && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText
        primary={label}
        primaryTypographyProps={{
          sx: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
        }}
      />
    </MenuItem>
  );
});
SelectMenuItem.displayName = 'SelectMenuItem';
SelectMenuItem.muiName = 'MenuItem';

export default SelectMenuItem;
