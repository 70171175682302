import { Avatar } from '@mui/material';
import React from 'react';
import TablerIconUser from '~icons/tabler/user';

import { useGitHubUrls } from '../../hooks/use-github-urls';
import { GitHubChip } from './GitHubChip';

export interface GitHubAccountChipProps {
  /**
   * The username of the GitHub user or organization.
   */
  account?: string;
}

/**
 * A Chip that displays a GitHub user or organization with the proper avatar or fallback icon.
 */
export const GitHubAccountChip = React.forwardRef<
  HTMLDivElement,
  GitHubAccountChipProps
>(({ account, ...props }, ref) => {
  const githubUrls = useGitHubUrls();
  return (
    <GitHubChip
      {...props}
      ref={ref}
      url={account ? githubUrls.user(account) : undefined}
      renderAvatar={(avatarProps) => (
        <Avatar
          sx={{
            backgroundColor: avatarProps.backgroundColor,
            color: avatarProps.color,
          }}
          src={account ? githubUrls.avatar(account) : undefined}
        >
          <TablerIconUser
            width={avatarProps.size}
            height={avatarProps.size}
            color={avatarProps.color}
          />
        </Avatar>
      )}
      label={account ? `@${account}` : 'Anonymous'}
    />
  );
});
GitHubAccountChip.displayName = 'GitHubUserChip';
GitHubAccountChip.muiName = 'Chip';
