import { RikerIcon, type RikerIconName } from '@joggrdocs/riker-icons';
import { Box, Chip, Stack, Tooltip, Typography } from '@mui/material';
import _ from 'lodash';
import React from 'react';

import { createComponentClasses } from '@stargate/theme';

import type { JDocTemplate } from '../../types';

export const joggrDocTemplateChipClasses = createComponentClasses(
  'JoggrDocTemplateChip',
  ['root', 'tooltip', 'chip']
);

export interface JoggrDocTemplateChipProps {
  /**
   * The Joggr Doc Template to display.
   */
  template?: JDocTemplate | null;
}

/**
 * Component for Joggr Doc Template Chip
 */
export const JoggrDocTemplateChip: React.FC<JoggrDocTemplateChipProps> = ({
  template,
}) => {
  if (_.isNil(template)) {
    return null;
  }

  return (
    <Box className={joggrDocTemplateChipClasses.root}>
      <Tooltip
        className={joggrDocTemplateChipClasses.tooltip}
        title={
          <React.Fragment>
            <Stack direction='row' spacing={1}>
              <RikerIcon icon={template.icon as RikerIconName} />
              <Typography variant='h5'>{template.name}</Typography>
            </Stack>
            <Typography variant='body2'>{template.description}</Typography>
          </React.Fragment>
        }
        sx={{
          maxWidth: '300px',
        }}
        placement='right'
        arrow
      >
        <Chip
          variant='outlined'
          classes={{
            root: joggrDocTemplateChipClasses.chip,
          }}
          shape='rounded'
          icon={<RikerIcon icon='template' />}
          label={'Built from Template'}
        />
      </Tooltip>
    </Box>
  );
};
