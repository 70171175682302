import React from 'react';
import TablerIconSend from '~icons/tabler/send';

import { Button, type ButtonProps } from '@mui/material';

export type Props = Partial<ButtonProps>;

export const SendInviteButton: React.FC<Props> = (props) => {
  return (
    <Button
      color='primary'
      startIcon={<TablerIconSend />}
      variant='contained'
      {...props}
    >
      Send Invite
    </Button>
  );
};

export default SendInviteButton;
