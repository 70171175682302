import type { AsyncStatus } from '@react-hookz/web';
import { produce } from 'immer';
import type * as TypeFest from 'type-fest';
import { create } from 'zustand';

import type { HttpError } from '@stargate/lib/http';
import type { APIContract, APIRoute } from '../types';

export interface APIClientCacheStoreState<Data> {
  /**
   * State for loading the API call.
   */
  status: AsyncStatus;

  /**
   * The result of the API call.
   */
  data: Data | null;

  /**
   * The error state of the API call.
   */
  error: Error | HttpError | null;

  /**
   * Timestamp of when the data was created (first fetched).
   */
  createdAt: number | null;

  /**
   * Timestamp of when the data was last updated.
   */
  updatedAt: number | null;

  /**
   * Timestamp of when the API call errored.
   */
  erroredAt: number | null;
}

export interface APIClientCacheStoreActions<Data> {
  /**
   * Sets the loading state.
   */
  setStatus: (status: AsyncStatus) => void;

  /**
   * Set the data, from the API.
   *
   * @param data The data.
   */
  setData: (data: Data) => void;

  /**
   * Clear the data.
   */
  clearData: () => void;

  /**
   * Set the error state.
   *
   * @param error The error.
   */
  setError: (error: Error | HttpError | null) => void;

  /**
   * Clear the error state.
   */
  clearError: () => void;
}

export type APIClientCacheStore<Data> = TypeFest.Simplify<
  APIClientCacheStoreState<Data> & APIClientCacheStoreActions<Data>
>;

/**
 * Create a new API client Store.
 *
 * @example
 *  const useApiClientStore = createClientStore('GET /documents');
 *
 *  const Foobar = () => {
 *    const [state, actions] = useApiClientStore();
 *    const [readDocsState, readDocsActions] = api.useClient('GET /documents/:id');
 *
 *    hookz.useMountEffect(() => {
 *      void readDocsActions.execute({
 *        params: {
 *          id: 'd383393d-fe39-4a3b-a063-e66c3382b6b7'
 *        }
 *      })
 *        .then((result) => actions.setData(result))
 *        .catch((error) => actions.setError(error));
 *    });
 *
 *    return (
 *     <div>
 *      {state.status === 'loading' && <p>Loading...</p>}
 *      {state.error && <p>Error: {state.error.message}</p>}
 *      {state.data && <p>Data: {state.data}</p>}
 *    </div>
 *  };
 *
 * @deprecated Use 'tanstack/react-query' instead.
 * @param schemaName A schema name, in the format of `METHOD /path/to/resource`, e.g. `GET /documents`.
 * @returns A built API client Store.
 */
export function createClientCacheStore<Name extends APIRoute>(
  schemaName: Name
) {
  return create<APIClientCacheStore<APIContract<Name>['response']>>((set) => ({
    status: 'not-executed',
    updatedAt: null,
    createdAt: null,
    erroredAt: null,
    data: null,
    error: null,
    setStatus: (update) => {
      set(
        produce((draft) => {
          draft.status = update;
        })
      );
    },
    setData: (data) => {
      set(
        produce((draft) => {
          draft.error = null;
          draft.data = data;
          draft.status = 'success';
          draft.updatedAt = Date.now();
          draft.createdAt = draft.createdAt ?? draft.updatedAt;
          draft.erroredAt = null;
        })
      );
    },
    clearData: () => {
      set(
        produce((draft) => {
          draft.status = 'idle';
          draft.data = null;
          draft.updatedAt = null;
          draft.createdAt = null;
          draft.erroredAt = null;
        })
      );
    },
    setError: (error) => {
      set(
        produce((draft) => {
          draft.error = error;
          draft.status = 'error';
          draft.erroredAt = Date.now();
        })
      );
    },
    clearError: () => {
      set(
        produce((draft) => {
          draft.error = null;
          draft.status = 'idle';
          draft.erroredAt = null;
        })
      );
    },
  }));
}
