import { Button, Dialog, DialogContent } from '@mui/material';
import * as hookz from '@react-hookz/web';
import React from 'react';

import { DialogTitle } from '@/components/dialog';
import { RikerIcon } from '@joggrdocs/riker-icons';
import { Img } from '@stargate/components/Images';
import { Confetti } from '@stargate/components/Utils';

import type { OnboardingStepItem } from '../OnboardingStep';

/*
|==========================================================================
| Completed
|==========================================================================
|
| Tells the user the completed initial onboarding and give them a reward.
|
*/

export type OnboardingStepCompletedProps = OnboardingStepItem;

export const OnboardingStepCompleted: React.FC<
  OnboardingStepCompletedProps
> = ({ active, onContinue }) => {
  React.useEffect(() => {
    // We want to automatically continue to the next step after 5 seconds.
    // Once the user has completed the onboarding process.
    if (active) {
      setTimeout(() => {}, 10000);
    }
  }, [active, onContinue]);

  const [cancel] = hookz.useTimeoutEffect(
    () => {
      onContinue();
    },
    active ? 10000 : undefined
  );

  const handleContinue = () => {
    cancel();
    onContinue();
  };

  if (!active) {
    return null;
  }

  return (
    <React.Fragment>
      <Confetti />
      <Dialog maxWidth='sm' fullWidth open>
        <DialogTitle
          startIcon={<RikerIcon icon='confetti' height={36} width={36} />}
          title={'You are ready to jog with us!'}
          titleTypographyProps={{
            variant: 'h1',
            align: 'center',
          }}
        />
        <DialogContent>
          <Img
            alt='Joggr Running'
            src={'/features/user-onboarding/joggr-running-start.gif'}
            responsive
          />
          <Button
            variant='contained'
            onClick={handleContinue}
            fullWidth
            color='success'
            sx={{
              mt: 2,
              fontSize: '24px',
            }}
          >
            Continue to Joggr
          </Button>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};
