import type { Theme } from '@mui/material';

import type { FrigadeThemeAspectTypography } from '../types';

/**
 * Generate a Frigade Theme Typography Aspect from a Riker theme
 *
 * @param theme A Riker theme
 * @returns A Frigade Theme Typography Aspect
 */
export const typography = (theme: Theme): FrigadeThemeAspectTypography => {
  const utils = createUtils(theme);

  return {
    fontFamilies: {
      default: theme.typography.fontFamily ?? 'sans-serif',
    },
    fontSizes: {
      xs: utils.fontSize('caption'),
      sm: utils.fontSize('body1'),
      md: utils.fontSize('h5'),
      lg: utils.fontSize('h4'),
      xl: utils.fontSize('h3'),
      '2xl': utils.fontSize('h2'),
      '3xl': utils.fontSize('h1'),
      '4xl': utils.fontSize('h1'),
      '5xl': utils.fontSize('h1'),
    },
    lineHeights: {
      xs: utils.lineHeight('body2'),
      sm: utils.lineHeight('body1'),
      md: utils.lineHeight('h6'),
      lg: utils.lineHeight('h5'),
      xl: utils.lineHeight('h4'),
      '2xl': utils.lineHeight('h3'),
      '3xl': utils.lineHeight('h2'),
      '4xl': utils.lineHeight('h1'),
    },
    letterSpacings: {
      md: '0.01em',
    },
    fontWeights: {
      thin: '100',
      extralight: '200',
      light: '300',
      regular: '400',
      medium: '500',
      demibold: '600',
      bold: '700',
      extrabold: '800',
      black: '900',
    },
  };
};

/*
|------------------
| Utils
|------------------
*/

type TypographyVariant =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'subtitle1'
  | 'subtitle2'
  | 'body1'
  | 'body2'
  | 'caption';

/**
 * Create utils for a theme
 *
 * @param theme A theme object
 * @returns A set of utility functions
 */
const createUtils = (theme: Theme) => {
  return {
    /**
     * Get the font size for a typography variant
     * @param variant A typography variant
     * @returns A font size in format for Frigade
     */
    fontSize(variant: TypographyVariant): string {
      return theme.typography[variant].fontSize as string;
    },
    /**
     * Get the line height for a typography variant
     * @param variant A typography variant
     * @returns A line height in format for Frigade
     */
    lineHeight(variant: TypographyVariant): string {
      return `${theme.typography[variant].lineHeight ?? 1.15}`;
    },
  };
};
