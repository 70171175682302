import {
  Autocomplete,
  FormControl,
  type FormControlProps,
  FormHelperText,
  InputAdornment,
  TextField,
} from '@mui/material';
import * as hookz from '@react-hookz/web';
import _ from 'lodash';
import React from 'react';

import apiClient from '@stargate/api';
import { SelectMenuItem } from '@stargate/components/FormFields';

import type { GitHubRepository } from '../../types';
import { GitHubRepositoryIcon } from '../Icons';

export interface GitHubRepositoryAutocompleteProps extends BaseProps {
  /**
   * The ref to the autocomplete element.
   */
  inputRef?: React.Ref<HTMLDivElement>;

  /**
   * If the autocomplete should open on focus.
   *
   * @default false
   */
  openOnFocus?: boolean;

  /**
   * The IDs of the repository owners to fetch.
   */
  repositoryOwnerIds?: Array<string | number>;

  /**
   * The default repository to select.
   */
  defaultValue?: GitHubRepository;

  /**
   * If the autocomplete is disabled.
   *
   * @default false
   */
  disabled?: boolean;

  /**
   * If the autocomplete should be full width.
   *
   * @default false
   */
  disableClearable?: boolean;

  /**
   * If the autocomplete should be full width.
   *
   * @default true
   */
  fullWidth?: boolean;

  /**
   * The helper text to display, if not provided, no helper text will be displayed.
   */
  helperText?: React.ReactNode;

  /**
   * Callback fired when the autocomplete is closed, except when the forceOpenState is true.
   */
  onClose?: () => void;

  /**
   * Callback fired when the autocomplete is opened, except when the forceOpenState is false.
   */
  onOpen?: () => void;

  /**
   * Callback fired when the selected value changes.
   */
  onChange: (repository: GitHubRepository | null) => void;
}

/**
 * Select a branch from a list of branches for a given repository.
 */
export const GitHubRepositoryAutocomplete = React.forwardRef<
  HTMLDivElement,
  GitHubRepositoryAutocompleteProps
>(
  (
    {
      fullWidth = true,
      defaultValue,
      disabled = false,
      disableClearable = false,
      helperText,
      onChange,
      openOnFocus = false,
      onOpen,
      onClose,
      inputRef: autoCompleteRef,
      ...props
    },
    ref
  ) => {
    const [ghRepoSearchState, ghRepoSearchActions] = apiClient.useRequestClient(
      'GET /github/repositories'
    );

    /*
    |------------------
    | State
    |------------------
    */

    const [open, setOpen] = React.useState(false);
    const [searchValue, setSearchValue] = React.useState('');
    const previousSearchValue = hookz.usePrevious(searchValue);
    const [selected, setSelected] = React.useState<GitHubRepository | null>(
      defaultValue ?? null
    );

    /*
    |------------------
    | Computed
    |------------------
    */

    // Convert the repository owner IDs to strings
    // & sort them to make sure it's consistent for comparison
    const repositoryOwnerIds = _.chain(props.repositoryOwnerIds ?? [])
      .map((id) => id.toString())
      .sort()
      .value();
    const previousOwnerIds = hookz.usePrevious(repositoryOwnerIds);

    const options: GitHubRepository[] = React.useMemo(() => {
      if (_.isNil(ghRepoSearchState.result)) {
        return [];
      }
      return ghRepoSearchState.result.map((repo) => ({
        id: repo.id.toString(),
        name: repo.name,
        defaultBranch: repo.defaultBranch,
        visibility: repo.visibility,
        owner: {
          login: repo.owner.login,
          name: repo.owner.name,
          id: repo.owner.id.toString(),
          type: repo.owner.type as 'organization',
        },
      }));
    }, [ghRepoSearchState.result]);

    /*
    |------------------
    | Handlers
    |------------------
    */

    const handleOpen = React.useCallback(() => {
      onOpen?.();
      setOpen(true);
      if (ghRepoSearchState.status === 'not-executed') {
        handleLoadData(null);
      }
    }, [onOpen, ghRepoSearchState.status]);

    const handleClose = React.useCallback(() => {
      onClose?.();
      setOpen(false);
    }, [onClose]);

    const handleLoadData = React.useCallback(
      (search: string | null): void => {
        void ghRepoSearchActions.execute({
          querystring: {
            ownerIds:
              repositoryOwnerIds.length > 0 ? repositoryOwnerIds : undefined,
            name: search ?? undefined,
          },
        });
      },

      [repositoryOwnerIds, ghRepoSearchActions]
    );

    const handleChange = React.useCallback(
      (
        _e: React.SyntheticEvent<Element, Event>,
        newValue: GitHubRepository | null,
        _reason: string
      ): void => {
        setSelected(newValue);
        onChange(newValue);
        setSearchValue('');
        handleLoadData(null);
      },
      [handleLoadData, onChange]
    );

    const handleInputChange = React.useCallback(
      (
        _e: React.SyntheticEvent<Element, Event>,
        newInputValue: string,
        reason: string
      ): void => {
        if (reason === 'input' && !_.isEmpty(newInputValue)) {
          setSearchValue(newInputValue);
        } else if (reason === 'clear' || _.isEmpty(newInputValue)) {
          setSearchValue('');
          handleLoadData(null);
        }
      },
      [handleLoadData]
    );

    /*
    |------------------
    | Effects
    |------------------
    */

    // Listen to changes to the search and load the data
    // from the API
    hookz.useDebouncedEffect(
      () => {
        if (
          ghRepoSearchState.status !== 'loading' &&
          !_.isNil(searchValue) &&
          searchValue.length > 1 &&
          searchValue !== previousSearchValue
        ) {
          handleLoadData(searchValue);
        }
      },
      [searchValue, ghRepoSearchState.status, handleLoadData],
      200,
      500
    );

    // We changed and we need to reset the selected value
    // if the repository owner ID changes
    React.useEffect(() => {
      if (
        !_.isEqual(repositoryOwnerIds, previousOwnerIds) &&
        repositoryOwnerIds.length > 0 &&
        !_.isNil(selected) &&
        !repositoryOwnerIds.includes(selected.owner.id.toString())
      ) {
        setSelected(null);
        onChange(null);
        setSearchValue('');
      }
    }, [repositoryOwnerIds, selected, onChange, previousOwnerIds]);

    // If the repository owner ID changes, we need to fetch the repos
    React.useEffect(() => {
      if (
        !_.isEqual(repositoryOwnerIds, previousOwnerIds) &&
        repositoryOwnerIds.length > 0
      ) {
        handleLoadData(null);
      }
    }, [previousOwnerIds, repositoryOwnerIds, handleLoadData]);

    // If the default repository changes, we need to update the selected value
    React.useEffect(() => {
      if (!_.isNil(defaultValue)) {
        setSelected((v) => {
          if (v?.id !== defaultValue.id) {
            return defaultValue;
          }
          return v;
        });
      }
    }, [defaultValue]);

    return (
      <FormControl
        {..._.omit(props, ['repositoryOwnerIds', 'forceOpenState'])}
        fullWidth={fullWidth}
        disabled={disabled}
        ref={ref}
      >
        <Autocomplete
          size='small'
          open={!disabled && open}
          value={selected}
          defaultValue={defaultValue}
          disabled={disabled}
          options={options}
          loading={ghRepoSearchState.status === 'loading'}
          slotProps={{
            paper: {
              elevation: 1,
            },
          }}
          noOptionsText={
            searchValue === ''
              ? 'Start typing to select a repository'
              : 'No GitHub repositories found'
          }
          // We have to disable so we can run the async fetch
          filterOptions={(x) => x}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={(option) => option.name}
          onOpen={handleOpen}
          onClose={handleClose}
          onChange={handleChange}
          onInputChange={handleInputChange}
          renderOption={(optionProps, option) => (
            <SelectMenuItem
              {...optionProps}
              key={option.id}
              icon={<GitHubRepositoryIcon />}
              label={option.name}
              value={option.id.toString()}
            />
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label='GitHub Repository'
              placeholder='Start typing to select a GitHub repository'
              inputRef={autoCompleteRef}
              slotProps={{
                input: {
                  ...params.InputProps,
                  startAdornment: !disabled ? (
                    <InputAdornment position='start'>
                      <GitHubRepositoryIcon />
                    </InputAdornment>
                  ) : null,
                },
              }}
            />
          )}
          disableClearable={disableClearable}
          autoHighlight
          openOnFocus={openOnFocus}
          autoComplete
          includeInputInList
        />
        {!_.isNil(helperText) && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    );
  }
);
GitHubRepositoryAutocomplete.displayName = 'GitHubRepositoryAutocomplete';

/*
|------------------
| Utils
|------------------
*/

type BaseProps = Omit<
  FormControlProps,
  'onChange' | 'defaultValue' | 'fullWidth'
>;
