import { Box, type SxProps } from '@mui/material';
import { SimpleTreeView, type SimpleTreeViewProps } from '@mui/x-tree-view';
import _ from 'lodash';
import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { createComponentClasses } from '@stargate/theme';
import useDirectoryTree from '../../hooks/use-directory-tree';
import type * as types from '../../types';
import { DirectoryTreeItem } from './DirectoryTreeItem';
import { DirectoryTreeItemEmpty } from './DirectoryTreeItemEmpty';
import { DirectoryTreeItemGap } from './DirectoryTreeItemGap';

/*
|==========================================================================
| DirectoryTree
|==========================================================================
|
| A tree view of the directory structure, used for navigation.
|
*/

export const directoryTreeViewClasses = createComponentClasses(
  'DirectoryTreeView',
  ['root', 'itemEmpty'] as const
);

export interface DirectoryTreeViewProps {
  maxWidth?: number;
  sx?: SxProps;
}

/**
 * Inner component, so the Provider is setup properly.
 */
const DirectoryTreeViewInner: React.FC<DirectoryTreeViewProps> = ({
  sx = {},
  maxWidth = 300,
}) => {
  const directoryTree = useDirectoryTree();
  const treeViewRef = React.useRef<HTMLUListElement>(null);

  const rootNodes = React.useMemo(
    () =>
      !_.isNil(directoryTree.tree)
        ? directoryTree.tree.filter((node) => _.isNil(node.parentId))
        : [],
    [directoryTree.tree]
  );

  /*
  |------------------
  | Callbacks
  |------------------
  */

  const handleNodeToggle = React.useCallback<
    Required<SimpleTreeViewProps<false>>['onExpandedItemsChange']
  >(
    (event, itemIds) => {
      directoryTree.onExpandNodes(itemIds);
    },

    []
  );

  const handleNodeSelect = React.useCallback<
    Required<SimpleTreeViewProps<false>>['onItemSelectionToggle']
  >(
    (_event, itemId) => {
      if (!_.isNil(directoryTree.tree)) {
        directoryTree.onItemSelectionToggle(itemId);
      }
    },
    [directoryTree]
  );

  const renderTreeNode = React.useCallback(
    (node: types.DirectoryTreeNode | null, first: boolean) => {
      if (_.isNil(node)) {
        return null;
      }

      const nodeChildren =
        'children' in node && _.isArray(node.children)
          ? node.children
              .map((child) =>
                directoryTree.tree?.find(
                  (treeNode) =>
                    treeNode.id === child.id &&
                    treeNode.nodeType === child.nodeType
                )
              )
              .filter((child) => !_.isNil(child))
          : [];

      return (
        <React.Fragment key={node.id}>
          {first && (
            <DirectoryTreeItemGap
              key={`${node.id}-gap-top`}
              parentNodeId={node.parentId ?? null}
              previousNodeId={null}
              itemId={`${node.id}-gap-top`}
            />
          )}
          <DirectoryTreeItem
            key={node.id}
            treeViewRef={
              treeViewRef as unknown as React.RefObject<HTMLUListElement>
            }
            node={node}
          >
            {node.nodeType === 'directory' && nodeChildren.length === 0 && (
              <DirectoryTreeItemEmpty
                treeViewRef={
                  treeViewRef as unknown as React.RefObject<HTMLUListElement>
                }
                directory={node}
              />
            )}
            {node.nodeType === 'directory' &&
              nodeChildren.map((childNode, index) =>
                renderTreeNode(childNode, index === 0)
              )}
          </DirectoryTreeItem>
          <DirectoryTreeItemGap
            key={`${node.id}-gap-bottom`}
            parentNodeId={node.parentId ?? null}
            previousNodeId={node.id}
            itemId={`${node.id}-gap-bottom`}
          />
        </React.Fragment>
      );
    },
    [directoryTree.tree]
  );

  if (_.isNil(directoryTree.tree)) {
    return null;
  }

  return (
    <Box
      sx={{ maxWidth, ...sx, position: 'relative' }}
      className={directoryTreeViewClasses.root}
    >
      <SimpleTreeView
        aria-label='document directory tree'
        expandedItems={directoryTree.expandedNodes}
        selectedItems={directoryTree.selectedNode}
        onExpandedItemsChange={handleNodeToggle}
        onItemSelectionToggle={handleNodeSelect}
        ref={treeViewRef}
        sx={{
          maxWidth: `${maxWidth}px`,
          overflowX: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          pb: '10px',
        }}
      >
        {rootNodes.map((node, index) => renderTreeNode(node, index === 0))}
      </SimpleTreeView>
    </Box>
  );
};

/**
 * Provider with custom options (scoped root element) for the DndProvider.
 *
 * This customization is necessary because the DndProvider disables native drop
 * events, which conflicts with ProseMirror editor's native drop handling.
 * By modifying adding a scoped rootElement, we ensure that native drop events within
 * ProseMirror (and others) are not intercepted by React DnD, allowing ProseMirror (and others) to handle
 * these events correctly.
 *
 * @see https://github.com/outline/outline/pull/1918/files
 * @see https://github.com/react-dnd/react-dnd/issues/802
 */
export const DirectoryTreeView: React.FC<DirectoryTreeViewProps> = (props) => {
  const [dndArea, setDndArea] = React.useState<HTMLSpanElement | null>(null);
  const handleSidebarRef: React.RefCallback<HTMLSpanElement> =
    React.useCallback((node) => {
      setDndArea(node);
    }, []);
  const html5Options = React.useMemo(
    () => ({ rootElement: dndArea }),
    [dndArea]
  );
  return (
    <Box ref={handleSidebarRef} component='span'>
      {!_.isNil(dndArea) && (
        <DndProvider debugMode backend={HTML5Backend} options={html5Options}>
          <DirectoryTreeViewInner {...props} />
        </DndProvider>
      )}
    </Box>
  );
};

export default DirectoryTreeView;
