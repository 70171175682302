import React from 'react';

import { Grid, useTheme } from '@mui/material';

/*
|==========================================================================
| SettingsActionCards
|==========================================================================
|
| Provide Settings Action Cards in a list/group such as Configuration, Security, and more. 
|
*/

export interface SettingsActionCardsProps {
  children: React.ReactNode;
}

export const SettingsActionCards: React.FC<SettingsActionCardsProps> = (
  props
) => {
  const theme = useTheme();
  return (
    <Grid
      container
      spacing={0}
      direction='row'
      justifyContent='stretch'
      alignItems='stretch'
      sx={{
        ml: -1,
        width: `calc(100% + ${theme.spacing(1)})`,
        p: 0,
      }}
    >
      {props.children}
    </Grid>
  );
};

export default SettingsActionCards;
