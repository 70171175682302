import React from 'react';

import {
  Box,
  CircularProgress,
  type CircularProgressProps,
  useTheme,
} from '@mui/material';

import { ImgLogo } from '@stargate/components/Images';

export interface LoadingLogoProps {
  loading?: boolean;
  size?: 'small' | 'medium' | 'large';
  containerProps?: React.ComponentProps<typeof Box>;
  circularProgressProps?: CircularProgressProps;
}

export const LoadingLogo: React.FC<LoadingLogoProps> = ({
  loading = true,
  size = 'medium',
  ...props
}) => {
  const theme = useTheme();

  /*
  |------------------
  | Computed
  |------------------
  */

  const logoSize = React.useMemo(() => {
    switch (size) {
      case 'small':
        return 6;
      case 'large':
        return 10;
      default:
        return 8;
    }
  }, [size]);

  const gutter = React.useMemo(() => {
    return 1;
  }, []);

  return (
    <Box
      {...props.containerProps}
      sx={{
        ...props.containerProps?.sx,
        position: 'relative',
        p: gutter,
        width: (theme) => theme.spacing(logoSize + gutter * 2),
        height: (theme) => theme.spacing(logoSize + gutter * 2),
      }}
    >
      <Box
        sx={{
          display: 'block',
          width: (theme) => theme.spacing(logoSize),
          height: (theme) => theme.spacing(logoSize),
        }}
      >
        <ImgLogo logo='icon' shape='circle' />
      </Box>
      {loading && (
        <CircularProgress
          {...props.circularProgressProps}
          size={theme.spacing(logoSize + gutter)}
          sx={{
            color: (theme) => theme.palette.primary.main,
            position: 'absolute',
            top: (theme) => `${theme.spacing(gutter / 2)}`,
            left: (theme) => theme.spacing(gutter / 2),
            zIndex: 1,
          }}
        />
      )}
    </Box>
  );
};

export default LoadingLogo;
