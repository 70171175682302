import { Box } from '@mui/material';
import { Node as ProseMirrorNode } from '@tiptap/pm/model';
import { NodeViewContent, NodeViewWrapper } from '@tiptap/react';
import React from 'react';

import type { CodeMirrorLang } from '@stargate/lib/codemirror';

import useCodeBlockExit from '../hooks/use-codeblock-exit';
import type { CodeBlockNodeViewProps } from '../types';
import { CodeBlockBasic } from './CodeBlockBasic';
import { CodeBlockMermaid } from './CodeBlockMermaid';
import { CodeBlockSnippet } from './CodeBlockSnippet';
import { CodeBlockError } from './shared/CodeBlockError';

/**
 * NodeView for the "codeblock" extension.
 */
export const CodeBlock = React.memo<CodeBlockNodeViewProps>(
  ({ node, deleteNode, selected, getPos, editor, updateAttributes }) => {
    const codeBlockExitProps = useCodeBlockExit({
      editor,
      getPos,
      size: node.nodeSize,
    });

    /*
    |------------------
    | Computed
    |------------------
    */

    const readonly = React.useMemo(
      () => !editor.isEditable,
      [editor.isEditable]
    );

    /*
    |------------------
    | Callbacks
    |------------------
    */

    const handleLanguageChange = React.useCallback(
      (language: CodeMirrorLang) => {
        if (!readonly) {
          updateAttributes({
            language,
          });
        }
      },
      [updateAttributes, readonly]
    );

    const handleDelete = React.useCallback(() => {
      if (!readonly) {
        deleteNode();
      }
    }, [deleteNode, readonly]);

    const handleCodeChange = React.useCallback(
      (code: string) => {
        if (readonly) {
          return;
        }

        queueMicrotask(() => {
          const thisPos = getPos();
          editor
            .chain()
            .setNodeSelection(thisPos)
            .command(({ tr }) => {
              const newNode = ProseMirrorNode.fromJSON(editor.schema, {
                type: node.type.name,
                attrs: {
                  ...node.attrs,
                  codeSnippetId: node.attrs.codeSnippetId,
                  placeholder: false,
                },
                content: [
                  {
                    type: 'text',
                    text: code,
                  },
                ],
              });
              tr.replaceSelectionWith(newNode);
              return true;
            })
            .run();
        });
      },
      [editor, getPos, node.attrs, node.type.name, readonly]
    );

    /*
    |------------------
    | Component Switch
    |------------------
    */

    const renderCodeBlock = React.useMemo(() => {
      switch (node.attrs.type) {
        case 'mermaid':
          return (
            <CodeBlockMermaid
              code={node.textContent}
              readonly={readonly}
              onChangeCode={handleCodeChange}
              onDelete={handleDelete}
            />
          );
        case 'basic':
          return (
            <CodeBlockBasic
              selected={selected}
              language={node.attrs.language}
              code={node.textContent}
              readonly={readonly}
              onChangeLanguage={handleLanguageChange}
              onChangeCode={handleCodeChange}
              onDelete={handleDelete}
              onKeyUp={codeBlockExitProps.onKeyUp}
              getPos={getPos}
              updateAttributes={updateAttributes}
            />
          );
        case 'snippet':
          return (
            <CodeBlockSnippet
              selected={selected}
              codeSnippetId={node.attrs.codeSnippetId}
              placeholder={node.attrs.placeholder}
              language={node.attrs.language}
              readonly={readonly}
              code={node.textContent}
              onDelete={handleDelete}
              onKeyUp={codeBlockExitProps.onKeyUp}
              onChangeLanguage={handleLanguageChange}
              onChangeCode={handleCodeChange}
              getPos={getPos}
              updateAttributes={updateAttributes}
            />
          );
        default:
          return (
            <CodeBlockError
              message='Invalid code block type, please delete and re-add the code block.'
              onDelete={handleDelete}
            />
          );
      }
    }, [
      node.attrs,
      getPos,
      selected,
      node.textContent,
      handleCodeChange,
      readonly,
      handleLanguageChange,
      handleDelete,
      updateAttributes,
      codeBlockExitProps.onKeyUp,
    ]);

    return (
      <NodeViewWrapper
        className='dashdraft-codeblock'
        // @ts-expect-error - valid
        data-uid={node.attrs.uid}
      >
        <Box contentEditable={false} className='dashdraft-codeblock-container'>
          <Box contentEditable={false} className='dashdraft-codeblock-content'>
            {renderCodeBlock}
          </Box>
          <NodeViewContent
            as='span'
            className='dashdraft-codeblock-NodeViewContent'
          />
        </Box>
      </NodeViewWrapper>
    );
  }
);
CodeBlock.displayName = 'CodeBlock';
