import { Skeleton } from '@mui/material';
import * as hookz from '@react-hookz/web';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';
import React from 'react';

import { jdocAncestorsQueryOptions } from '@stargate/api';
import {
  DirectoryBreadCrumbs,
  DirectoryBreadCrumbsContainer,
  type DirectoryTree,
} from '@stargate/features/directories';
import { useDirectoryTree } from '@stargate/features/directories';
import { useLocalization } from '@stargate/localization';
import { useSearchParams } from '@stargate/routes';

import { useJDocDraftMutate } from '@/features/docs/hooks/use-jdoc-draft-mutate';
import type { JDocAncestor, JDocComponentProps } from '@/features/docs/types';
import { JoggrDocBreadcrumbsDialog } from './JoggrDocBreadcrumbsDialog';

export type JoggrDocBreadcrumbsProps = JDocComponentProps;

export const JoggrDocBreadcrumbs: React.FC<JoggrDocBreadcrumbsProps> = ({
  doc,
  draft,
  mode,
}) => {
  const localz = useLocalization();
  const jdocMutateDraft = useJDocDraftMutate();
  const directoryTree = useDirectoryTree();
  const [modifying, setModifying] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [, , removeSearchParams] = useSearchParams('app.documents.create');

  /*
  |------------------
  | Queries & Mutations
  |------------------
  */

  const queryClient = useQueryClient();
  const readQuery = useQuery({
    ...jdocAncestorsQueryOptions(doc?.id),
    initialData: [],
    enabled: mode === 'view' || mode === 'edit',
  });

  /*
  |------------------
  | Computed
  |------------------
  */

  const data = React.useMemo(() => {
    if (mode === 'create') {
      return getAncestors(directoryTree.tree ?? [], draft?.dirId ?? undefined);
    }

    // We return empty if it's a placeholder data
    if (readQuery.isPlaceholderData) {
      return [];
    }

    if (readQuery.data.length === 0) {
      return [rootWithTitle];
    }

    return buildAncestors(readQuery.data);
  }, [readQuery, directoryTree.tree, mode, draft?.dirId]);

  const dirId = React.useMemo(() => {
    return _.last(data)?.id ?? null;
  }, [data]);

  const tooltip = React.useMemo(() => {
    if (mode === 'view') {
      return undefined;
    }

    if (!_.isNil(doc?.baseDocumentId)) {
      return 'You cannot move a JoggrDoc that is a version of another JoggrDoc, please move the parent JoggrDoc if you want to move it.';
    }

    return localz.formatMessage(`features.docs.${mode}.breadcrumbs.tooltip`);
  }, [mode, localz, doc?.baseDocumentId]);

  const openDialog = React.useMemo(() => {
    if (mode === 'view' || !_.isNil(doc?.baseDocumentId)) {
      return undefined;
    }

    return () => {
      setOpen(true);
    };
  }, [mode, doc?.baseDocumentId]);

  /*
  |------------------
  | Callbacks
  |------------------
  */

  // biome-ignore lint/correctness/useExhaustiveDependencies: ignore params
  const handleSubmit = React.useCallback(
    (newDirectoryId?: string | null) => {
      setOpen(false);
      if (mode === 'edit' && !_.isNil(doc?.id)) {
        setModifying(true);
        directoryTree
          .onMoveDirectoryDocument({
            fromDirectoryId: dirId || null,
            toDirectoryId: newDirectoryId || null,
            documentId: doc.id,
          })
          .then(() => {
            void directoryTree.onLoad();
            setModifying(false);
          });
      } else if (mode === 'create') {
        setModifying(true);
        jdocMutateDraft.update({
          dir: newDirectoryId,
        });
        removeSearchParams('dir');
        setModifying(false);
      }
    },

    [doc?.id, mode, dirId, jdocMutateDraft.update, directoryTree]
  );

  /*
  |------------------
  | Effects
  |------------------
  */

  // If we reload the directory that means we need to reload the ancestors
  // as we probably have new data
  const previousDirTreeLoading = hookz.usePrevious(directoryTree.loading);
  React.useEffect(() => {
    if (
      directoryTree.loading &&
      !previousDirTreeLoading &&
      readQuery.isFetched
    ) {
      queryClient.invalidateQueries({
        queryKey: jdocAncestorsQueryOptions(doc?.id).queryKey,
      });
    }
  }, [
    directoryTree.loading,
    previousDirTreeLoading,
    readQuery.isFetched,
    doc?.id,
    queryClient,
  ]);

  // If the directory changes, we need to keep in sync with the draft
  // if we are in create mode ONLY
  React.useEffect(() => {
    if (
      dirId !== draft?.dirId &&
      dirId !== 'root' &&
      !_.isNil(dirId) &&
      _.isNil(draft?.dirId) &&
      mode === 'create'
    ) {
      jdocMutateDraft.update({
        dir: dirId,
      });
      removeSearchParams('dir');
    }
  }, [draft?.dirId, dirId, mode, jdocMutateDraft.update, removeSearchParams]);

  if (
    directoryTree.loading ||
    readQuery.isLoading ||
    readQuery.isPlaceholderData ||
    data.length === 0 ||
    modifying
  ) {
    return <JoggrDocBreadcrumbsSkeleton />;
  }

  return (
    <React.Fragment>
      <DirectoryBreadCrumbs
        directoryBreadCrumbs={data}
        mode={data.length > 2 ? 'first-and-last' : 'all'}
        onClick={openDialog}
        tooltip={tooltip}
      />
      {mode !== 'view' && (
        <JoggrDocBreadcrumbsDialog
          open={open}
          mode={mode}
          tree={directoryTree.tree}
          defaultDirectoryId={dirId}
          onClose={() => {
            setOpen(false);
          }}
          onConfirm={handleSubmit}
        />
      )}
    </React.Fragment>
  );
};

/*
|------------------
| Utils
|------------------
*/

const JoggrDocBreadcrumbsSkeleton: React.FC = () => {
  return (
    <DirectoryBreadCrumbsContainer>
      <Skeleton variant='rounded' width={40} height={30} />
      <Skeleton variant='rounded' width={40} height={30} />
    </DirectoryBreadCrumbsContainer>
  );
};

/**
 * Stub for the root directory.
 */
const root = {
  id: 'root',
  title: '',
  icon: 'home',
  iconType: 'riker',
  parentId: undefined,
} as const;

/**
 * Stub for the root directory, with the title.
 */
const rootWithTitle = {
  ...root,
  title: '<root>',
};

/**
 * Get the ancestors of a document.
 *
 * @param tree A directory tree.
 * @param dirId The ID of the desired dir.
 * @returns An array of ancestors.
 */
const getAncestors = (
  tree: DirectoryTree,
  dirId?: string
): Array<JDocAncestor> => {
  if (_.isNil(dirId)) {
    return [rootWithTitle];
  }

  const foundItem = tree.find((node) => node.id === dirId);
  if (_.isNil(foundItem)) {
    return [rootWithTitle];
  }
  let currentItem = foundItem;
  const ancestors: Array<JDocAncestor> = [foundItem];
  while (!_.isNil(currentItem)) {
    const parentItem = !_.isNil(currentItem.parentId)
      ? tree.find((node) => node.id === currentItem.parentId)
      : null;
    if (!_.isNil(parentItem)) {
      ancestors.unshift(parentItem);
      currentItem = parentItem;
    } else {
      break;
    }
  }

  return buildAncestors([...ancestors]);
};

/**
 * Builds a list of ancestors, with proper ids (including root).
 *
 * @param ancestors A list of ancestors.
 * @returns A list of ancestors, with proper ids (including root).
 */
const buildAncestors = (ancestors: JDocAncestor[]): JDocAncestor[] => {
  return _.map([root, ...ancestors], (ancestor) => {
    if (ancestor.id !== root.id && _.isNil(ancestor.parentId)) {
      return {
        id: ancestor.id,
        title: ancestor.title,
        icon: ancestor.icon,
        iconType: ancestor.iconType,
        parentId: root.id,
      };
    }

    return {
      id: ancestor.id,
      title: ancestor.title,
      icon: ancestor.icon,
      iconType: ancestor.iconType,
      parentId: ancestor.parentId,
    };
  });
};
