import React from 'react';

import { coalesce } from '@stargate/utils/data';

/*
|==========================================================================
| helpers
|==========================================================================
|
| A set of helpful hooks and utilities for working with React components, APIs
| data, and other parts of the application.
|
*/

/**
 * Coalesce a list of values, returning the first non-null value.
 */
export const useCoalesce = <T>(...args: T[]): T | null => {
  return React.useMemo(() => {
    return coalesce(...args);
  }, [args]);
};
